
import styled from 'styled-components';
import React,{ useState } from 'react';
import LogoIL from "assets/images/logo_full.png";


const splitText = (text) => {
    const [boldText, normalText] = text.split(':');
    return (
      <>
        <strong>{boldText}:</strong>{normalText}
      </>
    );
  };

  const HockeyCustom = ({language}) =>{
    const t = translations[language];

    return (
        <>
        <LogoArea>
        <Logo2 src={LogoIL} />
        </LogoArea>
       
        <Text>
        <Logo src="https://firebasestorage.googleapis.com/v0/b/charactercode-a247a.appspot.com/o/email%2FEN%20FLAT%20CMYK%403x.jpg?alt=media&token=237b7ad3-3070-4c63-9978-9fbec1194e97" />
             <h1>{t.welcome}</h1>
      <h2>{t.subtitle}</h2>
      <p>{t.intro}</p>
      <h3>{t.whatYouNeedToKnow}</h3>
      <ul>
        <li>{splitText(t.timeCommitment)}</li>
        <li>{splitText(t.honesty)}</li>
        <li>{splitText(t.anonymity)}</li>
        <li>{splitText(t.dataUsage)}</li>
        <li>{splitText(t.interviewOpportunity)}</li>
      </ul>
      <h3>{t.beforeYouBegin}</h3>
      <ul>
        <li>{t.quietTime}</li>
        <li>{t.candidResponses}</li>
      </ul>
      <p>{t.questions}</p>
      <ul>
        <li>{t.tollFree}</li>
        <li>{t.email} <a href="mailto:info@sea-hockey-ems.ca">info@sea-hockey-ems.ca</a></li>
      </ul>
      <p>{t.moreInfo} <a href="http://www.sea-hockey-ems.ca" target="_blank">www.sea-hockey-ems.ca</a>.</p>
        
        <p><i><strong>{t.strongNotice}</strong> {t.important} <a href="https://sportintegritycommissioner.ca/complaints-reports" target="_blank">{language=="EN"?"website":"site web"}</a>.</i></p>
        </Text>
        </>
    )
}
export default HockeyCustom

const Text = styled.div`
font-size: 16px;
line-height: 1.6;
color: #2A3039;
white-space: pre-wrap;
font-family: 'Raleway', sans-serif;
background-color: white;
padding:20px;
border-radius:20px;

padding-bottom:40px;


h1 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0.5em;
}

h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0.5em;
}
li {
    maregin-bottom: 0.5em;
}

`

const Logo2 = styled.img`
    width:150px;
`

const LogoArea = styled.div`
    display:flex;
    justify-content:center;
    margin-bottom:20px;
    width:100%;
`

const Image = styled.img`
    width:162px;
    display: inline;
    vertical-align: middle;
    margin-left: 0.25em;
    margin-right: 0.25em;
`

const InlineWrapper = styled.span`
  display: inline-flex;
  align-items: center;
`;

const Highlight = styled.span`
background: #EBF1FD;
border-radius: 40px;
padding:0px 16px;
margin:0px -8px;

`

const Bold = styled.span`
    font-weight:600;
    color:#2D70E2;
`
const Logo = styled.img`
    width:300px;
`


const translations = {
  EN: {
    welcome: "Welcome to the Sport Environment Assessment Survey",
    subtitle: "Hockey Culture Index – Member Branches Survey",
    intro: "Thank you for participating in this important initiative. Your experience and knowledge are valuable to our efforts in assessing the environment and culture of Hockey Canada and hockey in Canada.",
    whatYouNeedToKnow: "What You Need to Know:",
    timeCommitment: "Time Commitment: This survey will take approximately 15 minutes to complete.",
    honesty: "Honesty is Key: There are no right or wrong answers – we're seeking your genuine perspectives.",
    anonymity: "Complete Anonymity: Your responses are anonymous. We do not collect personally identifiable information to help ensure your privacy is protected.",
    dataUsage: "Data usage: the information gathered from this survey will be used to understand the current organizational environment and culture within your hockey organization and identify areas to improve the sport environment.",
    interviewOpportunity: "Post-Survey Interview Opportunity: After completing the survey, you can sign up for an interview. This is entirely voluntary and separate from your survey responses, maintaining your anonymity.",
    beforeYouBegin: "Before You Begin:",
    quietTime: "Ensure you have uninterrupted quiet time to complete the survey.",
    candidResponses: "Feel free to be open and candid in your responses.",
    questions: "If you have any questions, please don't hesitate to reach out to the SEA Team:",
    tollFree: "Toll-free: 1-833-974-1281",
    email: "Email:",
    moreInfo: "For more information about the Sport Environment Assessment, visit",
    nextButton: "Next",
    strongNotice: "Important Notice:",
    important:"This survey is not intended for filing complaints or reports about maltreatment in sport. If you need to submit such a complaint or report, please visit the Office of the Sport Integrity Commissioner (OSIC)"
  },
  FR: {
    welcome: "Bienvenue à l'enquête d'évaluation de l'environnement sportif",
    subtitle: "Indice de la culture du hockey - Enquête des Membres",
    intro: "Merci de participer à cette initiative importante. Votre expérience et vos connaissances sont précieuses pour nos efforts d'évaluation de l'environnement et de la culture de Hockey Canada et du hockey au Canada.",
    whatYouNeedToKnow: "Ce que vous devez savoir :",
    timeCommitment: "Temps nécessaire : ce sondage prendra environ 15 minutes à remplir.",
    honesty: "L'honnêteté est essentielle : il n'y a pas de bonnes ou de mauvaises réponses - nous recherchons vos perspectives authentiques.",
    anonymity: "Anonymat complet : Vos réponses sont anonymes. Nous ne collectons pas d'informations personnellement identifiables afin de garantir la protection de votre vie privée.",
    dataUsage: "Utilisation des données : Les informations recueillies à partir de cette enquête seront utilisées pour comprendre l'environnement organisationnel et culturel actuel au sein de votre organisation de hockey et pour identifier les domaines à améliorer dans l'environnement sportif.",
    interviewOpportunity: "Opportunité d'entrevue après le sondage : Après avoir terminé le sondage, vous pouvez vous inscrire pour une entrevue. Cela est entièrement volontaire et séparé de vos réponses au sondage, maintenant ainsi votre anonymat.",
    beforeYouBegin: "Avant de commencer :",
    quietTime: "Assurez-vous d'avoir du temps calme sans interruption pour compléter le sondage.",
    candidResponses: "N'hésitez pas à être ouvert et sincère dans vos réponses.",
    questions: "Si vous avez des questions, n'hésitez pas à contacter l'équipe SEA :",
    tollFree: "Sans frais : 1-833-974-1281",
    email: "Courriel :",
    moreInfo: "Pour plus d'informations sur l'évaluation de l'environnement sportif, visitez",
    nextButton: "Suivant",
    strongNotice: "Avis Important :",
    important:"Ce sondage n'est pas destiné à déposer des plaintes ou des rapports sur des mauvais traitements dans le sport. Si vous devez soumettre une telle plainte ou un tel rapport, veuillez visiter le Bureau du commissaire à l'intégrité du sport (BCIS)"
  }
};