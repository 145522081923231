import React from 'react';
import styled from 'styled-components';
import {words} from '../translations'

const ProgressBar = ({ currentQuestion, totalQuestions, quesId,language,color,color2 }) => {
  const progressPercentage = (currentQuestion / totalQuestions) * 100;
  const isLocalhost = window.location.hostname === 'localhost';

  return (
    <ProgressBarContainer color2={color2}>
      <ProgressText>
        {/* <span style={{ color: '#2A3039', fontWeight: 600 }}>{currentQuestion}</span> {words['of'][language]} {totalQuestions} */}
        {isLocalhost && <>ID# ({quesId} for debugging) </>}
      </ProgressText>
      <FilledBar style={{ width: `${progressPercentage}%` }} color={color} />
    </ProgressBarContainer>
  );
};

export default ProgressBar;

const ProgressBarContainer = styled.div`
  width: calc(100vw - 40px);
  margin-left:20px;
  margin-top:50px;
  height: 6px;
  background-color: ${props=>props.color2?props.color2:'#D5E2F9'};
  position: fixed;
  border-radius: 20px;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
`;

const FilledBar = styled.div`
  height: 100%;
  background-color: ${props=>props.color?props.color:'#2D70E2'};
  transition: width 300ms;
  border-radius: 20px;
`;

const ProgressText = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #666D79;
  top:-30px;
  font-size: 14px;
  font-weight: 400;
`;