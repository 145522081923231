import React from "react";

const Privacy = () =>{

	return (
		<div style={{padding:20}}>
			<h3>Innerlogic Privacy Statement</h3>

			<p>Innerlogic (“Innerlogic”, “we”, “us” or “our”) takes steps intended to meet privacy principles and 
			requirements with respect to personal information under applicable Canadian privacy legislation. 
			The purpose of this statement is to inform our customers and other individuals we deal with 
			(“you” or “your”) how we collect, use, disclose and protect your personal information. Personal 
			information is information about an identifiable individual, as more particularly described 
			under applicable privacy legislation. This statement applies to our collection, use and 
			disclosure of personal information in Canada. This statement does not apply to information 
			about our employees or to information that is not personal information.</p>

			<h3>Data Controller and Data Processor</h3>

			<p>Innerlogic’s business customers are the data controllers for most of the information that is 
			entered into the Innerlogic web application, website, and supporting systems or that is shared 
			periodically with Innerlogic employees to deliver services. This positions Innerlogic as the data 
			processor for most information stored and processed by Innerlogic. There are some pieces of 
			information that are collected directly by Innerlogic to facilitate security, logging, and 
			application performance. These items include IP address and behavior within the Innerlogic 
			platform. For these pieces of information Innerlogic acts as the data controller and processor. 
			Additionally, Innerlogic employs a variety of technologies and partners that periodically act 
			as sub-processors. If users have any questions or concerns about the processing and handling 
			of their personal information, they may reach out to Innerlogic directly by email at mike@Innerlogic.com.
			</p>

			<h3>Personal Information We Collect</h3>

			<p>Innerlogic and its agents and representatives collect personal information in a number 
			of circumstances in the course of assisting organizations in their mandate to analyze, 
			improve and track diversity and inclusion in the workplace. Personal information we collect includes:
			</p>

			<ul>
				<li>demographic characteristics, including but not limited to gender, ethnicity, sexual identity, and disability </li>
				<li>details on role/position in your sport</li>
				<li>sentiment around personal experiences of safety, mental and physical health, leadership, coaching, the training environment, sport science, and other areas in relation to the culture of your sport organization </li>
				<li>such other information we may collect with your consent or as permitted or required by law.</li>
			</ul>

			<p>All personal information above is collected with explicit consent in the form of an opt-in box prior to any 
			disclosure of information . Consent is digitally recorded and logged, including IP address and date of 
			consent. See section on Consent for more information.  </p>

			<h3>Use of Personal Information</h3>	
			<p>Innerlogic generally uses personal information for the following purposes: </p>

			<ul>
				<li>provide your sport organization or community with aggregated insights on culture in their organization  </li>
				<li>track progress on culture in your sport organization or community  </li>
				<li>create role-specific culture benchmarks  </li>
				<li>managing, administering, collecting or otherwise enforcing accounts </li>
				<li>maintaining business records for reasonable periods, and generally managing and administering our business including defending and bringing legal actions;</li>
				<li>meeting legal, regulatory, insurance, security and processing requirements; and</li>
				<li>otherwise with consent or as permitted or required by law</li>
			</ul>

			<h3>Mode, Place, and Methods of Processing the Data</h3>

			<p>Innerlogic takes appropriate security measures to prevent unauthorized access, disclosure, modification, or data destruction.</p>
			<p>Data is processed using computers or tech-enabled tools, following organizational policies and procedures strictly related to the purposes indicated. 
			In some cases, data may be accessible to Innerlogic employees involved with the Innerlogic website’s operation, the Innerlogic web application (platform), 
			and supporting applications. Data may also be accessible to external parties appointed, if necessary, as data processors
			 or sub-processors by Innerlogic. External parties may include third-party technical service providers, hosting providers,
			  and IT companies.</p>

			<h3>Legal Basis of Processing</h3>

<p>Innerlogic may process personal data relating to users if one of the following applies:</p>

<ul><li>Users have given their consent for one or more specific purposes.</li>
<li>Provision of data is necessary for the performance of an agreement with the user.</li>
<li>Processing is necessary for compliance with a legal obligation.</li>
</ul>



<p>In any case, Innerlogic will gladly help clarify the specific legal basis that applies to the processing, mainly whether the provision of personal data is a statutory or contractual requirement or a requirement necessary to enter into a contract.</p>



<p><strong>Your Consent</strong></p>



<p>Consent to the collection, use and disclosure of personal information may be given in various ways. Consent can be express (for example, orally, electronically or on a form you may sign describing the intended uses and disclosures of personal information) or implied (for example, when you provide information necessary for a service you have requested). You may provide your consent in some circumstances where notice has been provided to you about our intentions with respect to your personal information and you have not withdrawn your consent for an identified purpose, such as by using an “opt out” option provided, if any. Consent may be given by your authorized representative (such as a legal guardian or a person having a power of attorney). Generally, by providing us with personal information, we will assume that you consent to our collection, use and disclosure of such information for the purposes identified or described in this privacy statement, if applicable, or otherwise at the time of collection.</p>



<p>You may withdraw your consent to our collection, use and disclosure of personal information at any time, subject to contractual and legal restrictions and reasonable notice. Note that if you withdraw your consent to certain uses of your personal information, we may no longer be able to provide certain of our products or services to your employer or community. Note also that where we have provided or are providing services to you, your consent will be valid for so long as necessary to fulfil the purposes described in this Privacy Statement or otherwise at the time of collection, and you may not be permitted to withdraw consent to certain necessary uses and disclosures (for example, but not limited to, maintaining reasonable business and transaction records, disclosures to Canadian and foreign government entities as required to comply with laws, and reporting on credit information after credit has been granted, if applicable).</p>



<p>Innerlogic collects, uses and discloses your personal information with your consent, except as permitted or required by law. We may be required or permitted under statute or regulation to collect, use or disclose personal information without your consent, for example to comply with a court order, to comply with local or federal regulations or a legally permitted inquiry by a government agency, or to collect a debt owed to us.</p>



<p><strong>Security</strong></p>



<p>We take reasonable steps to protect your personal information using physical, electronic or procedural security measures appropriate to the sensitivity of the information in our custody or control, which may include safeguards to protect against loss or theft, as well as unauthorized access, disclosure, copying, use or modification. Authorized employees, agents and mandataries of Innerlogic who require access to your personal information in order to fulfil their job requirements will have access to your personal information.</p>



<p><strong>Retention Time</strong></p>



<p>Personal data is processed and stored for as long as required to fulfill the purpose for which it is collected.</p>



<p>Therefore:</p>



<ul><li>Personal data collected for the performance of a contract between Innerlogic and a business customer is retained until such contract has been entirely performed or the business customer asks for the data to be deleted.</li><li>Personal data collected for Innerlogic’s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding Innerlogic’s legitimate interests within the relevant sections of this document or by contacting Innerlogic.</li></ul>



<p>Innerlogic may be allowed to retain personal information for a more extended period whenever the user has given consent to such processing, as long as such consent is not withdrawn. Furthermore, Innerlogic may be obliged to retain personal data for a more extended period whenever required to perform a legal obligation or upon order of an authority.</p>



<p>Once the retention period expires, the user’s personal data will be securely deleted.</p>



<p><strong>The Purposes of Processing</strong></p>



<p>The data concerning the user is collected to allow Innerlogic to provide its services, as well as for the following purposes: analytics, user database management, managing contacts and sending messages, handling payments, interaction with external social networks and platforms, remarketing and behavioral targeting, contacting the user, displaying content from external platforms, hosting and backend infrastructure, interaction with live chat platforms, and spam protection.</p>



<p>Users can find further detailed information about such purposes of processing and the specific personal data used for each purpose in the respective sections of this document.</p>



<p><strong>Detailed Information on the Processing of Personal Data</strong></p>



<p>Personal data is collected for the following purposes and using the following services and third parties:</p>



<p><strong>Analytics</strong></p>



<p>The services contained in this section enable Innerlogic to monitor and analyze web traffic and can be used to keep track of user behavior.</p>



<p><strong>HubSpot Analytics (HubSpot, Inc.)</strong></p>



<p>HubSpot Analytics is an analytics service provided by HubSpot, Inc.</p>



<p>Personal data collected: cookies and usage data.</p>



<p>Place of processing: US – <a href="https://www.hubspot.com/legal/privacy-policy" target="_blank" rel="noreferrer noopener">Privacy Policy</a></p>



<p><strong>Google Analytics (Google Inc.)</strong></p>



<p>Google Analytics is a web analysis service provided by Google Inc. (“Google”). Google utilizes the data collected to track and examine the use of this application, to prepare reports on its activities, and to share the reports with other Google services.</p>



<p>Google may use the data collected to contextualize and personalize the ads of its own advertising network.</p>



<p>Personal data collected: cookies and usage data.</p>



<p>Place of processing: US – <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank" rel="noreferrer noopener">Privacy Policy</a></p>



<p><strong>Contacting the User</strong></p>



<p><strong>Contact Form (The Innerlogic Web Application)</strong></p>



<p>By filling in the contact form with their data, users authorize the Innerlogic web application to use these details to reply to requests for information, quotes, or any other kind of request as indicated by the form’s header.</p>



<p>Personal data collected: address, city, company name, country, email address, first name, last name, phone number, job role, province, state, and ZIP/Postal code.</p>



<p><strong>Displaying Content from External Platforms</strong></p>



<p>This type of service allows users to view content hosted on external platforms directly from the pages of the Innerlogic web application and interact with them.</p>



<p>This type of service might still collect web traffic data for the pages where the service is installed, even when users do not use it.</p>



<p><strong>Google Fonts (Google Inc.)</strong></p>



<p>Google Fonts is a typeface visualization service provided by Google Inc. that allows this Application to incorporate content of this kind on its pages.</p>



<p>Personal data collected: usage data and various types of data as specified in the service’s privacy policy.</p>



<p>Place of processing: US – <a href="https://www.google.it/intl/policies/privacy/" target="_blank" rel="noreferrer noopener">Privacy Policy</a>. Privacy Shield participant.</p>



<p><strong>Hosting and Back-End Infrastructure</strong></p>



<p>This type of service has the purpose of hosting data and files that enable the Innerlogic website and the Innerlogic web application to run and be distributed. Additionally, these services provide the infrastructure to run specific features or parts of the application. Some of these services work through geographically distributed servers, making it difficult to determine the actual location where the personal data is stored.</p>



<p><strong>Linode Web serivces</strong></p>



<p>Linode Web Services is a hosting and backend service provided by Linode LLC.</p>



<p>Personal data collected: various types of data as specified in the privacy policy of the service.</p>



<p>Place of processing: See the Linode privacy policy – <a href="https://www.linode.com/legal-privacy/">Privacy Policy</a>.</p>



<p><strong>Postmark</strong></p>



<p>Postmark is an email delivery service.</p>



<p>Personal data collected: various types of data as specified in the privacy policy of the service.</p>



<p>Place of processing: See the Postmark privacy policy – <a href="https://wildbit.com/privacy-policy">Privacy Policy</a>.</p>



<p><strong>Managing Contacts and Sending Messages</strong></p>



<p>This type of service makes it possible to manage a database of email contacts, phone contacts, or any other contact information to communicate with the user.</p>



<p>These services may also collect data concerning the date and time when the message was viewed by the user and when the user interacted with it, such as by clicking on links included in the message.</p>



<p><strong>HubSpot Email (HubSpot, Inc.)</strong></p>



<p>HubSpot Email is an email address management and message sending service provided by HubSpot, Inc.</p>



<p>Personal data collected: email address and usage data.</p>



<p>Place of processing: US – <a href="https://legal.hubspot.com/privacy-policy?%20%20__hstc=129224531.eb3cfbb51b5cb344ebb3eec78b905c3e.1615210114938.1621989245110.1622145380912.19&amp;__%20%20hssc=129224531.2.1622145380912&amp;%20__hsfp=206845015&amp;__%20hstc=88467652.3475fe95b7c433b46a4b78e404622c5c.1619523281511.1626196645320.1626264358381.121&amp;__hssc=88467652.8.1626264358381" target="_blank" rel="noreferrer noopener">Privacy Policy</a>..</p>



<p><strong>Changes to This Privacy Notice</strong></p>



<p>Innerlogic reserves the right to make changes to this privacy notice at any time by giving notice to users on this page and possibly within this application or–as far as technically and legally feasible–sending a notice to users via any contact information available to Innerlogic. Users are strongly recommended to check this page often, referring to the date of the last modification listed at the bottom. Should the changes affect processing activities performed based on the users’ consent, Innerlogic shall collect new consent from the user where required.</p>



<p><strong>Further Information about Personal Data</strong></p>



<p><strong>The Rights of Users</strong></p>



<p>Users may exercise certain rights regarding their data processed by Innerlogic.</p>



<p>In particular, users have the right to do the following:</p>



<ul><li>Withdraw their consent at any time. Users have the right to withdraw consent after they have previously given their consent to the processing of their personal data.</li><li>Object to processing of their data. Users have the right to object to the processing of their data if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below.</li><li>Access their data. Users have the right to learn if Innerlogic is processing their data, obtain disclosure regarding certain aspects of the processing, and obtain a copy of the data undergoing processing.</li><li>Verify and seek rectification. Users have the right to verify their data accuracy and ask for it to be updated or corrected.</li><li>Restrict the processing of their data. Users have the right, under certain circumstances, to restrict the processing of their data. In this case, Innerlogic will not process their data for any purpose other than storing it.</li><li>Have their personal data deleted or otherwise removed. Users have the right, under certain circumstances, to obtain the erasure of their data from Innerlogic.</li><li>Receive their data and have it transferred to another controller. Users have the right to receive their data in a structured, commonly used, machine-readable format, and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the data is processed by automated means and that the processing is based on the user’s consent, on a contract that the user is part of, or on precontractual obligations.</li><li>Lodge a complaint. Users have the right to bring a claim before their competent data protection authority.</li><li>Details About the Right to Object to Processing</li></ul>



<p>Where personal data is processed for the public interest, in the exercise of an official authority vested in Innerlogic or for the legitimate interests pursued by Innerlogic, users may object to such processing by providing a ground related to their particular situation to justify the objection.</p>



<p>However, users must know that should their personal data be processed for direct marketing purposes, they can object to that processing at any time without providing any justification. To learn whether the Innerlogic is processing Personal Data for direct marketing purposes, users may refer to the relevant sections of this document.</p>



<p><strong>How to Exercise These Rights</strong></p>



<p>Any requests to exercise user rights can be directed to Innerlogic through the contact details provided in this document (privacy@Innerlogic.com). These requests can be exercised free of charge and will be addressed by Innerlogic as early as possible and always within one month.</p>



<p><strong>Additional Information about Data Collection and Processing</strong></p>



<p><strong>Legal Action</strong></p>



<p>Users’ personal data may be used for legal purposes by Innerlogic in court or the stages leading to possible legal action arising from improper use of this application or the related services. The users declare they are aware that Innerlogic may be required to reveal personal data upon request of public authorities.</p>



<p><strong>Additional Information About Users’ Personal Data</strong></p>



<p>In addition to the information contained in this privacy notice, this application may provide users with additional and contextual information concerning particular services or the collection and processing of personal data upon request.</p>



<p><strong>System Logs and Maintenance</strong></p>



<p>For operation and maintenance purposes, this application and any third-party services may collect files that record interaction with this application (e.g., system logs) using other personal data (e.g., IP Address) for this purpose.</p>



<p><strong>Information Not Contained in This Notice</strong></p>



<p>More details concerning the collection or processing of personal data may be requested from Innerlogic at any time. Users may use the contact information at the beginning of this document.</p>



<p><strong>How “Do Not Track” Requests are Handled</strong></p>



<p>This application does not support “Do Not Track” requests.</p>



<p>To determine whether any of the third-party services it uses honor “Do Not Track” requests, users should read their privacy policies.</p>



<p><strong>Definitions and Legal References</strong></p>



<p><strong>Personal Data (or Data)</strong></p>



<p>Any information that directly, indirectly, or in connection with other information—including a personal identification number—allows for the identification or identifiability of a natural person.</p>



<p><strong>Usage Data</strong></p>



<p>Information collected automatically through this application (or obtained by services employed in this application)can include: the IP addresses or domain names of the computers utilized, the Uniform Resource Identifier (URI) addresses, the time of the request, the method used to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server’s answer (successful outcome, error, etc.), the country of origin, the features of the users’ browser and operating system, the various time details per visit (e.g., the time spent on each page within the application), and the information on the path followed within the application with particular reference to the sequence of pages visited, and other parameters about the device operating system or the users’ IT environment.</p>



<p><strong>User</strong></p>



<p>The individual using this application who, unless otherwise specified, coincides with the data subject.</p>



<p><strong>Data Subject</strong></p>



<p>The natural person to whom the personal data refers.</p>



<p><strong>Data Processor</strong></p>



<p>The natural or legal person, public authority, agency, or other body that processes personal data on behalf of the controller, as described in this privacy notice.</p>



<p><strong>Sub-Processor</strong></p>



<p>This refers to any additional third party who processes personal data on behalf of the data processor in fulfilling contractual obligations and services.</p>



<p><strong>Data Controller</strong></p>



<p>The person, public authority, agency, or other body that determines the purposes and means of processing personal data, including the security measures concerning the operation and use of this application.</p>



<p><strong>This Application</strong></p>



<p>The information technology system that collects and processes the personal data of the user.</p>



<p><strong>Service</strong></p>



<p>The service provided by the Innerlogic platform or Innerlogic team.</p>



<p><strong>European Union (EU)</strong></p>



<p>Unless otherwise specified, all references made within this document to the European Union (EU) include all current member states to the European Union and the European Economic Area.</p>



<p><strong>Cookies</strong></p>



<p>Small piece of data stored on the user’s device.</p>



<p><strong>Legal Information</strong></p>



<p>This privacy notice has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).</p>



<p>This privacy notice relates to the Innerlogic website, application, and supporting services unless otherwise stated within this document.</p>


</div>
		)

}


export default Privacy