import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { Dimmer, Loader, Segment, Icon } from "semantic-ui-react";

import Outcome from "ModularQuestions/Outcome";
import ValuesBuilder from "ModularQuestions/ValuesBuilder";

import {
  UPDATE_BRIEF_RESPONSE,
  SG_UPDATE_BRIEF_RESPONSE,
} from "constants/actions";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { TRANSITIONSPEED, SlideDirection } from "constants/animation";
import { v4 as uuidv4 } from 'uuid';

const ModularQuestion = ({
  id,
  setModularComplete,
  token,
  briefResponseId,
  setSurveyModularResp,
  setEnableNavigation,
  language,
}) => {
  const dispatch = useDispatch();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(false);

  const [allQuestions, setAllQuestions] = useState([]);
  const [answers, setAnswers] = useState({});


  const { get_brief_response, get_modular_questions } = useSelector(
    (state) => ({
      get_brief_response: state.briefresponse,
      get_modular_questions: state.questions,
    }),
    shallowEqual
  );

  // Extract feedback_builder questions
  useEffect(() => {
    const _totalQuestions = [];
   
    if (
      get_modular_questions?.modular_questions?.feedback_builder?.questions?.[0]
        ?.questions
    ) {
      get_modular_questions.modular_questions.feedback_builder.questions[0].questions.forEach(
        (q) => _totalQuestions.push({ question_type: "feedback_builder", ...q })
      );
    }
    if (
      get_modular_questions?.modular_questions?.outcome_question?.questions
    ) {
      get_modular_questions.modular_questions.outcome_question.questions.forEach(
        (questionGroup) => {
          if (questionGroup?.questions?.length > 0) {
            questionGroup.questions.forEach((q) => {
              _totalQuestions.push({ question_type: "outcome_question", ...q });
            });
          }
        }
      );
    }
      // {
      //   get_modular_questions.modular_questions.outcome_question.questions[0].questions.forEach(
      //     (q) => _totalQuestions.push({ question_type: "outcome_question", ...q })
      //   );
      // }
     
    
    setAllQuestions(_totalQuestions);
    const timer1 = setTimeout(() => {
      if (_totalQuestions?.length === 0) {
        setModularComplete(true);
      }
		}, 1000);
    return () => clearTimeout(timer1);

  }, [get_modular_questions]);

  useEffect(() => {
    if (allQuestions?.length > 0) {
      setCurrentQuestion(allQuestions[currentQuestionIndex]);
    }
  }, [
    currentQuestionIndex,
    allQuestions,
    setModularComplete,
    setEnableNavigation,
  ]);

  const saveAnswer = (currentresponse) => {
    const mod_answers = { ...answers }; // Create a shallow copy of answers

    // Initialize the nested objects if they don't exist
    if (!mod_answers[currentresponse?.question_type]) {
      mod_answers[currentresponse?.question_type] = {};
    }

    // Assign currentresponse to the appropriate question type and id
    mod_answers[currentresponse?.question_type][`${currentresponse?.q}-${currentresponse?.s}`] =
      currentresponse;

    setAnswers(mod_answers);

    if (currentQuestionIndex + 1 > allQuestions?.length - 1) {
      // forced delay to allow state to update
      setTimeout(() => {
        const keys = Object.keys(mod_answers);
        const modResp = {}
        keys.forEach((key) => {
          const modResponses = Object.values(mod_answers?.[key]);
          modResp[key]= {
            "id": uuidv4(),
            "value": key,
            "on_date": new Date(),
            "ask_again": 1,
            "completed": true,
            "responses": modResponses
          }
        })
        setSurveyModularResp(modResp);
        MarkCurrentComplete(modResp);
        setModularComplete(true);
        setEnableNavigation(true);
      }, 50);
    }

    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };


  // // console.log(allQuestions)
  // console.log("answers", answers);
  // console.log("allQuestions", allQuestions);

  function MarkCurrentComplete(modResp) {
    const payload = {
      visual_question: get_brief_response?.visual_question,
      current_question: get_brief_response?.current_question,
      current_answer: get_brief_response?.current_answer,
      high_answer: get_brief_response?.high_answer,
      answers: get_brief_response?.answers,
      complete: get_brief_response?.complete,
      intro_completed: get_brief_response?.intro_completed,
      intro2_completed: get_brief_response?.intro2_completed,
      validation_token: get_brief_response?.validation_token,
      feedback: get_brief_response?.feedback,
      survey_personality: get_brief_response?.survey_personality,
      in_survey_personality: get_brief_response?.in_survey_personality,
      modular_response: modResp,
    };

    dispatch({
      type: UPDATE_BRIEF_RESPONSE,
      payload,
    });

    // persist with api
    if (briefResponseId) {
      dispatch({
        type: SG_UPDATE_BRIEF_RESPONSE,
        payload: {
          id: briefResponseId,
          stored_response: payload,
          query: `?token=${token}`,
          validation_token: get_brief_response?.validation_token,
        },
      });
    }
  }

  return (
    <SwitchTransition>
      <CSSTransition
        key={currentQuestion?.question_type || 0}
        timeout={TRANSITIONSPEED}
        classNames={SlideDirection[1]}
      >
        {!currentQuestion ? (
          <SpinnerContainer>
            <Icon loading name="spinner" />
          </SpinnerContainer>
        ) : (
          <>
            {currentQuestion?.question_type === "outcome_question" && (
              <Outcome
                currentQuestion={currentQuestion}
                saveAnswer={saveAnswer}
              />
            )}

            {(currentQuestion?.question_type === "values_builder") |
              (currentQuestion?.question_type === "feedback_builder") && (
              <ValuesBuilder
                currentQuestion={currentQuestion}
                saveAnswer={saveAnswer}
                language={language}
              />
            )}
          </>
        )}
      </CSSTransition>
    </SwitchTransition>
  );
};

export default ModularQuestion;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  
  .spinner.icon {
    font-size: 2em; /* Adjust the size as needed */
  }
`;