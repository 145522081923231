import React from 'react';
import styled from 'styled-components';
import Checkbox from '../SurveyComponents/Checkbox'
 import Logo from 'assets/images/caat.png'
 import { Icon } from "semantic-ui-react";
 import { Link } from "react-router-dom";


const EN1 = "You will not be able to proceed with the assessment until you have checked the following boxes:*"
const FR1 = "Vous ne pourrez pas commencer l’évaluation avant d’avoir coché les cases suivantes :*"

const EN2 = "Anonymity Statement"
const FR2 = "Déclaration d’anonymité"

const EN3 = `The Culture Assessment and Audit survey does not collect any direct (i.e., name, email address) 
         or indirect (i.e., role) identifiers that link responses to an individual. A minimum of three data points 
         per role is required in the analysis to ensure that individual responses cannot be identified. All responses 
         will be reported anonymously and data will be aggregated to ensure objectivity.`
const FR3 = "Le sondage de l’évaluation et de l’audit de la culture ne recueille aucun identifiant direct (ex. : nom, courriel) ou indirect (ex. : rôle) qui pourrait relier les réponses à une personne. Un minimum de trois points de données par rôle est nécessaire pour assurer que les réponses individuelles ne peuvent être identifiées. Toutes les réponses seront rapportées anonymement et les données seront regroupées pour assurer l’objectivité."


const EN4 = "Confidentiality Statement"
const FR4 = "Déclaration de protection de la vie privée"

const EN5 = "Your responses will be held in strict confidentiality and only anonymous, aggregate data will be used in reports for your NSO, Culture and Wellness Facilitators, and/or Own The Podium. "
const FR5 = "Vos réponses seront strictement confidentielles et seules des données anonymes et regroupées seront utilisées dans l’élaboration de rapports à l’intention de votre ONS, de vos facilitateurs de culture et de bien-être et/ou À nous le podium."

const EN6 = "Informed Consent"
const FR6 = "Consentement éclairé"

const EN7 = "I hereby give consent for the Culture and Wellness Facilitators to access anonymized and aggregated user data strictly in the further development of the CWF role by promoting collaboration, support and sharing of best practices. An individual’s responses cannot be identified. "
const FR7 ="Par la présente, je consens à l’accès aux données d’utilisateur anonymes et regroupées par les facilitateurs de culture et de bien-être, uniquement aux fins du développement du rôle de FCB pour favoriser la collaboration, le soutien et le partage des meilleures pratiques. Les réponses d’une personne particulière ne peuvent être identifiées."

const EN8 ="Secondary Use"
const FR8 ="Usage secondaire"

const EN9 ="I hereby give consent for Own the Podium or designate research partner to use anonymized and aggregated user data strictly in the further development of culture of excellence programming for the benefit of the high performance sport community in Canada. All direct and indirect identifiers, including sport, will be removed. An individual’s responses and the NSO’s results cannot be identified."
const FR9 ="Par la présente, je consens à ce qu’À nous le podium ou un partenaire de recherche désigné utilise des données d’utilisateur anonymes et regroupées, uniquement aux fins du développement de la programmation de la culture d’excellence à l’intention de la communauté du sport de haut niveau au Canada. Tous les identifiants directs et indirects, incluant le sport, seront supprimés. Les réponses d’une personne et les résultats d’un ONS ne pourront être identifiés. "

const EN10 = "Privacy Agreement"
const FR10 ="Entente de confidentialité"

const EN11 = "We are committed to maintaining the accuracy, confidentiality, and security of your personally identifiable information. "
const FR11 = "Nous nous engageons à maintenir l’exactitude, la confidentialité et la sécurité de vos renseignements permettant d’identifier une personne."

const EN12 = "Privacy Statement"
const FR12 ="Énoncé de confidentialité"

const EN13 ="Do you agree with your personal data being processed as described above and in our privacy statement? You must click accept to participate in this survey."
const FR13 ="Êtes-vous d’accord que vos données personnelles soient traitées tel que décrit ci-dessus et dans notre énoncé de confidentialité ? Vous devez cliquer pour accepter afin de participer à ce sondage."

const EN14 = "If you have any questions or concerns regarding your participation in this survey, please contact your NSO Contact [NSO contact"
const FR14 = "Si vous avez des questions ou des préoccupations au sujet de votre participation à ce sondage, veuillez communiquer avec [contact de l’ONS]."

// English variables
const EN15 = "Potential Risks of Participation:";
const EN16 = "The Culture Assessment and Audit survey includes questions regarding mental and physical health and wellbeing, psychological safety, and Safe Sport.";
const EN17 = (<div>We appreciate hearing from you about your experience and encourage your honesty in your response. Due to the anonymity of the survey, we are unable to follow up to any claims. Therefore, it is not an appropriate method to report harm.</div>);
const EN18 = (<div>If the topics in this survey cause you any emotional or psychological stress, you can find sport-specific resources through the <a href="https://www.ccmhs-ccsms.ca">Canadian Centre for Mental Health and Sport</a> or through <a href="https://www.mygameplan.ca/resources/health">Game Plan</a> at <a href="mailto:mentalhealth@mygameplan.ca">mentalhealth@mygameplan.ca</a></div>);
const EN19 = (<div>The Canadian Sport Helpline is a national toll-free sport helpline that offers assistance to victims or witnesses of harassment, abuse or discrimination. The anonymous, confidential and independent bilingual service provides a space for individuals to share and validate their concerns, obtain advice on required next steps, and be referred to appropriate resources for follow up. They can be accessed via phone, text email or live chat from 8AM to 8PM ET 7 days a week. <a href="https://abuse-free-sport.ca">https://abuse-free-sport.ca</a> <a href="mailto:info@abuse-free-sport.ca">info@abuse-free-sport.ca</a> 1-888-837-7678</div>);
const EN20 = (<div>The Sport Dispute Resolution Centre of Canada (SDRCC), provides tools and information to help prevent and resolve disputes at the highest levels of the Canadian sport system. You can learn more information about these services at <a href="http://www.crdsc-sdrcc.ca">http://www.crdsc-sdrcc.ca</a></div>);

// French variables
const FR15 = "Risques potentiels à la participation:";
const FR16 = "Le questionnaire d'évaluation et d'audit de la culture (CAAT) comprend des questions sur la santé mentale et physique et le bien-être, la sécurité psychologique et le sport sécuritaire.";
const FR17 = (<div>Nous souhaitons que vous nous fassiez part de votre expérience et encourageons votre honnêteté dans vos réponses. Cette évaluation est entièrement anonyme. C’est pourquoi nous ne pourrons donner suite à aucune plainte. Par conséquent, ce n'est pas une méthode appropriée pour signaler un abus ou un préjudice.</div>);
const FR18 = (<div>Si les sujets abordés dans ce questionnaire vous causent un stress émotionnel ou psychologique, vous pouvez trouver des ressources spécifiques au sport via le <a href="https://www.ccmhs-ccsms.ca/accueil">Centre canadien de la santé mentale et du sport</a> ou via <a href="https://www.mygameplan.ca/fr/ressources/sante">Plan de Match</a> à <a href="mailto:mentalhealth@mygameplan.ca">mentalhealth@mygameplan.ca</a></div>);
const FR19 = (
  <div>
    La Ligne d’Assistance du Sport Canadien est une ligne d'assistance nationale sans frais pour le sport qui offre de l'aide aux victimes ou aux témoins de harcèlement, d'abus ou de discrimination. Le service bilingue anonyme, confidentiel et indépendant offre un espace où les personnes peuvent partager et valider leurs préoccupations, obtenir des conseils sur les prochaines étapes requises et être référées aux ressources appropriées pour un suivi. Elles sont accessibles par téléphone, courriel, texto ou clavardage en direct de 8 h à 20 h HE, 7 jours sur 7. <a href="https://sport-sans-abus.ca/ligne-dassistance">https://sport-sans-abus.ca/ligne-dassistance</a> <a href="mailto:info@sport-sans-abus.ca">info@sport-sans-abus.ca</a> 1-888-837-7678
  </div>
);

const FR20 = (
  <div>
    Le Centre de règlement des différends sportifs du Canada (CRDSC) fournit des outils et des renseignements pour aider à prévenir et à résoudre les différends aux plus hauts niveaux du système sportif canadien. Vous pouvez en savoir plus sur ces services à <a href="http://www.crdsc-sdrcc.ca">http://www.crdsc-sdrcc.ca</a>
  </div>
);



const Intro = ({saveAnswer, title, intro,customElement,surveyEnabled,elementRef,language }) =>{

  const [agree1,setAgree1] = React.useState(false)
  const [agree2,setAgree2] = React.useState(false)
  const [agree3,setAgree3] = React.useState(false)
  const [agree4,setAgree4] = React.useState(false)
  const [agree5,setAgree5] = React.useState(false)
  const [agree6,setAgree6] = React.useState(false)


  const [expand1,setExpand1] = React.useState(false)
  const [expand2,setExpand2] = React.useState(false)
  const [expand3,setExpand3] = React.useState(false)
  const [expand4,setExpand4] = React.useState(false)
  const [expand5,setExpand5] = React.useState(false)
  const [expand6,setExpand6] = React.useState(false)

  function StartSurvey() {
    
    saveAnswer()

  }

  function expandAll(){
    setExpand1(!expand1)
    setExpand2(!expand2)
    setExpand3(!expand3)
    setExpand4(!expand4)
    setExpand5(!expand5)
    setExpand6(!expand6)
  }

	return (
		<Container ref={elementRef}>
      
      <Content>
        <Image src={Logo}/>
  			<Title>{title}</Title>
        <Section>Introduction</Section>
  			<SubTitle expanded={true}>{intro}</SubTitle>


        <All onClick={()=>expandAll()}>{language==1?'Développer tout':'Expand All'}</All>
        <T2>{language==1?FR15:EN15} <Expand onClick={()=>setExpand6(!expand6)}><Icon name="chevron down"/></Expand></T2>
        <SubTitle expanded={expand6}>
        <p>{language === 1 ? FR16 : EN16}</p>
        <p>{language === 1 ? FR17 : EN17}</p>
        <p>{language === 1 ? FR18 : EN18}</p>
        <p>{language === 1 ? FR19 : EN19}</p>
        <p>{language === 1 ? FR20 : EN20}</p>

        </SubTitle>

        <Checkbox label={language==1?'Je comprends':'I understand'} color={'black'} onChange={()=>setAgree6(!agree6)} checked={agree6}/>

        <SubTitle expanded={true}>{language==1?FR1:EN1}</SubTitle>


        

         <T2>{language==1?FR2:EN2}<Expand onClick={()=>setExpand1(!expand1)}><Icon name="chevron down"/></Expand></T2>
         <SubTitle expanded={expand1}>
         <p>{language==1?FR3:EN3}
         </p>
         </SubTitle>


           <Checkbox label={language==1?'Je comprends':'I understand'} color={'black'} onChange={()=>setAgree1(!agree1)} checked={agree1}/>

          <T2>{language==1?FR4:EN4} <Expand onClick={()=>setExpand2(!expand2)}><Icon name="chevron down"/></Expand></T2>
         <SubTitle expanded={expand2}>
         <p>{language==1?FR5:EN5}

         </p>
         </SubTitle>


           <Checkbox label={language==1?'Je reconnais':'I acknowledge'} color={'black'} onChange={()=>setAgree2(!agree2)} checked={agree2}/>


          <T2>{language==1?FR6:EN6} <Expand onClick={()=>setExpand3(!expand3)}><Icon name="chevron down"/></Expand></T2>
         <SubTitle expanded={expand3}>
         <p>{language==1?FR7:EN7}


         </p>
         </SubTitle>


           <Checkbox label={language==1?"J'accepte":'I consent'} color={'black'} onChange={()=>setAgree3(!agree3)} checked={agree3}/>

           <T2>{language==1?FR8:EN8} <Expand onClick={()=>setExpand4(!expand4)}><Icon name="chevron down"/></Expand></T2>
            <SubTitle expanded={expand4}>
         <p style={{marginBottom:30}}>{language==1?FR9:EN9}


         </p>
         </SubTitle>


           <Checkbox label={language==1?"J'accepte":'I accept'} color={'black'} onChange={()=>setAgree4(!agree4)} checked={agree4}/>


           <T2>{language==1?FR10:EN10} <Expand onClick={()=>setExpand5(!expand5)}><Icon name="chevron down"/></Expand></T2>
         <SubTitle expanded={expand5}>
         <p>{language==1?FR11:EN11} 
         </p>
          <div style={{marginBottom:20}}>
          <Link to={'/otp/privacy'} target="_blank">{language==1?FR12:EN12}</Link>
         </div>

         <p>{language==1?FR13:EN13}</p>
         </SubTitle>

          <Checkbox label={language==1?"J'accepte":'I accept'} color={'black'} onChange={()=>setAgree5(!agree5)} checked={agree5}/>


          <SubTitle expanded={true}>
          <p>{language==1?FR14:EN14}</p></SubTitle>
  			<Next enabled={agree1 && agree2 && agree3 && agree4 && agree5 && agree6} onClick={StartSurvey} id="begin"> {language===1?'Commencer':'Begin'}</Next>
      </Content>
		</Container>

		)
}

export default Intro


const Title = styled.div`
	font-size: 26px;
  font-weight: bold;
  text-align:center;
  
  margin-bottom:15px;
  color:black;
   line-height:1.2;
   max-width:654px;

   @media only screen and (min-width:992px){
   font-size: 32px;
   margin-bottom:30px;
   line-height:1.3;
  }
`
const SubTitle = styled.div`
	font-size: 18px;
	line-height: 1.17;
  color: black;
  font-family: 'Barlow', sans-serif;
  margin-bottom:${props=>props.expanded?'20px':0};
  max-height:${props=>props.expanded?'1000px':0};
  overflow:hidden;
  transition:all 1s ease-in-out;


   @media only screen and (min-width:992px){
   margin-bottom:${props=>props.expanded?'10px':'10px'};
   font-size: 18px;
  }
`

const Next = styled.div`
	width: 170px;
  height: 47px;
   border-radius: 5px;
  background-color: #E5B63D;
  color: white;
   font-size: 16px;
  font-weight: bold;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  margin-top:30px;
  margin-bottom:50px;

  ${(props) => !props.enabled && `
    opacity: 0.5;
    cursor:auto;
    pointer-events: none;
  `}
`

const Container = styled.div`
  position:absolute;
  top:0%;
  width:100vw;
  padding:40px 20px;
  display:flex;
  flex-direction:column;
  align-items:center;

  @media only screen and (min-width:768px){
    left: 0%;
    top:0%;
  }


  @media(min-height:)
`

const T2 = styled.div`
  font-weight:bold;
  font-size:20px;
  margin-top:20px;
  margin-bottom:10px;
  align-items:center;
  justify-content:space-between;
  display:flex;
  width:100%;
  background-color:lightgrey;
  padding:10px;
`



const Image = styled.img`
  position:absolute;
  top:-140px;
  left:-80px;
  height:250px;

  @media(max-width:768px){
    height:150px;
    top:-80px;
    left:0px;
  }
`

const Content = styled.div`
  max-width:800px;
  position:relative;
  padding-top:50px;
  margin-top:60px;
`

const Section = styled.div`
  font-weight:bold;
    font-size: 18px;
  line-height: 1.17;
  margin-bottom:5px;
`

const Expand = styled.div`
  cursor:pointer;
`

const All = styled.div`
  width:100%;
  display:flex;
  justify-content:flex-end;
  margin-top:20px;
   font-size: 18px;
   text-decoration:underline;
   cursor:pointer;
`