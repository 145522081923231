import React from 'react';
import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react'

const SelectTeamMember = (props) => {
const {selected, teamList, setIntQuestion, 
  get_questions, employeeId, SubmitAnswer} = props
  const select = (e, t) => {
    // console.log(t)
    if (t.value.length < 11) {
      props.setSelected(t.value)
    }
  }


  React.useEffect(() => {
    setIntQuestion(get_questions.questions.filter(
			p => p.id === 1)[0])
  }, [setIntQuestion, get_questions]);
  // console.log(props.teamList)
const OtherTeam = teamList.filter(tl => tl.id !== employeeId)
// console.log(OtherTeam)

return (
    <Container>
      <Question>Select the members from your organization that you spent the most time working or collaborating with this week.</Question>
      <SubText>Please select at least 1 person and at most 10</SubText>
      <DropDownContainer>
        <Dropdown
          placeholder='Select team members'
          fluid
          multiple
          search
          selection
          options={OtherTeam}
          value={selected}
          onChange={(e, t) => select(e, t)}
        />
      </DropDownContainer>
      <ButtonContainer>
        {/* <Button enabled={selected.length < 10} onClick={SubmitTeam}>Next</Button> */}
        <StyledSubmitButton enabled={true} onClick={SubmitAnswer}>Submit</StyledSubmitButton>
      </ButtonContainer>
    </Container>
  )
}


export default SelectTeamMember


const Container = styled.div`
   top:50px;
   position: absolute;
   width:90%;
   display:flex;
   flex-direction:column;
   box-sizing: border-box;



   @media(max-height:600px){
     top:30px;
   }

  @media(min-width:992px) and (min-height:650px){
    top:200px;
  }
`


const Question = styled.div`
font-size: 18px;
width: 700px;
font-family: 'Red Hat Display', sans-serif;
line-height: 1.3;
margin-top:14px;
max-width:100%;

font-weight:500;
@media only screen and (min-width:992px) and (max-height:700px){
   font-size: 20px;
    margin-top:28px;
}

@media only screen and (min-width:992px) and (min-height:700px){
   font-size: 23px;
    margin-top:28px;
}
`

const DropDownContainer = styled.div`
	max-width:700px;
	width:100%;
	margin-top:30px;
	margin-bottom:20px;

  @media(max-width:768px){
    margin-bottom:0px;
  }
`

const SubText = styled.div`
	max-width: 700px;
	font-family: 'Red Hat Display', sans-serif;
	margin-top:10px;
`

// const Button = styled.div`
// height: 40px;
// border-radius: 10px;
// background-color: ${props => props.enabled ? "#6a00ff" : "grey"}; 
// color:white;
// font-size: 16px;
// font-weight: bold;
// font-family: 'Red Hat Display', sans-serif;
// display:flex;
// justify-content:center;
// align-items:center;
// cursor:${props => props.enabled ? "pointer" : "not-allowed"};
// width:100px;
// margin-top:50px;

// @media (max-height:600px){
//   margin-top:30px;
// }
// `

const StyledSubmitButton = styled.div`
height: 40px;
border-radius: 10px;
background-color: ${props => props.enabled ? "#008000" : "grey"}; 
color:white;
font-size: 16px;
font-weight: bold;
font-family: 'Red Hat Display', sans-serif;
display:flex;
justify-content:center;
align-items:center;
cursor:${props => props.enabled ? "pointer" : "not-allowed"};
width:100px;
margin-top:50px;

@media (max-height:600px){
  margin-top:30px;
}
`

const ButtonContainer = styled.div`
	width:700px;
`
