import React from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";

const Index = styled.div`
  font-size: 12px;
  font-weight: 900;
  font-family: "Red Hat Display", sans-serif;
  color: ${(props) => props.color};
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
`;

const Content = (props) => {
	// console.log(props)
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: 85,
        justifyContent: "center",
        paddingLeft: 23,
      }}
    >
      <Index color={props.color}>{props.i}</Index>
      <Title>
         {/* eslint-disable-next-line eqeqeq */}
        {props.data.title=="Culture"?"Team health":props.data.title}{" "}
        <span style={{ marginLeft: 5 }}>
          {props.data.completed && props.data.curr_ques ? (
            <Icon name="check circle" />
          ) : (
			props.data.curr_ques? props.data.progress : ""
          )}
        </span>
      </Title>
    </div>
  );
};

export default Content;
