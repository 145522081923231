import styled,{keyframes} from 'styled-components';
import React,{ useState } from 'react';
import Pic from 'assets/images/People.png'
import Logo from 'assets/images/Logo blue.png'

const EN1 = "Thank you for participating in the innerlogic survey. Your insights matter to us."
const FR1 = "Merci d'avoir participé à l'enquête innerlogic. Vos opinions comptent pour nous."
const ES1 = "Gracias por participar en la encuesta de innerlogic. Sus opiniones son importantes para nosotros."


const Finish = ({language}) =>{

    const getText = () =>{
        if(language == "EN") return EN1
        if(language == "FR") return FR1
        if(language == "ES") return ES1
    }

    return (
        <IntroductionContainer>
             <SmallText>
        <LOGO src={Logo} />
        </SmallText>
        
        <Text>
        {getText()}

        </Text>
        
         
           
        </IntroductionContainer>
       
    )
}

export default Finish


const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;


const Text = styled.div`
font-weight: 400;
font-size: 20px;
line-height: 1.4;
color: #2A3039;


@media (max-width: 768px) {
    font-size: 38px;
}
`

const SmallText = styled.div`
font-weight: 600;
font-size: 14px;
text-transform: uppercase;
margin-bottom: 20px;
`

const Image = styled.img`
    width:300px;
    display: inline;
    vertical-align: middle;
    margin-left: 0.25em;
    margin-right: 0.25em;
    margin-bottom:20px;

`

const LOGO = styled.img`
    width:150px;
`

const InlineWrapper = styled.span`
  display: inline-flex;
  align-items: center;
`;

const Highlight = styled.span`
background: #EBF1FD;
border-radius: 40px;
padding:0px 16px;
margin:0px -8px;

`

const Bold = styled.span`
    font-weight:600;
    color:#2D70E2;
`

const IntroductionContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
max-width:800px;

opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
`;

const ButtonArea = styled.div`
    display: flex;
    width:100%;
    justify-content: flex-end;
    margin-top: 20px;

`;

const Button = styled.button`
    background-color: #2D70E2;
    color: white;
    font-size: 16px;
    font-weight: 400;
    border: none;
    border-radius: 5px;
    padding: 15px 30px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
        background-color: #1F4E9D;
    }
`;