
import styled from 'styled-components';
import React,{ useState } from 'react';
import LogoIL from "assets/images/logo_full.png";


const splitText = (text) => {
    const [boldText, normalText] = text.split(':');
    return (
      <>
        <strong>{boldText}:</strong>{normalText}
      </>
    );
  };

  const HockeyCustom = ({language}) =>{
    const t = translations[language];

    return (
        <>
        <LogoArea>
        <Logo2 src={LogoIL} />
        </LogoArea>
       
        <Text>
        <Logo src="https://firebasestorage.googleapis.com/v0/b/charactercode-a247a.appspot.com/o/email%2FEN%20FLAT%20CMYK%403x.jpg?alt=media&token=237b7ad3-3070-4c63-9978-9fbec1194e97" />
             <h1>{t.welcome}</h1>
      <h2>{t.subtitle}</h2>
      <p>{t.intro}</p>
      <h3>{t.whatYouNeedToKnow}</h3>
      <ul>
        <li>{splitText(t.timeCommitment)}</li>
        <li>{splitText(t.honesty)}</li>
        <li>{splitText(t.anonymity)}</li>
        <li>{splitText(t.dataUsage)}</li>
        <li>{splitText(t.recommendations)}</li>
        <li>{splitText(t.interviewOpportunity)}</li>
      </ul>
      <h3>{t.beforeYouBegin}</h3>
      <ul>
        <li>{t.quietTime}</li>
        <li>{t.candidResponses}</li>
      </ul>
      <p>{t.questions}</p>
      <ul>
        {t.tollFree && <li>{t.tollFree}</li>}
        {t.email && <li>{t.email} <a href="mailto:info@sea-hockey-ems.ca">info@sea-hockey-ems.ca</a></li>}
      </ul>
      <p>{t.moreInfo} <a href="http://www.sea-hockey-ems.ca" target="_blank">www.sea-hockey-ems.ca</a>.</p>
        
        {language === 'EN' ? EN1 : FR1}
        </Text>
        </>
    )
}
export default HockeyCustom

const Text = styled.div`
font-size: 16px;
line-height: 1.6;
color: #2A3039;
white-space: pre-wrap;
font-family: 'Raleway', sans-serif;
background-color: white;
padding:20px;
border-radius:20px;

padding-bottom:40px;


h1 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0.5em;
}

h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0.5em;
}
li {
    maregin-bottom: 0.5em;
}

`

const Logo2 = styled.img`
    width:150px;
`

const LogoArea = styled.div`
    display:flex;
    justify-content:center;
    margin-bottom:20px;
    width:100%;
`

const Image = styled.img`
    width:162px;
    display: inline;
    vertical-align: middle;
    margin-left: 0.25em;
    margin-right: 0.25em;
`

const InlineWrapper = styled.span`
  display: inline-flex;
  align-items: center;
`;

const Highlight = styled.span`
background: #EBF1FD;
border-radius: 40px;
padding:0px 16px;
margin:0px -8px;

`

const Bold = styled.span`
    font-weight:600;
    color:#2D70E2;
`
const Logo = styled.img`
    width:300px;
`

const EN1 = (
  <div>
    <i>
    <p>
      <strong>Important Notice:</strong> This survey is not intended for filing complaints or reports about maltreatment in sport. If you have concerns of maltreatment against a specific individual, you may file a Complaint through Hockey Canada’s ITP <a href="mailto:complaints@sportcomplaints.ca">complaints@sportcomplaints.ca</a> or <a href="https://app.alias-solution.com/contact/fr/ls41" target="_blank">https://app.alias-solution.com/contact/fr/ls41</a> (for Quebec Complaints).
    </p>
    <p>
      If you are unsure about who to reach out to or want to speak in confidence, the Abuse-Free Sport Helpline operators can be reached by phone or text at <strong>1-888-837-7678 </strong>or by email at <a href="mailto:info@abuse-free-sport.ca">info@abuse-free-sport.ca</a> or <a href="mailto:info@sport-sans-abus.ca">info@sport-sans-abus.ca</a> (en français).
    </p>
    </i>
  </div>
)

const FR1 = (
  <div>
    <i>
    <p>
      <strong>Avis important :</strong> Ce sondage n'est pas destiné à déposer des plaintes ou des rapports sur des mauvais traitements dans le sport. Si vous avez des préoccupations concernant des mauvais traitements à l'égard d'une personne en particulier, vous pouvez déposer une plainte auprès de l'ITP de Hockey Canada à l'adresse <a href="mailto:complaints@sportcomplaints.ca">complaints@sportcomplaints.ca</a> ou <a href="https://app.alias-solution.com/contact/fr/ls41" target="_blank">https://app.alias-solution.com/contact/fr/ls41</a> (pour les plaintes au Québec).
    </p>
    <p>
      Si vous n'êtes pas sûr de qui contacter ou si vous souhaitez parler en toute confidentialité, les opérateurs de la ligne d'assistance Sport sans abus peuvent être contactés par téléphone ou par SMS au <strong>1-888-837-7678</strong> ou par courriel à <a href="mailto:info@abuse-free-sport.ca">info@abuse-free-sport.ca</a> ou <a href="mailto:info@sport-sans-abus.ca">info@sport-sans-abus.ca</a> (en français).
    </p>
    </i>
  </div>
)


const translations = {
  EN: {
    "welcome": "Welcome to the Sport Environment Assessment Survey",
    "subtitle": "Hockey Culture Index – Participant Survey",
    "intro": "Thank you for participating in this important initiative. Your experience and knowledge are valuable to our efforts in assessing the environment and culture of hockey in Canada.",
    "whatYouNeedToKnow": "What You Need to Know:",
    "timeCommitment": "Time Commitment: This survey will take approximately 15 minutes to complete.\nSurvey open until November 20,2024.",
    "honesty": "Honesty is Key: There are no right or wrong answers – we're seeking your genuine perspectives.",
    "anonymity": "Complete Anonymity: Your responses are anonymous. We do not collect personally identifiable information to help ensure your privacy is protected.",
    "dataUsage": "Data usage: the information gathered from this survey will be used to understand the current environment and culture within your hockey organization. This allows for a better understanding of the opportunities for achieving a healthy hockey culture, which supports both well-being and high performance in hockey.",
    "recommendations":"Report and recommendations: Which are informed by this survey and other information will be published by the Office of the Sport Integrity Commissioner in Spring 2025.",
    "interviewOpportunity": "Post-Survey Interview Opportunity: After completing the survey, you can sign up for an interview. This is entirely voluntary and separate from your survey responses, maintaining your anonymity.",
    "beforeYouBegin": "Before You Begin:",
    "quietTime": "Ensure you have uninterrupted quiet time to complete the survey.",
    "candidResponses": "Feel free to be open and candid in your responses.",
    "questions": "",
    "tollFree": "",
    "email": "",
    "moreInfo": "For more information about the Sport Environment Assessment, visit ",
    "nextButton": "Next",
    "strongNotice": "Important Notice:",
    "important": "This survey is not intended for filing complaints or reports about maltreatment in sport. If you need support or wish to submit such a complaint or report, please visit the Office of the Sport Integrity Commissioner (OSIC) website."
  },
  FR: {
    "welcome": "Bienvenue au Sondage d'Évaluation de l'Environnement Sportif",
    "subtitle": "Indice de Culture du Hockey – Sondage des Participants",
    "intro": "Merci de participer à cette initiative importante. Votre expérience et vos connaissances sont précieuses pour nos efforts d'évaluation de l'environnement et de la culture du hockey au Canada.",
    "whatYouNeedToKnow": "Ce que vous devez savoir :",
    "timeCommitment": "Temps requis : Ce sondage prendra environ 15 minutes à compléter.\nSondage ouvert jusqu'au 20 novembre 2024.",
    "honesty": "L'honnêteté est essentielle : Il n'y a pas de bonnes ou mauvaises réponses – nous recherchons vos perspectives authentiques.",
    "anonymity": "Anonymat complet : Vos réponses sont anonymes. Nous ne collectons pas d'informations personnellement identifiables afin de garantir la protection de votre vie privée.",
    "dataUsage": "Utilisation des données : Les informations recueillies dans ce sondage seront utilisées pour comprendre l'environnement et la culture actuels au sein de votre organisation de hockey. Cela permet une meilleure compréhension des opportunités pour atteindre une culture saine du hockey, qui soutient à la fois le bien-être et la haute performance dans le hockey.",
    "recommendations":"Rapport et recommandations : Qui sont informés par ce sondage et d'autres informations seront publiés par le Bureau du Commissaire à l'intégrité dans le sport au printemps 2025.",
    "interviewOpportunity": "Possibilité d'entrevue après le sondage : Après avoir complété le sondage, vous pouvez vous inscrire pour une entrevue. Cela est entièrement volontaire et séparé de vos réponses au sondage, préservant ainsi votre anonymat.",
    "beforeYouBegin": "Avant de commencer :",
    "quietTime": "Assurez-vous d'avoir du temps calme et ininterrompu pour compléter le sondage.",
    "candidResponses": "N'hésitez pas à être ouvert et sincère dans vos réponses.",
    "questions": "",
    "tollFree": "",
    "email": "",
    "moreInfo": "Pour plus d'informations sur l'Évaluation de l'Environnement Sportif, visitez ",
    "nextButton": "Suivant",
    "strongNotice": "Avis important :",
    "important": "Ce sondage n'est pas destiné à déposer des plaintes ou des signalements concernant la maltraitance dans le sport. Si vous avez besoin de soutien ou souhaitez soumettre une telle plainte ou un signalement, veuillez visiter le site web du Bureau du Commissaire à l'intégrité dans le sport (BCIS)."
  }  
};