import _ from "lodash";
import {
  GET_ERRORS,
  ALL_ERRORS,
  VALIDATION_ERRORS,
  CLEAR_ERRORS,
} from "constants/actions";

const initialState = {
  msg: {},
  status: {},
  errors: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      // console.log('reducer error payload', action.payload)
      return {
        msg: action.payload.msg,
        status: action.payload.status,
      };
    case ALL_ERRORS:
      // console.log('reducer error payload', action)

      if (_.get(action, "message.data")) {
        return {
          msg: action.message,
          errors: action.message.response.data,
          status: action.message.response.status,
        };
      } else if (_.get(action, "message")) {
        return {
          msg: action.message,
          errors: action.message.response,
          status: action.message.response,
        };
      } else {
        return {
          msg: action.message,
          errors: action,
          status: action,
        };
      }

    case VALIDATION_ERRORS:
      // console.log('reducer error payload', action)
      // console.log('reducer error payload', action.error.response.data)
      if (_.get(action, "error")) {
        return {
          msg: action.error.response,
          errors: action.error.response.data,
          status: action.error.response.status,
        };
      } else {
        return {
          msg: action,
          errors: action,
          status: action,
        };
      }

    case CLEAR_ERRORS:
      return {
        msg: {},
        status: {},
        errors: {},
      };

    default:
      return state;
  }
}
