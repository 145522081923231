import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Select from "react-select";

const CategoryQuestions = (props) => {
	const { id, prevResponse, 
		 data, employeeCategory } = props

	const [quesResponse, setQuesResponse] = useState(null)
	const [formatOptions, setFormatOptions] = useState([])
	const [hasValue, setHasValue] = useState(false)
	
	console.log(data)

	useEffect(() => {
		setFormatOptions(data.answers.map((item, key) => {
			return {
				text: item.name,
				value: item.id,
			}
		}))
	}, [data, quesResponse]);

	useEffect(() => {
		const EmployeeCat = employeeCategory.find(
			({ name_id }) => name_id === data?.id)
		setQuesResponse(null)
		if (EmployeeCat) {
			setQuesResponse(data.answers.filter(an => an.name === EmployeeCat.value
			).map((item) => {
				return {
					text: item.name,
					value: item.id
				}
			})[0])
		}
	}, [employeeCategory, data]);
	

	useEffect(() => {
		setHasValue(false)
		if (quesResponse) {
			setHasValue(true)
		}
	}, [quesResponse]);

	useEffect(() => {
		if (prevResponse?.response !== null) {
			if (!quesResponse) {
				setQuesResponse(formatOptions.filter(so => so.value === prevResponse?.response)[0])
			}
		}
	}, [prevResponse, formatOptions, quesResponse]);

	const next = () => {
		props.save({
			id: data.id,
			response: quesResponse?.value,
			// question: props.data.question,
			subfactor: data?.subfactor,
			priority: data?.priority
		})
		setQuesResponse(null)
		setHasValue(false)
	}

	const getQuestion = () => {
		return (
			<>
				<Text>{data.question}</Text>
				<DropDownContainer>
					<Select
						placeholder="Select an option"
						onChange={setQuesResponse}
						value={quesResponse}
						options={formatOptions}
						// isLoading={loading}	
						id={`categoryQuesSelectList_${id}`}
						getOptionLabel={(option) => option.text}
						getOptionValue={(option) => option.value}
					/>
				</DropDownContainer>
			</>)
	}

	return (
		<SurveyContainer>
			{getQuestion()}
			<Button
				onClick={() => next()}
				disabled={!hasValue}
				id="next">
				Next
			</Button>
		</SurveyContainer>
	)
}


export default CategoryQuestions


const SurveyContainer = styled.div`
  padding: 0px;
  box-sizing: border-box;
  position: absolute;
  left: 0%;
  top: 10%;
  padding: 20px;
  width:100%;


  @media only screen and (min-width: 992px) {
    padding: 30px;
    left: 25%;
    top: 15%;
  }
`;


const DropDownContainer = styled.div`
  padding: 0px;
  left: 0%;
  top: 10%;

  width:600px;
  max-width:100%;

  @media only screen and (min-width: 992px) {

    left: 25%;
    top: 15%;
  }
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Barlow", sans-serif;
  color: white;
  margin-top: 14px;
  max-width:600px;
  line-height:1.3;
  margin-bottom:30px;

  @media only screen and (min-width: 992px) {
    font-size: 23px;
    margin-top: 28px;
  }
`;

const Button = styled.button`
  height: 40px;
  border-radius: 10px;
  background-color: white;
  border: 0;
  box-shadow: none;
  color: #6a00ff;
  font-size: 16px;
  font-weight: bold;
  font-family: "Barlow", sans-serif;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100px;
  margin-top: 50px;
  // display: none;

  :disabled{
  	background-color:lightgrey;
  	color:grey;
  	cursor:not-allowed;
  	opacity:0.5;
  }


  @media only screen and (min-width: 992px), (min-height: 760px) {
    display: flex;
  }
`;