import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import format from "date-fns/format";

const End = ({ message, onTime, status }) => {
  const [endDate, setEndDate] = useState(Date())
  // console.log( message, onTime, status)

  // console.log(status == 100)

  useEffect(() => {
    if (onTime) {
      setEndDate(format(new Date(onTime), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"))
    }
  }, [onTime])

  let CustomMessage = 'We are unable to get information about this survey'
  if (status === 3) {
    CustomMessage = 'This survey has been completed'
  }
  if (status > 4 && status < 11) {
    CustomMessage = 'This survey has been closed and is no longer accessible'
  }

  if (status === 100) {
    CustomMessage = 'Hmm! It looks like this survey has expired'
  }
// console.log(status > 4, CustomMessage)
  // const Start = format(onTime, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
  return (
    <Container>
      <Title>
        {message ? message : CustomMessage
          
        }
      </Title>

      <SubTitle>  {endDate ? `Updated on ${format(
          new Date(endDate.toLocaleString()),
          "EEEE, MMM do h:mm a"
        )}` : ''}
        
      </SubTitle>


    </Container>

  )
}


export default End


const Title = styled.div`
	font-size: 30px;
  font-weight: bold;
  font-family: 'Barlow', sans-serif;
  margin-bottom:20px;
  color:white;
   line-height:1;
   max-width:654px;

   @media only screen and (min-width:992px){
   font-size: 30px;
   margin-bottom:50px;
  }
`
const SubTitle = styled.div`
	font-size: 18px;
	line-height: 1.17;
  color: white;
  font-family: 'Barlow', sans-serif;
  margin-bottom:20px;
  max-width:654px;

   @media only screen and (min-width:992px){
   font-size: 72px;
   margin-bottom:50px;
   font-size: 24px;
  }
`

// const Next = styled.div`
// 	width: 170px;
//   height: 47px;
//    border-radius: 5px;
//   background-color: white;
//   color: #6a00ff;
//    font-size: 16px;
//   font-weight: bold;
//   font-family: 'Red Hat Display', sans-serif;
//   display:flex;
//   align-items:center;
//   justify-content:center;
//   cursor:pointer;
// `

const Container = styled.div`
  position:absolute;
  top:15%;
  padding:20px;

  @media only screen and (min-width:992px){
    left: 25%;
  top: 25%;
  }
`