import React from 'react';
import styled from 'styled-components';



const Language = ({selected,setSelected,color}) =>{


	return (
		<Container>
			<Choices>
				<L1 selected={selected===0} onClick={()=>setSelected(0)} color={color}>EN</L1>
				<L1 selected={selected===1} onClick={()=>setSelected(1)} color={color}>FR</L1>
			</Choices>
		</Container>
		)
}

export default Language



const Container = styled.div`
	font-family: 'Barlow', sans-serif;
	position:absolute;
	top:100px;
	right:250px;

	@media(max-width:1100px){
		top:50px;
		right:100px;
	}

	@media(max-width:768px){
		top:20px;
		right:20px;
	}

	@media(max-height:800px){
		top:50px;
	}

	@media(max-width:376px){
		height:20px;
		top:20px;
	}

	@media(max-height:550px){
		top:20px;
	}

`

const L1 = styled.div`
	font-size:18px;
	color:${props=>props.selected? 'black':'white'};
	font-weight:bold;
	margin-right:10px;
	background-color:${props=>props.selected? 'white':'rgba(0,0,0,0.3)'};
	border-radius:5px;
	cursor:pointer;
	padding:10px;
`

const Choices = styled.div`
	display:flex;
`

// const Title = styled.div`
// 	color:white;
// 	font-size:12px;
// 	margin-bottom:5px;
// `