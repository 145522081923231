import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import {REVERSE} from "data/reverseScores"


const CommentsFeedback = ({ ruleFeedback,
  setRuleFeedback,
  setRuleFeedbackComplete,
  language,
  textColor,
  setAskFeedback,
  setComplete,
}) => {
  const [totalQues, setTotalQues] = useState(0)
  const [currQues, setCurrQues] = useState(0)
  const [respValue, setRespValue] = useState('')
  const [responseList, setResponseList] = useState([])
  const [getResponse, setGetResponse] = useState({})

  useEffect(() => {
    setTotalQues(ruleFeedback?.length)
  }, [ruleFeedback]);

  useEffect(() => {
    setGetResponse(ruleFeedback[currQues])
  }, [ruleFeedback, currQues]);
  
  const save = () => {
    const _resList = responseList
    _resList.push({
      ...getResponse,
      feedback: respValue,
    })
    setResponseList(_resList)

    if (currQues + 2 > totalQues) {
      setRuleFeedback(_resList)
      setRuleFeedbackComplete(true)
      setAskFeedback(false)
      setComplete(true)
      return
    }

    if (currQues + 1 <= totalQues) {
      setCurrQues(currQues + 1)
    }
  
    setRespValue('')
    // 
  }


	return (
		<SurveyContainer>
			<Text>{language && language===1?getResponse?.fr: getResponse?.question} - Feedback {currQues + 1} / {totalQues}</Text>
			<SubText
			placeholder={language && language===1?"Vous avez obtenu un score faible / élevé à cette question, veuillez nous dire pourquoi"
      :"You scored low / high in this question, please tell us why "}
	        value={respValue}
	        id="textbox"
	        rows="6"
	        onChange={(e)=>setRespValue(e.target.value)}/>

	        <Button 
          onClick={()=>save()}
           color={textColor} id="next">
            {language===1?'Suivant':'Next'}
            </Button>
		</SurveyContainer>
		)
}

export default CommentsFeedback


const SurveyContainer = styled.div`
  padding: 0px;
  box-sizing: border-box;
  position: absolute;
  left: 0%;
  top: 10%;
  padding: 20px;

  @media only screen and (min-width: 992px) {
    padding: 30px;
    left: 25%;
    top: 15%;
  }
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: white;
  margin-top: 14px;
  max-width:600px;
  line-height:1.3;
  margin-bottom:30px;

  @media only screen and (min-width: 992px) {
    font-size: 23px;
    margin-top: 28px;
  }
`;

const SubText = styled.textarea`
  width:600px;
  color: black;
  font-size: 16px;
  background-color:white;
  border-radius:8px;
  border:none;
  padding:15px;
  resize: none;

  &:focus{
  outline: none;
  }

  ::placeholder{
    color: #b8b8b8;
  }

  @media(max-width:768px){
    max-width:calc(100vw - 40px);

  }
`

const Button = styled.button`
  height: 40px;
  border-radius: 10px;
  background-color: white;
  border: 0;
  box-shadow: none;
  color: ${props=>props.color};
  font-size: 16px;
  font-weight: bold;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100px;
  margin-top: 50px;
  // display: none;

  :disabled{
  	background-color:lightgrey;
  	color:grey;
  	cursor:not-allowed;
  	opacity:0.5;
  }


  @media only screen and (min-width: 992px), (min-height: 760px) {
    display: flex;
  }
`;