import styled from "styled-components";
import React from "react";



const words = ["Disagree", "", "Neutral", "", "Agree"];

const Likert = ({ value, saveResult }) => {

  return (
    <QuestionContainer>
      {[0, 1, 2, 3, 4].map((item, i) => {
        return (
          <QuestionItem
            i={i + 1}
            answer={value}
            onClick={() => saveResult(i + 1)}
            key={i}
          >
            <Num>{item + 1}</Num>
            <Rating>{words[i]}</Rating>
          </QuestionItem>
        );
      })}
    </QuestionContainer>
  )
}


export default Likert



const QuestionItem = styled.div`
  width: 18%;
  height: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Red Hat Display", sans-serif;
  border-radius: ${(props) =>
    props.i === 1 ? "7px 0px 0px 7px" : props.i === 5 ? "0 7px 7px 0" : "none"};
  border: 1px solid white;
  cursor: pointer;
  background-color: ${(props) => (props.answer === props.i ? "white" : "none")};
  color: ${(props) => (props.answer === props.i ? "#6a00ff" : "white")};

  &:active {
    background: white;
    color:#6a00ff;
  }

  @media only screen and (min-width: 992px) {
    height: 85px;
    width: 20%;

      &:hover {
    background: white;
    color: #6a00ff;
  }
  &:active {
    background: #6a00ff;
    color:white;
  }
  }

  
`;

const QuestionContainer = styled.div`
  width: 100vw;
  height: 65px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;

  @media only screen and (min-width: 992px) {
    width: 600px;
    margin-top: 40px;
  }
`;

const Num = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;

  @media only screen and (min-width: 992px) {
    font-size: 24px;
    margin-top: 20px;
  }
`;
const Rating = styled.div`
  font-size: 10px;
  font-weight: 500;
  margin-top: 0px;

  @media only screen and (min-width: 992px) {
    font-size: 15px;
    margin-top: 10px;
  }
`;
