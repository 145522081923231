import React from "react";
import {Loader,Dimmer} from 'semantic-ui-react'



const Loading = ()=>{
	
	return (
		<div style={{height:'100vh',width:'100vw',display:'flex',alignItems:'center',justifyContent:'center'}}>
			<Dimmer active inverted><Loader inverted>Loading</Loader></Dimmer>
		</div>
	)
}


export default Loading