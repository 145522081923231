import styled from "styled-components";
import React from "react";
import { useDispatch } from "react-redux";
import { MOOD_UPDATE_COMMENTS } from "constants/actions";
// import definition from "data/emotion_definitions.json"


const EmotionCard = (props) => {
const dispatch = useDispatch();
  const { mood } = props;
  const [comment, setComment] = React.useState("");

  const select = () => {
    if (!props.selected) {
      props.setSelected();
    }
  };

  React.useEffect(() => {
    if (props.mood.comment) {
      setComment(props.mood.comment)
    }
  }, [props])

  function UpdateComments(e) {
    dispatch({type: MOOD_UPDATE_COMMENTS, payload : {...mood, comment: e}})
  }

// console.log(mood)

  return (
    <Container selected={props.selected} onClick={() => select()}>
      <Top color={mood.color}>
        <Emotion>{mood.name}</Emotion>
        <Definition>{mood.feeling}</Definition>
      </Top>
      <TextContainer>
        <TextBox
          selected={props.selected}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          onBlur={(e) => UpdateComments(e.target.value)}
          rows="8"
          maxLength="300"
          disabled={!props.selected}
          placeholder="Add reflection comments here"
        />
      </TextContainer>
      <Total>{comment? comment.length: 0}/300</Total>
    </Container>
  );
};

export default EmotionCard;

const Top = styled.div`
  width: 100%;
  height: 120px;
  background-color: ${(props) => "rgba(" + props.color + ")"};
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
`;
const Emotion = styled.div`
  font-size: 23px;
  font-weight: bold;
  color: #ffffff;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 10px;
`;

const Definition = styled.div`
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  line-height: 1.31;
  color: #ffffff;
`;

const TextContainer = styled.div`
  width: 100%;
  height: 180px;
  padding: 12px;
`;

const Container = styled.div`
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 #ececec;
  width: calc(100vw - 30px);
  min-width: 285px;
  max-width: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  margin-right: 24px;
  opacity: ${(props) => (props.selected ? 1 : 0.2)};
  z-index: ${(props) => (props.selected ? 2 : 1)};
  user-select: ${(props) => (props.selected ? "auto" : "none")};
  cursor: ${(props) => (props.selected ? "auto" : "pointer")};
  position: absolute;
  left: ${(props) => (props.selected ? 0 : "500px")};
  top: 0;
  transition: left 500ms;

  :hover {
    opacity: ${(props) => (props.selected ? 1 : 0.4)};
  }

  @media only screen and (min-width: 500px) {
    width: 300px;
    position: static;
  }
`;

const TextBox = styled.textarea`
  width: 100%;
  line-height: 1.31;
  color: #4a4a4a;
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  border: none;
  resize: none;
  cursor: ${(props) => (props.selected ? "auto" : "pointer")};

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: #4a4a4a;
  }
`;

const Total = styled.div`
  font-size: 12px;
  font-weight: 900;
  color: #696969;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 12px;
  padding-bottom: 12px;
  font-family: "Red Hat Display", sans-serif;
`;