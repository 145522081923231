import React, { useEffect, useState } from "react";
import styled from "styled-components";


const EN1 = () => (
    <p>To access the Hockey Sport Environment Assessment survey (an initiative of the Office of the Sport Integrity Commissioner), we’ll need to email you a unique and personalized link that verifies that your responses are <strong>secure and genuine.</strong></p>
  );
  
  const EN2 = () => (
    <p>While we will use your email to deliver a secure link to your survey, your responses remain <strong>completely anonymous</strong> and will only be analyzed and presented in aggregate form.</p>
  );
  
  const EN3 = () => (
    <p>Your survey responses are <strong>not linked to your email or identity.</strong></p>
  );
  
  const EN4 = () => (
    <p>Please look for an email from <strong>innerlogic@innerlogic.com</strong></p>
  );
  
  const FR1 = () => (
    <p>Pour accéder au sondage d'évaluation de l'environnement sportif de Hockey Canada (une initiative du Bureau du Commissaire à l'intégrité dans le sport), nous devrons vous envoyer par e-mail un lien unique et personnalisé qui vérifie que vos réponses sont <strong>sécurisées et authentiques.</strong></p>
  );
  
  const FR2 = () => (
    <p>Alors que nous utiliserons votre e-mail pour vous envoyer un lien sécurisé vers votre sondage, vos réponses restent <strong>totalement anonymes</strong> et ne seront analysées et présentées que sous forme agrégée.</p>
  );
  
  const FR3 = () => (
    <p>Vos réponses au sondage ne sont <strong>pas liées à votre e-mail ou identité.</strong></p>
  );
  
  const FR4 = () => (
    <p>Veuillez chercher un e-mail provenant de <strong>innerlogic@innerlogic.com</strong></p>
  );

const HockeyIntro = ({language}) => {


    return (
        <Content>
               <Logo src="https://firebasestorage.googleapis.com/v0/b/charactercode-a247a.appspot.com/o/email%2FEN%20FLAT%20CMYK%403x.jpg?alt=media&token=237b7ad3-3070-4c63-9978-9fbec1194e97" />
            <Title>{language == 'EN' ? 'Hockey Culture Index - Member Branch Survey' : 'Indice de culture du hockey - Sondage de Membre'}</Title>
            {language == 'EN' ? EN1(): FR1()}
            {language == 'EN' ? EN2(): FR2()}
            {language == 'EN' ? EN3(): FR3()}
            {language == 'EN' ? EN4(): FR4()}
            
        </Content>
    )


}

export default HockeyIntro;



const Content = styled.div`
    max-width: 800px;
    width: 100%;
    padding: 40px;
    border-radius:20px;
    line-height: 1.5;
    font-weight:500;
    color:#2A3039;
    font-family: 'Raleway', sans-serif;

    @media(max-width:768px){
        font-size:14px;
        padding:0px;
    }

`

const Title = styled.div`
    font-size: 24px;
    font-weight:700;
    margin-bottom: 20px;
    color:#2A3039;
    font-family: 'Raleway', sans-serif;
`

const Logo = styled.img`
    width:300px;
    margin-bottom:20px;
    margin-left:-15px;

    
`