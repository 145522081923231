import React, { useState,useRef,useEffect } from 'react';
import styled from 'styled-components';
import {words} from '../translations'



const Submit = ({language}) =>{


    return (

        <FeedbackContainer>
            <Emoji><span role="img" aria-label="submit clap">👏</span> </Emoji>
            <QuestionText>{words['Congratulations'][language]}</QuestionText>
            <Description>
           {words['Value'][language]} <Bold>{words['Submit'][language]}</Bold> {words['Value2'][language]}
            </Description>
        </FeedbackContainer>

    )
}


export default Submit;


const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width:600px;
  font-family: 'Raleway', sans-serif;
`;

const QuestionText = styled.h2`
  margin-bottom: 1rem;
  color:#2A3039;
  font-size: 20px;
  font-weight: 600;
`;

const Description = styled.div`
color: #2A3039;
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
margin-bottom:26px;
line-height: 150%;
`
const Emoji = styled.div`
    font-size: 48px;
    margin-bottom:24px;
`

const Bold = styled.span`
    font-weight:600;
    color:#2D70E2;
`
