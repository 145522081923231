import React from 'react';
import styled from 'styled-components';

const BulletProgressBar = ({ current,total }) => {
  return (
    <BulletContainer>
      {Array(total)
        .fill(null)
        .map((_, index) => (
          <Bullet key={index} active={index === current} />
        ))}
    </BulletContainer>
  );
};

export default BulletProgressBar;

const BulletContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Bullet = styled.div`
  background-color: ${({ active }) => (active ? '#2D70E2' : '#D5E2F9')};
  border-radius: 10px;
  width: ${({ active }) => (active ? '35px' : '12px')};
  height: 6px;
  margin: 0 0.25rem;
  transition: all 300ms;
`;
