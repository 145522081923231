import React,{useRef,useEffect,useState} from 'react';
import styled,{keyframes} from 'styled-components';
import Logo from 'assets/images/Logo blue.png'
import { Loader } from "semantic-ui-react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_CHAT_GPT_SUMMARY } from "constants/actions";
import { v4 as uuidv4 } from "uuid";
import {structurePrompt} from './structurePrompt'
import Typewriter from "typewriter-effect";
import {words} from './translations'

const NewValidationToken = uuidv4();


const Conversation = ({responses,setScreen,response,id,saveResponse,language}) =>{
  const dispatch = useDispatch();
  const timeout = useRef(null);
  const [loading, setLoading] = useState(true);
  const textareaRef = useRef();
  const [question, setQuestion] = useState("");
  const [feedbackText, setFeedbackText] = useState("");

  const { get_chat_gpt } = useSelector(
    (state) => ({
      get_chat_gpt: state.chatgpt,
    }),
    shallowEqual
  );

  useEffect(() => {
    setLoading(true)
      SubmitChatGpt();
  }, [id]);

  useEffect(() => {
    if (get_chat_gpt?.response_pending === true) {
      timeout.current = setTimeout(() => {
        setLoading(false);
      }, 15000);
    }

    if (get_chat_gpt?.response_pending === false) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
    setLoading(get_chat_gpt?.response_pending);
    if (get_chat_gpt.chat_gpt_summary?.response) {
      setQuestion(get_chat_gpt.chat_gpt_summary?.response);
    }

    return () => {
      clearTimeout(timeout.current);
    };
  }, [get_chat_gpt]);

 
  const handleChange = (event) => {
    setFeedbackText(event.target.value);
    const ResponseObj = {
        response: event.target.value,
        question: question,
        type:'ai'
    };

    saveResponse(ResponseObj);

   
  };

  function SubmitChatGpt() {

    let _prompt = structurePrompt(responses,language)

    dispatch({
      type: SG_GET_CHAT_GPT_SUMMARY,
      payload: {
        payload: _prompt,
        token: NewValidationToken,
      },
    });
  }

  const save = () =>{
    setQuestion("")
    setFeedbackText("")
    setScreen()
  }


    return (
        <FeedbackContainer key={id}>
            <Title>
                <IMG src={Logo} />
            </Title>
            <Description>
                {loading ? (
                <Loader inline active/>
                ) : <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .changeDelay(12)
                    .typeString(question)
                    .pauseFor(80)
                    .start();
                }}
              />
                }
            </Description>

            <StyledTextarea
                value={feedbackText}
                onChange={handleChange}
                placeholder={words['textarea'][language]}
                ref={textareaRef}
            />

            <ButtonArea>
                <Button onClick={()=>save()} disabled={response==undefined}>
                    {words['Next'][language]}
                </Button>
            </ButtonArea>

            </FeedbackContainer>

    )
}

export default Conversation



const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;


// Define keyframes
const loading01 = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

// Styled component for loading text
const Loading = styled.div`
  font-size: 18px;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  text-align: center;

  span {
    display: inline-block;
    margin: 0;
  }
`;

// Styled component for loading01 animation
const Loading01 = styled(Loading)`
  span {
    animation: ${loading01} 1.4s infinite alternate;

    &:nth-child(1) {
      animation-delay: 0s;
    }
    &:nth-child(2) {
      animation-delay: 0.1s;
    }
    &:nth-child(3) {
      animation-delay: 0.2s;
    }
    &:nth-child(4) {
      animation-delay: 0.3s;
    }
    &:nth-child(5) {
      animation-delay: 0.4s;
    }
    &:nth-child(6) {
      animation-delay: 0.5s;
    }
    &:nth-child(7) {
      animation-delay: 0.6s;
    }
  }
`;



const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 600px;
  width: 100%;
  font-family: "Raleway", sans-serif;

  opacity:0;

  animation: ${fadeIn} 1s ease-out forwards;
`;


const Description = styled.div`
  color: #2a3039;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 34px;
  line-height: 1.6;
`;
const Title = styled.div`
  color: #2d70e2;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 40px;
  display: flex;
`;

const StyledTextarea = styled.textarea`
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #2a3039;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: none;
  font-family: "Raleway", sans-serif;
  height:200px;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #2d70e2;
    outline: 0;
  }
`;


const Circle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: #2d70e2;
  margin-right: 10px;
  min-width: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;



const IMG = styled.img`
width:150px;`;

const ButtonArea = styled.div`
    display: flex;
    width:100%;
    justify-content: flex-end;
    margin-top: 20px;

`;

const Button = styled.button`
    background-color: #2D70E2;
    color: white;
    font-size: 16px;
    font-weight: 400;
    border: none;
    border-radius: 5px;
    padding: 10px 35px;
    cursor: pointer;
    transition: all 0.3s;
    margin-top:40px;
    &:hover {
        background-color: #1F4E9D;
    }

    &:disabled{
        background-color: #ccc;
        cursor: not-allowed;
    }
`;