import React from "react";
import styled from "styled-components";

import FlippedCard from "./FlippedCard";

import Curiosity from "assets/images/curiosity.svg";
import Enjoyment from "assets/images/enjoyment.svg";
import Motivation from "assets/images/motivation.svg";
import Discontent from "assets/images/discontent.svg";
import Stress from "assets/images/stress.svg";
import Anger from "assets/images/anger.svg";

const Card = styled.div`
  width:50%;
  height:18vh;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  background-color: transparent;
  perspective: 1000px;

   @media only screen and (min-width:768px) and (max-height:750px){
     width: 200px;
    height: 150px;
    margin-bottom:5px;
    }

  @media only screen and (min-width:992px) and (min-height:750px){
     width: 200px;
  height: 200px;
  margin-bottom:30px;
  }




`;

const CardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  transform: ${(props) => (props.flipped ? "rotateY(180deg)" : "none")};
`;
const CardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;

  @media only screen and (min-width:992px){
      font-size: 18px;
    }
`;

const Mood = styled.div`
  font-weight: bold;
  line-height: 1.11;
  font-family: "Red Hat Display", sans-serif;
  color: ${(props) => "rgba(" + props.color + ")"};    
`;

const Feelings = styled.div`
  line-height: 1.11;
  font-family: "Red Hat Display", sans-serif;
  color: ${(props) => "rgba(" + props.color + ")"};
`;

const Icon = styled.img`
   height:5vh;
   width:5vh;
   margin-bottom:5px;

   @media only screen and (min-width:992px){
     margin-bottom: 21px;
     height:auto;
   }
`;

const Icons = {
  Curiosity: Curiosity,
  Enjoyment: Enjoyment,
  Motivation: Motivation,
  Discontent: Discontent,
  Stress: Stress,
  Anger: Anger,
};

const MoodCard = (props) => {
  const { mood } =props

  return (
    <Card>
      <CardInner flipped={props.flipped === props.i}>
        <CardFront onClick={() => props.setFlip(props.i)}>
          <Icon src={Icons[mood.name]} />
          <Mood color={mood.color}>{mood.name}</Mood>
          <Feelings color={mood.color}>
            feelings
          </Feelings>
        </CardFront>
        <FlippedCard
          mood={mood.name}
          values={mood.values}
          color={mood.color}
          selectEmotion={(e) => props.selectEmotion(e)}
          selected={props.selected}
        />
      </CardInner>
    </Card>
  );
};

export default MoodCard;
