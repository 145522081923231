export const INTRODUCTION = "INTRODUCTION";
export const CATEGORIES = "CATEGORIES";

export const submitSurvey = "submit"
export const introductionBtn = "introduction"
export const nextQuestionBtn ="next"
export const questionsType = "questions"
export const commentsType = "comments"
export const introductionTypeQuestion = 'introduction'
export const categoryTypeQuestion = 'categories'
export const questionTypeQuestion = 'question'
export const modularOutcomeTypeQuestion = 'modularOutcomeTypeQuestion'
export const modularValuesTypeQuestion = 'valuesQuestion'
export const modularCommentTypeQuestion = "modularCommentTypeQuestion"
export const multiAiFollowupCommentQuestion = 'multiAiFollowUpcommentQuestion'
export const personalityTypeQuestion = 'personalityQuestion'
export const multipleChoiceTypeQuestion = 'multipleChoice'
export const feedbackTypeQuestion = 'feedback'
export const followupAiTypeQuestion = 'aiFollowUp'
export const introSectionTypeQuestion = 'introSection'
export const commentTypeQuestion = 'comment'
export const valuesTypeQuestion = 'values'
export const completedTypeQuestion = 'completed' // if there is no token or the survey is complete
export const submitTypeQuestion = 'submit' // survey to be submitted

// response field within response object
export const ResponseFields = {
    questionsType: "response",
    categoryTypeQuestion: "response",
    modularOutcomeTypeQuestion: "response",
    followupTypeQuestion: "feedback",
    feedbackTypeQuestion: "feedback",
    multiFollowupTypeQuestion: "multiFeedback"
}

export const ModularSurveyQuestionTypes  = {
    values_builder: modularValuesTypeQuestion,
    outcome_question: modularOutcomeTypeQuestion,
    feedback_builder: modularCommentTypeQuestion,
}


export const personalityWordList = ["Disagree strongly", "Disagree moderately","Disagree a little", "Neither agree nor disagree", 
"Agree a little","Agree moderately", "Agree strongly"];

export const personalityQuestionList = ['extraverted, enthusiastic.', 'critical, quarrelsome.', 'dependable, self-disciplined.', 
'anxious, easily upset.', 
'open to new experiences, complex.',
    'reserved, quiet.', 'sympathetic, warm.', 
    'disorganized, careless.', 'calm, emotionally stable.', 'conventional, uncreative.']
