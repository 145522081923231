import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, shallowEqual, useDispatch } from "react-redux";


import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {Icon} from 'semantic-ui-react'
import Question from './Question';
import MultipleChoice from './MultipleChoice';
import ProgressBar from '../AuditV2/Components/ProgressBar';
import Button from '../AuditV2/Components/Button';

import Introduction from '../AuditV2/Introduction';
import '../AuditV2/index.css'

import {questions} from './questions';

import Submit from './Submit';
import IntroSection from './IntroSection';

import ROI from './ROI'

import MultiSelect from './MultiSelect';
// ... existing components ...
import {
	SG_MARKETING_ROI
} from "constants/actions";



  const Survey = () => {
    const dispatch = useDispatch();
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [direction, setDirection] = useState('');
    const [clicked,setClicked] = useState(0)
    const [totalIntro, setTotalIntro] = useState(questions.filter(f=>f.type=='introduction').length);
    const [isVisible, setIsVisible] = useState(true);
    const [surveyFinished,setSurveyFinished] = useState(false)
    const [responses, setResponses] = useState({ });
    const [selectedResponse, setSelectedResponse] = useState(null);
    const [showErrors, setShowErrors] = useState(false)

    const [email, setEmail] = React.useState('');

    const { get_marketingRoi, get_errors } = useSelector(
      (state) => ({
        get_marketingRoi: state.marketingRoi,
        get_errors: state.errors.errors,
      }),
      shallowEqual
    );


    // console.log(get_errors)

    useEffect(() => {
      const setVh = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }
      
      // Listen to the resize event
      window.addEventListener('resize', setVh);
      
      // Set the initial value
      setVh();
      
      // Remove event listener on cleanup
      return () => {
        window.removeEventListener('resize', setVh);
      };
      }, []);
  
      useEffect(() => {
      document.body.addEventListener('touchmove', function(e){ 
        e.preventDefault();
      });
    }, []);


    const isIntroductionActive = () => {
        return questions[currentQuestion]?.type === 'introduction' || questions[currentQuestion]?.type== 'introSection';
      };

      const handleResponse = (questionId, responseId) => {
        setResponses(prevResponses => ({
          ...prevResponses,
          [questionId]: responseId
        }));
      }

      const getResponse = (questionId) => {
        if (questionId in responses) {
            return responses[questionId];
        }
        else {
            return null;
        }
    }
    

    const handleNext = () => {
      if (currentQuestion < questions.length - 1 ) {
        setDirection('next'); 
        setClicked(clicked + 1)

        if(selectedResponse!=null){
          handleResponse(questions[currentQuestion].id,selectedResponse)
          setSelectedResponse(null)
        }
        if(currentQuestion ==questions.length-3){
          setSurveyFinished(true)
        }
      }
    };

    useEffect(()=>{
        if(direction === 'next'){
            setSelectedResponse(getResponse(questions[currentQuestion+1].id));
            setCurrentQuestion(currentQuestion + 1);
        }
        if(direction === 'back'){
          setSelectedResponse(getResponse(questions[currentQuestion-1].id));
            setCurrentQuestion(currentQuestion - 1);
        }
    },[clicked])
  
    const handleBack = () => {
      if (currentQuestion > 0) {
        setDirection('back');
        setClicked(clicked -1)
        
      }
    };

    const onEntered = () => {
      setIsVisible(true);
    };
  
    const onExited = () => {
      setIsVisible(false);
    };

    const handleMultiSelect = (choice) => {
      if(!selectedResponse){
        setSelectedResponse([choice])
        return
      }
      
      setSelectedResponse((prevArray) => {
        if (prevArray.includes(choice)) {
          // Remove the value
          let val = prevArray.filter(item => item !== choice);
          if(val.length==0){
            return null
          }
          return val
        } else {
          // Append the value
          return [...prevArray, choice];
        }
      });

    }

    function handleSubmit() {
      const payload = {
        email: email,
        response: responses,
        query_string: window.location.href,
      }

      // console.log(payload)

      dispatch({
        type: SG_MARKETING_ROI,
        payload: payload
      })
    }

    useEffect(() => {
      if (get_marketingRoi?.markeingRoi?.id > 0) {
        console.log("this changed here")
        setCurrentQuestion(9)
        setSurveyFinished(true)
      }
    },[get_marketingRoi])

    // console.log(questions[9])
    // console.log(get_marketingRoi)

    useEffect(() => {
      setShowErrors(get_errors?.data)
    },[get_errors.data])

    const renderModule = (question) => {
        switch (question.type) {
          case 'introduction':
            return <Introduction id={question.intro_id} currentQuestion={currentQuestion} total={question.total}/>;
          case 'question':
                return <Question id={question.id} question={question.question} setSelectedResponse={(r)=>setSelectedResponse(r)} selectedResponse={selectedResponse} scale={question.choices}/>;
          case 'multipleChoice':
            return <MultipleChoice choices={question.choices} question={question.text} isVisible={!isVisible} setSelectedResponse={(r)=>setSelectedResponse(r)} selectedResponse={selectedResponse}/>;
          case 'multiSelect':
            return <MultiSelect choices={question.choices} question={question.text} isVisible={!isVisible} setSelectedResponse={(r)=>handleMultiSelect(r)} selectedResponse={selectedResponse}/>;  
          case 'introSection':
            return <IntroSection data={question}/>;
          case 'completed':
            return <ROI 
                      data={responses} 
                      handleSubmit={handleSubmit}
                      email={email} 
                      setEmail={setEmail}
                     showerrors={showErrors}
                     setShowErrors={setShowErrors} />;
          case 'submit':
              return <Submit email={email} />;
          default:
            return null;
        }
      };

      const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
          // Enter key pressed
          handleNext();
        }
      };

      const renderButtons = (question) =>{
        if (question?.buttonsVisible === false) {
          return
        }
        if(surveyFinished || question.type=='completed'){
          return 
        }
        if(isIntroductionActive()){
          return (
          <>
          <div/>
          <Button onClick={handleNext}>{question?.buttonType?question?.buttonType:'Next'}</Button>
          </>)
          
        }
        if(question?.buttonType){

          return(
            <>
            <Button onClick={handleBack} type={'back'}>Back</Button>
            <Button onClick={handleNext}>{question?.buttonType}</Button>
            </>
          ) 
        }


        return (
          <>
          <Button onClick={handleBack} type={'back'}>Back</Button>
          <Button onClick={handleNext} disabled={selectedResponse===null}>Next</Button>
          </>
        )

      }
  

    return (
        <SurveyContainer>
            {!isIntroductionActive() && !surveyFinished && <ProgressBar currentQuestion={currentQuestion - totalIntro} totalQuestions={questions.length - totalIntro-2} /> }
            {isIntroductionActive() && currentQuestion!=0 && <Back onClick={handleBack}><Icon name="chevron left" /> </Back>}
          <TransitionGroup>
          <CSSTransition
                key={questions[currentQuestion]?.id}
                classNames={direction === 'next' ? 'slide' : 'slide-back'}
                timeout={{ enter: 500, exit: 500 }}
                onEntered={onEntered}
                onExited={onExited}
            >
               <ModuleContainer className="module-wrapper" finished={surveyFinished}>{renderModule(questions[currentQuestion])}</ModuleContainer> 
            </CSSTransition>
           
          </TransitionGroup>
          



            <ButtonContainer finished={surveyFinished}>
              {renderButtons(questions[currentQuestion])}                 
            </ButtonContainer>

        </SurveyContainer>
      );
    };
    
    export default Survey;
  

  // ...
  const SurveyContainer = styled.div`
	width: 100%;
	height: calc(var(--vh, 1vh) * 100);
	display: flex;
	flex-direction: column;
	background-color: #f8f9fa;
	position:relative;
	font-family: 'Raleway', sans-serif;
	overflow-x:hidden;
	`;

const SurveyContent = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
// ...

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${props=>props.finished?'none':'flex'};
  justify-content: space-between;
  padding: 1rem;
  background-color: #f8f8f8;
  z-index: 2;


`;


// ...


const ModuleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:20px;
  min-height:${props=>!props.finished?'100%':'750px'};

  @media (max-width: 768px) {
    min-height:${props=>!props.finished?'100%':'1050px'};
  }


`;

const Back = styled.div`
    position:absolute;
    left:28px;
    top:20px;
    cursor: pointer;
    font-size:20px;
    width:50px;
    height:50px;
    z-index:2;
    color:#2A3039;
`