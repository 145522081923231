// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
      translation: {
        "ModalHeader": "Your Feedback Matters! 🌟",
        "ModalContent": {
          "Intro": "We understand that your time is precious, but we'd like to take a moment to emphasize how important your feedback is to us. Your thoughts and insights can make a world of difference!",
          "Emoji": "🌍 By sharing your experiences, you help us:",
          "DriveChange": "Drive meaningful change 🛠️",
          "DriveChangeDesc": "Your feedback pinpoints areas for improvement, enabling us to prioritize and implement impactful changes.",
          "Strengths": "Reinforce our strengths 💪",
          "StrengthsDesc": "Your positive feedback helps us maintain and enhance the features that resonate with you, ensuring exceptional services.",
          "Decisions": "Influence strategic decisions 🧭",
          "DecisionsDesc": "Your input informs our decision-making process, leading to a more responsive and user-focused organization."
        },
      }
    },
    fr: {
      translation: {
        "ModalHeader": "Votre avis compte ! 🌟",
        "ModalContent": {
          "Intro": "Nous comprenons que votre temps est précieux, mais nous aimerions prendre un moment pour souligner l'importance de vos commentaires pour nous. Vos pensées et perspectives peuvent faire une grande différence!",
          "Emoji": "🌍 En partageant vos expériences, vous nous aidez à :",
          "DriveChange": "Apporter des changements significatifs 🛠️",
          "DriveChangeDesc": "Vos commentaires identifient les domaines à améliorer, nous permettant de prioriser et de mettre en œuvre des changements impactants.",
          "Strengths": "Renforcer nos points forts 💪",
          "StrengthsDesc": "Vos commentaires positifs nous aident à maintenir et à améliorer les caractéristiques qui vous touchent, garantissant des services exceptionnels.",
          "Decisions": "Influencer les décisions stratégiques 🧭",
          "DecisionsDesc": "Votre apport informe notre processus de prise de décision, conduisant à une organisation plus réactive et axée sur l'utilisateur."
        }
      }
    }
  };

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
