import React from "react";
import styled from "styled-components";



const Form = (props) =>{

	return (
		<StyledContainer>
			<div style={{width:900}}>
				<Title>Which gender do you most identify with?</Title>
				<Option>Female</Option>
				<Option>Male</Option>
				<Option>Non-Binary</Option>
				<Option>Trans-gender</Option>
				<Option>Prefer not to say</Option>

				<Next onClick={()=>props.next()}> Next</Next>
			</div>
		</StyledContainer>
		)
}


export default Form


const Title = styled.div`
	color:white;
	font-size:18px;
	font-weight:bold;
	margin-bottom:50px;
`

const Option = styled.div`
	padding:10px;
	border:1px solid white;
	background-color:rgba(255, 255, 255,0.2);
	color:white;
	font-weight:bold;
	border-radius:6px;
	margin-top:5px;
	cursor:pointer;
	width:200px;
`

const StyledContainer = styled.div`
  margin-top: 20vh;
  position: absolute;
  top:0;
  width: calc(100vw - 30px);
  display:flex;
  flex-direction:column;
  align-items:center;

  @media only screen and (min-width: 992px) {
    margin-top: 20vh;
  }
`;

const Next = styled.div`
  width: 170px;
  height: 47px;
  border-radius: 5px;
  background-color: white;
  color: black;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top:30px;
`;