import styled from "styled-components";
import React from "react";

import Slider from '@material-ui/core/Slider';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';



// const PrettoSlider = withStyles({
//   root: {
//     color: 'grey',
//     height: 8,
//   },
//   thumb: {
//     height: 24,
//     width: 24,
//     backgroundColor: '#FA675C',
//     color: '#FA675C',
//     border: '2px solid currentColor',
//     marginTop: -8,
//     marginLeft: -12,
//     '&:focus, &:hover, &$active': {
//       boxShadow: 'inherit',
//     },
//   },
//   active: {},
//   valueLabel: {
//     left: 'calc(-50% + 4px)',
//   },
//   track: {
//     height: 8,
//     borderRadius: 4,
//   },
//   rail: {
//     height: 8,
//     borderRadius: 4,
//   },
// })(Slider);

function isInt(value) {
  return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
}

const SliderQ = ({ preValue, saveResult, id, color,language,reversed }) => {

  const [res1, setRes1] = React.useState(0)
  // const [clicked, setClicked] = React.useState(false)
  let muiTheme = createMuiTheme({
  overrides:{
    MuiSlider: {
      thumb:{
      'color':'#009370',
      },
      mark:{
        color:'white'
      },
      track: {
        color:'#009370'
      },
      rail: {
        color: 'black'
      }
    }
}
});



  // console.log(preValue)
  // console.log(clicked)
  // console.log(preValue !== undefined)

  React.useEffect(() => {
    if (isInt(preValue)) {
      setRes1(preValue)
      // setClicked(true)
    }
     else {
      setRes1(0)
      // setClicked(false)
    }
  }, [preValue]);

    function ClickedOnSlider(t) {
    // setClicked(true)
    setRes1(t)
     
  }

   function commitSlide(t) {
    saveResult(t)
     
  }

  // const save = () => {
  //   saveResult(res1)
  //   setRes1(0)
  //   setClicked(false)
  // }

  return (
    <QuestionContainer>
      <Text>{ }</Text>
      <ThemeProvider theme={muiTheme}>
        <Slider
          valueLabelDisplay={"on"}
          aria-label="pretto slider"
          value={res1}
          step={1}
          marks
          min={0}
          max={10}
          id={`ediQuesSlider_${id}`}
          onChange={(e, t) => ClickedOnSlider(t)}
          onChangeCommitted={(e, t) => commitSlide(t)}
        />
      </ThemeProvider>
      <Scale>
        <div>{language===1?"Pas du tout d'accord":"Strongly disagree"}</div>
        <div style={{marginLeft:-10}}>{language===1?"Neutre":"Neutral"}</div>
        <div>{language===1?"Tout à fait d'accord":"Strongly agree"}</div>
      </Scale>

    {/*  <Button
        onClick={() => save()}
        disabled={!clicked}
        color={color}
        id="next">
        {language===1?"Suivant":"Next"}
      </Button>*/}
    </QuestionContainer>
  )
}


export default SliderQ



const QuestionContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  padding-left:10px;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    width: 600px;
    margin-top: 40px;
  }
`;


// const Button = styled.button`
//   height: 40px;
//   border-radius: 10px;
//   background-color: white;
//   border: 0;
//   box-shadow: none;
//   color: ${props=>props.color};
//   font-size: 16px;
//   font-weight: bold;

//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   width: 100px;
//   margin-top: 50px;
//   // display: none;

//   :disabled{
//     background-color:lightgrey;
//     color:grey;
//     cursor:not-allowed;
//     opacity:0.5;
//   }


//   @media only screen and (min-width: 992px), (min-height: 760px) {
//     display: flex;
//   }
// `;

const Text = styled.div`
  color:white;
  font-size:20px;
  margin-bottom:40px;
  margin-top:20px;
`


const Scale = styled.div`
  width:calc(100% + 40px);
  margin-left:-20px;
  display:flex;
  justify-content:space-between;
  color:white;
  font-size:12px;
`