import {
    PENDING_MARKETING_ROI,
    MARKETING_ROI,
    CLEAR_MARKETING_ROI,
  } from "constants/actions";
  

  const initialState = {
    pending: false,
    markeingRoi: {}
  }


export default function MarketingRoi(state = initialState, action) {
    switch (action.type) {
      case PENDING_MARKETING_ROI:
        return {
          ...state,
          pending: true
        }
    
      case MARKETING_ROI:
            return {
                ...state,
                pending: false,
                markeingRoi: action.payload
            }

      case CLEAR_MARKETING_ROI:
        return {
            ...state,
            pending: false,
            markeingRoi: {}
        }
      
      default:
        return state;
    }
  }