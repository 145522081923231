import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Screen1 from "./screen1";
import Question from "./Question";
import Sample from "./sample.json"
import Conversation from "./Conversation";
import Finish from "./Finish";
import Language from "./Language";

const Demo = () =>{
    const [screen, setScreen] = useState(1)
    const [response, setResponse] = useState({})
    const [language, setLanguage] = useState("EN")

    const saveResponse = (resp) =>{
        let _response = {...response}

        _response[screen] = resp

        setResponse(_response)      
    }

    const renderScreen = () =>{

        if(screen ==1){
            return <Screen1 setScreen={()=>setScreen(2)} language={language}/>
        }

        if(screen < Sample.length+2){
            return <Question 
            question={Sample[screen-2]} 
            response={response?.[screen]?.response}
            language={language}
            saveResponse={(resp)=>saveResponse(resp)} 
            setScreen={()=>setScreen(screen+1)}/>
        }

        if(screen == Sample.length+4){
            return <Finish language={language}/>
        }

        return <Conversation 
            id={screen}
            language={language}
            responses={response}
            response={response?.[screen]?.response}
            saveResponse={(resp)=>saveResponse(resp)} 
            setScreen={()=>setScreen(screen+1)} />
           
    }

    return (
        <Container>
            <Language language={language} setLanguage={setLanguage} languages={["EN","FR","ES"]}/>
           {renderScreen()}
        </Container>
    )
}

export default Demo

const Container = styled.div`
    display:flex;
    justify-content:center;
    font-family: 'Raleway';
    align-items:center;
    height:100vh;
    width:100vw;
    padding:20px;
`