import React, { useEffect, useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { Loader } from "semantic-ui-react";
import Typewriter from "typewriter-effect";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_CHAT_GPT_SUMMARY_MULTI, CLEAR_CHAT_GPT_SUMMARY } from "constants/actions";
import Logo from "assets/images/Ilogo.svg";
import {words} from '../translations'
import SkipModal from "../Components/SkipModal";

const follow_ups = [
  {
    question: "This is the first question that was asked",
    response: "This was the response to the question",
  },
];
const outcome =
  "What follow up question would a world class culture consultant ask based on the feedback given that would help to get more context around this area, and also help to generate recommendations for this organization?";

const context =
  "This is a conversation between a person and an organization trying to dig deeper into the feedback given in a culture survey.";

const contextFr =
  "Ceci est une conversation entre une personne et une organisation qui essaie d'approfondir les commentaires donnés dans une enquête sur la culture.";

const outcomeFr = `Quelle question de suivi un consultant en culture de classe mondiale poserait-il en fonction des commentaires donnés qui aideraient à obtenir plus de contexte dans ce domaine et aideraient également à générer des recommandations pour cette organisation?`;

const contextEs =
  "Esta es una conversación entre una persona y una organización que intenta profundizar en los comentarios dados en una encuesta cultural.";

const outcomeEs = `¿Qué pregunta de seguimiento haría un consultor de cultura de clase mundial basada en los comentarios dados que ayudarían a obtener más contexto en esta área y también ayudarían a generar recomendaciones para esta organización?`;


const MultiFollowUp = ({
  localToken,
  questionSequenceMap,
  currentQuestionDet,
  saveUpdatedAnswers,
  direction,
  handleNext,
  handleBack,
  language
}) => {
  const dispatch = useDispatch();
  const timeout = useRef(null);
  const [prompt, setPrompt] = useState(false);
  const [loading, setLoading] = useState(true);
  const [question, setQuestion] = useState("");
  const textareaRef = useRef();
  const [feedbackText, setFeedbackText] = useState("");
  const [refQues, setRefQues] = useState("");
  // skip modal
  const [open, setOpen] = useState(false);

  // console.log("MultiFollowUp", currentQuestionDet)
  // console.log("feedbackText", feedbackText)
  useEffect(() => {
    const timer1 = setTimeout(() => {
    
    }, 500);
    return () => clearTimeout(timer1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("currentQuestionDet?.response?.question", currentQuestionDet)
  // console.log("!currentQuestionDet?.response?.question", !currentQuestionDet?.response?.question) 
  // console.log("!currentQuestionDet?.response?.questio currentQuestionDet?.followUpQuestions?.length >", !currentQuestionDet?.response?.question &&
  // currentQuestionDet?.followUpQuestions?.length > 0) 

  useEffect(() => {

    let followUpStrings = [];
    // if previous qestions were skipped ignore and skip this
    // we need to add this because of reset changes !currentQuestionDet?.response
    let SkipQuestion = [];


    if (
      (!currentQuestionDet?.response?.question &&
      currentQuestionDet?.followUpQuestions?.length > 0)
    ) {
      // console.log("I am here ======================================= 67", currentQuestionDet?.id )
      setRefQues(questionSequenceMap[currentQuestionDet.followUpQuestions[0]]);
      currentQuestionDet.followUpQuestions.forEach((cq) => {
        // console.log("cq", cq);
        // console.log("questionSequenceMap[cq]", questionSequenceMap[cq]?.response);
        // console.log("questionSequenceMap[cq]?.response?.skipped", questionSequenceMap[cq]?.response?.skip);
          if (questionSequenceMap[cq]?.response?.skip) {
            SkipQuestion.push(cq);
          }

          if(language === 'EN'){
            followUpStrings.push(
              `\n\nQuestion:\n${questionSequenceMap[cq]?.response?.question}\nResponse:\n${questionSequenceMap[cq]?.response?.response}\n`
            );
          }
        if(language === 'FR'){
            followUpStrings.push(
              `\n\nQuestion:\n${questionSequenceMap[cq]?.response?.fr}\nResponse:\n${questionSequenceMap[cq]?.response?.response}\n`
            );
        }
        if(language === 'ES'){
            followUpStrings.push(
              `\n\nQuestion:\n${questionSequenceMap[cq]?.response?.es}\nResponse:\n${questionSequenceMap[cq]?.response?.response}\n`
            );
        }
        });

      let _prompt = `Context: ${context}. Here is the sequence of questions and responses so far:`;
      _prompt += followUpStrings.join("");
      _prompt += `Task: Based on this dialogue, ${outcome}. Make sure your response is simple enough for an 7th grader to understand. Only return the question itself.\n\nQuestion:\n`;

      if(language=='FR'){
        _prompt = `Contexte: ${contextFr}. Voici la séquence des questions et des réponses jusqu'à présent:`;
        _prompt += followUpStrings.join("");
        _prompt += `Tâche: Sur la base de ce dialogue, ${outcomeFr}. Assurez-vous que votre réponse est assez simple pour qu'un élève de 7e année la comprenne. Ne retournez que la question elle-même.\n\nQuestion:\n`;
      }

      if(language=='ES'){
        _prompt = `Contexto: ${contextEs}. Aquí está la secuencia de preguntas y respuestas hasta ahora:`;
        _prompt += followUpStrings.join("");
        _prompt += `Tarea: Basado en este diálogo, ${outcomeEs}. Asegúrese de que su respuesta sea lo suficientemente simple para que un estudiante de 7º grado la entienda. Solo devuelve la pregunta en sí.\n\nPregunta:\n`;
      }
      // console.log("SkipQuestion", SkipQuestion)

      if (SkipQuestion?.length > 0) {
        // quwstion was skipped because one of the previous questions was skipped
        if (direction === "slide-back") {

          handleBack();
        } else {
          const ResponseObj = {
            survey_q_id: currentQuestionDet.id,
            comments: `askOnRefResp - out of range skip checked question ${SkipQuestion}`,
            skip: true,
            feedback: "",
            dimension: refQues?.response?.dimension,
            factor: refQues?.response?.factor,
            fr: refQues?.response?.fr,
            es: refQues?.response?.es,
            id: refQues?.response?.id,
            q: refQues?.response?.q,
            s: refQues?.response?.s,
            ques_order: refQues?.response?.ques_order,
            question: refQues?.response?.question,
            response: "",
            reverse: refQues?.response?.reverse,
            role: refQues?.response?.role,
            type: refQues?.response?.type,
            prompt: "",
            prompt_question: "",
          };
          saveUpdatedAnswers(
            currentQuestionDet.id,
            ResponseObj,
            currentQuestionDet?.quesType
          );
          setOpen(false);
          handleNext();
        }
      } else {

       
        setPrompt(_prompt);
        // console.log(_prompt)
        dispatch({
          type: SG_GET_CHAT_GPT_SUMMARY_MULTI,
          payload: {
            token: localToken,
            payload: {
              prompt: `${_prompt}`,
              survey_token: localToken,
              question_no: currentQuestionDet?.sno,
            },
          },
        });

        setFeedbackText("");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentQuestionDet.id,
    // dispatch,
    // localToken,
    // currentQuestionDet,
    // questionSequenceMap,
    // handleNext,
    // refQues,
    // saveUpdatedAnswers, 
    //  handleBack,
    //  direction
  ]);

  // useEffect(() => {
  //   const delayedExecution = () => {
  //     // Your code to run after the delay
  //     let followUpStrings = [];
  //     // if previous qestions were skipped ignore and skip this
  //     // we need to add this because of reset changes !currentQuestionDet?.response
  //     let SkipQuestion = [];
  //     if (
  //       (!currentQuestionDet?.response?.question &&
  //       currentQuestionDet?.followUpQuestions?.length > 0)
  //     ) {
  //       console.log("I am here ======================================= 67", currentQuestionDet?.id )
  //       setRefQues(questionSequenceMap[currentQuestionDet.followUpQuestions[0]]);
  //       currentQuestionDet.followUpQuestions.forEach((cq) => {
  //         // console.log("cq", cq);
  //         // console.log("questionSequenceMap[cq]", questionSequenceMap[cq]?.response);
  //         // console.log("questionSequenceMap[cq]?.response?.skipped", questionSequenceMap[cq]?.response?.skip);
  //         if (questionSequenceMap[cq]?.response?.skip) {
  //           SkipQuestion.push(cq);
  //         }
  //         followUpStrings.push(
  //           `\n\nQuestion:\n${questionSequenceMap[cq]?.response?.question}\nResponse:\n${questionSequenceMap[cq]?.response?.response}\n`
  //         );
  //       });
  
  //       let _prompt = `Context: ${context}. Here is the sequence of questions and responses so far:`;
  //       _prompt += followUpStrings.join("");
  //       _prompt += `Task: Based on this dialogue, ${outcome}. Make sure your response is simple enough for an 7th grader to understand. Only return the question itself.`;
  
  //       console.log("SkipQuestion", SkipQuestion)
  
  //       if (SkipQuestion?.length > 0) {
  //         // quwstion was skipped because one of the previous questions was skipped
  //         if (direction === "slide-back") {
  //           handleBack();
  //         } else {
  //           const ResponseObj = {
  //             survey_q_id: currentQuestionDet.id,
  //             comments: `askOnRefResp - out of range skip checked question ${SkipQuestion}`,
  //             skip: true,
  //             feedback: "",
  //             dimension: refQues?.response?.dimension,
  //             factor: refQues?.response?.factor,
  //             fr: refQues?.response?.fr,
  //             id: refQues?.response?.id,
  //             q: refQues?.response?.q,
  //             ques_order: refQues?.response?.ques_order,
  //             question: refQues?.response?.question,
  //             response: "",
  //             reverse: refQues?.response?.reverse,
  //             role: refQues?.response?.role,
  //             type: refQues?.response?.type,
  //             prompt: "",
  //             prompt_question: "",
  //           };
  //           saveUpdatedAnswers(
  //             currentQuestionDet.id,
  //             ResponseObj,
  //             currentQuestionDet?.quesType
  //           );
  //           setOpen(false);
  //           handleNext();
  //         }
  //       } else {
  //         setPrompt(_prompt);
  //         dispatch({
  //           type: SG_GET_CHAT_GPT_SUMMARY_MULTI,
  //           payload: {
  //             token: localToken,
  //             payload: {
  //               prompt: `${_prompt}`,
  //               survey_token: localToken,
  //               question_no: currentQuestionDet?.sno,
  //             },
  //           },
  //         });
  
  //         setFeedbackText("");
  //       }
  //     }
  
  //     console.log('Delayed execution completed.');
  //   };
  
  //   // Set a delay of, for example, 3000 milliseconds (3 seconds)
  //   const delayMilliseconds = 10;
  
  //   // Use setTimeout to delay the execution of delayedExecution
  //   const timeoutId = setTimeout(delayedExecution, delayMilliseconds);
  
  //   // Cleanup the timeout to avoid memory leaks
  //   return () => clearTimeout(timeoutId);
  
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [questionSequenceMap.id]); // Empty dependency array to run this effect only once after the initial render

  
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = `${
        textareaRef.current.scrollHeight - 16
      }px`;
    }
  }, []);

  const handleInput = (e) => {
    e.target.style.height = "inherit";
    e.target.style.height = `${e.target.scrollHeight - 16}px`;
  };

  const handleChange = (event) => {
    setFeedbackText(event.target.value);
    const ResponseObj = {
      ques_sort_order: currentQuestionDet.id,
      ques_order: currentQuestionDet?.id,
      id: currentQuestionDet.id,
      followUpQuestions: currentQuestionDet?.followUpQuestions,
      question_type: currentQuestionDet?.quesType,
      question: question,
      fr: refQues?.response?.fr,
      es: refQues?.response?.es,
      response: event.target.value,
      prompt: prompt,
      clicked: true,
    };

    saveUpdatedAnswers(
      currentQuestionDet?.id,
      ResponseObj,
      currentQuestionDet.quesType
    );
  };

  const { get_chat_gpt } = useSelector(
    (state) => ({
      get_chat_gpt: state.chatgpt,
    }),
    shallowEqual
  );

  useEffect(() => {

    if (currentQuestionDet?.response?.question) {
      setLoading(false);

      let _question = currentQuestionDet?.response?.question;

      if (language === "FR" && currentQuestionDet?.response?.fr) {
        _question = currentQuestionDet?.response?.fr;
      }

      if (language === "ES" && currentQuestionDet?.response?.es) {
        _question = currentQuestionDet?.response?.es;
      }

      setQuestion(_question);
      setFeedbackText(currentQuestionDet?.response?.response);
    } else {
      setLoading(get_chat_gpt?.response_pending);
      setQuestion(get_chat_gpt.chat_gpt_summary?.response);
    }
  }, [get_chat_gpt, currentQuestionDet]);

  useEffect(() => {
    if (get_chat_gpt?.response_pending === true) {
      timeout.current = setTimeout(() => {
        setLoading(false)
        SkipQuestion()
      }, 15000);
    }

    if (get_chat_gpt?.response_pending === false) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }

    setLoading(get_chat_gpt?.response_pending);
    if (get_chat_gpt.chat_gpt_summary?.response) {
      setQuestion(get_chat_gpt.chat_gpt_summary?.response);
    }

    return () => {
      clearTimeout(timeout.current);
    };
  }, [get_chat_gpt]);

  useEffect(() => {
    if (get_chat_gpt?.response_pending === false) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }

    return () => {
      clearTimeout(timeout.current);
    };
  }, [get_chat_gpt]);

  const handleSkip = () => {
    setOpen(true);
    dispatch({
      type: CLEAR_CHAT_GPT_SUMMARY,
    })
  };

  function SkipQuestion() {
    const ResponseObj = {
      survey_q_id: currentQuestionDet.id,
      feedback: "",
      dimension: refQues?.response?.dimension,
      factor: refQues?.response?.factor,
      fr: refQues?.response?.fr,
      es: refQues?.response?.es,
      id: refQues?.response?.id,
      q: refQues?.response?.q,
      ques_order: refQues?.response?.ques_order,
      question: refQues?.response?.question,
      response: "",
      reverse: refQues?.response?.reverse,
      role: refQues?.response?.role,
      type: refQues?.response?.type,
      prompt: "",
      prompt_question: "",
    };
    saveUpdatedAnswers(
      currentQuestionDet.id,
      ResponseObj,
      currentQuestionDet?.quesType
    );
    setOpen(false);
    dispatch({
      type: CLEAR_CHAT_GPT_SUMMARY,
    })
    handleNext();
  }

  return (
    <FeedbackContainer>
      <Title>
        <Circle>
          <IMG src={Logo} />
        </Circle>
        Innerlogic AI
      </Title>
      <Description>
        {loading ? (
          <Loader active inline />
        ) : (
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .changeDelay(14)
                .typeString(question)
                .pauseFor(100)
                .start();
            }}
          />
        )}
      </Description>

      <StyledTextarea
        id={`txt_${currentQuestionDet?.id}`}
        value={feedbackText}
        onChange={handleChange}
        placeholder={words["textarea"][language]}
        ref={textareaRef}
        onInput={handleInput}
      />
      <Skip onClick={handleSkip}>{words['Skip'][language]} &gt;</Skip>
      <SkipModal open={open} setOpen={setOpen} SkipQuestion={SkipQuestion} language={language}/>
    </FeedbackContainer>
  );
};

export default MultiFollowUp;

// {/* <FeedbackContainer>
// <Title><Circle><IMG src={Logo}/></Circle>Innerlogic AI</Title>
// <Description>
//    {loading? <Loader active inline /> :
//        <Typewriter
//        onInit={(typewriter) => {
//        typewriter.changeDelay(14)
//        .typeString(question)
//        .pauseFor(100)
//        .start()

//        }}
//    />}
// </Description>

// <StyledTextarea
//    id={`txt_${currentQuestionDet?.id}`}
//    value={feedbackText}
//    onChange={handleChange}
//    placeholder="Type your answer here"
//    ref={textareaRef}
//    onInput={handleInput}/>

// <Skip onClick={handleSkip}>Skip &gt;</Skip>
// <SkipModal open={open} setOpen={setOpen} SkipQuestion={SkipQuestion} />
// </FeedbackContainer> */}

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 600px;
  width: 100%;
  font-family: "Raleway", sans-serif;
`;

const Description = styled.div`
  color: #2a3039;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 34px;
  line-height: 1.6;
`;
const Title = styled.div`
  color: #2d70e2;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 40px;
`;

const StyledTextarea = styled.textarea`
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #2a3039;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: none;
  font-family: "Raleway", sans-serif;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #2d70e2;
    outline: 0;
  }
`;

const Circle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: #2d70e2;
  margin-right: 10px;
  min-width: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

const IMG = styled.img`
  width: 20px;
`;

const Skip = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #2d70e2;
  margin-top: 40px;
  cursor: pointer;
`;
