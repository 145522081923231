import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';
import { Link } from "react-router-dom";

const Agreement = (props) => {
    const {agreementText, id, handleConsentCb, hasConsented,color,language,fontSize,type} = props;

    return (
        <>
          
          <ConsentText color={color} fontSize={fontSize}>{agreementText}</ConsentText>
          <Privacy>
          {type==='EDI' || type==='OTP'?
          <Link to={type==='EDI'? '/edi-survey/privacy':'/otp/privacy'} target="_blank">Privacy & Research Statement</Link>:
           <a href="https://innerlogic.com/privacy" 
            rel="noopener noreferrer" 
            target="_blank">{language===1?"Déclaration de confidentialité":"Privacy Statement"}</a>
        }
           

          </Privacy>
          <Checkbox 
          checked={hasConsented}
          id={id}
          onChange={() => handleConsentCb(!hasConsented)} 
          language={language}
          label={'I agree'}
          color={color} />
         
        </>
    );
};

Agreement.propTypes = {
  handleConsentCb: PropTypes.func,
  agreementText: PropTypes.string,
  hasConsented: PropTypes.bool,
}

/******** Styles ****************************************************/

const ConsentText = styled.div`
  font-size: 18px;
  line-height: 1.4;
  color: ${props=>props.color};
  font-family: 'Barlow', sans-serif;

  margin-bottom:20px;
  max-width:654px;

   @media only screen and (min-width:992px){
   font-size: ${props=>props.fontSize?props.fontSize:'20px'};
   line-height:1.4;
  }
`;

const Privacy = styled.div`
 font-size: 12px;
  line-height: 1.17;
   margin-bottom:20px;

   @media only screen and (min-width:992px){
   
   font-size: 14px;
  }
`


export default Agreement; 