import React from 'react';
import styled,{ keyframes } from 'styled-components';

import Intro1 from './Intro1'

const FR1 = "Vos réponses ici sont totalement anonymes. Ils ne seront en aucun cas associés à votre identité. Merci d'avance pour vos commentaires avisés."
const EN1 = "Your responses here are completely anonymous. They will never be associated with your identity in any way. Thank you in advance for the thoughtful feedback."


const Intro = ({
  saveAnswer, 
  // title, intro,
  customElement,surveyEnabled,textColor,background,language,customContent,title }) =>{

  const [page,setPage] = React.useState(0)

  function StartSurvey() {
    saveAnswer()
  }

  React.useEffect(()=>{
     window.scrollTo(0, 0)
  },[page])

  function getPage(){
    if(page===0){
      return <Intro1 setPage={()=>setPage(1)} color={textColor} language={language} custom={customContent}/>
    }

    return (
      <Content color={textColor}> 
        <SubTitle>{customContent?.custom_privacy? customContent?.custom_privacy : language===1?FR1:EN1}</SubTitle>
        {customElement}
        <Next enabled={surveyEnabled} 
        onClick={StartSurvey} id="begin" 
        color={textColor}>
          {language===1?'Commencer':'Begin'}
          </Next>
      </Content>
      )

  }

  return (
    <Container color={background}>

      <div>{title}</div>

      {getPage()}


    </Container>

    )
}

export default Intro


// const Title = styled.div`
// 	font-size: 38px;
//   font-weight: bold;

//   margin-bottom:30px;
//   color:white;
//    line-height:1;
//    max-width:654px;

//    @media only screen and (min-width:992px){
//    font-size: 54px;
//    margin-bottom:50px;
//   }
// `
const SubTitle = styled.div`
	font-size: 18px;
	line-height: 1.4;
  color: white;
  margin-bottom:10px;
  max-width:654px;


   @media only screen and (min-width:992px){
   font-size: 72px;
   margin-bottom:20px;
   font-size: 20px;
  }


`

const Next = styled.button`
	width: 170px;
  height: 47px;
   border-radius: 5px;
  background-color: white;
  color: ${props=>props.color};
   font-size: 16px;
  font-weight: bold;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  border: none;

   ${(props) => !props.enabled && `
    opacity: 0.4;
    cursor:auto;
    pointer-events: none;
  `}
`

const Container = styled.div`
  position:absolute;
  top:10%;
  padding:20px;
  background:${props=>props.color};


   @media only screen and (min-width: 768px) {
    left: calc(50% - 350px);
    top: 15%;
  }

  @media only screen and (max-height:550px){
  top: 10%;
  }

   @media(max-width:350px){
    height:850px;
  }
`

const first = keyframes`
  from {
    opacity:0;
  }

  to {
    opacity:1;
  }
`;

const Content = styled.div`
  animation: ${first} 0.5s linear;

  a{
    color:${props=>props.color==='#15056b'?'white':props.color};
    text-decoration:underline;
  }
`