import React, { useEffect, useState } from "react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
// import { Dimmer, Loader } from 'semantic-ui-react'

// import Personality from '../InSurveyPersonality'

import { v4 as uuidv4 } from 'uuid';

import {
	SG_START_BRIEF_SURVEY,
	NEW_VALIDATION_TOKEN,
	RESET_VALIDATION_TOKEN,
	// CLEAR_ALL,
	SG_POST_BRIEF_RESPONSE,
	UPDATE_BRIEF_RESPONSE,
	SG_UPDATE_BRIEF_RESPONSE
} from "constants/actions";

import {
	// BrowserView,
	// MobileView,
	isBrowser,
	isMobile,
	deviceType,
	osName,
	osVersion,
	deviceDetect,
} from "react-device-detect";

import Intro from "../SurveyComponents/Intro";

import SurveyMessage from "./Message";

import styled from "styled-components";
import { Icon } from "semantic-ui-react";

import NoToken from "./NoToken";

import { RunRules } from "utilities/FeedbackRules"
import CategoryQuestion from "./Categories";
import CommentsFeedback from './CommentsFeedback'

import { SwitchTransition, CSSTransition } from "react-transition-group";
import { TRANSITIONSPEED, SlideDirection } from "constants/animation"

import "../survey.css";

import QuestionTemplate from './Questions'
// import CommentTemplate from './Comments'
import ExistingDataFound from "survey/SurveyComponents/ExistingData";

import Agreement from "survey/SurveyComponents/Agreement";
import End from "./End";
import ModularQuestion from "survey/EDI/ModularQuestions/index";

const categoryType = "category"
const questionsType = "questions"
const commentsType = "comments"

const arrange_Questions = (questions) => {
	if (questions) {
		return questions?.factors.map((factor, f) => {
			return factor?.questions.map((q, idx) => {
				return {
					question: q.q,
					factor: f,
					q: idx,
					role: q.role,
					reverse: q.reverse,
					order: q.id
				}
			})
		}).flat().flat().sort((a, b) => a.order - b.order)
	}
}


const EdiSurvey = () => {
	const dispatch = useDispatch();
	const deviceDetected = deviceDetect();

	let { token } = useParams();

	const [direction, setDirection] = useState(1);
	const [showPrev, setShowPrev] = useState(true);
	const [showNext, setShowNext] = useState(true);
	const [role] = useState(null)
	const [showIntro, setShowIntro] = useState(false)
	const [noResponse, setNoResponse] = useState(false)
	const [ruleFeedback, setRuleFeedback] = useState(false)
	const [ruleFeedbackComplete, setRuleFeedbackComplete] = useState(true)
	const [askFeedback, setAskFeedback] = useState(false)
	const [enableNavigation, setEnableNavigation] = useState(true)
	const [completedRuleFeedback, setCompletedRuleFeedback] = useState([])

	const [defaultMessage, setDefaultMessage] = useState({
		title: "Survey completed",
		subTitle: "This survey has been successfully submitted"
	})
	// 
	const [totalQuestions, setTotalQuestions] = useState({
		total: 0,
		runningTotal: 0,
		questions: 0,
		culture: 0,
		sequence: {}
	})
	const [questions, setQuestions] = useState([])
	const [categoryQues, setCategoryQues] = useState([])
	const [employeeCategory, setEmployeeCategory] = useState([])
	// stored persisting response
	const [validationToken, setValidationToken] = useState(false)
	const [briefResponseId, setBriefResponseId] = useState(false)
	const [storedResponseFound, setStoredResponseFound] = useState(false)
	//
	const [briefResponse, setBriefResponse] = useState([])
	const [questionNo, setQuestionNo] = useState(0)
	const [visualQuestionNo, setVisualQuestionNo] = useState(0)
	//
	const [prevResponse, setPrevResponse] = useState({})
	//
	const [checkRole, setCheckRole] = useState(false)

	const [questionAnswers, setQuestionAnswers] = useState({})

	const [thankMessage, setThankMessage] = useState(false)
	const [complete, setComplete] = useState(false)

	const [surveyStatus, setSurveyStatus] = useState(0)
	const [resetAnswers, setResetAnswers] = useState(false)
	const [surveyDeleted, setSurveyDeleted] = useState(false);
	const [surveyDeletedOn, setSurveyDeletedOn] = useState(false);

	const [primaryCategory, setPrimaryCategory] = useState(false);
	const [neverAskResponse, setNeverAskResponse] = useState(false);
	const [prevFoundResponse, setPrevFoundResponse] = useState(false);

	const [consentProvided, setConsentProvided] = useState(false);
	const [language, setLanguage] = React.useState(0)
	const [askComments, setAskComments] = useState(false);

	const [modularComplete, setModularComplete] = useState(false)

	const { get_brief_response } = useSelector(
		(state) => ({
			get_brief_response: state.briefresponse,
		}),
		shallowEqual
	);

	useEffect(() => {
		setRuleFeedbackComplete(true)
		const RuleRun = RunRules({ questions, get_brief_response })
		// console.log("Feedback Rules", RuleRun)
		if (RuleRun.length > 0) {
			setRuleFeedbackComplete(false)
		}
		setRuleFeedback(RuleRun)
	}, [get_brief_response, questions]);

	// question no based on role skip logic
	// step 1 set role
	useEffect(() => {
		if (questionAnswers?.categories) {
			const CatRes = Object.values(questionAnswers?.categories)
			setCheckRole(CatRes.filter(ct => ct.priority === "secondary")?.[0]?.response)
		}
	}, [questionAnswers]);

	// // Ask Comments
	useEffect(() => {
		if (get_brief_response?.response?.questions?.comments) {
			if (get_brief_response?.response?.questions?.comments?.length > 0) {
				setAskComments(get_brief_response?.response?.questions?.comments)
			}
		}
	}, [get_brief_response]);

	useEffect(() => {
		if (get_brief_response && !complete) {
			setQuestions(arrange_Questions(get_brief_response?.response?.questions))
			setEmployeeCategory(get_brief_response?.response?.employee_category)
			setCategoryQues(get_brief_response?.response?.culture_questions?.sort((a, b) => a?.id - b?.id))
		}

	}, [get_brief_response, complete]);

	// primary Response 
	useEffect(() => {
		let _id = ''
		let _response = 0
		let subfactorFound = false
		let _subfactor = null
		const SurveyName = get_brief_response?.response?.survey_name

		if (get_brief_response?.response?.primary_question) {
			get_brief_response.response.primary_question.map(pq => {
				_id = pq.id
				if (pq?.subfactor) {
					subfactorFound = true
				}
				pq && pq.answers.map(aq => {
					if (aq?.name === SurveyName) {

						if (subfactorFound) {
							_subfactor = aq?.id
							_response = aq?.parent_id
						} else {
							_response = aq?.id
						}
					}
					return null
				})
				return null
			})
		}

		setBriefResponseId(get_brief_response?.id)

		if (get_brief_response?.validation_token) {
			setValidationToken(get_brief_response?.validation_token)
			if (get_brief_response?.response?.stored_response?.high_answer > 2) {
				setStoredResponseFound(true)
			} else {
				setStoredResponseFound(false)
			}
		} else {
			setStoredResponseFound(false)
			setValidationToken(false)
		}

		setShowIntro(get_brief_response?.intro_completed)
		// setShowIntro2(get_brief_response?.intro2_completed)
		// setStoredResponse(get_brief_response?.storedResponse)

		setPrimaryCategory({
			id: _id,
			response: _response,
			subfactor: _subfactor,
			priority: "primary"
		})

	}, [get_brief_response]);


	//skip questions
	useEffect(() => {
		const _seq = {}
		// const _empCat = employeeCategory
		// add category question nos to list

		const _prevResp = {}
		const _neverAskResp = {}

		let running_total = 0
		let total = 0
		let CatLength = 0
		if (categoryQues) {
			categoryQues.map((q) => {
				if (q?.input === "always_ask") {
					// console.log(q)
					running_total += 1
					CatLength += 1
					total += 1
					_seq[running_total] = {
						from: `always_ask ${q.id}`,
						quesType: categoryType,
						skip: false,
						sno: running_total
					}
					return null
				}

				// change this to ids
				if (q?.input === "ask_if_not_found") {
					// setPrevFoundResponse
					const _empCat = employeeCategory.filter(ec => ec.name_id === q.id)?.[0]?.value_id
					if (_empCat) {
						// skip question if response found
						running_total += 1
						CatLength += 1
						total += 0
						_seq[running_total] = {
							from: `ask_if_not_found ${q.id}`,
							quesType: categoryType,
							skip: true,
							sno: running_total
						}
						_prevResp[running_total] = {
							id: q.id,
							response: _empCat,
							priority: q.priority
						}

					} else {
						// ask question if response NOT found
						running_total += 1
						CatLength += 1
						total += 1
						_seq[running_total] = {
							from: `ask_if_not_found  ${q.id}`,
							quesType: categoryType,
							skip: false,
							sno: running_total
						}
					}
					return null
				}
				// change this to ids
				if (q?.input === "never_ask") {
					running_total += 1
					CatLength += 1
					total += 0
					_seq[running_total] = {
						from: `never_ask  ${q.id}`,
						quesType: categoryType,
						skip: true,
						sno: running_total
					}

					const _empCat = employeeCategory.filter(ec => ec.name_id === q.id)?.[0]?.value_id

					_neverAskResp[running_total] = {
						id: q.id,
						response: _empCat ? _empCat : null,
						priority: q.priority
					}
					return null
				}

				if (!q?.input) {
					running_total += 1
					CatLength += 1
					total += 1
					_seq[running_total] = {
						from: `undefined`,
						quesType: categoryType,
						skip: false,
						sno: running_total
					}
				}
				return null
			})
		}

		// console.log("employeeCategory", employeeCategory)
		// console.log("_neverAskResp", _neverAskResp)
		// console.log("_prevResp", _prevResp)

		setPrevFoundResponse(_prevResp)
		setNeverAskResponse(_neverAskResp)

		let quesLength = 0
		if (questions) {
			questions.map((q, v) => {
				if (checkRole) {
					if (q?.role) {
						if (q?.role.includes(checkRole)) {
							running_total += 1
							quesLength += 1
							total += 1
							_seq[running_total] = {
								quesType: questionsType,
								from: "question for specific role",
								skip: false,
								sno: running_total
							}
							return null
						} else {
							running_total += 1
							// quesLength += 1
							_seq[running_total] = {
								quesType: questionsType,
								from: "question not included role specifics",
								skip: true,
								sno: running_total
							}
							return null
						}
					} else {
						running_total += 1
						quesLength += 1
						total += 1
						_seq[running_total] = {
							quesType: questionsType,
							from: "question without role",
							skip: false,
							sno: running_total
						}
						return null
					}
				} else {

					running_total += 1
					quesLength += 1
					total += 1
					_seq[running_total] = {
						quesType: questionsType,
						from: "no role checked",
						skip: false,
						sno: running_total
					}
					return null
				}
			}
			)
		}

		let commLength = 0
		if (askComments) {
			askComments.map((q, v) => {
				running_total += 1
				commLength += 1
				total += 1
				_seq[running_total] = {
					quesType: commentsType,
					skip: false,
					sno: running_total
				}
				return null
			}
			)
		}

		setTotalQuestions({
			total: total,
			runningTotal: running_total,
			questions: quesLength,
			category: CatLength, // add primaryCategory
			comments: commLength,
			sequence: _seq
		})
	}, [questions, categoryQues, askComments, checkRole, employeeCategory]);

	

	// console.log(questionAnswers)
	// if the number of questions changes based on responses
	// reset or remove the older questions which were answered
	useEffect(() => {
		setResetAnswers(true)
	}, [totalQuestions.total]);

	useEffect(() => {
		if (resetAnswers && questionAnswers?.categories !== undefined) {
			setResetAnswers(false)
			let _quesAns = questionAnswers

			setQuestionAnswers({
				categories: _quesAns?.categories,
				questions: {},
				comments: {}
			})
		}
	}, [resetAnswers, questionAnswers, dispatch, totalQuestions, questionNo]);

	function nextQuestion() {
		let _questionNo = questionNo + 1
		while (_questionNo <= totalQuestions?.runningTotal) {
			if (totalQuestions.sequence[_questionNo]?.skip) {
				_questionNo++
			} else {
				setQuestionNo(_questionNo)
				setVisualQuestionNo(visualQuestionNo + 1)
				return
			}
		}
	}

	function prevQuestion() {
		let _questionNo = questionNo - 1
		while (_questionNo >= 1) {
			if (totalQuestions.sequence[_questionNo]?.skip) {
				_questionNo--
			} else {
				setQuestionNo(_questionNo)
				setVisualQuestionNo(visualQuestionNo - 1)
				return
			}
		}
	}

	// console.log("undo this at PreCloseSurvey")
	function PreCloseSurvey() {
		if ((questionNo + 1) <= totalQuestions.runningTotal) {
			nextQuestion()
			return false
		} else {
			// undo thois
			// console.log("un do this")
			if (!ruleFeedbackComplete) {
				setAskFeedback(true)
				return false
			}
			setAskFeedback(false)
			setComplete(true)
			return true
		}
	}

	useEffect(() => {
		if (get_brief_response?.response) {
			setNoResponse(false)
		} else {
			setNoResponse(true)
		}
	}, [get_brief_response]);

	useEffect(() => {
		setPrevResponse(null)
		if (questionNo) {
			const QueType = totalQuestions?.sequence?.[questionNo]?.quesType
			// console.log(QueType)
			if (QueType === categoryType) {
				setPrevResponse(get_brief_response?.answers?.categories?.[questionNo])
			}
			if (QueType === questionsType) {
				setPrevResponse(get_brief_response?.answers?.questions?.[questionNo])
			}
			if (QueType === commentsType) {
				setPrevResponse(get_brief_response?.answers?.comments?.[questionNo])
			}
		}
	}, [totalQuestions, get_brief_response.answers, questionNo]);

	useEffect(() => {
		setShowPrev(false)
		setShowNext(false)
		setBriefResponse(get_brief_response)
		if (!questionNo) {
			setShowPrev(false)
			setShowNext(false)
		}
		if (questionNo === 1) {
			setShowPrev(false)
			if ((questionNo) <= (get_brief_response?.high_answer)) {
				if (questionNo < totalQuestions?.total) {
					setShowNext(true)
				}
			}
		}
		if (questionNo > 1) {
			setShowPrev(true)
			if ((questionNo) <= (get_brief_response?.high_answer)) {
				if (questionNo < totalQuestions?.total) {
					setShowNext(true)
				}
			}
		}
		// 
		setSurveyDeleted(get_brief_response?.to_delete ? get_brief_response?.to_delete : false)
		setSurveyDeletedOn(get_brief_response?.deleted_on ? get_brief_response?.deleted_on : false)
		setSurveyStatus(get_brief_response?.survey_status ? get_brief_response?.survey_status : 0)
		setQuestionAnswers(get_brief_response?.answers)

	}, [get_brief_response, questionNo, totalQuestions]);


	useEffect(() => {
		// Run! Like go get some data from an API.
		if (token) {
			dispatch({
				type: SG_START_BRIEF_SURVEY,
				payload: `?token=${token}`
			});
		}
	}, [dispatch, token]);

	//
	// useEffect(() => {
	// 	const _seq = {}
	// 	// add category question nos to list
	// 	let CatLength = 0
	// 	if (categoryQues) {
	// 		CatLength = categoryQues?.length
	// 		categoryQues.map((q, v) => _seq[v + 1] = "category")
	// 	}

	// 	if (questions) {
	// 		questions.map((q, v) => _seq[v + CatLength + 1] = "question")
	// 	}

	// 	setTotalQuestions({
	// 		total: questions?.length + CatLength,
	// 		questions: questions?.length,
	// 		category: CatLength,
	// 		sequence: _seq
	// 	})
	// }, [questions, categoryQues]);



	function submitAnswer() {
		const CompleteCategories = { 
			...questionAnswers?.categories, 
			...prevFoundResponse, ...neverAskResponse }
		if (CompleteCategories) {
			CompleteCategories[0] = primaryCategory
		}

		let _completeCategories = []
		if (CompleteCategories) {
			_completeCategories = Object.values(CompleteCategories)
		}

		let _completeQuestions = []
		if (questionAnswers.questions) {
			_completeQuestions = Object.values(questionAnswers.questions)
		}

		let _completeComments = []
		if (questionAnswers.comments) {
			_completeComments = Object.values(questionAnswers.comments)
		}

		const surveyComponents = get_brief_response?.modular_response


		dispatch({
			type: SG_POST_BRIEF_RESPONSE,
			payload: {
				survey_token: briefResponse?.survey_token,
				debrief_schedule: briefResponse?.debrief_schedule,
				employee: briefResponse?.employee,
				response: {
					questions: {
						questions: _completeQuestions,
						categories: _completeCategories,
						comments: _completeComments,
					},

					total_question_map: totalQuestions,
					feedback: completedRuleFeedback,
					modular_response: surveyComponents,
				},
				full_response: {
					isBrowser: isBrowser,
					isMobile: isMobile,
					browser: deviceDetected.browserName,
					deviceType,
					osVersion,
					osName,
					deviceDetected: deviceDetected,
					response: {
						questions: {
							questions: _completeQuestions,
							categories: _completeCategories,
							comments: _completeComments,
						},
						total_question_map: totalQuestions,
						feedback: completedRuleFeedback,
						modular_response: surveyComponents,
					}
				}
			},
		});

		dispatch(
			{
				type: RESET_VALIDATION_TOKEN,
				payload: null
			}
		)

		setThankMessage(true)

	}

	const IntroQuestionAnswer = () => {
		const CatLength = categoryQues ? categoryQues.length : 0;
		setDirection(1)
		const NewValidationToken = uuidv4()
		const payload = {
			current_question: CatLength > 0 ? 0 : 1,
			current_answer: 0,
			high_answer: 0,
			answers: {
				categories: {},
				questions: {},
				comments: {}
			},
			complete: false,
			intro_completed: true,
			validation_token: NewValidationToken,
			modular_response: get_brief_response?.modular_response,			
		}

		setDirection(1)
		dispatch({
			type: NEW_VALIDATION_TOKEN,
			payload: NewValidationToken
		})
		dispatch({
			type: UPDATE_BRIEF_RESPONSE,
			payload: {
				total_categories: CatLength,
				total_questions: totalQuestions?.runningTotal,
				current_category: CatLength > 0 ? 1 : 0,
				category_answer: 0,
				high_category: 0,
				total_question_map: totalQuestions,
				...payload
			}
		});
		// persist with api
		if (briefResponseId) {
			dispatch({
				type: SG_UPDATE_BRIEF_RESPONSE,
				payload: {
					id: briefResponseId,
					query: `?token=${token}&start=true`,
					validation_token: NewValidationToken,
					...(payload && { stored_response: payload }),
				}
			})
		}


		setShowIntro(true)
		nextQuestion()
	}

	const saveCategoryAnswer = (response) => {
		let _answers = questionAnswers?.categories
		setDirection(1)
		if (_answers?.questionNo) {
			_answers.questionNo = response

		} else {
			_answers[questionNo] = response
		}

		setQuestionAnswers({
			question: questionAnswers.question,
			categories: _answers,
		})

		let _complete = PreCloseSurvey()

		const payload = {
			current_question: questionNo,
			current_answer: questionNo,
			high_answer: questionNo > briefResponse?.high_answer ? questionNo : briefResponse?.high_answer,
			answers: {
				questions: questionAnswers?.questions,
				comments: questionAnswers?.comments,
				categories: _answers
			},
			complete: _complete,
			intro_completed: showIntro,
			validation_token: validationToken,
			survey_personality: get_brief_response?.survey_personality,
            in_survey_personality: get_brief_response?.in_survey_personality,
			modular_response: get_brief_response?.modular_response,
		}

		dispatch({
			type: UPDATE_BRIEF_RESPONSE,
			payload
		});

		// persist with api
		if (briefResponseId) {
			dispatch({
				type: SG_UPDATE_BRIEF_RESPONSE,
				payload: {
					id: briefResponseId,
					query: `?token=${token}`,
					validation_token: validationToken,
					...(payload && { stored_response: payload }),
				}
			})
		}


	}


	// const saveQuestionAnswer = (response) => {
	// 	let _answers = questionAnswers.question
	// 	setDirection(1)
	// 	if (_answers?.questionNo) {
	// 		_answers.questionNo = response

	// 	} else {
	// 		_answers[questionNo] = response
	// 	}
	// 	setQuestionAnswers({
	// 		categories: questionAnswers.categories,
	// 		question: _answers
	// 	})

	// 	let _complete = PreCloseSurvey()

	// 	const payload = {
	// 		current_question: questionNo,
	// 		current_answer: questionNo,
	// 		high_answer: questionNo > briefResponse?.high_answer ? questionNo : briefResponse?.high_answer,
	// 		answers: {
	// 			categories: questionAnswers?.categories,
	// 			comments: questionAnswers?.comments,
	// 			questions: _answers
	// 		},
	// 		complete: _complete,
	// 		intro_completed: showIntro,
	// 		validation_token: validationToken,
	// 	}
	// 	dispatch({
	// 		type: UPDATE_BRIEF_RESPONSE,
	// 		payload
	// 	});
	// 	// persist with api
	// 	dispatch({
	// 		type: SG_UPDATE_BRIEF_RESPONSE,
	// 		payload: {
	// 			id: briefResponseId,
	// 			stored_response: payload,
	// 			query: `?token=${token}`,
	// 			validation_token: validationToken,
	// 		}
	// 	})
	// }

	const saveQuestionAnswer = (response) => {
		// console.log(response)

		let _answers = questionAnswers.questions
		setDirection(1)
		if (_answers?.questionNo) {
			_answers.questionNo = response

		} else {
			_answers[questionNo] = response
		}
		setQuestionAnswers({
			categories: questionAnswers?.categories,
			comments: questionAnswers?.comments,
			questions: _answers
		})
		let _complete = PreCloseSurvey()
		const payload = {
			visual_question: visualQuestionNo,
			current_question: questionNo,
			current_answer: questionNo,
			high_answer: questionNo > briefResponse?.high_answer ? questionNo : briefResponse?.high_answer,
			answers: {
				categories: questionAnswers?.categories,
				comments: questionAnswers?.comments,
				questions: _answers
			},
			complete: _complete,
			intro_completed: showIntro,
			validation_token: validationToken,
			modular_response: get_brief_response?.modular_response,
		}
		dispatch({
			type: UPDATE_BRIEF_RESPONSE,
			payload
		});
		// persist with api
		if (briefResponseId) {
			dispatch({
				type: SG_UPDATE_BRIEF_RESPONSE,
				payload: {
					id: briefResponseId,
					stored_response: payload,
					query: `?token=${token}`,
					validation_token: validationToken,
				}
			})
		}

	}


	const forward = () => {
		if (briefResponse?.high_answer >= questionNo) {
			setPrevResponse(null)
			setDirection(1)
			if ((questionNo + 1) <= totalQuestions.runningTotal) {
				nextQuestion()
				setComplete(false)
			} else {
				setComplete(true)

			}
		}
	}


	const back = () => {
		if (questionNo > 1) {
			setDirection(0)
			setPrevResponse(null)
			setComplete(false)
			if ((questionNo) === totalQuestions.runningTotal) {
				setComplete(false)
				if (questionNo === briefResponse?.current_question) {
					dispatch({
						type: UPDATE_BRIEF_RESPONSE,
						payload: {
							current_question: 0,
							current_answer: 0,
							complete: false
						},
					});
				}
				else {
					prevQuestion()
				}
			}
			else {
				prevQuestion()
			}
		}
	}

	// function PreCloseSurvey() {
	// 	if ((questionNo + 1) <= totalQuestions.runningTotal) {
	// 		setQuestionNo(questionNo + 1)
	// 		return false
	// 	} else {
	// 		if (!ruleFeedbackComplete) {
	// 			setAskFeedback(true)
	// 			return false
	// 		}
	// 		setAskFeedback(false)
	// 		setComplete(true)
	// 		return true
	// 	}
	// }



	useEffect(() => {
		if (role != null) {
			setQuestions(questions.filter(f => (f.role === null || f.role === role)))
		}
	}, [role, questions])

	useEffect(() => {
		// setPageLoading(true);
		setDefaultMessage({
			title: "Looking for Survey",
			subTitle: "Please wait while we try and get that survey loaded"
		})
		const timer1 = setTimeout(() => {
			setDefaultMessage({
				title: "No Survey details found",
				subTitle: "Unfortunately we have not been retrive any information on this survey"
			})
		}, 5000);
		return () => clearTimeout(timer1);
	}, [noResponse]);

	// console.log(totalQuestions?.sequence[questionNo])
	// console.log(questionNo)
	// console.log("complete", complete)
	// console.log("modularComplete", modularComplete)
	// console.log("askFeedback", askFeedback)

	const Main_section = () => {
		const introText = "nuLogic processes your personal data and aggregates responses on behalf of your employer. Your personal data is never directly personally identifiable to your employer and is only ever presented in an anonymized fashion. Please consult our privacy & research statement for further details."
		const consentText = "Do you consent with your personal data being processed as described above? You must click accept in order to give your feedback.";
		const customAgreement = (<Agreement
			agreementText={consentText}
			color={'#009370'}
			id={'intro1AgreeCheckBox'}
			handleConsentCb={(checked) => setConsentProvided(checked)}
			type={'EDI'}
			hasConsented={consentProvided} />);

		if (!showIntro) {
			// ask for existing data in storage
			if (storedResponseFound) {
				return (
					<ExistingDataFound
						show={true}
						setQuestionNo={setQuestionNo}
						setVisualQuestionNo={setVisualQuestionNo}
						setShowIntro={setShowIntro}
						token={token}
						briefResponseId={briefResponseId}
						validationToken={validationToken}
						storedResponse={get_brief_response?.response?.stored_response}

					/>
				)
			}
			return <Intro
				key={questionNo}
				title={'Equity Diversity and Inclusion'}
				intro={introText}
				consentText={consentText}
				setShowIntro={setShowIntro}
				saveAnswer={IntroQuestionAnswer}
				consentCheckedCb={(response) => setConsentProvided(response)}
				customElement={customAgreement}
				surveyEnabled={consentProvided}
			/>
		}

		if (complete) {
			// before we close check for any additonal modular questions which are enabled
			if (!modularComplete) {
				return <ModularQuestion
					id={questionNo}
					briefResponseId={briefResponseId}
					setModularComplete={setModularComplete}
					setEnableNavigation={setEnableNavigation}
					language={language}
					token={token} />
			}

			if (modularComplete) {
			return <End
				key={questionNo}
				title={''}
				submitAnswer={() => submitAnswer()}
				intro={'You have completed all the questions in this survey. Please submit your responses'} />
			}
		}

		if (askFeedback) {
			return <CommentsFeedback
				ruleFeedback={ruleFeedback}
				id={questionNo}
				setRuleFeedback={setRuleFeedback}
				setCompletedRuleFeedback={setCompletedRuleFeedback}
				setRuleFeedbackComplete={setRuleFeedbackComplete}
				// submitAnswer={() => submitAnswer()}
				setAskFeedback={setAskFeedback}
				setComplete={setComplete}
			/>
		}

		if (totalQuestions?.sequence[questionNo]?.quesType === categoryType) {
			return <CategoryQuestion
				key={questionNo}
				id={questionNo}
				checkQuestion={totalQuestions?.sequence[questionNo]}
				save={saveCategoryAnswer}
				response={prevResponse}
				data={categoryQues[questionNo - 1]}
				employeeCategory={employeeCategory}
			/>
		}
		if (!complete) {
			if (totalQuestions?.sequence[questionNo]?.quesType === questionsType) {
				return <QuestionTemplate
					key={questionNo}
					id={questionNo}
					// save={(r) => saveQ(r)}
					saveQuestionAnswer={saveQuestionAnswer}
					response={prevResponse}
					textColor={'#009370'}
					question={questions.filter(
						q => q.order === questionNo - totalQuestions?.category
					)[0]}
				/>
			}
			// if (totalQuestions?.sequence[questionNo]?.quesType === commentsType) {
			// 	return <CommentTemplate
			// 		key={questionNo}
			// 		// save={(r) => saveQ(r)}
			// 		saveCommentAnswer={saveCommentAnswer}
			// 		response={prevResponse}
			// 		textColor={getTextColor()}
			// 		language={language}
			// 		question={askComments.filter(
			// 			q => Number(q.order) === questionNo - totalQuestions?.category
			// 		)[0]}
			// 	/>
			// }
		}

		// if (comments.length < CultureAuditQuestion.question.comments.length) {
		// 	return <Comments
		// 		key={location}
		// 		save={(r) => saveComment(r)}
		// 		data={CultureAuditQuestion.question.comments[comment_index]} />
		// }
		// if (complete) {
		// 	return <div id="end" />
		// }


		return <NoToken />


	}

	if (noResponse) {
		return <SwitchTransition>
			<CSSTransition
				key={`${questionNo} ${showIntro} ${askFeedback}`}
				timeout={TRANSITIONSPEED}
				classNames={SlideDirection[1]}
			>
				<NoToken
					{...defaultMessage}
				/>
			</CSSTransition>
		</SwitchTransition>

	}
	if (!token) {
		return <NoToken
			{...defaultMessage} />
	}

	if (thankMessage) {
		return (
			<Container>
				<SwitchTransition>
					<CSSTransition
						key={`${questionNo} ${showIntro} ${askFeedback}`}
						timeout={TRANSITIONSPEED}
						classNames={SlideDirection[direction]}
					>
						<SurveyMessage
							message={'Thanks for your survey response'}
							onTime={new Date()}
						/>
					</CSSTransition>
				</SwitchTransition>
			</Container>
		)

	}

	if (surveyDeleted) {
		return (
			<Container>
				<SwitchTransition>
					<CSSTransition
						key={`${questionNo} ${showIntro} ${askFeedback}`}
						timeout={TRANSITIONSPEED}
						classNames={SlideDirection[direction]}
					>
						<SurveyMessage
							onTime={surveyDeletedOn}
							status={surveyStatus}
						/>
					</CSSTransition>
				</SwitchTransition>

			</Container>
		)
	}


	return (
		<Container>
			<SwitchTransition>
				<CSSTransition
					key={`${questionNo} ${showIntro} ${askFeedback}`}
					timeout={TRANSITIONSPEED}
					classNames={SlideDirection[direction]}
				>
					{Main_section()}
				</CSSTransition>
			</SwitchTransition>

			{enableNavigation && showIntro &&<CounterContainer>
				<Counter>
					<div>
						<Title>EDI Survey</Title>
						{visualQuestionNo === 0 ? 1 : visualQuestionNo} of {totalQuestions?.total} questions
					</div>
				</Counter>
				<Icon
					name="chevron up"
					disabled={!showPrev || askFeedback}
					style={{ cursor: (!showNext || askFeedback) ? "not-allowed" : "pointer" }}
					onClick={back}
				/>
				<Space />

				<Icon
					name="chevron down"
					style={{ cursor: (!showNext || askFeedback) ? "not-allowed" : "pointer", zIndex: 2 }}
					disabled={!showNext || askFeedback}
					onClick={forward}
				/>
			</CounterContainer>}

		</Container>
	)
}

/******** Styles ****************************************************/

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0,0.9);
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CounterContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-size: 14px;
  @media only screen and (min-width: 992px) {
    bottom: 50px;
    right: 50px;
    font-size: 20px;
  }
`;

const Counter = styled.div`
  padding: 5px 10px 5px 10px;
  background-color: rgba(236, 236, 236, 0.3);
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  border-radius: 7px;
  margin-right: 10px;
  @media only screen and (min-width: 992px) {
    padding: 10px 25px 10px 25px;
    font-size: 16px;
    margin-right: 25px;
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  @media only screen and (min-width: 992px) {
    margin-bottom: 5px;
    font-size: 20px;
  }
`;

const Space = styled.div`
  @media only screen and (min-width: 992px) {
    width: 20px;
  }
`;

export default EdiSurvey
