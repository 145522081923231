import React from 'react';
import styled,{keyframes} from 'styled-components';
import Slider from './Slider';
import {words} from './translations';

const Question = ({ question,id,saveResponse,setScreen,response,language }) => {


  const getQuestion = () =>{
    if(language=="EN"){
      return question.question
    }
    if(language=="FR"){
      return question.fr
    }
    if(language=="ES"){
      return question.es
    }
  }

    
 return (<QuestionContainer key={question.id}>
    <Title>{words['Culture Question'][language]}</Title>
    <Qualifier>{words['qualifier'][language]}</Qualifier>
    <QuestionText>{getQuestion()}</QuestionText>
    <Slider 
      id={`slider-track-${id}`} 
      question={question}
      language={language}
      saveUpdatedAnswers={saveResponse}/>


    <ButtonArea>
                <Button onClick={()=>setScreen()} disabled={response==undefined}>
                {words['Next'][language]}
                </Button>
            </ButtonArea>
  </QuestionContainer>)
};
export default Question;



const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const QuestionContainer = styled.div`
  width: 100%;
  max-width:600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
`;

const Title = styled.div`
  color: #2D70E2;
  text-transform: uppercase;
  font-size:14px;
  font-weight:600;
  margin-bottom: 40px;
`

const Qualifier = styled.div`
  color:#2A3039;
  font-size:16px;
  font-weight:600;
  margin-bottom:14px;
  line-height:1.4;
`

const QuestionText = styled.p`
  font-size: 16px;
  font-weight:400;
  color: #2A3039;
`;


const ButtonArea = styled.div`
    display: flex;
    width:100%;
    justify-content: flex-end;
    margin-top: 20px;

`;

const Button = styled.button`
    background-color: #2D70E2;
    color: white;
    font-size: 16px;
    font-weight: 400;
    border: none;
    border-radius: 5px;
    padding: 10px 35px;
    cursor: pointer;
    transition: all 0.3s;
    margin-top:40px;
    &:hover {
        background-color: #1F4E9D;
    }

    &:disabled{
        background-color: #ccc;
        cursor: not-allowed;
    }
`;