import React from 'react';
import styled,{ keyframes } from 'styled-components';
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { TRANSITIONSPEED, SlideDirection } from "constants/animation"
import {ArrowIcon} from '../ArrowIcon'

import {Text1,Text2,Text3,Text4} from './Text'


const Intro = ({
  saveAnswer, 
  // title, intro,
  customElement,surveyEnabled,textColor,background,language,customContent,title }) =>{

  const [page,setPage] = React.useState(0)
  const [show,setShow] = React.useState(false)
  const [direction, setDirection] = React.useState(1);

  function StartSurvey() {
    saveAnswer()
  }

  React.useEffect(()=>{
     window.scrollTo(0, 0)
  },[page])

  React.useEffect(()=>{
    setShow(true)
  },[])


  const next = () =>{

    if(page==3){
      saveAnswer()
    }

    setShow(false)
    setTimeout(()=>{
      setPage(page+1)
      setShow(true)
    },1000)
  }

  return (

          <Container color={background}>

              <TextWrapper show={show}>
               {page==0?<Text1 color={textColor} language={language}/>
                 :page==1?<Text2 color={textColor} language={language}/>:
                 page==2?<Text3 color={textColor} language={language}/>:
                <Text4 language={language}/>}
              </TextWrapper>
              
              <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                <Block show={page==3} onClick={()=>next()} color={textColor=='#5A3080'?'#5A3080':'#09E1C0'}>
                  <Text show={page==3}>{language==1? "J'accepte":"I agree"}</Text>
                  <ArrowContainer show={page==3}>
                  {ArrowIcon(textColor=='#5A3080'?'#5A3080':'#09E1C0')}
                  </ArrowContainer>

                </Block>
              </div>

          </Container>

    )
}

export default Intro




const Container = styled.div`
  position:absolute;
  top:calc(40% - 200px);
  padding:15px;
  background:${props=>props.background};
  font-family: 'Poppins', sans-serif;
  max-width:100vw;
  display:flex;
  flex-direction:column;

  @media(max-width:768px){
    width:100vw;
  }

  @media(min-width:768px){
    left:calc(50% - 300px);
    top:25%
  }
`



const TextWrapper = styled.div`
  opacity:${props=>props.show?1:0};
  transform:${props=>props.show?'translate(20px,10px)':''};
  transition:all 1s;
  height:350px;
  max-width:600px;
  padding:0px;
  justify-content:center;

  @media (max-width:768px){
    max-width:calc(100% - 40px);
  }

  @media (min-width:768px){
    width:600px;
  }
`


const Image = styled.img`
  height:50px;
  cursor:pointer;
  position:absolute;
  right:${props=>props.show?'-40px':'20px'};
   user-select: none;
`


const ArrowContainer = styled.div`
    height:50px;
  cursor:pointer;
  position:absolute;
  right:${props=>props.show?'-40px':'20px'};
   user-select: none;
`

const Block = styled.div`
  background-color:${props=>props.color?props.color:'#09E1C0'};
  color:${props=>props.show?'white':'#002046'};
  display:flex;
  align-items:center;
  padding-left:${props=>props.show?'20px':0};
  padding-right:0px;
  margin-right:${props=>props.show?'50px':0};
  border-radius:7.5px;
  position:relative;
  height:50px;
  width:${props=>props.show?'90px':0};
  transition:all 0.5s;
  cursor:pointer;
   user-select: none;

`

const Text = styled.div`
  display:${props=>props.show?'flex':'none'};
`