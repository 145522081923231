import React, { useEffect } from "react";
import styled from "styled-components";

import "survey/survey.css";
import SelectTeamMember from './SelectTeamMembers'
// import RankTime from './RankTime'
import TeamHealth from './TeamHealth'
// import Background from './Background'
// import ErrorRedo from "./ErrorRedo";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";

import { CSSTransition, SwitchTransition } from "react-transition-group";
import {TRANSITIONSPEED} from "constants/animation"

import Intro from './Intro'

import {
  // BrowserView,
  // MobileView,
  isBrowser,
  isMobile,
  deviceType,
  osName,
  osVersion,
  deviceDetect,
} from "react-device-detect";

import {
  LOAD_CULTURE_QUESTIONS,
  LOAD_MOOD_QUESTIONS,
  LOAD_LEADERSHIP_QUESTIONS,
  SET_ANSWERS,
  SG_START_SURVEY,
  LOAD_PREVIOUS_RESPONSE,
  ADD_CULTURE_ANSWER,
  SUBMIT_ANSWER,
  ADD_TEAM_ANSWER,
} from "constants/actions";

import BlankUser from "assets/images/empty/user.svg"
const TeamIntelligence = (props) => {
  // console.log(props)
  const {setSurveyType} = props
  const dispatch = useDispatch();
  const deviceDetected = deviceDetect();
  let { token } = useParams();
  

  const [selected, setSelected] = React.useState([])
  const [question, setQuestion] = React.useState(1)
  const [intro,setIntro] = React.useState(true)

  const [intQuestion, setIntQuestion] = React.useState([])
  // const [answer,setAnswer] = React.useState(null)

  const [answer, setAnswer] = React.useState(false);
  const [comment, setComment] = React.useState(null);

  const [employeeId, setEmployeeId] = React.useState(false)

  const [teamList, setTeamList] = React.useState([])

  const [currQuestion, setCurrQuestion] = React.useState(0);
  const [maxQuestion, setMaxQuestion] = React.useState(0);
  const [cultureAnswers, setCultureAnswers] = React.useState([]);

  useEffect(() => {
    // Run! Like go get some data from an API.
    if (token) {
      dispatch({ type: SG_START_SURVEY, payload: `?token=${token}` });
      dispatch({ type: LOAD_PREVIOUS_RESPONSE, payload: `?token=${token}` });
      dispatch({ type: LOAD_CULTURE_QUESTIONS, payload: `?token=${token}` });
      dispatch({ type: LOAD_MOOD_QUESTIONS, payload: `?token=${token}` });
      dispatch({ type: LOAD_LEADERSHIP_QUESTIONS, payload: `?token=${token}` });
    }
  }, [dispatch, token]);

  const { get_questions,
    previous_response,
    get_answers,  } = useSelector(
      (state) => ({
        get_questions: state.questions.culture_questions.questions,
        previous_response: state.questions.previous_response,
        get_answers: state.answers,

      }),
      shallowEqual
    );

  function SubmitTeam(no) {
    // console.log(no)
    dispatch({
      type: ADD_TEAM_ANSWER,
      payload: selected,
    })
    setQuestion(no)
    dispatch({
      type: SET_ANSWERS,
      payload: {
        curr_direction: 1,
        culture_max_ques: 6,
        culture_cur_ques: 1,
        culture_cur_comment: 0,
        isBrowser: isBrowser,
        isMobile: isMobile,
        browser: deviceDetected.browserName,
        deviceType,
        osVersion,
        osName,
        deviceDetected: deviceDetected,
      },
    });
    setCurrQuestion(1)
  }

  React.useEffect(() => {
    if (get_answers?.culture_cur_ques) {
      setCurrQuestion(get_answers.culture_cur_ques);
      setMaxQuestion(get_answers.culture_max_ques);
    }
    if (get_answers?.employee) {
      setEmployeeId(get_answers.employee)
    }
   
    setCultureAnswers(get_answers?.culture_answers)
    
  }, [get_answers]);
  // function startSurvey() {

  // }

  const SaveAnswer = () => {
    dispatch({
      type: ADD_CULTURE_ANSWER,
      payload: {
        id: intQuestion.id,
        answer: answer,
        comments: comment,
      },
    });
    dispatch({
      type: SET_ANSWERS,
      payload: {
        culture_high_ans: intQuestion.id,
        curr_direction: 1,
      },
    });
    // let response = [answer, comment];
    setAnswer(null);
    setComment(null);
      window.scrollTo(0, 0)
    setQuestion(question + 1)
    // props.next(response);
  };
  //
  // console.log(selected)
// console.log(get_answers.token)

  const SubmitAnswer = () => {
    setSurveyType(2)
    
    // dispatch({
    //   type: ADD_CULTURE_ANSWER,
    //   payload: {
    //     id: intQuestion.id,
    //     answer: answer,
    //     comments: comment,
    //   },
    // });
    
    // console.log(get_answers.culture_answers)
    // console.log(get_answers.culture_answers.length)

  
    dispatch({
      type: ADD_TEAM_ANSWER,
      payload: selected,
    })

    dispatch({
      type: SUBMIT_ANSWER,
      payload: {
        culture_answers: [
          ...get_answers.culture_answers,
          // { id: intQuestion.id, answer: answer, comments: comment },
        ],
        time_spent_with: selected,
        employee: get_answers.employee,
        token: get_answers.token,
        device: deviceDetected,
        mobile: isMobile ? "Yes" : "No",
        browser: isBrowser ? "Desktop" : "Other Devise",
        operating_system: osName,
        user_ip: get_answers.user_ip,
        culture_survey_compliance: get_answers.culture_survey_compliance,
      },
    });

    dispatch({
      type: SET_ANSWERS,
      payload: {
        curr_survey_type: get_answers.all_survey_types[1],
        survey_type: get_answers.all_survey_types[1],
        all_survey_types: get_answers.all_survey_types.slice(1),
      },
    });

    // history.push(`/survey/${token}`)
    // props.next(response);
  };
  // console.log(get_questions)
  // console.log(previous_response)

  React.useEffect(() => {
    function SetName(p) {
      if (p.first_name) {
        return `${p.first_name} ${p.last_name}`
      } else {
        return p.email
      }
    }
    const team = []
    if (previous_response?.team_list) {
      previous_response.team_list.map(p => {
        // console.log(p.picture)
        return team.push(
          {
            "id": p.id,
            "key": p.id,
            "text": SetName(p),
            "value": p.id,
            "image": { avatar: true, src: p.picture ? p.picture : BlankUser },
          })
      })
    }
    setTeamList(team)
  }, [previous_response.team_list])

  // useEffect(() => {
  //   if (previous_response?.team_list) {
  //     setTeamList(previous_response?.team_list)
  //   }
  // },[previous_response])
  // console.log(question)

  function ShowResponse() {
    // the intro page
    if (intro) {
      return  (
        <Intro 
                  next={()=>setIntro(false)}
                  currQuestion={get_answers.culture_cur_ques}
                  key={0}/>
      )
    }
     // all responses start here 
                // there are 6 questions with follow up 
                // the seventh question would be 
                // about the team
    return (
       <Response
                  question={question}
                  selected={selected}
                  setSelected={setSelected}
                  setQuestion={setQuestion}
                  SubmitTeam={SubmitTeam}
                  key={question}
                  employeeId={employeeId}
                  teamList={teamList}
                  previous_response={previous_response}
                  answer={answer}
                  get_questions={get_questions}
                  intQuestion={intQuestion}
                  setIntQuestion={setIntQuestion}
                  setAnswer={setAnswer}
                  comment={comment}
                  cultureAnswers={cultureAnswers}
                  setComment={setComment}
                  SaveAnswer={SaveAnswer}
                  SubmitAnswer={SubmitAnswer}
                  currQuestion={currQuestion}
                  maxQuestion={maxQuestion}
              />
    )
  }

  return (
    <>
    <Container>
      <SurveyContainer>

      <SwitchTransition>
					<CSSTransition
						key={question}
						timeout={TRANSITIONSPEED}
						classNames="SlideUp"
					>
            {ShowResponse()}
          </CSSTransition>
      </SwitchTransition>

      </SurveyContainer>
    </Container> 
    </>
  )
}


export default TeamIntelligence

function Response({ selected, setSelected,
  employeeId,
  setQuestion, teamList,
  intQuestion, setIntQuestion,
  comment, setComment,
  answer, setAnswer,
  cultureAnswers,
  get_questions,
  SubmitAnswer, SaveAnswer,
  previous_response,
  currQuestion, maxQuestion,
  question }) {

  if (currQuestion === 7) {
    return  <SelectTeamMember
      selected={selected}
      setSelected={(s) => setSelected(s)}
      employeeId={employeeId}
      // setQuestion={() => setQuestion(2)} key={question}
      // SubmitTeam={() => SubmitTeam(2)} # replace with submit option
      teamList={teamList}
      SubmitAnswer={SubmitAnswer}
      get_questions={get_questions}
      setIntQuestion={setIntQuestion}
    />

  }

  // console.log("question", question)
  // console.log("currQuestion", currQuestion)
  // console.log(intQuestion)
  // console.log(get_questions.questions.filter(
  //   p => p.id === 1)[0])

  // if (question === 2) {
  //   return <RankTime data={selected}
  //     SubmitTeam={() => SubmitTeam(3)}
  //     // setQuestion={() => SubmitTeam(3)}
  //     key={question} friendOptions={teamList} />
  // }

  if (question > 0) {

    return <TeamHealth
      key={question}
      setQuestion={() => setQuestion(question + 1)}
      question={question}
      get_questions={get_questions}
      answer={answer}
      cultureAnswers={cultureAnswers}
      setAnswer={setAnswer}
      comment={comment}
      setComment={setComment}
      setIntQuestion={setIntQuestion}
      previous_response={previous_response}
      intQuestion={intQuestion}
      SaveAnswer={SaveAnswer}
      // SubmitAnswer={SubmitAnswer}
      currQuestion={currQuestion}
      maxQuestion={maxQuestion}
    />

  }
  return <p>Loading ...</p>

}


const SurveyContainer = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  box-sizing: border-box;
  min-height:750px;

  @media only screen and (min-width: 992px) {
    padding: 30px;
  }
`;

const Container = styled.div`
height:100vh;
min-height:650px;
width:100vw;
display:flex;
flex-direction:row;
position:relative;

@media only screen and (min-width:992px){
    height:100vh;
}

`

// const StyledMessage = styled.div`
//   font-size: 18px;
//   line-height: 1.17;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   padding: 15px;
//   background-color: black;
//   color: white;
//   font-family: "Red Hat Display", sans-serif;
//   margin-bottom: 20px;
//   max-width: 654px;

//   @media only screen and (min-width: 992px) {
//     font-size: 72px;
//     margin-bottom: 50px;
//     font-size: 24px;
//   }
// `;

