import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { Icon,Loader } from "semantic-ui-react";
import Chat from 'assets/images/chat.png'
import HockeyIntro from "./HockeyIntro";
import Logo from "assets/images/logo_full.png";
import Language from "./Language";
import { Message } from "semantic-ui-react";
import Courtex from "./Custom/courtex";
import HockeyIntro2 from "./Custom/HockeyIntro_v2"
import HockeyIntro3 from "./Custom/HockeyIntro_v3"

import { CSSTransition, TransitionGroup } from "react-transition-group"; // new
import LogoAnimation from "survey/SurveyComponents/LogoAnimations";

import {
  SG_ADD_EMPLOYEE_TO_DEBRIEF_SURVEY,
  SG_DEBRIEF_SURVEY,
  CLEAR_DEBRIEF_SURVEY,
} from "constants/actions";
import { get } from "http";

const SurveyStart = ({ title }) => {
  const dispatch = useDispatch();
  let { token } = useParams();

  let [searchParams, setSearchParams] = useSearchParams();
  const direct_language = Number(searchParams.get("language"));

  const [debriefSurvey, setDebriefSurvey] = useState(false);
  const [direction, setDirection] = useState("slide");
  const [isVisible, setIsVisible] = useState(true);
  const [submitError, setSubmitError] = useState(false);
  const [language, setLanguage] = useState("EN");

  const [loading, setLoading] = useState(true);

  const [email, setEmail] = useState("");

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);

  const [surveyCreated, setSurveyCreated] = useState(false);

  const { get_debrief_survey, get_errors } = useSelector(
    (state) => ({
      get_debrief_survey: state.debrief_survey,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  console.log("get_debrief_survey", get_debrief_survey);
  console.log("get_debrief_survey?.debrief_survey?.organization_info", get_debrief_survey?.debrief_survey?.organization_info);
  console.log("get_debrief_survey?.debrief_survey?.organization_name", get_debrief_survey?.debrief_survey?.organization_name);
  console.log("get_debrief_survey?.debrief_survey?.organization_services", get_debrief_survey?.debrief_survey?.organization_services);

  useEffect(() => {
    if (get_debrief_survey?.debrief_survey) {
      setDebriefSurvey(get_debrief_survey?.debrief_survey);
    }
  }, [get_debrief_survey.debrief_survey]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }, []);

  useEffect(() => {
    if (get_debrief_survey?.employee_added) {
        setSurveyCreated(get_debrief_survey?.employee_added);
        setFormErrors({submitted: {error: false, message: 'already submitted, reset the form'}, errors: 1});
      }
  }, [get_debrief_survey.employee_added]);

  useEffect(() => {
   setLanguage(direct_language === 1 ? "FR" : "EN");
  }, [direct_language]);


  useEffect(() => {
    if (token) {
      dispatch({
        type: SG_DEBRIEF_SURVEY,
        payload: token,
      });
    }
  }, [dispatch, token]);

  function handleSubmit() {
    if (formErrors?.errors > 0) {
      setPristine(false);
      return;
    }

    dispatch({
      type: SG_ADD_EMPLOYEE_TO_DEBRIEF_SURVEY,
      payload: {
        survey_id: token,
        email_id: email,
      },
    });
  }

  useEffect(() => {
    setSubmitError(get_errors?.errors?.data?.message?.message)
  },[get_errors])

  useEffect(() => {
    const ErrorList = {};
    let totalErrors = 0;
    setSubmitError(false);
    console.log(get_errors)

    if (email?.length === 0) {
      ErrorList["email"] = {
        error: true,
        message: language == "EN" ? "Please enter an email" : "Veuillez entrer un e-mail",
      };
      totalErrors += 1;
    } else {
      if (!ValidateEmail(email)) {
        ErrorList["email"] = {
          error: true,
          message: language == 'EN' ? "Please enter a valid email" : "Veuillez entrer un e-mail valide",
        };
        totalErrors += 1;
      }
    }
    if (!debriefSurvey?.survey_id) {
      ErrorList["debriefSurvey"] = {
        error: true,
        message: language == 'EN' ? "No Valid Survey found" : "Aucun sondage valide trouvé",
      };
    }
    if (get_errors?.errors?.data?.detail) {
      ErrorList["token"] = {
        error: true,
        message: get_errors?.errors?.data?.detail,
      };
    }

    if(get_errors?.errors?.status === 403){
      ErrorList["token"] = {
        error: true,
        message: language == "EN" ? 'Looks like this email has already been used to complete a survey. Please use a different email address to proceed.' : "Il semble que cet e-mail ait déjà été utilisé pour compléter un sondage. Veuillez utiliser une autre adresse e-mail pour continuer.",
      };
    }
    ErrorList["errors"] = totalErrors;
    setFormErrors(ErrorList);
  }, [email, pristine, debriefSurvey, get_errors]);

  function ChangeEmailId(e) {
    setEmail(e.target.value)
    setFormErrors({ email: { error: false, message: "" }, errors: 0 });
  }



  const getCustomIntro = () =>{
    

    if(loading){
      return (
        <div style={{width:800,height:300,display:'flex',alignItems:'center'}}>
                 <Loader active inline='centered' />
        </div>)
 
    }

    if(get_debrief_survey?.debrief_survey?.organization_info){
      console.log(get_debrief_survey?.debrief_survey?.organization_info?.public_intro)

      if(get_debrief_survey?.debrief_survey?.organization_info?.public_intro == "hockey"){
        return <HockeyIntro language={language}/>
      }

      if(get_debrief_survey?.debrief_survey?.organization_info?.public_intro == "courtex"){
        return <Courtex/>
      }

      if(get_debrief_survey?.debrief_survey?.organization_info?.public_intro == "hockey_members"){
        return <HockeyIntro2 language={language} />
      }

      if(get_debrief_survey?.debrief_survey?.organization_info?.public_intro == "hockey_participants"){
        return <HockeyIntro3 language={language} />
      }

    }



    return (
          <Content>
                Welcome to <Blue>innerlogic</Blue>, a safe and secure platform for collecting culture feedback.
                <Image src={Chat} alt="Image description" />
            </Content>
    )
  }


  return (
    <TransitionGroup>
      <CSSTransition
        key={token}
        classNames={direction}
        timeout={{ enter: 500, exit: 500 }}
        onEntered={() => setIsVisible(true)}
        onExited={() => setIsVisible(false)}
      >
        <ModuleContainer className="module-wrapper">
          <Form>
            

            
            <Powered>
             
            { get_debrief_survey?.debrief_survey?.organization_info?.public_intro !== "courtex" && <IMG src={Logo} alt="Innerlogic logo" /> }
            { get_debrief_survey?.debrief_survey?.organization_info?.public_intro == "hockey" && <Language language={language} setLanguage={setLanguage} />}
            {get_debrief_survey?.debrief_survey?.organization_info?.public_intro == "hockey_members" && <Language language={language} setLanguage={setLanguage} />}
            {get_debrief_survey?.debrief_survey?.organization_info?.public_intro == "hockey_participants" && <Language language={language} setLanguage={setLanguage} />}
            </Powered>

          

            {getCustomIntro()}
            
            
           
         
            {formErrors?.email?.error && !pristine && (
              <Message
                error
                // header={props.message}
                content={formErrors?.email?.message}
              />
            )}
            {formErrors?.token?.error && (
              <Message
                error
                // header={props.message}
                content={formErrors?.token?.message}
              />
            )}
            {submitError && (
              <Message
                error
                // header={props.message}
                content={submitError}
              />
            )}
            {surveyCreated?.message?.message && (
              <Message
                success
                // header={props.message}
                content={language =='EN'? surveyCreated?.message?.message :"Veuillez vérifier votre e-mail pour le lien du sondage."} 
              />
            )}
             <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
              <Row>
                <Title>{language=='EN'?'Enter your email to receive your unique survey link.':
                'Entrez votre adresse e-mail pour recevoir votre lien de sondage unique.'}
                </Title>
                <Input
                  type="text"
                  value={email}
                  onChange={(e) => ChangeEmailId(e)}
                  placeholder={language=='EN'? "Enter email ": "Entrez votre adresse e-mail"}
                />
                <Button type="submit" onClick={handleSubmit}>
                  {language=='EN'? 'Send my survey link': 'Envoyer mon lien de sondage'}
                </Button>
              </Row>

            </div>
            
          </Form>
        </ModuleContainer>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default SurveyStart;

export const ValidateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
const ModuleContainer = styled.div`
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 3;
  max-width:100vw;

  @media (max-height: 700px) {
    top:5%;
  }

  @media (max-width: 768px) {
    top: 0;
  }

`;

const Powered = styled.div`
  display:flex;
  font-family: 'Raleway', sans-serif;
  align-items:center;
  font-weight:600;
  margin-bottom:20px;
  position:relative;
`



const Content = styled.div`
    max-width: 600px;
    width: 100%;
    padding: 40px;
    border-radius:20px;
    background-color: #F8FAFF;
    font-size:44px;
    line-height: 1.5;
    font-weight:500;
    color:#2A3039;
    font-family: 'Raleway', sans-serif;

    @media(max-width:768px){
        font-size:24px;
    }
`

const Image = styled.img`
  width: 100px;
  display: inline;
  vertical-align: middle;
  margin-left: 0.25em;
  margin-right: 0.25em;

    @media(max-width:768px){
        width: 50px;
    }
`;

const Blue = styled.span`
    color: #2d70e2;
    font-weight:700;
`

const Form = styled.div`
  display: flex;
  flex-direction: column;

  background: white;
  padding: 20px;
  border-radius: 8px;
`;

const Title = styled.div`
  font-size: 14px;
  margin-top:20px;
  line-height: 1.4;
  color: #2a3039;
  margin-bottom: 10px;
  width:100%;
  font-weight:bold;
  font-family: 'Raleway', sans-serif;
`;

const Input = styled.input`
  font-size: 14px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  font-family: 'Raleway', sans-serif;
  margin-bottom: 10px;
`;

const Button = styled.button`
    font-size: 16px;
    font-weight:400;
    font-family: 'Raleway', sans-serif;
    padding:15px;
    color: white;
    background-color: #2254AA;
    border: none;
    cursor: pointer;

    @media(max-width:768px){
    margin-top:20px;

  }
`
const IMG = styled.img`
  width: 120px;
  margin-left: 10px;
  
  @media(max-width:768px){
   margin-top:8px;
}

`

const Row = styled.div`
display:flex;
flex-direction:column;


width:400px;
max-width:100%;

  @media(max-width:768px){
    flex-direction:column;
    padding-bottom:50px;
    width:100%;
  }

`