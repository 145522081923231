import React, { useEffect, useState } from "react";

import Slider from './Slider'


import styled from "styled-components";


const QuestionTemplate = ({ saveQuestionAnswer,
  question,
  response,name,language,onClick,showNext, goForward}) => {

  const [respValue, setRespValue] = useState(0)
  const [selectedButton, setSelectedButton] = useState(null);
  const [isBinary,setBinary] = useState(question?.binary)

    const handleButtonClick = (button) => {
      
      const response = {
        id: question?.dimension,
        factor: question?.factor,
        q: question?.q,
        ques_order: question.order,
        response: button === 'yes' ? 10 : 0,
        role: question?.role,
        reverse: question?.reverse,
        }
        
        setSelectedButton(button);
        saveQuestionAnswer(response)
    };



  const save = (resp) => {

    const response = {
      id: question?.dimension,
      factor: question?.factor,
      q: question?.q,
      ques_order: question.order,
      response: resp,
      role: question?.role,
			reverse: question?.reverse,
      }

    saveQuestionAnswer(response)
  }
  // console.log(response)
  // console.log(question)
  // console.log(data)

  useEffect(() => {
    if (response) {
      setRespValue(response?.response)
      if(question.binary){
        setSelectedButton(response?.response===10?'yes':'no')
      }
    } else {
      setRespValue(null)
    }


  }, [response]);


  return (
    <SurveyContainer>
      <Container>
        <Controls>
        <BackButton onClick={onClick}><span style={{fontSize:24,marginTop:-3,marginRight:5}}>&#8249;</span> {language===1?'Retour':'Back'}</BackButton>
        {showNext && response!=undefined && <BackButton onClick={goForward}> {language===1?'Suivant':'Forward'}<span style={{fontSize:24,marginTop:-3,marginLeft:5}}>&#8250;</span></BackButton>}
      
        </Controls>
      <Name>{language===1? 'En relation avec':'In relation to'} {name}:</Name>
      <Text>{language===1?'fr' in question?question.fr:question?.question:question?.question}</Text>
      
      {isBinary?<ButtonContainer>
            <StyledButton 
                selected={selectedButton === 'yes'}
                onClick={() => handleButtonClick('yes')}
            >
                {language===1?'Oui':'Yes'}
            </StyledButton>
            <StyledButton
                selected={selectedButton === 'no'}
                onClick={() => handleButtonClick('no')}
            >
                {language===1?'Non':'No'}
            </StyledButton>
        </ButtonContainer>:""}
      
      
      {!isBinary?
      <Slider
        preValue={respValue}
         language={language}
        // setCheckResponse
        saveResult={(resp) => save(resp)} />:""}

       
        </Container>
    </SurveyContainer>
  )
}




export default QuestionTemplate



const SurveyContainer = styled.div`
  padding: 0px;
  box-sizing: border-box;
  position: absolute;
  left: 0%;
  top: 10%;
  width:100%;
  max-width:650px;

   @media only screen and (min-width: 768px) {
    left: calc(50% - 350px);
    top: 10%;
  }


   @media only screen and (min-width:992px){
     padding:30px;
    left: calc(50% - 350px);
    top:10%;
  }
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Barlow", sans-serif;
  color: black;
  margin-top: 14px;
  max-width:600px;
  line-height:1.3;

  @media only screen and (min-width: 992px) {
    font-size: 23px;
    margin-top: 28px;
  }
`;


const Name = styled.div`
  margin-bottom:5px;
  color:grey;
  font-family: "Barlow", sans-serif;
`

const Container = styled.div`
  padding:30px;
  position:relative;
  width:100%;
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:30px;
`;

const StyledButton = styled.button`
    background-color: ${props => props.selected ? '#E5B63D' : 'white'};
    color: ${props => props.selected ? 'white' : 'black'};
    border: 1px solid lightgrey;
    border-radius: 10px;
    padding: 12px 24px;
    width: 167px;
    height: 44px;
    margin: 0 15px; // 30px between buttons
    cursor: pointer;
`;

const BackButton = styled.div`
color:grey;
font-weight:400;
font-size: 14px;
font-family: "Barlow", sans-serif;

border-radius: 10px;
 justify-content: center;
align-items: center;
display: flex;
width:75px;

cursor:pointer;
margin-bottom:30px;
margin-left:-15px;
`
const Controls = styled.div`
display:flex;
justify-content:space-between;
width:100%;

`


// const Button = styled.div`
//   height: 40px;
//   border-radius: 10px;
//   background-color: white;
//   color: #6a00ff;
//   font-size: 16px;
//   font-weight: bold;
//   font-family: "Barlow", sans-serif;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   width: 100px;
//   margin-top: 50px;
// `;


// const SubmitButton = styled(Button)`
//   width: 170px;
//   height: 47px;
//   border-radius: 5px;
//   background-color: ${(props) => (props.enabled ? "white" : "#bbc7bd")};
//   // color: white;
//   // background-color: white;
//   color: #6A00FF;
//   font-size: 16px;
//   font-weight: bold;
//   font-family: "Red Hat Display", sans-serif;
//   cursor: ${(props) => (props.enabled ? "pointer" : "not-allowed")};
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
// `;

