import { put, call, all, takeLatest } from "redux-saga/effects";

import { START_SURVEY, SG_START_SURVEY, 
  ALL_ERRORS } from "constants/actions";

import * as api from "constants/api";

function* getStartSurvey(action) {
  try {
    const json = yield call(api.GET_START_SURVEY, action.payload);
    yield put({ type: START_SURVEY, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* loadStartSurvey() {
  yield takeLatest(SG_START_SURVEY, getStartSurvey);
}


export default function* index() {
  yield all([loadStartSurvey(),
    
  ]);
}
