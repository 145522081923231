import React from 'react';
import styled from 'styled-components';

const End = ({submitAnswer}) =>{

	return (
		<Container>
			<SubTitle>Submit your answers</SubTitle>
			<Next onClick={()=>submitAnswer()}>Submit</Next>
		</Container>

		)
}

export default End


// const Title = styled.div`
// 	font-size: 50px;
//   font-weight: bold;
//   font-family: 'Red Hat Display', sans-serif;
//   margin-bottom:20px;
//   color:white;
//    line-height:1;
//    max-width:654px;

//    @media only screen and (min-width:992px){
//    font-size: 72px;
//    margin-bottom:50px;
//   }
// `
const SubTitle = styled.div`
	font-size: 18px;
	line-height: 1.17;
  color: white;
  font-family: 'Red Hat Display', sans-serif;
  margin-bottom:20px;
  max-width:654px;

   @media only screen and (min-width:992px){
   font-size: 72px;
   margin-bottom:50px;
   font-size: 24px;
  }
`

const Next = styled.div`
	width: 170px;
  height: 47px;
   border-radius: 5px;
  background-color: white;
  color: #6a00ff;
   font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
`

const Container = styled.div`
  position:absolute;
  top:15%;
  padding:20px;

  @media only screen and (min-width:992px){
    left: 25%;
  top: 25%;
  }
`