import React from "react";
import styled from "styled-components";

import { SwitchTransition, CSSTransition } from "react-transition-group";
import { TRANSITIONSPEED } from "constants/animation"

import "../survey.css";
import Intro from './Intro'
import Form from './form'
import Inclusion from './Inclusion'

const Blink = () => {

  const [question, setQuestion] = React.useState(1)

  return (
    <Container>
      <SurveyContainer>
        <SwitchTransition>
          <CSSTransition
            key={question}
            timeout={TRANSITIONSPEED}
            classNames="SlideUp"
          >
            <>
              {/* eslint-disable-next-line eqeqeq */}
              {question == 1 ?
                <Intro key={question} next={() => setQuestion(2)} />
                :
                // eslint-disable-next-line eqeqeq
                question == 2 ?
                  <Form next={() => setQuestion(3)} key={question} />
                  : <Inclusion key={question} />
              }
            </>
          </CSSTransition>
        </SwitchTransition>
      </SurveyContainer>
    </Container>
  )
}



export default Blink





const SurveyContainer = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  box-sizing: border-box;
  min-height:750px;

  @media only screen and (min-width: 992px) {
    padding: 30px;
  }
`;

const Container = styled.div`
height:100vh;
min-height:650px;
width:100vw;
display:flex;
flex-direction:row;
position:relative;
background-color:black;

@media only screen and (min-width:992px){
    height:100vh;
}

`
