import styled from "styled-components";

export const Container = styled.div`
  width: 274px;
  min-width: 274px;
  height: 100vh;
  min-height:600px;
  background-color: #f3eaff;
  
  box-sizing: border-box;
  display: none;

  @media only screen and (min-width: 992px) {
    display: inline;
    z-index: 999;
    padding: 70px 36px 36px 36px;
  }
`;

export const Avatar = styled.img`
height:45px;
width: 45px;
border-radius:22.5px;
`

export const IMG = styled.div`
  width: 45px;
  height: 45px;
  border: solid 2px #ffffff;
  border-radius: 22.5px;
  display:flex;
  align-items:center;
  overflow:hidden;
  justify-content:center;
`;
export const Name = styled.div`
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  margin-left: 17px;
  color: #4a4a4a;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-left: ${(props) =>
    props.surveyType === 1 ? "1px solid #979797" : "1px solid #979797"};
  position: relative;
  color:#4a4a4a;
`;

export const Selected = styled.div`
  position: absolute;
  top: ${(props) => props.top + "px"};
  left: -1px;
  width: 4px;
  background-color: #4a4a4a;
  height: 85px;
  color: #4a4a4a;
`;
