
import React from 'react';
import styled from 'styled-components';

const Question = (props) => {
  const { SaveAnswer, 
    setComment, comment, askFollowUp,
    answer, team_avg, previous_score, area
  } = props

  function ChangedComments(e) {
    setComment(e.target.value)
  }

  // console.log(askFollowUp)

  return (
    <Container>
      <Questioned>
        <ConditionQuestion
        askFollowUp={askFollowUp}
        answer={answer}
        team_avg={team_avg}
        previous_score={previous_score}
        area={area}
      />
      </Questioned>
      <Sub>Providing feedback helps your team grow stronger</Sub>
      <SubText
        placeholder="Please type your answer here... "
        value={comment ? comment : ''}
        rows="5"
        onChange={(e) => ChangedComments(e)}
        maxLength="400"
      />

      <ButtonContainer>
        {/* {currQuestion !== maxQuestion ? */}
          <StyledButton enabled={true} onClick={SaveAnswer}>Next</StyledButton>
          {/* :
          
        } */}

      </ButtonContainer>
    </Container>
  )
}

// answer={answer}
// comment={comment}
// setComment={setComment}
// askFollowUp={askFollowUp}
// team_avg={Number(teamResponse.score)} // team_avg
// previous_score={Number(indResponse.score)} // previous_score
// area={intQuestion.area}
// SaveAnswer={SaveAnswer}

export default Question

const ConditionQuestion = ({ askFollowUp, answer, team_avg, previous_score, area }) => {
  // console.log(answer, team_avg, previous_score, area)
  // team_avg = 77
  // console.log(askFollowUp)

  // // console.log(answer)
  // console.log(previous_score)
  // // if (isNaN(parseFloat(previous_score))) {
  // //   return "Are there any comments you'd like to make on your %s score?".replaceAll('%s', area)
  // // }
  // // console.log("here 1")
  // // if (askFollowUp) {
  // // console.log(askFollowUp?.filter(l => l.level === 1)[0])

  if (answer > previous_score) {
    // 1 0 1
    if (answer < team_avg) {
      return askFollowUp?.find(({ level }) => level === 1)?.comment.replaceAll('%s', area)
    }
    // 1 1 0
    if (previous_score > team_avg) {
      return askFollowUp?.find(({ level }) => level === 2)?.comment.replaceAll('%s', area)
    }
    // 1 0 1

    if (previous_score < team_avg && answer > team_avg) {
      return askFollowUp?.find(({ level }) => level === 3)?.comment.replaceAll('%s', area)
    }

    if(previous_score===team_avg){
    	return askFollowUp?.find(({ level }) => level === 2)?.comment.replaceAll('%s', area)
    }
  }
  if (answer < previous_score && previous_score < team_avg) {
    return askFollowUp?.find(({ level }) => level === 4)?.comment.replaceAll('%s', area)
  }
  if (answer < previous_score && answer > team_avg && previous_score > team_avg) {
    return askFollowUp?.find(({ level }) => level === 5)?.comment.replaceAll('%s', area)
  }
  if (answer < previous_score && previous_score > team_avg && answer < team_avg) {
    return askFollowUp?.find(({ level }) => level === 6)?.comment.replaceAll('%s', area)
  }

  // }

  return "Are there any comments you'd like to make on your %s score?".replaceAll('%s', area)
  //No change in score???? 
  // Same as team average???
  
}

const Container = styled.div`
   top:15%;
   position: absolute;
   width:90%;
   display:flex;
   flex-direction:column;




   @media(max-height:600px){
     top:5%;
   }


    @media(min-width:992px) and (min-height:650px){
    top:200px;
  }
`

const Questioned = styled.div`
font-size: 18px;
width: 700px;
font-family: 'Red Hat Display', sans-serif;
line-height: 1.3;
margin-top:14px;
max-width:100%;
margin-bottom:40px;


@media only screen and (min-width:992px) and (max-height:700px){
   font-size: 20px;
    margin-top:28px;
}

@media only screen and (min-width:992px) and (min-height:700px){
   font-size: 23px;
    margin-top:28px;
}
`

const Sub = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	width:700px;
	font-size:12px;
	max-width:95%;
`

// const Box = styled.div`
//   width: calc(100% - 30px);
//   border-radius: 8px;
//   background-color: #7c2aef;
//   margin-top: 32px;
//   overflow: hidden;

//   @media only screen and (min-width: 992px) {
//     width: 401px;
//   }
// `;
// const Answer = styled.div`
//   font-size: 18px;
//   font-weight: bold;
//   color: white;
//   width: 100%;
//   border-bottom: 1px solid white;
//   padding: 15px;
//   font-family: "Red Hat Display", sans-serif;
// `;

const SubText = styled.textarea`
  max-width: 700px;
  color: black;
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  background-color:  #f8f8f8;
  border-radius: 8px;
  border: none;
  padding: 15px;
  margin: 12px 12px 12px 0px;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: #b8b8b8;
  }
`;

const StyledButton = styled.div`
height: 40px;
border-radius: 10px;
background-color: ${props => props.enabled ? "#6a00ff" : "grey"}; 
color:white;
font-size: 16px;
font-weight: bold;
font-family: 'Red Hat Display', sans-serif;
display:flex;
justify-content:center;
align-items:center;
cursor:${props => props.enabled ? "pointer" : "not-allowed"};
width:100px;
margin-top:50px;

@media (max-height:600px){
  margin-top:30px;
}
`
// const StyledSubmitButton = styled.div`
// height: 40px;
// border-radius: 10px;
// background-color: ${props => props.enabled ? "#008000" : "grey"}; 
// color:white;
// font-size: 16px;
// font-weight: bold;
// font-family: 'Red Hat Display', sans-serif;
// display:flex;
// justify-content:center;
// align-items:center;
// cursor:${props => props.enabled ? "pointer" : "not-allowed"};
// width:100px;
// margin-top:50px;

// @media (max-height:600px){
//   margin-top:30px;
// }
// `

const ButtonContainer = styled.div`
	width:700px;
`