import React from "react";
import styled from "styled-components";


import Img from 'assets/images/blink2.png'

const Title = styled.div`
  font-size: 50px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 20px;
  color: white;
  line-height: 1;
  max-width:600px;

  @media only screen and (min-width: 992px) {
    font-size: 72px;
    margin-bottom: 50px;
  }
`;
const SubTitle = styled.div`
  font-size: 18px;
  line-height: 1.17;
  color: white;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 20px;
  max-width: 654px;

  @media only screen and (min-width: 992px) {
    font-size: 72px;
    margin-bottom: 50px;
    font-size: 24px;
  }
`;

// const StyledMessage = styled.div`
//   font-size: 18px;
//   line-height: 1.17;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   padding: 15px;
//   background-color: black;
//   color: white;
//   font-family: "Red Hat Display", sans-serif;
//   margin-bottom: 20px;
//   max-width: 654px;

//   @media only screen and (min-width: 992px) {
//     font-size: 72px;
//     margin-bottom: 50px;
//     font-size: 24px;
//   }
// `;

const Next = styled.div`
  width: 170px;
  height: 47px;
  border-radius: 5px;
  background-color: white;
  color: black;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledContainer = styled.div`
  margin-top: 20vh;
  position: absolute;
  top:0;
  width: calc(100vw - 30px);
  display:flex;
  flex-direction:column;
  align-items:center;

  @media only screen and (min-width: 992px) {
    margin-top: 20vh;
  }
`;

const Image = styled.img`
  height:50px;
  margin-bottom:20px;
`

const Intro = (props) => {

  return (
    <StyledContainer>
      <div>
        <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
          <Image src={Img} />
        </div>
        <Title>Diversity & Inclusion Survey</Title>
            <SubTitle>Your responses are completely anonymous and your data will never be used to personally identify you. Responses to this survey is completely voluntary.</SubTitle>
        {/* missing no of team members your [12] team members */}
        <Next onClick={()=>props.next()}>Begin</Next>
      </div>
    </StyledContainer>
  );
};

export default Intro;
