import React from 'react';
// import styled from 'styled-components';
import BulletProgressBar from '../IntroScreens/BulletProgressBar';
import CustomButton from './Button';
import { introductionBtn, nextQuestionBtn, submitSurvey } from 'constants/questionTypes';

import {words} from '../translations'
// intro pages


function NavigationButtons({
  introQuestion,
  currentQuestionDet,
  handleBack,
  handleNext,
  surveyFinished,
  submitAnswer,
  StartSurvey,
  language,
  color,
  disabledNext = false
 }) {
  let currentQues = currentQuestionDet?.sort_order

  if (surveyFinished) {
    return null
  }
  if (currentQuestionDet?.buttonType === introductionBtn) {
    return (
      <>
        <BulletProgressBar current={currentQues - 1} total={introQuestion} />
        <CustomButton id={`nxt_btn_${currentQuestionDet?.id}`} disabled={disabledNext} onClick={currentQuestionDet?.id === 1 ? StartSurvey : handleNext} color={color}>{words[currentQuestionDet?.buttonText][language]}</CustomButton>
      </>)
  }
  if (currentQuestionDet?.buttonType === submitSurvey) {
    return (
      <>
        <CustomButton id={`bk_btn_${currentQuestionDet?.id}`} onClick={handleBack} type={'back'}>{words['back'][language]}</CustomButton>
        <CustomButton id={`nxt_btn_${currentQuestionDet?.id}`} onClick={submitAnswer} disabled={!currentQuestionDet?.response?.response} color={color}>{words[currentQuestionDet?.buttonText][language]}</CustomButton>
      </>
    )
  }
  return (
    <>
      <CustomButton id={`bk_btn_${currentQuestionDet?.id}`} onClick={handleBack} type={'back'}>{words['back'][language]}</CustomButton>
      <CustomButton id={`nxt_btn_${currentQuestionDet?.id}`} onClick={handleNext} disabled={!currentQuestionDet?.response?.response && !currentQuestionDet?.response?.clicked} color={color}>{words[currentQuestionDet?.buttonText][language]}</CustomButton>
    </>
  )

}

export default NavigationButtons

