import React, { useEffect, useState } from "react";
import Slider from './Slider'

import styled from "styled-components";


const QuestionTemplate = ({ saveQuestionAnswer,
  question,
  response,
  textColor,
  language,
  type,
  setBackground,
  qualifier
}) => {
  
  const [respValue, setRespValue] = useState(0)
  const save = (resp) => {
    saveQuestionAnswer({
      id: question?.dimension,
      factor: question?.factor,
      q: question?.q,
      ques_order: question.order,
      response: resp,
      role: question?.role,
      reverse: question?.reverse,
    })
  }

  useEffect(() => {
    if (response) {
      setRespValue(response?.response)
    } else {
      setRespValue(null)
    }
  }, [response]);

  useEffect(() => {
    if (question?.reverse) {
      setBackground(true)
    } else {
      setBackground(false)
    }
  }, [question, setBackground])

  if (question?.reverse) {

    return (
      <SurveyContainer>
        <Container>
         
          <Info>* {language === 1 ? "Cette question est notée à l'envers" : 'Please pay attention to the wording of this statement when selecting your level of agreement'}</Info>
          <Qualifier>{qualifier ? qualifier : type === 3 || type === 4 ? language === 1 ? 'Dans cette culture...' : 'In this culture...' : ""}</Qualifier>
          <Text>{language === 1 ? 'fr in question' ? question.fr : question?.question : question?.question}</Text>
          <Slider
            preValue={respValue}
            // setCheckResponse
            color={textColor}
            language={language}
            reversed={true}
            saveResult={(resp) => save(resp)} />
        </Container>
      </SurveyContainer>
    )
  }

  return (
    <SurveyContainer>
      
      <Container>
      
        <Qualifier>{qualifier ? qualifier : type === 3 || type === 4 ? language === 1 ? 'Dans cette culture...' : 'In this culture...' : ""}</Qualifier>
        <Text>{language === 1 ? 'fr in question' ? question.fr : question?.question : question?.question}</Text>
        <Slider
          preValue={respValue}
          // setCheckResponse
          color={textColor}
          language={language}
          saveResult={(resp) => save(resp)} />
      </Container>

    </SurveyContainer>
  )
}

export default QuestionTemplate


const SurveyContainer = styled.div`
  padding: 0px;
  box-sizing: border-box;
  position: absolute;
  left: 0%;
  top: 15%;
  width:100%;
  max-width:650px;

  @media only screen and (min-width: 768px) {
    padding: 30px;
    left: calc(50% - 350px);
    top: 15%;
  }
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 300;

  color: white;
  margin-top: 14px;
  max-width:600px;
  line-height:1.6;

  @media only screen and (min-width: 992px) {
    font-size: 20px;
    margin-top: 28px;
  }
`;


const Qualifier = styled.div`
  color:white;
  font-size: 16px;
  font-style:italic;

  @media(max-width:768px){
    margin-bottom:25px;
  }
`

const Container = styled.div`
  padding:30px;
  position:relative;
  width:100%;
`

const Info = styled.div`
  position:absolute;
  color:white;
  font-size:16px;
  font-weight:bold;
  top:-50px;

  @media(max-width:768px){
    max-width:80%;
  }
`

// const Button = styled.div`
//   height: 40px;
//   border-radius: 10px;
//   background-color: white;
//   color: #6a00ff;
//   font-size: 16px;
//   font-weight: bold;
//   font-family: "Barlow", sans-serif;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   width: 100px;
//   margin-top: 50px;
// `;


// const SubmitButton = styled(Button)`
//   width: 170px;
//   height: 47px;
//   border-radius: 5px;
//   background-color: ${(props) => (props.enabled ? "white" : "#bbc7bd")};
//   // color: white;
//   // background-color: white;
//   color: #6A00FF;
//   font-size: 16px;
//   font-weight: bold;
//   font-family: "Red Hat Display", sans-serif;
//   cursor: ${(props) => (props.enabled ? "pointer" : "not-allowed")};
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
// `;

