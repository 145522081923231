import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Select from "react-select";

const CategoryQuestions = (props) => {
	const {
		// key, 
		save, 
		prevResponse,
		data,
		name,
		employeeCategory,
		language } = props

	const [quesResponse, setQuesResponse] = useState(null)
	const [formatOptions, setFormatOptions] = useState([])

	useEffect(() => {
		if(language===1){
				setFormatOptions(data.answers.map((item) => {
			return {
				text: 'fr'in item? item.fr_description? item.fr + ' - ' + item.fr_description:item.fr : item.name,
				value: item.id,
			}
			}))
		}else{
			setFormatOptions(data.answers.map((item) => {
				// console.log(item)
			return {
				text: item.description? item.name + ' - '+item.description:item.name,
				value: item.id,
			}
		}))
		}
	}, [props]);

	useEffect(() => {
		const EmployeeCat = employeeCategory.find(
			(f) => f?.name === data?.name)
		setQuesResponse(null)
		if (EmployeeCat) {
			setQuesResponse(data.answers.filter(an => an.name === EmployeeCat.value
			).map((item) => {
				return {
					text: item.name,
					value: item.id
				}
			})[0])
		}
	}, [employeeCategory, data]);

	useEffect(() => {
		if (prevResponse?.response !== null) {
			if (!quesResponse) {
				setQuesResponse(formatOptions.filter(so => so.value === prevResponse?.response)[0])
			}
		}
	}, [prevResponse, formatOptions, quesResponse]);


	const next = () => {
		save({
			id: data.id,
			response: quesResponse?.value,
			// question: data.question,
			subfactor: data?.subfactor,
			priority: data?.priority
		})

		setQuesResponse(null)
	}


	const checkDisabled = () =>{
		return quesResponse === null || quesResponse === undefined
	}

	const getQuestion = () => {
		return (
			<>
				 <Name>{language===1 && 'fr' in data? 'En relation avec':'In relation to'} {name}:</Name>
				<Text>{language===1 && 'fr' in data?data.fr:data.question}</Text>
				<DropDownContainer>
					<Select
						placeholder={language===1 && 'fr' in data?"Sélectionner une option":"Select an option"}
						onChange={setQuesResponse}
						value={quesResponse}
						options={formatOptions}
						// isLoading={loading}	
						getOptionLabel={(option) => option.text}
						getOptionValue={(option) => option.value}
					/>
				</DropDownContainer>
			</>)
	}

	return (
		<SurveyContainer>
			{getQuestion()}
			<Button
				onClick={() => next()}
				disabled={checkDisabled()}
				id="next">
				{language==1?'Suivant':'Next'}
			</Button>
		</SurveyContainer>
	)
}


export default CategoryQuestions


const SurveyContainer = styled.div`
  padding: 0px;
  box-sizing: border-box;
  position: absolute;
  left: 0%;
  top: 10%;
  padding: 20px;
  width:100%;
  max-width:600px;

  @media only screen and (min-width: 768px) {
    padding: 20px;
    left: calc(50% - 350px);
    top: 10%;
  }


   @media only screen and (min-width:992px){
   	padding:30px;
    left: calc(50% - 350px);
    top:10%;
  }
`;


const DropDownContainer = styled.div`
  padding: 0px;
  left: 0%;
  top: 10%;

  width:600px;
  max-width:100%;

  @media only screen and (min-width: 992px) {

    left: 25%;
    top: 15%;
  }
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Barlow", sans-serif;
  color: black;
  margin-top: 14px;
  max-width:600px;
  line-height:1.3;
  margin-bottom:30px;

  @media only screen and (min-width: 992px) {
    font-size: 23px;
    margin-top: 28px;
  }
`;

const Button = styled.button`
  height: 40px;
  border-radius: 10px;
  background-color: #E5B63D;
  border: 0;
  box-shadow: none;
  color:white ;
  font-size: 16px;
  font-weight: bold;
  font-family: "Barlow", sans-serif;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100px;
  margin-top: 50px;

  :disabled {
  	background-color:lightgrey;
  	color:grey;
  	cursor:not-allowed;
  	opacity:0.5;
  }


  @media only screen and (min-width: 992px), (min-height: 760px) {
    display: flex;
  }
`;

const Name = styled.div`
  margin-bottom:5px;
  fontsize:10px;
  color:grey;
  font-family: "Barlow", sans-serif;
`