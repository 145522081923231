import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import CustomDropdown from '../Components/CustomDropdown';
import { categoryTypeQuestion } from 'constants/questionTypes';
import {words} from '../translations'




const Categories = (props) => {

  const { saveUpdatedAnswers,
    currentQuestionDet,
    question,
    description,
    categoryQues,
    organizationName,
    language,
  public_intro } = props

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCategoryQues, setSelectedCategoryQues] = useState(false);
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState([]);

  useEffect(() => {
    if (categoryQues) {
      const CatQues = categoryQues.filter(cq =>
        cq.id === currentQuestionDet.category_question_id)?.[0]
      setSelectedCategoryQues(CatQues)
      if (CatQues?.answers) {
        setSelectedCategoryOptions(CatQues?.answers)
      } else {
      setSelectedCategoryOptions(false)
    }
    } 
  }, [categoryQues, currentQuestionDet])

  useEffect(() => {
    if (currentQuestionDet?.response && selectedCategoryQues?.answers) {
      setSelectedCategory(selectedCategoryQues?.answers.filter(op => op.id === currentQuestionDet?.response.response)?.[0])
    } else {
      setSelectedCategory('')
    }
  },[currentQuestionDet, selectedCategoryQues])

  function SaveResponse(response) {
    const Response = {
      ques_sort_order: currentQuestionDet?.id,
      id: selectedCategoryQues.id,
      response: response?.id, 
      subfactor: selectedCategoryQues?.subfactor,
      priority: selectedCategoryQues?.priority,
      role: selectedCategoryQues?.role,
      reverse: selectedCategoryQues?.reverse,
      clicked: true,
    }
    saveUpdatedAnswers(currentQuestionDet?.id, Response, categoryTypeQuestion)
    setSelectedCategory(selectedCategoryQues?.answers.find(op => op.id === Response.response))
  }

  const getTitle = ()=>{
    if(selectedCategoryQues?.fr_name && language === 'FR'){
      return selectedCategoryQues?.fr_name
    }

    if(selectedCategoryQues?.es_name && language === 'ES'){
      return selectedCategoryQues?.es_name
    }

    if(selectedCategoryQues?.name){
      return selectedCategoryQues?.name
    }

    return words["category_completed"][language]
  }

  const getDescription = () =>{
    if(selectedCategoryQues?.fr && language === 'FR'){
      return selectedCategoryQues?.fr
    }
    if(selectedCategoryQues?.fr_question && language === 'FR'){
      return selectedCategoryQues?.fr_question
    }
    if(selectedCategoryQues?.es && language === 'ES'){
      return selectedCategoryQues?.es
    }
    if(selectedCategoryQues?.question){
      return selectedCategoryQues?.question
    }

    if(public_intro =='hockey_participants'){
      return language === 'EN' ? EN1 : FR1

    }

    return words["category_completed2"][language].replace('{organizationName}',organizationName)
  }


  return (
    <CategoriesContainer>
      <Title>{words['category'][language]}</Title>
      <QuestionText>{getTitle()}</QuestionText>
      <Description questionLength={selectedCategoryQues?.question?.length} > {getDescription()}</Description>
      {selectedCategoryOptions && <CustomDropdown
        id={`sel_${currentQuestionDet?.id}`}
        listId={`lists_${currentQuestionDet?.id}`}
        value={selectedCategory}
        onSelect={SaveResponse}
        language={language}
        options={selectedCategoryOptions} />}

    </CategoriesContainer>
  );
};

export default Categories;

const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width:calc(100%);
  max-width:600px;
`;

const QuestionText = styled.h2`
  margin-bottom: 1rem;
  color:#2A3039;
  font-size: 20px;
  font-weight: 600;
`;

const Dropdown = styled.select`
  font-size: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Title = styled.div`
color: #2D70E2;
text-transform: uppercase;
font-size:14px;
font-weight:600;
`

const Description = styled.div`
color: #2A3039;
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height:1.6;
margin-bottom:26px;

@media(max-height:700px){
  font-size:${props => props.questionLength > 200 ? '14px' : '16px'};
}

@media(max-height:550px){
  font-size:${props => props.questionLength > 200 ? '12px' : '16px'};
}
`

const EN1 = (<div>
  <p>
  For the remainder of the survey, we will be asking about your personal experience with the environment and culture of hockey in Canada. However, to ensure your feedback reflects the current state of hockey, please focus only on your experiences from the <strong>past five years</strong>.
  </p>
  <p>
  As you answer, think about your recent hockey participation and how it has shaped your experience. Your honest responses will help us understand the opportunities for achieving healthy culture in the future of hockey.
  </p>
</div>)

const FR1 = (<div>
  <p>
  Pour le reste du sondage, nous vous demanderons de partager votre expérience personnelle de l'environnement et de la culture du hockey au Canada. Cependant, pour garantir que vos commentaires reflètent l'état actuel du hockey, veuillez vous concentrer uniquement sur vos expériences des <strong>cinq dernières années</strong>.
  </p>
  <p>
  En répondant, réfléchissez à votre participation récente au hockey et à la manière dont elle a façonné votre expérience. Vos réponses honnêtes nous aideront à comprendre les opportunités pour atteindre une culture saine dans l'avenir du hockey.
  </p>
</div>
)