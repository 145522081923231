// ... other imports ...

import styled from 'styled-components';
import React,{ useState } from 'react';
import Pic from 'assets/images/anonymous.png'
import {words} from '../translations'

const Screen2 = ({language}) =>{

   
    return (
        <Text>
        {words['intro3-0'][language]} <Highlight>{words['intro3-1'][language]}</Highlight> {words['intro3-2'][language]} 
        {words['intro3-3'][language]}
        
        {words['intro3-4'][language]} <Image src={Pic} alt="Image description" /> <Bold>{words['intro3-5'][language]}</Bold>

        </Text>
    )
}

export default Screen2

const Text = styled.div`
font-weight: 500;
font-size: 44px;
line-height: 1.4;
color: #2A3039;

@media (max-width: 768px) {
    font-size: 38px;
}
`

const Image = styled.img`
    height:52px;
    display: inline;
    vertical-align: middle;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 30px;
`

const InlineWrapper = styled.span`
  display: inline-flex;
  align-items: center;
`;

const Highlight = styled.span`
background: #EBF1FD;
border-radius: 40px;
padding:0px 16px;
margin:0px 0px 0px -8px;

`

const Bold = styled.span`
    font-weight:600;
    color:#2D70E2;
`
