import React, { useState, useEffect } from "react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import Loading from './Loading'
import {
  LOAD_CULTURE_QUESTIONS,
  LOAD_MOOD_QUESTIONS,
  LOAD_LEADERSHIP_QUESTIONS,
  SET_ANSWERS,
  SG_START_SURVEY,
} from "constants/actions";

import Mood from "./Mood/index";

import TeamIntelligence from "./TeamIntelligence/index";
import Leadership from "./Leadership/index";

import NoToken from "./NoToken"
import End from "./End"
import SurveyMessage from "./Message"

import { CSSTransition, SwitchTransition } from "react-transition-group";
import {TRANSITIONSPEED} from "constants/animation"

import SideBar from "./Sidebar/index";
import BottomBar from "./BottomBar";
// import "./survey.css";
import { Message, Icon } from "semantic-ui-react";

import { Container as StyledContainer } from "./styledList";

const Survey = () => {
  const dispatch = useDispatch();
  // const search = window.location.search;
  // const params = new URLSearchParams(search);
  // const token = params.get("token");
  // token is not query string but a param
  let { id, token } = useParams();

  const [tokenMatched, setTokenMatched] = useState([]);

  const [cultureQuestionList, setCultureQuestionList] = useState([]);
  const [cultureAnswersList, setCultureAnswersList] = useState([]);
  const [moodQuestionList, setMoodQuestionList] = useState([]);

  const [leadershipQuestionList, setLeadershipQuestionList] = useState([]);
  const [leadershipAnswersList, setLeadershipAnswersList] = useState([]);

  const [surveyType, setSurveyType] = React.useState(0);
  const [direction, setDirection] = React.useState(1);

  const [cultureQues, setCultureQues] = React.useState(false);
  const [moodQues, setMoodQues] = React.useState(false);
  const [leadershipQues, setLeadershipQues] = React.useState(false);

  const [loading, setLoading] = React.useState(true)

  const [surveyCompleted, setSurveyCompleted] = useState([]);
  // const [surveyMessage, setSurveyMessage] = useState(false);

	const [surveyStatus, setSurveyStatus] = useState(0)
	// const [validUpto, setValidUpto] = useState(false)
	// const [surveyDeleted, setSurveyDeleted] = useState(false);
	const [surveyDeletedOn, setSurveyDeletedOn] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  });

  useEffect(() => {
    // Run! Like go get some data from an API.

    if (token) {
      dispatch({ type: SG_START_SURVEY, payload: `?token=${token}` });
      dispatch({ type: LOAD_CULTURE_QUESTIONS, payload: `?token=${token}` });
      dispatch({ type: LOAD_MOOD_QUESTIONS, payload: `?token=${token}` });
      dispatch({ type: LOAD_LEADERSHIP_QUESTIONS, payload: `?token=${token}` });
    }
  }, [dispatch, token]);

  const { get_questions, get_curr_direction,
    get_answers, survey_type } = useSelector(
      (state) => ({
        get_questions: state.questions,
        get_curr_direction: state.answers.curr_direction,
        //get this from question token
        get_answers: state.answers,
        survey_type: state.answers.curr_survey_type,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (get_answers.token === token) {
      if (get_answers.culture_survey_compliance ||
        get_answers.mood_survey_compliance ||
        get_answers.leadership_survey_compliance
      ) {
        setTokenMatched(true)
      } else {
        setTokenMatched(false)
      }
    } else {
      setTokenMatched(false)
    }
    setSurveyCompleted(get_answers.all_survey_types.length)

    // setSurveyDeleted(get_answers?.to_delete)
    setSurveyDeletedOn(get_answers?.deleted_on)
    // setSurveyMessage(get_answers?.token_message)
    setSurveyStatus(get_answers?.completion_status ? get_answers?.completion_status : 0)
		// setValidUpto(new Date(get_answers?.answers?.valid_upto).valueOf() < new Date().valueOf())


    // console.log(get_answers)
  }, [get_answers, token])

  

  useEffect(() => {

    if (get_questions && survey_type) {
      setCultureQuestionList(get_questions.culture_questions.questions);
      setCultureAnswersList(get_questions.culture_questions.questions?.options);
      setMoodQuestionList(get_questions.mood_questions.questions);
      setLeadershipQuestionList(get_questions.leadership_questions.questions);
      setLeadershipAnswersList(
        get_questions.leadership_questions.questions?.options
      );
      setSurveyType(survey_type);
      setCultureQues(get_questions.pending_culture_questions);
      setMoodQues(get_questions.pending_mood_questions);
      setLeadershipQues(get_questions.pending_leadership_questions);
    }

  }, [dispatch, get_questions, id, token, survey_type]);

  // culture question additional question for team related question
  useEffect(() => {
    dispatch({
      type: SET_ANSWERS,
      payload: {
        culture_max_ques: cultureQuestionList?.questions?.length + 1,
        mood_max_ques: moodQuestionList?.max_response,
        leadership_max_ques: leadershipQuestionList?.questions?.length,
        curr_survey_type: survey_type,
        culture_survey_status: 1,
        mood_survey_status: 1,
        leadership_survey_status: 1,
        mood_high_ans: 1,
      },
    });
  }, [
    dispatch,
    cultureQuestionList,
    moodQuestionList,
    leadershipQuestionList,
    survey_type,
  ]);

  // Get list of answer ids from the answer list
  // use the list to check if all questions are
  // answered
  useEffect(() => {
    if (survey_type) {
      setSurveyType(survey_type);
    }
  }, [survey_type]);
  useEffect(() => {
    setDirection(get_curr_direction);
  }, [get_curr_direction]);

  // old code
  const [q, setQ] = React.useState(0);

  const nextQ = (i) => {
    setDirection(1);
    setQ(q + 1);
  };
  // old code end

  // console.log(surveyDeleted)

  if (loading) {
    return <Loading />
  }
  // console.log(surveyStatus)

  if (!tokenMatched) {
    return (
      <StyledContainer survey={1}>
        <SwitchTransition>
					<CSSTransition
						key={q}
						timeout={TRANSITIONSPEED}
						classNames={direction === 1 ? "SlideUp" : "SlideDown"}
					>
            <NoToken />
          </CSSTransition>
        </SwitchTransition>

    
      </StyledContainer>
    )
  }
  // console.log('reached here')
  if (surveyDeletedOn) {
    // console.log(get_answers?.deleted_on)
    return (
      <StyledContainer survey={surveyType}>

        <SwitchTransition>
					<CSSTransition
						key={q}
						timeout={TRANSITIONSPEED}
						classNames={direction === 1 ? "SlideUp" : "SlideDown"}
					>
            <SurveyMessage 
              // message={surveyMessage} 
              onTime={surveyDeletedOn} 
              status={surveyStatus}
              />
          </CSSTransition>
        </SwitchTransition>
      </StyledContainer>
    )
  }

  if (!surveyCompleted) {
    return (
      <StyledContainer survey={surveyType}>
        <SwitchTransition>
					<CSSTransition
						key={q}
						timeout={TRANSITIONSPEED}
						classNames={direction === 1 ? "SlideUp" : "SlideDown"}
					>
            <End />
          </CSSTransition>
        </SwitchTransition>
      </StyledContainer>
    )
  }

  if (!surveyType)
  {
     return (
      <StyledContainer survey={surveyType}>
        <Loading />

      </StyledContainer>
    )
  }

  return (
    <>
      <StyledContainer survey={surveyType}>

        <SideBar surveyType={surveyType} q={q} />


        {surveyType === 1 && (
          cultureQues ? (
            <Message icon>
              <Icon name="circle notched" loading />
              <Message.Content>
                <Message.Header>Just one second</Message.Header>
                We are fetching that content for you.
              </Message.Content>
            </Message>
          ) : (
            <TeamIntelligence
              cultureQuestionList={cultureQuestionList}
              cultureAnswersList={cultureAnswersList}
              // Start={Start}
              token={token}
              direction={direction}
              setSurveyType={setSurveyType}
            />
          )
        )}
        {surveyType === 2 && (
          moodQues ? (
            <Message icon>
              <Icon name="circle notched" loading />
              <Message.Content>
                <Message.Header>Just one second</Message.Header>
                We are fetching that content for you.
              </Message.Content>
            </Message>
          ) : (
            <Mood
              q={q}
              setQ={(i) => nextQ(i)}
              moodQuestions={moodQuestionList}
              key={q}
              // Start={Start}
              direction={direction}
              setSurveyType={setSurveyType}
            />
          )
        )}

        {surveyType === 3 && (
          leadershipQues ? (
            <Message icon>
              <Icon name="circle notched" loading />
              <Message.Content>
                <Message.Header>Just one second</Message.Header>
                We are fetching that content for you.
              </Message.Content>
            </Message>
          ) : (
            <Leadership
              leadershipQuestionList={leadershipQuestionList}
              leadershipAnswersList={leadershipAnswersList}
              // Start={Start}
              direction={direction}
            />
          )
        )}
        <BottomBar surveyType={surveyType} setDirection={setDirection} />
      </StyledContainer>
    </>
  )
};

export default Survey;
