export const primary_question_subfactor =  [{
    "fr": null,
    "id": "592e030d-2e64-4c9b-962f-dcbb64a2250b",
    "sno": 0,
    "name": "Sport",
    "answers": [{
        "id": "4cc5f68b-ae91-405c-9656-5a02eed675d7",
        "name": "Archery",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 0
    }, {
        "id": "320b8a6f-6366-4fa6-92d6-65dd684a920e",
        "name": "Artistic Swimming",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 1
    }, {
        "id": "22eeb288-0530-4b08-908e-e0251e90af5f",
        "name": "Athletics",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 2
    }, {
        "id": "ade9b1f8-3145-4ed0-9d63-5889b176bb6b",
        "name": "Boxing",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 3
    }, {
        "id": "8b3dac30-21dc-410a-8455-895095b349fe",
        "name": "Canoe/kayak",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 4
    }, {
        "id": "f0c86f88-264c-475d-9247-987ac407f743",
        "name": "Cycling",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 5
    }, {
        "id": "844426a1-870a-4204-833c-bbc2d04eaf79",
        "name": "Diving",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 6
    }, {
        "id": "81113ebb-54e1-4569-b780-8a6f58c1be9b",
        "name": "Fencing",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 7
    }, {
        "id": "ade32aab-1323-4621-9d49-364007c71bda",
        "name": "Golf",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 8
    }, {
        "id": "0db6fccc-1241-45e0-b234-3dba11ef8f80",
        "name": "Gymnastics",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 9
    }, {
        "id": "4ab2182a-6075-4011-bfd9-ebfdaeee03c9",
        "name": "Judo",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 10
    }, {
        "id": "70aa1df2-9ea6-473b-897f-fdef9c2de5ad",
        "name": "Karate",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 11
    }, {
        "id": "a5b7239b-943d-401d-80de-bd415fa678db",
        "name": "Rowing",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 12
    }, {
        "id": "7317eb21-50f3-4b07-be29-bf9addc17943",
        "name": "Rugby 7s (F)",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 13
    }, {
        "id": "011e35ef-e0b4-4388-ace0-ef297f26e604",
        "name": "Sailing",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 14
    }, {
        "id": "5f14d317-2263-4e60-a2cb-8ef5d9009bae",
        "name": "Soccer (F)",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 15
    }, {
        "id": "228f0b3d-f137-4323-9125-69cf8405feeb",
        "name": "Softball (F)",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 16
    }, {
        "id": "41300992-3afb-4d4b-baa2-263dd8e0726d",
        "name": "Swimming",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 17
    }, {
        "id": "5dccd631-b86d-47fc-984e-ac73d1234f7f",
        "name": "Taekwondo",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 18
    }, {
        "id": "12b9ae36-1cf5-4649-a208-2ecbccf70721",
        "name": "Tennis",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 19
    }, {
        "id": "2c60182b-0c96-4ec0-b140-ab933367377f",
        "name": "Triathlon",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 20
    }, {
        "id": "1c840f5a-0be7-4520-98b7-c2f5bcaea764",
        "name": "Volleyball - Beach",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 21
    }, {
        "id": "9818da96-c737-4202-9767-a1f0d081062f",
        "name": "Weightlifting",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 22
    }, {
        "id": "7a82bc88-1ada-4a7a-8efb-699b3c10683d",
        "name": "Wrestling",
        "parent_id": "3c8d22f2-e0af-4056-85dd-a85079a54cad",
        "parent_name": "Summer Sports",
        "parent_index": 23
    }, {
        "id": "0369694b-7390-4998-a355-6a087ade267c",
        "name": "Alpine Skiing",
        "parent_id": "487a9cfa-6534-435a-b756-991b07cfe28a",
        "parent_name": "Winter Sports",
        "parent_index": 0
    }, {
        "id": "f0d547e5-8d23-42f4-a7c7-bebafe9a629f",
        "name": "Biathlon",
        "parent_id": "487a9cfa-6534-435a-b756-991b07cfe28a",
        "parent_name": "Winter Sports",
        "parent_index": 1
    }, {
        "id": "e0635511-42dd-47c3-acb1-05391a0f2368",
        "name": "Bobsleigh",
        "parent_id": "487a9cfa-6534-435a-b756-991b07cfe28a",
        "parent_name": "Winter Sports",
        "parent_index": 2
    }, {
        "id": "f22c084c-9d35-488c-bde6-9580c58b6831",
        "name": "Cross Country Skiing",
        "parent_id": "487a9cfa-6534-435a-b756-991b07cfe28a",
        "parent_name": "Winter Sports",
        "parent_index": 3
    }, {
        "id": "ee69cba8-0b82-4628-8c80-7ddb62e0d4a5",
        "name": "Curling",
        "parent_id": "487a9cfa-6534-435a-b756-991b07cfe28a",
        "parent_name": "Winter Sports",
        "parent_index": 4
    }, {
        "id": "381d7878-ba3e-4049-b91c-2861ef419c5e",
        "name": "Figure Skating",
        "parent_id": "487a9cfa-6534-435a-b756-991b07cfe28a",
        "parent_name": "Winter Sports",
        "parent_index": 5
    }, {
        "id": "83ecba78-63d1-4d62-b6e7-6e9e19c24abb",
        "name": "Freestyle Skiing",
        "parent_id": "487a9cfa-6534-435a-b756-991b07cfe28a",
        "parent_name": "Winter Sports",
        "parent_index": 6
    }, {
        "id": "6d5fd1f9-abf2-41e9-96d4-743bede604e0",
        "name": "Hockey (F)",
        "parent_id": "487a9cfa-6534-435a-b756-991b07cfe28a",
        "parent_name": "Winter Sports",
        "parent_index": 7
    }, {
        "id": "69c70672-b110-4322-ba40-d07b7a3670b6",
        "name": "Hockey (M)",
        "parent_id": "487a9cfa-6534-435a-b756-991b07cfe28a",
        "parent_name": "Winter Sports",
        "parent_index": 8
    }, {
        "id": "e05d9f90-8487-42ea-ae80-aba7fc14dd4c",
        "name": "Luge",
        "parent_id": "487a9cfa-6534-435a-b756-991b07cfe28a",
        "parent_name": "Winter Sports",
        "parent_index": 9
    }, {
        "id": "ee6b3fc0-3a6c-445f-aa15-c0c346b12d4d",
        "name": "Skeleton",
        "parent_id": "487a9cfa-6534-435a-b756-991b07cfe28a",
        "parent_name": "Winter Sports",
        "parent_index": 10
    }, {
        "id": "1b7a2d21-030f-480f-a45e-e4a343178865",
        "name": "Ski Cross",
        "parent_id": "487a9cfa-6534-435a-b756-991b07cfe28a",
        "parent_name": "Winter Sports",
        "parent_index": 11
    }, {
        "id": "ff00dd17-f5ec-4114-bbd7-d64e3342c7fe",
        "name": "Snowboard",
        "parent_id": "487a9cfa-6534-435a-b756-991b07cfe28a",
        "parent_name": "Winter Sports",
        "parent_index": 12
    }, {
        "id": "0cb23c4b-fc4b-46f6-8d6b-50ccc9ebe813",
        "name": "Speed Skating - Long Track",
        "parent_id": "487a9cfa-6534-435a-b756-991b07cfe28a",
        "parent_name": "Winter Sports",
        "parent_index": 13
    }, {
        "id": "1587ae06-d2a3-4adb-892e-b3d23f06a1e6",
        "name": "Speed Skating - Short Track",
        "parent_id": "487a9cfa-6534-435a-b756-991b07cfe28a",
        "parent_name": "Winter Sports",
        "parent_index": 14
    }, {
        "id": "677131dd-e284-4c7f-91b5-076da05aaa0c",
        "name": "Boccia",
        "parent_id": "53682381-1a99-42fe-a429-b8671e29b394",
        "parent_name": "Para Summer",
        "parent_index": 0
    }, {
        "id": "a2f05883-5aaf-4b51-9fbc-c25adc79252f",
        "name": "Goalball (F)",
        "parent_id": "53682381-1a99-42fe-a429-b8671e29b394",
        "parent_name": "Para Summer",
        "parent_index": 1
    }, {
        "id": "a3264d76-ece9-4f4d-847c-1386b8738223",
        "name": "Para-Athletics",
        "parent_id": "53682381-1a99-42fe-a429-b8671e29b394",
        "parent_name": "Para Summer",
        "parent_index": 2
    }, {
        "id": "91a2c703-87fa-4e65-95ff-e91a952e011d",
        "name": "Para-Canoe",
        "parent_id": "53682381-1a99-42fe-a429-b8671e29b394",
        "parent_name": "Para Summer",
        "parent_index": 3
    }, {
        "id": "0018c508-cc8a-438b-99c8-5547e817ed70",
        "name": "Para-Cycling",
        "parent_id": "53682381-1a99-42fe-a429-b8671e29b394",
        "parent_name": "Para Summer",
        "parent_index": 4
    }, {
        "id": "961f602b-48bb-40a6-a483-73f92e88c7b9",
        "name": "Para-Equestrian",
        "parent_id": "53682381-1a99-42fe-a429-b8671e29b394",
        "parent_name": "Para Summer",
        "parent_index": 5
    }, {
        "id": "37e5c612-fc68-43bf-883e-a826a188aa89",
        "name": "Para-Judo",
        "parent_id": "53682381-1a99-42fe-a429-b8671e29b394",
        "parent_name": "Para Summer",
        "parent_index": 6
    }, {
        "id": "c237d488-ba6c-4d2f-b86c-44f5d58b4bc5",
        "name": "Para-Rowing",
        "parent_id": "53682381-1a99-42fe-a429-b8671e29b394",
        "parent_name": "Para Summer",
        "parent_index": 7
    }, {
        "id": "2ebe8e30-3790-4daf-b2c8-9b963778f514",
        "name": "Para-Swimming",
        "parent_id": "53682381-1a99-42fe-a429-b8671e29b394",
        "parent_name": "Para Summer",
        "parent_index": 8
    }, {
        "id": "0ea80699-5803-40a9-8c4a-0661f0768d5c",
        "name": "Para-Triathlon",
        "parent_id": "53682381-1a99-42fe-a429-b8671e29b394",
        "parent_name": "Para Summer",
        "parent_index": 9
    }, {
        "id": "0e72e584-fe05-46b5-a93a-2a9cdf7f3eaa",
        "name": "Sitting Volleyball (F)\t",
        "parent_id": "53682381-1a99-42fe-a429-b8671e29b394",
        "parent_name": "Para Summer",
        "parent_index": 10
    }, {
        "id": "140a9732-21c1-4b77-b03b-8c12c4195c35",
        "name": "Wheelchair Rugby",
        "parent_id": "53682381-1a99-42fe-a429-b8671e29b394",
        "parent_name": "Para Summer",
        "parent_index": 11
    }, {
        "id": "c3b59991-6181-4e6c-90e2-5e3cedc9b16b",
        "name": "Wheelchair Tennis",
        "parent_id": "53682381-1a99-42fe-a429-b8671e29b394",
        "parent_name": "Para Summer",
        "parent_index": 12
    }, {
        "id": "4169aeee-1143-4fea-b3d6-0715015aa848",
        "name": "Para-Alpine Skiing",
        "parent_id": "96249260-a559-448f-b12a-e57ec5ddece5",
        "parent_name": "Para Winter",
        "parent_index": 0
    }, {
        "id": "94d4684f-5a40-49aa-a0e0-d0ba2a8445ac",
        "name": "Para-Nordic Skiing",
        "parent_id": "96249260-a559-448f-b12a-e57ec5ddece5",
        "parent_name": "Para Winter",
        "parent_index": 1
    }, {
        "id": "f362b03f-89bc-467e-902f-ea7fcdaa7c71",
        "name": "Para-Snowboard",
        "parent_id": "96249260-a559-448f-b12a-e57ec5ddece5",
        "parent_name": "Para Winter",
        "parent_index": 2
    }, {
        "id": "ead3d823-dea6-4a26-8829-fed0c40ff548",
        "name": "Para-Ice Hockey",
        "parent_id": "96249260-a559-448f-b12a-e57ec5ddece5",
        "parent_name": "Para Winter",
        "parent_index": 3
    }, {
        "id": "6c102527-481b-4872-b96f-7176568399f6",
        "name": "Wheelchair Curling",
        "parent_id": "96249260-a559-448f-b12a-e57ec5ddece5",
        "parent_name": "Para Winter",
        "parent_index": 4
    }, {
        "id": "2b01d71c-24c3-4fad-90ea-1e3a8288aec9",
        "name": "Badminton",
        "parent_id": "8cb20aba-38f7-4359-9917-a18774dbb5ed",
        "parent_name": "Non-Targeted",
        "parent_index": 0
    }, {
        "id": "0a698b3b-f9be-45ab-b868-c1a08adbf962",
        "name": "Breaking",
        "parent_id": "8cb20aba-38f7-4359-9917-a18774dbb5ed",
        "parent_name": "Non-Targeted",
        "parent_index": 1
    }, {
        "id": "0cf5bc18-36ee-46b6-806d-86a4e70fd368",
        "name": "CKC Slalom",
        "parent_id": "8cb20aba-38f7-4359-9917-a18774dbb5ed",
        "parent_name": "Non-Targeted",
        "parent_index": 2
    }, {
        "id": "d431d61c-baa7-448e-b759-f12af8f38126",
        "name": "Climbing",
        "parent_id": "8cb20aba-38f7-4359-9917-a18774dbb5ed",
        "parent_name": "Non-Targeted",
        "parent_index": 3
    }, {
        "id": "c94ba2b2-7e35-4323-a0fe-2024e3ea7467",
        "name": "Fencing Epee",
        "parent_id": "8cb20aba-38f7-4359-9917-a18774dbb5ed",
        "parent_name": "Non-Targeted",
        "parent_index": 4
    }, {
        "id": "e2fb4b52-6ff7-4904-809a-f87e861d5b92",
        "name": "Skateboard",
        "parent_id": "8cb20aba-38f7-4359-9917-a18774dbb5ed",
        "parent_name": "Non-Targeted",
        "parent_index": 5
    }, {
        "id": "093d14d6-a042-4de9-877e-39237b5475c9",
        "name": "Goalball",
        "parent_id": "8cb20aba-38f7-4359-9917-a18774dbb5ed",
        "parent_name": "Non-Targeted",
        "parent_index": 6
    }, {
        "id": "417b8b13-c313-4922-bb28-842720554d43",
        "name": "Fencing Sabre",
        "parent_id": "8cb20aba-38f7-4359-9917-a18774dbb5ed",
        "parent_name": "Non-Targeted",
        "parent_index": 7
    }, {
        "id": "415d5069-55d4-4a3d-8132-73af2a4e5c56",
        "name": "Table Tennis",
        "parent_id": "8cb20aba-38f7-4359-9917-a18774dbb5ed",
        "parent_name": "Non-Targeted",
        "parent_index": 8
    }, {
        "id": "4455c2a3-d323-44a8-a60e-999e27242fd3",
        "name": "Equestrian",
        "parent_id": "8cb20aba-38f7-4359-9917-a18774dbb5ed",
        "parent_name": "Non-Targeted",
        "parent_index": 9
    }, {
        "id": "aa72c98e-9b2c-4fe5-8c3c-86c8933908dd",
        "name": "Rhythmic gymnastics",
        "parent_id": "8cb20aba-38f7-4359-9917-a18774dbb5ed",
        "parent_name": "Non-Targeted",
        "parent_index": 10
    }],
    "priority": "primary",
    "question": "Which Sport is most relevant to you?",
    "subfactor": 11,
    "subfactor_options": ["Archery", "Artistic Swimming", "Athletics", "Boxing", "Canoe/kayak", "Cycling", "Diving", "Fencing", "Golf", "Gymnastics", "Judo", "Karate", "Rowing", "Rugby 7s (F)", "Sailing", "Soccer (F)", "Softball (F)", "Swimming", "Taekwondo", "Tennis", "Triathlon", "Volleyball - Beach", "Weightlifting", "Wrestling", "Alpine Skiing", "Biathlon", "Bobsleigh", "Cross Country Skiing", "Curling", "Figure Skating", "Freestyle Skiing", "Hockey (F)", "Hockey (M)", "Luge", "Skeleton", "Ski Cross", "Snowboard", "Speed Skating - Long Track", "Speed Skating - Short Track", "Boccia", "Goalball (F)", "Para-Athletics", "Para-Canoe", "Para-Cycling", "Para-Equestrian", "Para-Judo", "Para-Rowing", "Para-Swimming", "Para-Triathlon", "Sitting Volleyball (F)\t", "Wheelchair Rugby", "Wheelchair Tennis", "Para-Alpine Skiing", "Para-Nordic Skiing", "Para-Snowboard", "Para-Ice Hockey", "Wheelchair Curling", "Badminton", "Breaking", "CKC Slalom", "Climbing", "Fencing Epee", "Skateboard", "Goalball", "Fencing Sabre", "Table Tennis", "Equestrian", "Rhythmic gymnastics"]
}]


export const primary_question_non = [{
    "fr": null,
    "id": "7d625c11-7d22-4353-ab3e-f24bdb0b21d2",
    "sno": 0,
    "name": "Sport",
    "answers": [{
        "id": "82177062-9f78-4e5c-9114-bafe752632b7",
        "name": "Hockey (W)",
        "parent_id": null,
        "parent_name": null,
        "parent_index": null
    }, {
        "id": "f587b6c0-34d3-40ec-aa38-e31b999ceb65",
        "name": "Hockey (M)",
        "parent_id": null,
        "parent_name": null,
        "parent_index": null
    }, {
        "id": "6479b4b3-ea84-4d75-a8d1-55ad8a2522f6",
        "name": "Basketball (M)",
        "parent_id": null,
        "parent_name": null,
        "parent_index": null
    }, {
        "id": "dadefd2d-e286-4289-957c-d3b740ce85c0",
        "name": "Basketball (W)",
        "parent_id": null,
        "parent_name": null,
        "parent_index": null
    }, {
        "id": "665bf48f-fd1a-4835-8306-f5c9b24dc7ab",
        "name": "Track & Field (M)",
        "parent_id": null,
        "parent_name": null,
        "parent_index": null
    }, {
        "id": "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
        "name": "Track and Field (W)",
        "parent_id": null,
        "parent_name": null,
        "parent_index": null
    }, {
        "id": "f1fb0f5e-a688-45b4-8dfe-8d0c61f8948b",
        "name": " Soccer (M)",
        "parent_id": null,
        "parent_name": null,
        "parent_index": null
    }, {
        "id": "c0fe1607-f3f6-4f12-b175-19de553764db",
        "name": "New Sport",
        "parent_id": null,
        "parent_name": null,
        "parent_index": null
    }],
    "priority": "primary",
    "question": "Which sport type is most pertinent to you?",
    "subfactor": 0,
    "subfactor_options": []
}]