import React, { useState } from "react";
import MultiLeaderItem from './MultiLeaderItem'

import { useDispatch } from "react-redux";

import {
 
  RESET_LEADERSHIP,
  SUBMIT_ANSWER,
  SET_ANSWERS,
} from "constants/actions";

import {
  SurveyContainer,
  Title,
  Button,
  SubmitButton,
  ResponseContainer,
} from "./styled-list";



import {
  // BrowserView,
  // MobileView,
  isBrowser,
  isMobile,
  osName,
  deviceDetect,
} from "react-device-detect";

const MultiQuestion = (props) => {
  const dispatch = useDispatch();
  const deviceDetected = deviceDetect();
  const { question, leadershipAnswersList,
    get_answers, leaderCount, leaderList } = props;


  const [selected,setSelected] = useState(0)
  const [selectedLeader,setSelectedLeader] = useState(0)
  const [answerCount, setAnswerCount] = useState(0);


  const NextQuestion = () => {
    let _currentLeaderQues = question.id
    if (parseInt(question.id + 1) <= get_answers.leadership_max_ques) {
      _currentLeaderQues = question.id + 1;
    }
    dispatch({
      type: SET_ANSWERS,
      payload: {
        leadership_high_ans: question.id,
        leadership_cur_ques: _currentLeaderQues,
        curr_direction: 1,
      },
    });
    
  };



  const SubmitAnswer = () => {
    // dispatch({
    //   type: ADD_LEADERSHIP_ANSWER,
    //   payload: {
    //     row_id: `${leaderSelected.leader_id}${question.id}`,
    //     leader_id: leaderSelected.leader_id,
    //     id: question.id,
    //     answer: answer,
    //     comments: comment,
    //   },
    // });

    dispatch({
      type: SUBMIT_ANSWER,
      payload: {
        leadership_answers: get_answers.leadership_answers,
        employee: get_answers.employee,
        token: get_answers.token,
        device: deviceDetected,
        mobile: isMobile? "Yes" : "No",
        browser: isBrowser? "Desktop" : "Other Devise",
        operating_system: osName,
        user_ip: get_answers.user_ip,
        leadership_survey_compliance: get_answers.leadership_survey_compliance,
      },
    });

    dispatch({
      type: SET_ANSWERS,
      payload: {
        curr_survey_type: get_answers.all_survey_types[1],
        survey_type: get_answers.all_survey_types[1],
        all_survey_types: get_answers.all_survey_types.slice(1),
      },
    });
    dispatch({
      type: RESET_LEADERSHIP,
    });
    // props.next(response);
  };

  // console.log(get_answers.leadership_max_ques === get_answers.leadership_cur_ques);
  // console.log(get_answers);
  // console.log(leadershipAnswersList.filter((p) => p.id === question.id).length);
function selectLeader(id) {
  // console.log("Leader Selected",id)
  // console.log(leaderList.find(({leader_id})=> leader_id === id)[0])
  setSelectedLeader(leaderList.filter((p) => p.leader_id === id)[0])
}

// console.log(leaderList[selected])
// console.log(selectedLeader)

function NextLeader() {
  if (selected < leaderCount){
    setSelected(selected+1)
    // setSelectedLeader(selected+1)
  }
  // console.log(leaderList)
  // console.log(leaderCount)
}

React.useEffect(() =>
{
  setSelectedLeader(leaderList[selected])
},[leaderList, selected])

React.useEffect(()=>{
  setAnswerCount(leadershipAnswersList.filter((p) => p.id === question.id).length)
},[leadershipAnswersList, question])

// console.log(selected)

  return (
    <SurveyContainer>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Title>{question.category}</Title>
      </div>

     <ResponseContainer>

     {leaderList.map((item,i)=> 
       <MultiLeaderItem 
       key={i}
       question={question}
       leaderSelected={item} 
       selected={item===selectedLeader}

       onClicked={selectLeader}
       NextLeader={NextLeader}


      //  onComplete={()=>setSelected(selected+1)} 


       />)}

    
        
     </ResponseContainer>



   {get_answers.leadership_max_ques === get_answers.leadership_cur_ques ? (
        <SubmitButton
          enabled={leaderCount === answerCount}
          onClick={SubmitAnswer}
        >
          Submit
        </SubmitButton>
      ) : (
        <Button 
        enabled={leaderCount === answerCount} 
        onClick={NextQuestion}>
          Next
        </Button>
      )
      }
    </SurveyContainer>
  );
};

export default MultiQuestion;
