import React,{useState,useEffect} from 'react';
import styled, { keyframes, css } from 'styled-components';
import '../AuditV2/index.css'


const MultiSelect = ({ currentQuestionDet, choices,question,isVisible,setSelectedResponse,selectedResponse }) => {

  const [visibleChoices, setVisibleChoices] = useState([]);


  const handleSelection = (choice) => {
    setSelectedResponse(choice);
  };

  useEffect(() => {
    let timeoutIds = [];
    choices.map((choice, index) => {
      const timeoutId = setTimeout(() => {
        setVisibleChoices((prevVisibleChoices) => [...prevVisibleChoices, choice]);
      }, index * 20); // Adjust the delay as needed (300ms here)
      timeoutIds.push(timeoutId);
    });

    return () => {
      timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, [choices]);

    console.log(selectedResponse)

  return (
    <ChoiceContainer>
      <Question>{question}</Question>
      <ListContainer>
      {visibleChoices.map((choice, index) => (
        <StyledChoice key={index} delay={index * 100}>
            <ListItem key={index} isSelected={index === selectedResponse}>
            <CheckboxInput
                id={`chk_${index}_${currentQuestionDet?.id}`}
                name="choices"
                value={choice.text}
                checked={selectedResponse?.includes(index)}
                onChange={() => handleSelection(index)}
            />
            <Checkmark className="checkmark" />
            <Choice> {choice.text}</Choice>
           
          </ListItem>
        </StyledChoice>
      ))}
      </ListContainer>
    </ChoiceContainer>
  );
};

export default MultiSelect;


const ChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width:calc(100% - 40px);
  max-width:600px;

  @media(max-height:550px){
    overflow-y:scroll;
    max-height:70%;
    /* For Webkit browsers like Chrome, Safari, etc. */
    &::-webkit-scrollbar {
      width: 2px; /* width of the scrollbar */
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #888; /* color of the scroll thumb */
      border-radius: 4px; /* roundness of the scroll thumb */
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background-color: #555; /* color of the scroll thumb on hover */
    }
  
    /* For Firefox */
    scrollbar-width: thin;
    scrollbar-color: #888 #f5f5f5; /* thumb and track color */
  
  }
`;

const Choice = styled.div`
margin-left:20px;
`;

const Title = styled.div`
color: #2D70E2;
text-transform: uppercase;
font-size:14px;
font-weight:600;
margin-bottom: 40px;
`

const fadeInMoveDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledChoice = styled.div`
  transform: translateY(-15px);
  opacity:0;
  animation-name: ${fadeInMoveDown};
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  ${({ delay }) =>
    delay &&
    css`
      animation-delay: ${delay}ms;
    `}
`;
const Question = styled.div`
  color:#2A3039;
  font-size: 20px;
  font-weight:600;
  margin-bottom:14px;
  line-height: 1.5;

  @media(max-width:768px){
    font-size: 18px;
  }
`

const ListContainer = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  width:100%;
`;

const ListItem = styled.label`
  display: block;
  position: relative;
  padding: 16px;
  padding-left: 40px;
  cursor: pointer;
  user-select: none;
  width:100%;
  background-color: ${({ isSelected }) => (isSelected ? '#EBF1FD' : 'transparent')};
  color: ${({ isSelected }) => (isSelected ? '#2D70E2' : 'inherit')};
  
  &:hover {
    background-color: ${({ isSelected }) => (isSelected ? '#EBF1FD' : '#f0f0f0')};
  }
`;

const RadioInput = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ .checkmark {
    background-color: #EBF1FD;
    border-color: #2D70E2;
  }

  &:checked ~ .checkmark:after {
    display: block;
  }
`;
const Checkmark = styled.span`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 3px;

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 4px;  // adjust left position
    top: -4px;  // adjust top position
    width: 7px;  // increase width
    height: 12px;  // increase height
    border: solid black; 
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;


// Style the CheckboxInput component
const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ .checkmark {
    border-color: #2D70E2;
    background-color: #EBF1FD;
  }

  &:checked ~ .checkmark:after {
    display: block;
  }
`;