import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PersonalityQuestion from "./PersonalityQuestion";

import Intro from "./Intro";
import End from "./End";
import styled from "styled-components";
import { Icon, Loader } from "semantic-ui-react";

import NoToken from "./NoToken";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "../survey.css";

import {
  // BrowserView,
  // MobileView,
  isBrowser,
  isMobile,
  deviceType,
  osName,
  osVersion,
  deviceDetect,
} from "react-device-detect";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SET_ANSWERS,
  LOAD_PERSONALITY_QUESTIONS,
  SG_START_SURVEY,
  SG_GET_PERSONALITY_ANSWER,
  // SG_UPDATE_PERSONALITY_ANSWER,
  SG_START_PERSONALITY_ANSWER,
} from "constants/actions";

import {TRANSITIONSPEED, SlideDirection} from "constants/animation"

const deviceDetected = deviceDetect();
const PersonalitySurvey = () => {
  const dispatch = useDispatch();
  // const search = window.location.search;
  // const params = new URLSearchParams(search);
  // const token = params.get("token");
  let { token } = useParams();

  const [pQuestion, setPQuestion] = useState(0);
  const [direction, setDirection] = useState(1);
  const [tokenMatched, setTokenMatched] = useState([]);
  const [answers, setAnswer] = useState([]);
  const [personalityQuestionList, setPersonalityQuestionList] = useState([]);
  const [personalityAnswerList, setPersonalityAnswerList] = useState([]);

  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const [delayResponse, setDelayResponse] = useState(false);

  useEffect(() => {
    // Run! Like go get some data from an API.

    if (token) {
      dispatch({
        type: LOAD_PERSONALITY_QUESTIONS,
        payload: `?token=${token}`,
      });
      dispatch({
        type: SG_START_SURVEY,
        payload: `?token=${token}`,
      });
      dispatch({
        type: SG_GET_PERSONALITY_ANSWER,
        payload: `?token=${token}`,
      });
    }
  }, [dispatch, token]);

  const {
    get_questions,
    // survey_type,
    get_answers,
    personality_ans,
    personality_high_ans,
    personality_cur_ques,
    personality_complete,
  } = useSelector(
    (state) => ({
      get_questions: state.questions,
      //get this from question token
      survey_type: state.answers.curr_survey_type,
      personality_ans: state.answers.personality_answers,
      get_answers: state.answers,
      personality_high_ans: state.answers.personality_high_ans,
      personality_cur_ques: state.answers.personality_cur_ques,
      personality_complete: state.answers.personality_complete,
    }),
    shallowEqual
  );

  // Get list of question ids from the question list
  // use the list to check if all questions are
  // answered
  useEffect(() => {
    if (get_questions) {
      setPersonalityQuestionList(
        get_questions.personality_questions.questions?.onboard
      );
      setPersonalityAnswerList(personality_ans);
    }
  }, [dispatch, get_questions, personality_ans]);

  useEffect(() => {
    setTokenMatched(get_answers.token === token);
    setPQuestion(get_answers.personality_cur_ques);

    setTimeout(() => {
      setDelayResponse(get_answers.token !== token);
    }, 5000);
  }, [get_answers, token]);

  const back = () => {
    if (personality_high_ans > 0) {
      setDirection(0);
      setPQuestion(pQuestion - 1);
      dispatch({
        type: SET_ANSWERS,
        payload: {
          personality_cur_ques: pQuestion - 1,
          curr_direction: 0,
        },
      });
      setShowNext(true);
    }
    setShowPrev(false);
  };

  const forward = () => {
    if (answers.length > pQuestion) {
      setDirection(1);
      setPQuestion(pQuestion + 1);
      dispatch({
        type: SET_ANSWERS,
        payload: {
          personality_cur_ques: pQuestion + 1,
          curr_direction: 0,
        },
      });
      setShowNext(false);
      setShowPrev(true);
    }
  };

  const nextQ = (i) => {
    if (pQuestion === answers.length) {
      setAnswer((answers) => [...answers, i]);
    } else {
      let temp = answers;
      temp[pQuestion] = i;
      setAnswer(temp);
    }

    setDirection(1);
    if (pQuestion < 100) {
      setPQuestion(pQuestion + 1);
    }
  };

  function Start() {
    dispatch({
      type: SET_ANSWERS,
      payload: {
        curr_direction: 1,
        personality_cur_ques: 1,
        personality_max_ques: 100,
        token: token,
        isBrowser,
        mobile: isMobile,
        browser: deviceDetected.browserName,
        deviceType,
        osVersion,
        operating_system: osName,
        device: deviceDetected,
      },
    });
    if (get_answers?.response_code) {
      // dispatch({
      //   type: SG_UPDATE_PERSONALITY_ANSWER,
      //   payload: {
      //     full_response: get_answers },
      // })
    } else {
      dispatch({
        type: SG_START_PERSONALITY_ANSWER,
        payload: {
          employee_id: get_answers.employee,
          token: get_answers.token,
          participants_in_personality: get_answers.participants_in_personality,
          full_response: {
            personality_answers: get_answers.personality_answers,
            personality_max_ques: get_answers.personality_max_ques || 100,
            personality_high_ans: get_answers.personality_high_ans || 0,
            personality_cur_ques: get_answers.personality_cur_ques || 1,
            personality_complete: get_answers.personality_complete,
            personality_survey_status: get_answers.personality_survey_status,
            participants_in_personality:
              get_answers.participants_in_personality,
          },
        },
      });
    }
    setPQuestion(pQuestion + 1);
  }

  if (!tokenMatched) {
    return (
      <Container>
        <SwitchTransition>
					<CSSTransition
						key={pQuestion}
						timeout={TRANSITIONSPEED}
						classNames={SlideDirection[direction]}
					>
            <>{delayResponse ? <NoToken /> : <Loader />}</>
          </CSSTransition>
        </SwitchTransition>
      </Container>
    );
  }

  return (
    <Container>
      <SwitchTransition>
					<CSSTransition
						key={pQuestion}
						timeout={TRANSITIONSPEED}
						classNames={SlideDirection[direction]}
					>
            <>
              {/* Start */}

              {pQuestion === 0 && <Intro next={Start} />}
              {personality_cur_ques > 0 &&
                !personality_complete &&
                personalityQuestionList?.length > 0 && (
                  <PersonalityQuestion
                    question={personalityQuestionList.find(
                      ({ id }) => id === personality_cur_ques
                    )}
                    highAns={personality_high_ans}
                    setShowPrev={setShowPrev}
                    setShowNext={setShowNext}
                    setDirection={setDirection}
                    get_answers={get_answers}
                    answer={
                      personalityAnswerList.find(
                        ({ id }) => id === personality_cur_ques
                      )?.answer
                    }
                    next={(data) => nextQ(data)}
                    key={pQuestion}
                  />
                )}

              {/* End */}
              {personality_complete && <End />}
              {/* */}
            </>
          </CSSTransition>
        </SwitchTransition>

      <CounterContainer>
        <Counter>
          <div>
            <Title>Personality</Title>
            {personality_cur_ques} of 100 questions
          </div>
        </Counter>
        <Icon
          name="chevron up"
          disabled={!showPrev}
          style={{ cursor: showPrev ? "pointer" : "not-allowed" }}
          onClick={() => back()}
        />
        <Space />

        <Icon
          name="chevron down"
          style={{ cursor: showNext ? "pointer" : "not-allowed", zIndex: 2 }}
          disabled={!showNext}
          onClick={() => forward()}
        />
      </CounterContainer>
    </Container>
  );
};

export default PersonalitySurvey;

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #15056b;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CounterContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-size: 14px;

  @media only screen and (min-width: 992px) {
    bottom: 50px;
    right: 50px;
    font-size: 20px;
  }
`;

const Counter = styled.div`
  padding: 5px 10px 5px 10px;
  background-color: rgba(30, 33, 114, 0.3);
  font-size: 12px;
  border-radius: 7px;
  margin-right: 10px;

  @media only screen and (min-width: 992px) {
    padding: 10px 25px 10px 25px;
    font-size: 16px;
    margin-right: 25px;
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;

  @media only screen and (min-width: 992px) {
    margin-bottom: 5px;
    font-size: 20px;
  }
`;

const Space = styled.div`
  @media only screen and (min-width: 992px) {
    width: 20px;
  }
`;
