import { de } from "faker/lib/locales"

import {REVERSE} from "data/reverseScores"

export const getPromptLanguage = (language,getResponse,respValue) =>{

    switch(language){
        case 0:
            return `Context:
        The following is feedback from a culture analysis survey. The feedback is a response to the following statement: ${getResponse?.question} \n
        Feedback:\n${respValue}\n
        Question:\nWhat follow up question would a world class culture consultant ask based on the feedback given that would help create recommendations for the organization to address these issues? Make sure your response is simple enough for an 7th grader to understand. Only return the question istelf. \n
        Response:\n`

        case 1:
            return `Contexte :
            Voici un retour d'information provenant d'une enquête d'analyse de la culture. Le retour d'information est une réponse à l'énoncé suivant : ${getResponse?.question} \n
            Retour d'information :\n${respValue}\n
            Question :\nQuelle question de suivi un consultant culturel de classe mondiale poserait-il sur la base des retours d'information donnés qui aiderait à créer des recommandations pour l'organisation afin de traiter ces problèmes ? Assurez-vous que votre réponse est suffisamment simple pour qu'un élève de 7e année puisse la comprendre. Ne retournez que la question elle-même. \n
            Réponse :\n`

        default:
            return `Context:
            The following is feedback from a culture analysis survey. The feedback is a response to the following statement: ${getResponse?.question} \n
            Feedback:\n${respValue}\n
            Question:\nWhat follow up question would a world class culture consultant ask based on the feedback given that would help create recommendations for the organization to address these issues? Make sure your response is simple enough for an 7th grader to understand. Only return the question istelf. \n
            Response:\n`
    }

}


export const getBottomQuestionLanguage = (language,getResponse) =>{

    if (getResponse.type === 'min') {
        switch(language){
            case 0:
                return 'You scored a '+(getResponse.reverse? REVERSE[getResponse?.response]: getResponse.response)+'/10. Can you provide specific examples of how this factor has impacted you or your colleagues?'
            case 1:
                return 'Vous avez obtenu un '+(getResponse.reverse? REVERSE[getResponse?.response]: getResponse.response)+'/10. Pouvez-vous fournir des exemples spécifiques de la façon dont ce facteur vous a affecté, vous ou vos collègues?'
            default:
                return 'You scored a '+(getResponse.reverse? REVERSE[getResponse?.response]: getResponse.response)+'/10. Can you provide specific examples of how this factor has impacted you or your colleagues?'
       
        }
    }

    switch(language){
        case 0:
            return "We’d love to know more! Please provide an example of why."
        case 1:
            return "Nous aimerions en savoir plus! Veuillez fournir un exemple de pourquoi."
        default:
            return "We’d love to know more! Please provide an example of why."
    }

}

export const getTopQuestionLanguage = (language,getResponse) =>{
    if (getResponse.type === 'min') {
        switch(language){
            case 0:
                return 'We noticed you scored the following statement amongst the lowest overall:'
            case 1:
                return 'Nous avons remarqué que vous avez obtenu l’énoncé suivant parmi les plus bas dans l’ensemble:'
            default:
                return 'We noticed you scored the following statement amongst the lowest overall:'
      }
    }

      switch(language){
        case 0:
            return 'You scored the following statement the highest:'
        case 1:
            return 'Vous avez obtenu l’énoncé suivant le plus haut:'
        default:
            return 'You scored the following statement the highest:'
      }
}