import styled from 'styled-components';
import React from 'react';
import { Icon } from "semantic-ui-react";

const Container = styled.div`
	padding:0 15px 0 15px;
  	height: 25px;
   border-radius: 26.5px;
   background-color: ${(props) => 'rgba('+props.color+')'};
   display:flex;
   flex-direction:row;
   align-items:center;
   justify-content:center;
 	margin-right:5px;
   font-size: 14px;
   margin-top:5px;

    @media only screen and (min-width:992px) and (min-height:750px){
    	font-size: 20px;
		height: 45px;
		margin-right:13px;
    }
`
const Emotion = styled.div`
  font-weight: 500;
  color: #ffffff;
  font-family: 'Red Hat Display', sans-serif;
  margin-right:10px;
`

const VerticalLine = styled.div`
	height:100%;
	width:1px;
	background-color:white;
	margin-right:10px;
`

const MoodSelect = (props) =>{
const {item} =props

	return (
		<Container color={item.color} onClick={()=>props.remove(item.id)}>
			<Emotion>{item.name}</Emotion>
			<VerticalLine/>
			<Icon
	          name="close"
	          size={"small"}
	          style={{ margin: 0, color: "white" ,cursor:"pointer"}}
	        />
		</Container>
		)
}


export default MoodSelect