import React, { useEffect, useState } from "react";

import Slider from './Slider'


import styled from "styled-components";


const QuestionTemplate = ({ 
  saveQuestionAnswer,
  question,
  response, name, language }) => {

  const [respValue, setRespValue] = useState(0)

  const save = (resp) => {
    // console.log(resp)
    saveQuestionAnswer({
      id: question?.dimension,
      factor: question?.factor,
      q: question?.q,
      ques_order: question?.order,
      response: resp,
      role: question?.role,
      binary: question?.binary,
			reverse: question?.reverse,
      })
  }
  // console.log(response)
  // console.log(question)
  // console.log(data)

  useEffect(() => {
    if (response) {
      setRespValue(response?.response)
    } else {
      setRespValue(null)
    }
  }, [response]);

  // console.log(question)
  return (
    <SurveyContainer>
      <Container>
      <Name>{language===1? 'En relation avec':'In relation to'} {name}:</Name>
      <Text>{language===1?'fr' in question?question.fr:question?.question:question?.question}</Text>
      <Slider
        preValue={respValue}
         language={language}
        // setCheckResponse
        saveResult={(resp) => save(resp)} />
        </Container>
    </SurveyContainer>
  )
}




export default QuestionTemplate



const SurveyContainer = styled.div`
  padding: 0px;
  box-sizing: border-box;
  position: absolute;
  left: 0%;
  top: 10%;
  width:100%;
  max-width:650px;

   @media only screen and (min-width: 768px) {
    left: calc(50% - 350px);
    top: 10%;
  }


   @media only screen and (min-width:992px){
     padding:30px;
    left: calc(50% - 350px);
    top:10%;
  }
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Barlow", sans-serif;
  color: black;
  margin-top: 14px;
  max-width:600px;
  line-height:1.3;

  @media only screen and (min-width: 992px) {
    font-size: 23px;
    margin-top: 28px;
  }
`;


const Name = styled.div`
  margin-bottom:5px;
  color:grey;
  font-family: "Barlow", sans-serif;
`

const Container = styled.div`
  padding:30px;
  position:relative;
  width:100%;
`

// const Button = styled.div`
//   height: 40px;
//   border-radius: 10px;
//   background-color: white;
//   color: #6a00ff;
//   font-size: 16px;
//   font-weight: bold;
//   font-family: "Barlow", sans-serif;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   width: 100px;
//   margin-top: 50px;
// `;


// const SubmitButton = styled(Button)`
//   width: 170px;
//   height: 47px;
//   border-radius: 5px;
//   background-color: ${(props) => (props.enabled ? "white" : "#bbc7bd")};
//   // color: white;
//   // background-color: white;
//   color: #6A00FF;
//   font-size: 16px;
//   font-weight: bold;
//   font-family: "Red Hat Display", sans-serif;
//   cursor: ${(props) => (props.enabled ? "pointer" : "not-allowed")};
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
// `;

