import React, { useEffect, useState, useRef } from "react";
import Shapes from './Shapes'
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import {
	SG_START_BRIEF_SURVEY,
	NEW_VALIDATION_TOKEN,
	RESET_VALIDATION_TOKEN,
	CLEAR_ALL,
	SG_POST_BRIEF_RESPONSE,
	UPDATE_BRIEF_RESPONSE,
	SG_UPDATE_BRIEF_RESPONSE
} from "constants/actions";

import {
	isBrowser,
	isMobile,
	deviceType,
	osName,
	osVersion,
	deviceDetect,
} from "react-device-detect";

import Intro from "./Intro";
import SurveyMessage from "./Message";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import Language from './Language'

import CategoryQuestion from './Categories'

import NoToken from "./NoToken";

import { SwitchTransition, CSSTransition } from "react-transition-group";
import { TRANSITIONSPEED, SlideDirection } from "constants/animation"

import "../survey.css";
import QuestionTemplate from './Questions'
import CommentTemplate from './Comments'
import ExistingDataFound from "survey/SurveyComponents/ExistingData";


import Agreement from "survey/SurveyComponents/Agreement";
import End from './End'

import { RunRules } from "utilities/FeedbackRules"
import CommentsFeedback from './CommentsFeedback'

const categoryType = "category"
const questionsType = "questions"
const commentsType = "comments"

const arrange_Questions = (questions) => {
	if (questions?.dimensions) {
		return questions.dimensions.map((item, i) => {
			return item.factors.map((factor, f) => {
				return factor.questions.map((q, idx) => {
					return {
						question: q.q,
						dimension: i,
						factor: f,
						q: idx,
						role: q.role,
						reverse: q.reverse,
						order: q.id,
						fr: q.fr
					}
				})
			})
		}).flat().flat().sort((a, b) => a.order - b.order)
	}
}


const OtpSurvey = () => {
	const dispatch = useDispatch();
	const deviceDetected = deviceDetect();
	// const search = window.location.search;
	// const params = new URLSearchParams(search);
	// const token = params.get("token");
	// token is not query string but a param

	let [searchParams, setSearchParams] = useSearchParams();
	const direct_language = Number(searchParams.get("language"));

	let { token } = useParams();
	const [direction, setDirection] = useState(1);
	const [showPrev, setShowPrev] = useState(true);
	const [showNext, setShowNext] = useState(true);
	// const [location, setLocation] = useState(0)
	const [showIntro, setShowIntro] = useState(false)
	const [noResponse, setNoResponse] = useState(false)
	const elementRef = useRef(null);

	const [defaultMessage, setDefaultMessage] = useState({
		title: "Survey completed",
		subTitle: "This survey has been successfully submitted"
	})
	// 
	const [totalQuestions, setTotalQuestions] = useState({
		total: 0,
		runningTotal: 0,
		questions: 0,
		culture: 0,
		sequence: {}
	})
	const [questions, setQuestions] = useState([])
	const [categoryQues, setCategoryQues] = useState([])
	const [employeeCategory, setEmployeeCategory] = useState([])
	// 
	const [briefResponse, setBriefResponse] = useState([])
	const [questionNo, setQuestionNo] = useState(0)
	const [visualQuestionNo, setVisualQuestionNo] = useState(0)
	//
	const [prevResponse, setPrevResponse] = useState({})
	const [checkResponse, setCheckResponse] = useState(false)

	const [checkRole, setCheckRole] = useState(false)

	const [questionAnswers, setQuestionAnswers] = useState({
		categories: {},
		comments: {},
		questions: {},
	  });
	  
	const [surveyFeedbackResp, setSurveyFeedbackResp] = useState(false)
	const [surveyPersonalityResp, setSurveyPersonalityResp] = useState({})
	const [surveyModularResp, setSurveyModularResp] = useState(false)

	const [ruleFeedback, setRuleFeedback] = useState(false)
	const [ruleFeedbackComplete, setRuleFeedbackComplete] = useState(true)
	const [askFeedback, setAskFeedback] = useState(false)

	const [thankMessage, setThankMessage] = useState(false)
	const [complete, setComplete] = useState(false)

	const [surveyStatus, setSurveyStatus] = useState(0)
	const [resetAnswers, setResetAnswers] = useState(false)
	const [surveyDeleted, setSurveyDeleted] = useState(false);
	const [surveyDeletedOn, setSurveyDeletedOn] = useState(false);

	// stored persisting response
	const [validationToken, setValidationToken] = useState(false)
	const [briefResponseId, setBriefResponseId] = useState(false)
	const [storedResponseFound, setStoredResponseFound] = useState(false)

	const [primaryCategory, setPrimaryCategory] = useState(false);
	const [neverAskResponse, setNeverAskResponse] = useState(false);
	const [prevFoundResponse, setPrevFoundResponse] = useState(false);

	const [consentProvided, setConsentProvided] = useState(false);
	const [language, setLanguage] = useState(0)
	const [showLanguage, setShowLanguage] = useState(false)

	const [submitError, setSubmitError] = useState(false)

	const [askComments, setAskComments] = useState(false);
	// const [organizationInfo, setOrganizationInfo] = useState(false)

	const { get_brief_response, get_errors } = useSelector(
		(state) => ({
			get_brief_response: state.briefresponse,
			get_errors: state.errors,
		}),
		shallowEqual
	);

	useEffect(() => {
		if (direct_language === 1) {
			setLanguage(direct_language)
		}
	}, [direct_language])

	useEffect(() => {
		if (get_brief_response?.response?.questions?.comments) {
			if (get_brief_response?.response?.questions?.comments?.length > 0) {
				setAskComments(get_brief_response?.response?.questions?.comments)
			}
		}
	}, [get_brief_response]);

	useEffect(() => {
		if (get_brief_response) {
			setQuestions(arrange_Questions(get_brief_response?.response?.questions))
			setEmployeeCategory(get_brief_response?.response?.employee_category)
			setCategoryQues(get_brief_response?.response?.culture_questions?.sort((a, b) => a?.id - b?.id))
		}

		if (get_brief_response?.response?.questions?.language) {
			setShowLanguage(true)
			// console.log(get_brief_response)
		}
	}, [get_brief_response]);

	// question no based on role skip logic
	// console.log(get_brief_response?.response?.employee_category)
	// console.log(questionAnswers?.categories)
	// step 1 set role
	useEffect(() => {
		// if (questionAnswers?.categories) {
		// 	const CatRes = Object.values(questionAnswers?.categories)
		// 	setCheckRole(CatRes.filter(ct => ct.priority === "secondary")?.[0]?.response)
		// }
		// get_brief_response?.response?.employee_category
		if (get_brief_response?.response?.employee_category) {
			const CatRes = get_brief_response?.response?.employee_category
			setCheckRole(CatRes.filter(ct => ct.priority === "secondary")?.[0]?.value)
		}
	}, [get_brief_response.response.employee_category])

	useEffect(() => {
		setRuleFeedbackComplete(true)
		const RuleRun = RunRules({ questions, get_brief_response })
		// console.log(RuleRun)
		if (RuleRun.length > 0) {
			setRuleFeedbackComplete(false)
		}
		setRuleFeedback(RuleRun)
	}, [get_brief_response, questions]);

	useEffect(() => {
		let _id = ''
		let _response = 0
		let subfactorFound = false
		let _subfactor = null
		const SurveyName = get_brief_response?.response?.survey_name

		if (get_brief_response?.response?.primary_question) {
			get_brief_response.response.primary_question.map(pq => {
				_id = pq.id
				if (pq?.subfactor) {
					subfactorFound = true
				}
				pq && pq.answers.map(aq => {
					if (aq?.name === SurveyName) {

						if (subfactorFound) {
							_subfactor = aq?.id
							_response = aq?.parent_id
						} else {
							_response = aq?.id
						}
					}
					return null
				})
				return null
			})
		}

		setPrimaryCategory({
			id: _id,
			response: _response,
			subfactor: _subfactor,
			priority: "primary"
		})


	}, [get_brief_response]);

	//skip questions
	useEffect(() => {
		const _seq = {}
		// add category question nos to list
		const _prevResp = {}
		const _neverAskResp = {}

		let running_total = 0
		let total = 0
		let CatLength = 0
		if (categoryQues) {
			categoryQues.map((q, v) => {
				if (q?.input === "always_ask") {
					// console.log(q)
					running_total += 1
					CatLength += 1
					total += 1
					_seq[running_total] = {
						from: `always_ask ${q.id}`,
						quesType: categoryType,
						skip: false,
						sno: running_total
					}
					return null
				}

				if (q?.input === "ask_if_not_found") {
					// setPrevFoundResponse
					const _empCat = employeeCategory.filter(ec => ec.name === q.name)?.[0]?.value
					if (_empCat) {
						const _response = q.answers.filter(rq => rq.name === _empCat)?.[0]?.id
						running_total += 1
						CatLength += 1
						total += 0
						_seq[running_total] = {
							from: `ask_if_not_found ${q.id}`,
							quesType: categoryType,
							skip: true,
							sno: running_total
						}
						_prevResp[running_total] = {
							id: q.id,
							response: _response,
							priority: q.priority
						}

					} else {
						running_total += 1
						CatLength += 1
						total += 1
						_seq[running_total] = {
							from: `ask_if_not_found  ${q.id}`,
							quesType: categoryType,
							skip: false,
							sno: running_total
						}
					}
					return null
				}

				if (q?.input === "never_ask") {
					// console.log(q)
					running_total += 1
					CatLength += 1
					total += 0
					_seq[running_total] = {
						from: `never_ask  ${q.id}`,
						quesType: categoryType,
						skip: true,
						sno: running_total
					}

					const _empCat = employeeCategory.filter(ec => ec?.name === q?.name)?.[0]?.value_id
					
					_neverAskResp[running_total] = {
						id: q.id,
						response: _empCat ? _empCat : null,
						priority: q.priority
					}
					return null
				}

				if (!q?.input) {
					running_total += 1
					CatLength += 1
					total += 1
					_seq[running_total] = {
						from: `undefined`,
						quesType: categoryType,
						skip: false,
						sno: running_total
					}
				}
				return null
			})
		}

		setPrevFoundResponse(_prevResp)
		setNeverAskResponse(_neverAskResp)

		let quesLength = 0
		if (questions) {
			questions.map((q, v) => {
				if (checkRole) {
					if (q?.role) {
						if (q?.role.includes(checkRole)) {
							running_total += 1
							quesLength += 1
							total += 1
							_seq[running_total] = {
								quesType: questionsType,
								from: "question for specific role",
								skip: false,
								sno: running_total
							}
							return null
						} else {
							running_total += 1
							// quesLength += 1
							_seq[running_total] = {
								quesType: questionsType,
								from: "question not inlcuded role specifics",
								skip: true,
								sno: running_total
							}
							return null
						}
					} else {
						running_total += 1
						quesLength += 1
						total += 1
						_seq[running_total] = {
							quesType: questionsType,
							from: "question without role",
							skip: false,
							sno: running_total
						}
						return null
					}
				} else {

					running_total += 1
					quesLength += 1
					total += 1
					_seq[running_total] = {
						quesType: questionsType,
						from: "no role checked",
						skip: false,
						sno: running_total
					}
					return null
				}
			}
			)
		}

		let commLength = 0
		if (askComments) {
			askComments.map((q, v) => {
				running_total += 1
				commLength += 1
				total += 1
				_seq[running_total] = {
					quesType: commentsType,
					skip: false,
					sno: running_total
				}
				return null
			}
			)
		}

		setTotalQuestions({
			total: total,
			runningTotal: running_total,
			questions: quesLength,
			category: CatLength, // add primaryCategory
			comments: commLength,
			sequence: _seq
		})
	}, [questions, categoryQues, askComments, checkRole, employeeCategory]);

	// console.log("totalQuestions?.sequence", totalQuestions?.sequence)

	// if the number of questions changes based on responses
	// reset or remove the older questions which were answered
	useEffect(() => {
		setResetAnswers(true)
	}, [totalQuestions.total]);

	useEffect(() => {
		if (resetAnswers && questionAnswers?.categories !== undefined) {
			setResetAnswers(false)
			let _quesAns = questionAnswers

			// dispatch({
			// 	type: UPDATE_BRIEF_RESPONSE,
			// 	total_questions: totalQuestions.runningTotal,
			// 	current_question: questionNo,
			// 	current_answer: questionNo,
			// 	high_answer: questionNo,
			// 	payload: {
			// 		answers: {
			// 			questions: {},
			// 			comments: {},
			// 			categories: _quesAns?.categories
			// 		},
			// 	},
			// });
			setQuestionAnswers({
				categories: _quesAns?.categories,
				questions: {},
				comments: {}
			})
		}
	}, [resetAnswers, questionAnswers, dispatch, totalQuestions, questionNo]);

	function nextQuestion() {
		let _questionNo = questionNo + 1
		while (_questionNo <= totalQuestions?.runningTotal) {
			if (totalQuestions.sequence[_questionNo]?.skip) {
				_questionNo++
			} else {
				setQuestionNo(_questionNo)
				setVisualQuestionNo(visualQuestionNo + 1)
				return
			}
		}
	}

	function prevQuestion() {
		let _questionNo = questionNo - 1
		while (_questionNo >= 1) {
			if (totalQuestions.sequence[_questionNo]?.skip) {
				_questionNo--
			} else {
				setQuestionNo(_questionNo)
				setVisualQuestionNo(visualQuestionNo - 1)
				return
			}
		}
	}

	function PreCloseSurvey() {
		if ((questionNo + 1) <= totalQuestions.runningTotal) {
			nextQuestion()
			return false
		} else {
			if (!ruleFeedbackComplete) {
				console.log("no feedback close this")
				setAskFeedback(true)
				return false
			}
			setAskFeedback(false)
			setComplete(true)
			return true
		}
	}

	useEffect(() => {
		if (get_brief_response?.response?.id) {
			setNoResponse(false)
		} else {
			setNoResponse(true)
		}
		// 
		setBriefResponseId(get_brief_response?.id)

		if (get_brief_response?.validation_token) {
			setValidationToken(get_brief_response?.validation_token)
			if (get_brief_response?.response?.stored_response?.high_answer > 2) {
				setStoredResponseFound(true)
			} else {
				setStoredResponseFound(false)
			}
		} else {
			setStoredResponseFound(false)
			setValidationToken(false)
		}
	}, [get_brief_response]);

	useEffect(() => {
		setPrevResponse(null)
		if (questionNo) {
			const QueType = totalQuestions?.sequence?.[questionNo]?.quesType
			// console.log(QueType)
			if (QueType === categoryType) {
				setPrevResponse(get_brief_response?.answers?.categories?.[questionNo])
			}
			if (QueType === questionsType) {
				setPrevResponse(get_brief_response?.answers?.questions?.[questionNo])
			}
			if (QueType === commentsType) {
				setPrevResponse(get_brief_response?.answers?.comments?.[questionNo])
			}
		}
	}, [totalQuestions, get_brief_response.answers, questionNo]);

	useEffect(() => {
		setShowPrev(false)
		setShowNext(false)
		setBriefResponse(get_brief_response)
		if (!questionNo) {
			setShowPrev(false)
			setShowNext(false)
		}
		if (questionNo === 1) {
			setShowPrev(false)
			if ((questionNo) <= (get_brief_response?.high_answer)) {
				if (questionNo < totalQuestions?.total) {
					setShowNext(true)
				}
			}
		}
		if (questionNo > 1) {
			// setShowPrev(true)
			if (totalQuestions?.sequence?.[questionNo - 1]?.skip === false ) {
				setShowPrev(true);
			  }
			if ((questionNo) <= (get_brief_response?.high_answer)) {
				if (questionNo < totalQuestions?.total) {
					setShowNext(true)
				}
			}
		}
		setSurveyDeleted(get_brief_response?.to_delete ? get_brief_response?.to_delete : false)
		setSurveyDeletedOn(get_brief_response?.deleted_on ? get_brief_response?.deleted_on : false)
		setSurveyStatus(get_brief_response?.survey_status ? get_brief_response?.survey_status : 0)
		setShowIntro(get_brief_response?.intro_completed)
		if (get_brief_response?.response?.stored_response?.answers) {
			setQuestionAnswers(get_brief_response?.response?.stored_response?.answers)
		}
		
		setSurveyFeedbackResp(get_brief_response?.response?.stored_response?.feedback)
		setSurveyModularResp(get_brief_response?.response?.stored_response?.modular_response)
		setSurveyPersonalityResp(get_brief_response?.response?.stored_response?.survey_personality)

	}, [get_brief_response, questionNo, totalQuestions]);

	useEffect(() => {
		// Run! Like go get some data from an API.
		if (token) {
			dispatch({
				type: SG_START_BRIEF_SURVEY,
				payload: `?token=${token}`
			});
		}
	}, [dispatch, token]);


	function submitAnswer() {
		const CompleteCategories = { ...questionAnswers?.categories, ...prevFoundResponse, ...neverAskResponse }
		if (CompleteCategories) {
			CompleteCategories[0] = primaryCategory
		}
		if (totalQuestions?.questions && questionAnswers?.questions) {
			if (totalQuestions?.questions > Object.values(questionAnswers?.questions).length) {
				setSubmitError("There was error in the submition please try again!")
				return
			}
		}
		let _completeCategories = []
		if (CompleteCategories) {
			_completeCategories = Object.values(CompleteCategories)
		}

		let _completeQuestions = []
		if (questionAnswers.questions) {
			_completeQuestions = Object.values(questionAnswers.questions)
		}

		let _completeComments = []
		if (questionAnswers.comments) {
			_completeComments = Object.values(questionAnswers.comments)
		}
		const surveyComponents = get_brief_response?.modular_response
		// console.log(CompleteCategories)
		// console.log(questionAnswers)
		const payload = {
			survey_token: briefResponse?.survey_token,
			debrief_schedule: briefResponse?.debrief_schedule,
			employee: briefResponse?.employee,
			response: {
				questions: {
					questions: _completeQuestions,
					categories: _completeCategories,
					comments: _completeComments,
				},
				total_question_map: totalQuestions,
				feedback: surveyFeedbackResp,
				survey_personality: surveyPersonalityResp,
				modular_response: surveyModularResp,
			},
			full_response: {
				isBrowser: isBrowser,
				isMobile: isMobile,
				browser: deviceDetected.browserName,
				deviceType,
				osVersion,
				osName,
				deviceDetected: deviceDetected,
				response: {
					questions: {
						questions: _completeQuestions,
						categories: _completeCategories,
						comments: _completeComments,
					},
					total_question_map: totalQuestions,
					feedback: surveyFeedbackResp,
					survey_personality: surveyPersonalityResp,
					modular_response: surveyModularResp,
				},
			}
		}
		// console.log(payload)

		dispatch({
			type: SG_POST_BRIEF_RESPONSE,
			payload
		});

		dispatch(
			{
				type: RESET_VALIDATION_TOKEN,
				payload: null
			}
		)
		dispatch(
			{
				type: CLEAR_ALL,
				payload: null
			}
		)

		setThankMessage(true)
	}

	const IntroQuestionAnswer = () => {
		let CatLength = 0
		if (categoryQues) {
			CatLength = categoryQues?.length
		}
		const NewValidationToken = uuidv4()

		const payload = {
			current_question: CatLength > 0 ? 0 : 1,
			current_answer: 0,
			high_answer: 0,
			answers: {
				categories: {},
				questions: {},
				comments: {}
			},
			complete: false,
			intro_completed: true,
			validation_token: NewValidationToken,
			feedback: surveyFeedbackResp,
			survey_personality: surveyPersonalityResp,
			modular_response: surveyModularResp,
		}

		setDirection(1)
		dispatch({
			type: NEW_VALIDATION_TOKEN,
			payload: NewValidationToken
		})
		dispatch({
			type: UPDATE_BRIEF_RESPONSE,
			payload: {
				total_categories: CatLength,
				total_questions: totalQuestions?.runningTotal,
				current_category: CatLength > 0 ? 1 : 0,
				category_answer: 0,
				high_category: 0,
				total_question_map: totalQuestions,
				...payload
			}
		});
		// persist with api
		if (briefResponseId) {
			dispatch({
				type: SG_UPDATE_BRIEF_RESPONSE,
				payload: {
					id: briefResponseId,
					query: `?token=${token}&start=true`,
					validation_token: NewValidationToken,
					...(payload && { stored_response: payload }),
				}
			})
		}


		setShowIntro(true)
		nextQuestion()
	}

	const saveCategoryAnswer = (response) => {
		// console.log(response)
		let _answers = questionAnswers.categories
		setDirection(1)
		if (_answers?.questionNo) {
			_answers.questionNo = response

		} else {
			_answers[questionNo] = response
		}

		setQuestionAnswers({
			categories: _answers,
			questions: questionAnswers?.questions,
			comments: questionAnswers?.comments
		})

		let _complete = PreCloseSurvey()

		const payload = {
			visual_question: visualQuestionNo,
			current_question: questionNo,
			current_answer: questionNo,
			high_answer: questionNo > briefResponse?.high_answer ? questionNo : briefResponse?.high_answer,
			answers: {
				questions: questionAnswers?.questions,
				comments: questionAnswers?.comments,
				categories: _answers
			},
			complete: _complete,
			intro_completed: showIntro,
			validation_token: validationToken,
			feedback: surveyFeedbackResp,
			survey_personality: surveyPersonalityResp,
			modular_response: surveyModularResp,
		}

		dispatch({
			type: UPDATE_BRIEF_RESPONSE,
			payload
		});

		// persist with api
		if (briefResponseId) {
			dispatch({
				type: SG_UPDATE_BRIEF_RESPONSE,
				payload: {
					id: briefResponseId,
					query: `?token=${token}`,
					validation_token: validationToken,
					...(payload && { stored_response: payload }),
				}
			})
		}


	}

	const saveQuestionAnswer = (response) => {
		let _answers = questionAnswers.questions
		setDirection(1)

		if (_answers?.questionNo) {
			_answers.questionNo = response

		} else {
			_answers[questionNo] = response
		}
		setQuestionAnswers({
			categories: questionAnswers?.categories,
			comments: questionAnswers?.comments,
			questions: _answers
		})

		let _complete = PreCloseSurvey()
		const payload = {
			visual_question: visualQuestionNo,
			current_question: questionNo,
			current_answer: questionNo,
			high_answer: questionNo > briefResponse?.high_answer ? questionNo : briefResponse?.high_answer,
			answers: {
				categories: questionAnswers?.categories,
				comments: questionAnswers?.comments,
				questions: _answers
			},
			complete: _complete,
			intro_completed: showIntro,
			validation_token: validationToken,
			feedback: surveyFeedbackResp,
			survey_personality: surveyPersonalityResp,
			modular_response: surveyModularResp,
		}
		dispatch({
			type: UPDATE_BRIEF_RESPONSE,
			payload
		});
		// persist with api
		if (briefResponseId) {
			dispatch({
				type: SG_UPDATE_BRIEF_RESPONSE,
				payload: {
					id: briefResponseId,
					query: `?token=${token}`,
					validation_token: validationToken,
					...(payload && { stored_response: payload }),
				}
			})
		}

	}

	const saveCommentAnswer = (response) => {
		let _answers = questionAnswers.comments
		setDirection(1)

		if (_answers?.questionNo) {
			_answers.questionNo = response

		} else {
			_answers[questionNo] = response
		}

		setQuestionAnswers({
			categories: questionAnswers?.categories,
			questions: questionAnswers?.questions,
			comments: _answers
		})

		let _complete = PreCloseSurvey()
		setComplete(_complete)
		const payload = {
			visual_question: visualQuestionNo,
			current_question: questionNo,
			current_answer: questionNo,
			high_answer: questionNo > briefResponse?.high_answer ? questionNo : briefResponse?.high_answer,
			answers: {
				categories: questionAnswers?.categories,
				questions: questionAnswers?.questions,
				comments: _answers
			},
			complete: _complete,
			intro_completed: showIntro,
			validation_token: validationToken,
			feedback: surveyFeedbackResp,
			survey_personality: surveyPersonalityResp,
			modular_response: surveyModularResp,
		}
		dispatch({
			type: UPDATE_BRIEF_RESPONSE,
			payload,
		})
		// persist with api
		if (briefResponseId) {
			dispatch({
				type: SG_UPDATE_BRIEF_RESPONSE,
				payload: {
					id: briefResponseId,
					query: `?token=${token}`,
					validation_token: validationToken,
					...(payload && { stored_response: payload }),
				}
			})
		}


	}

	const forward = () => {
		if (briefResponse?.high_answer >= questionNo) {
			setPrevResponse(null)
			setDirection(1)
			if ((questionNo + 1) <= totalQuestions.runningTotal) {
				nextQuestion()
				setComplete(false)
				setCheckResponse(true)
			} else {
				setComplete(true)

			}
		}
	}

	const back = () => {
		if (questionNo > 1) {
			setDirection(0)
			setPrevResponse(null)
			setComplete(false)
			if ((questionNo) === totalQuestions.runningTotal) {
				setComplete(false)
				if (questionNo === briefResponse?.current_question) {
					dispatch({
						type: UPDATE_BRIEF_RESPONSE,
						payload: {
							current_question: 0,
							current_answer: 0,
							complete: false
						},
					});
				}
				else {
					prevQuestion()
					setCheckResponse(true)
				}
			}
			else {
				prevQuestion()
				setCheckResponse(true)
			}
		}
	}

	useEffect(() => {
		if (get_errors?.errors?.error?.request?.response) {
			let RESP = JSON.parse(get_errors?.errors?.error?.request?.response)
			setSubmitError(RESP?.detail)
		}
	}, [get_errors]);

	useEffect(() => {
		// setPageLoading(true);
		setDefaultMessage({
			title: "Looking for Survey",
			subTitle: "Please wait while we try and get that survey loaded"
		})
		const timer1 = setTimeout(() => {
			setDefaultMessage({
				title: "No Survey details found",
				subTitle: "Unfortunately we have not been retrive any information on this survey"
			})
		}, 5000);
		return () => clearTimeout(timer1);
	}, [noResponse]);

	const getTextColor = () => {

		return '#4048FF'
	}


	const Main_section = () => {
		// const introText = "Innerlogic processes your personal data and aggregates responses on behalf of OTP. Your personal data is never directly personally identifiable to your NSO and is only ever presented in an anonymized fashion. Please consult our privacy statement for further details."
		const consentText = "I hereby give consent for Own the Podium or designate research partner to use anonymized and aggregated user data only in the further development of culture of excellence programming for the benefit of the high performance sport community in Canada. Do you agree with your personal data being processed as described above and in our privacy statement? You must click accept in order to give your feedback.";
		const EndTextFR = "Vous avez répondu à toutes les questions de ce sondage. Merci de soumettre vos réponses."

		const customAgreement = (<Agreement
			agreementText={consentText}
			color={'black'}
			fontSize={'18px'}
			handleConsentCb={(checked) => setConsentProvided(checked)}
			type={'OTP'}
			hasConsented={consentProvided} />);

		const IntroText = () => {

			if (language == 1) {
				return (<div style={{ fontSize: 18 }}>
					<p>{get_brief_response.response?.survey_name} s’engage à l’optimisation de la culture de haute performance de son programme de l’équipe nationale. La santé physique et psychologique et la sécurité de tous les participants et participantes au programme sont aussi importants que la performance.
						Ainsi, {get_brief_response.response?.survey_name} appuyé par À nous le podium, entreprend l’audit et l’évaluation de sa culture sportive. </p>
					<p>Compte tenu de votre rôle au sein du programme, vous avez été choisi•e pour participer à un sondage. L’évaluation de la culture est un exercice complexe ;
						veuillez prévoir de 30 à 45 minutes pour compléter le sondage. Vos réponses seront sauvegardées si vous avez besoin d’y revenir plus tard. Toutefois, le lien pour
						le sondage ne fonctionnera qu’à partir de l’appareil que vous avez utilisé pour y accéder. Ceci assure l’anonymat et limite chaque répondant•e à une seule soumission au sondage. </p>

					<p>Vos réponses honnêtes et réfléchies sont essentielles pour comprendre l’état actuel de la culture du programme. Toutes les réponses demeureront anonymes pour favoriser des réflexions sincères et franches. </p>



				</div>)
			}

			return (
				<div style={{ fontSize: 18 }}>
					<p>{get_brief_response.response?.survey_name} is committed to optimizing the high performance culture of its National Team Program. The physical and psychological health and safety of everyone involved
						in the program are of equal importance to performance. As such, {get_brief_response.response?.survey_name} is engaging in a sport culture audit and assessment supported by Own the Podium. </p>
					<p>Given your role within the program, you have been selected to participate in a survey. Assessing culture is complex; therefore, you can anticipate the
						survey taking approximately 30-45 minutes to complete. Your responses will be saved if you need to come back at a later date, but you will only be able to access
						the survey from the link on the original device you used. This is to ensure anonymity while restricting each respondent to one submission only. </p>

					<p>Your honest and thoughtful responses are critical for understanding the current cultural status of the program. All responses will remain anonymous to encourage openness and candor while answering each question. </p>



				</div>
			)
		}



		if (!showIntro) {
			// ask for existing data in storage
			if (storedResponseFound) {
				return (
					<ExistingDataFound
						show={true}
						setQuestionNo={setQuestionNo}
						setVisualQuestionNo={setVisualQuestionNo}
						setShowIntro={setShowIntro}
						token={token}
						briefResponseId={briefResponseId}
						validationToken={validationToken}
						storedResponse={get_brief_response?.response?.stored_response}

					/>
				)
			}
			return <Intro
				key={questionNo}
				title={language == 1 ? "Outil d’évaluation et d’audit de la culture du sport de haute performance" : 'High Performance Sport Culture Assessment & Audit Tool '}
				intro={<IntroText />}
				setShowIntro={setShowIntro}
				saveAnswer={IntroQuestionAnswer}
				consentText={consentText}
				consentCheckedCb={(response) => setConsentProvided(response)}
				customElement={customAgreement}
				surveyEnabled={consentProvided}
				elementRef={elementRef}
				language={language}

			/>
		}

		if (complete) {
			return <End
				key={questionNo}
				title={''}
				language={language}
				submitAnswer={() => submitAnswer()}
				intro={language === 1 ? EndTextFR : 'You have completed all the questions in this survey. Please submit your responses'} />

		}

		if (askFeedback) {
			return <CommentsFeedback
				ruleFeedback={ruleFeedback}
				setRuleFeedback={setRuleFeedback}
				setRuleFeedbackComplete={setRuleFeedbackComplete}
				submitAnswer={() => submitAnswer()}
				setAskFeedback={setAskFeedback}
				setComplete={setComplete}
			/>
		}

		if (totalQuestions?.sequence[questionNo]?.quesType === categoryType) {
			return <CategoryQuestion
				key={questionNo}
				save={saveCategoryAnswer}
				checkResponse={checkResponse}
				setCheckResponse={setCheckResponse}
				name={get_brief_response.response.survey_name}
				prevResponse={prevResponse}
				data={categoryQues[questionNo - 1]}
				employeeCategory={employeeCategory}
				language={language}
			/>
		}

		if (totalQuestions?.sequence[questionNo]?.quesType === questionsType) {
			return <QuestionTemplate
				key={questionNo}
				// save={(r) => saveQ(r)}
				saveQuestionAnswer={saveQuestionAnswer}
				response={prevResponse}
				name={get_brief_response.response.survey_name}
				question={questions.filter(
					q => q.order === questionNo - totalQuestions?.category
				)[0]}
				language={language}
			/>
		}
		if (totalQuestions?.sequence[questionNo]?.quesType === commentsType) {

			return <CommentTemplate
				key={questionNo}
				// save={(r) => saveQ(r)}
				saveCommentAnswer={saveCommentAnswer}
				response={prevResponse}
				language={language}
				question={askComments.filter(
					q => q.order === questionNo - totalQuestions?.category
				)[0]}
			/>
		}

		return <NoToken />
	}

	if (noResponse) {
		return <SwitchTransition>
			<CSSTransition
				key={`${questionNo} ${showIntro} ${askFeedback}`}
				timeout={TRANSITIONSPEED}
				classNames={SlideDirection[1]}
			>
				<NoToken
					{...defaultMessage} />
			</CSSTransition>
		</SwitchTransition>
	}
	if (!token) {
		return <NoToken
			{...defaultMessage} />
	}

	if (submitError) {
		return (
			<Container>
				<SwitchTransition>
					<CSSTransition
						key={`${questionNo} ${showIntro} ${askFeedback}`}
						timeout={TRANSITIONSPEED}
						classNames={SlideDirection[direction]}
					>
						<SurveyMessage
							onTime={surveyDeletedOn}
							message={submitError}
							language={language}
						/>
					</CSSTransition>
				</SwitchTransition>
			</Container>
		)
	}

	const ThanksMessageFR = 'Merci pour votre réponse au sondage'

	if (thankMessage) {
		return (
			<Container>
				<SwitchTransition>
					<CSSTransition
						key={`${questionNo} ${showIntro} ${askFeedback}`}
						timeout={TRANSITIONSPEED}
						classNames={SlideDirection[direction]}
					>
						<SurveyMessage
							message={language == 1 ? ThanksMessageFR : 'Thanks for your survey response'}
							onTime={new Date()}
							language={language}
						/>
					</CSSTransition>
				</SwitchTransition>
			</Container>
		)
	}

	if (surveyDeleted) {
		return (
			<Container>
				<SwitchTransition>
					<CSSTransition
						key={`${questionNo} ${showIntro} ${askFeedback}`}
						timeout={TRANSITIONSPEED}
						classNames={SlideDirection[direction]}
					>
						<SurveyMessage
							onTime={surveyDeletedOn}
							status={surveyStatus}
							language={language}
						/>
					</CSSTransition>
				</SwitchTransition>
			</Container>
		)
	}


	return (
		<Container height={showIntro ? '100vh' : elementRef.current?.clientHeight + 100 + 'px'}>
			<SwitchTransition>
				<CSSTransition
					key={`${questionNo} ${showIntro} ${askFeedback}`}
					timeout={TRANSITIONSPEED}
					classNames={SlideDirection[direction]}
				>
					{Main_section()}
				</CSSTransition>
			</SwitchTransition>
			{showLanguage ? <Language selected={language} setSelected={setLanguage} color={getTextColor()} /> : ""}

			<CounterContainer>
				<Counter>
					<div>
						<Title>CAAT</Title>
						{visualQuestionNo === 0 ? 1 : visualQuestionNo} {language == 1 ? 'de' : 'of'} {totalQuestions?.total} questions
					</div>
				</Counter>
				<Icon
					name="chevron up"
					disabled={!showPrev}
					style={{ cursor: showPrev ? "pointer" : "not-allowed" }}
					onClick={back}
				/>
				<Space />
				<Icon
					name="chevron down"
					style={{ cursor: showNext ? "pointer" : "not-allowed", zIndex: 2 }}
					disabled={!showNext}
					onClick={forward}
				/>
			</CounterContainer>

			<Shapes />
		</Container>
	)
}

export default OtpSurvey


const Container = styled.div`
  height:${props => props.height};
  min-height:100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Barlow', sans-serif;
  position:relative;
`;

const CounterContainer = styled.div`
  position: fixed;
  bottom: 10px;
  right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-size: 14px;
  background-color:grey;
  border-radius:5px;
  z-index:1;

  @media only screen and (min-width: 992px) {
    bottom: 50px;
    right: 50px;
    font-size: 14px;
  }
`;

const Counter = styled.div`
  padding: 5px 10px 5px 10px;

  font-size: 12px;
  border-radius: 7px;
  margin-right: 10px;

  @media only screen and (min-width: 992px) {
    padding: 5px 10px;
    font-size: 12px;
    margin-right: 25px;
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;

  @media only screen and (min-width: 992px) {
    margin-bottom: -5px;
    font-size: 14px;
  }
`;

const Space = styled.div`
  @media only screen and (min-width: 992px) {
    width: 20px;
  }
`;