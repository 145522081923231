import React from 'react';
import styled,{ keyframes } from 'styled-components';


export const Text1 = ({color,language})=>{

  if(language==1){
    return (
      <Text>Votre expérience, <BR/>en tant que partie intégrante <BR/>de cette culture, <Bold color={color}>est importante.</Bold></Text>
  )
  }
	return (
          <Text>Your experience, <BR/>as an integral part <BR/>of this culture, <Bold color={color}>matters.</Bold></Text>
		)
}

export const Text2 = ({color,language})=>{

  if(language==1){
    return (
      <Text>Vos réponses <BR/>à cette enquête <BR/>seront utilisées pour créer un <Bold color={color}> véritable changement.</Bold></Text>
  )
  }

	return (
          <Text>Your responses <BR/>from this survey <BR/>will be used to create <Bold color={color}> real change.</Bold></Text>
		)
}



export const Text3 = ({color,language})=>{

  if(language==1){
    return (
      <Text>Vos réponses sont <BR/><Bold height={1} bottom={-10} color={color}>anonymes</Bold> <BR/>et ne seront jamais <BR/> associées à votre identité.</Text>
  )
  }

	return (
          <Text>Your responses are <BR/><Bold height={1} bottom={-10} color={color}>anonymous</Bold> <BR/>and will never be <BR/> attached to your identity.</Text>
		)
}


export const Text4 = ({language})=>{

  if(language==1){
    return (
      <>
        <T2>Êtes-vous d'accord avec <BR/>le traitement de vos données personnelles <BR/>comme décrit ci-dessus et <BR/>dans notre déclaration de confidentialité?</T2>
        <A><a href='https://innerlogic.com/privacy'>Lien vers l'accord de confidentialité</a></A>
      </>
      
    )
  }

	return (
		<>
          <T2>Do you agree with<BR/> your personal data being processed as described above and<BR/> in our privacy statement?</T2>
		<A><a href='https://innerlogic.com/privacy'>Link to privacy agreement</a></A>
		</>
		)
}










const Text = styled.div`
  font-size:24px;
  font-weight:bold;
  line-height:1.6;
  color:white;
  width:100%;
  display: contents;

  @media (min-width:768px){
    font-weight:300;
  }

`

const Bold = styled.span`
  color:${props=>props.color?props.color:'#4048FF'};
  font-size:45px;
  font-weight:bold;
  line-height:1;

  @media (min-width:768px){
  	font-size:26px;
    font-weight:900;

   
  }


`
const BR = styled.br`
  @media (min-width:768px){
    display:none;
  }
`

const T2 = styled.div`
  font-size:24px;
  font-weight:bold;
  color:white;
  line-height:1.6;
  width:100%;

  @media (min-width:768px){
    font-weight:300;
  }

`

const A = styled.div`
	padding-top:20px;
`