import React from "react";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";

// import * as serviceWorker from "./serviceWorker";

import {Environment, 
  SentryDSN_PROD, SentryDSN_STG } from "./_config";

import "semantic-ui-css/semantic.min.css";
import { ToastProvider } from "react-toast-notifications";

import store from "./store";
import routes from "./routes/index";
import './i18n';

if (Environment !== "Development") {
  const Sentr_DSN = Environment === "Production"? SentryDSN_PROD : SentryDSN_STG
  Sentry.init({
    dsn: `${Sentr_DSN}`,
    integrations: [new BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

function App() {
  const routing = useRoutes(routes);
  return (
    <Provider store={store}>
      <ToastProvider>
      {routing}
      </ToastProvider>
    </Provider>
  );
}

export default App;

