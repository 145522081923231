import styled from "styled-components";
import React from "react";
import Likert from './Responses/Likert'
import TakeAway from './Responses/TakeAway'
import TextQ from './Responses/Text'
import Slider from './Responses/Slider'

const QuestionTemplate = (props) => {



  return (
    <SurveyContainer>
      <Text>{props?.data?.question}</Text>
      {props?.data?.type === "likert" &&
        <Likert
          value={props?.value}
          saveResult={(r) => props.save(r)} />}

      {props?.data?.type === "takeaway" &&
        <TakeAway
          value={props?.value}
          saveResult={(r) => props.save(r)} />}

      {props?.data?.type === "text" &&
        <TextQ
          value={props?.value}
          saveResult={(r) => props?.save(r)} />}

      {props?.data?.type === "slider" &&
        <Slider
          words={props?.data?.words}
          value={props?.value}
          saveResult={(r) => props.save(r)} />}

      {/*{get_answers.personality_high_ans === 100 && (
        <SubmitButton 
        enabled={get_answers.personality_high_ans === 100} 
        onClick={SubmitAnswer}>
          Submit Answers
        </SubmitButton>
      )}*/}
    </SurveyContainer>
  )

}



export default QuestionTemplate


const SurveyContainer = styled.div`
  padding: 0px;
  box-sizing: border-box;
  position: absolute;
  left: 0%;
  top: 10%;
  padding: 20px;

  @media only screen and (min-width: 992px) {
    padding: 30px;
    left: 25%;
    top: 15%;
  }
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Red Hat Display", sans-serif;
  color: white;
  margin-top: 14px;
  max-width:600px;
  line-height:1.3;

  @media only screen and (min-width: 992px) {
    font-size: 23px;
    margin-top: 28px;
  }
`;

// const Button = styled.div`
//   height: 40px;
//   border-radius: 10px;
//   background-color: white;
//   color: #6a00ff;
//   font-size: 16px;
//   font-weight: bold;
//   font-family: "Red Hat Display", sans-serif;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   width: 100px;
//   margin-top: 50px;
// `;


// const SubmitButton = styled(Button)`
//   width: 170px;
//   height: 47px;
//   border-radius: 5px;
//   background-color: ${(props) => (props.enabled ? "white" : "#bbc7bd")};
//   // color: white;
//   // background-color: white;
//   color: #6A00FF;
//   font-size: 16px;
//   font-weight: bold;
//   font-family: "Red Hat Display", sans-serif;
//   cursor: ${(props) => (props.enabled ? "pointer" : "not-allowed")};
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
// `;
