
import React, { useState } from 'react';
import styled from 'styled-components';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Icon } from 'semantic-ui-react'


// New Survey
import Question from './questionTypes/Question';
import MultipleChoice from './questionTypes/MultipleChoice';
import ProgressBar from './Components/ProgressBar';

import Feedback from './questionTypes/Feedback';
import Categories from './questionTypes/Categories';
import Introduction from './Introduction';
import './index.css'

import NavigationButtons  from './Components/NavigationButtons';
import FollowUp from './chatGpt/FollowUp';
import Submit from './questionTypes/Submit';
import IntroSection from './IntroSection';
import Values from './questionTypes/Values';
import Completed from './questionTypes/Completed'
import Language from './Language'
import Comments from './questionTypes/Comment'
// new survey



const questionsList = [
  {
    id: 1,
    sort_order: 1,
    type: 'introduction',
    buttonType: 'introduction',
    intro_id: 1,
    total: 4,
    buttonText: 'Start',
  },
  {
    id: 2,
    sort_order: 2,
    type: 'introduction',
    buttonType: 'introduction',
    intro_id: 2,
    total: 4,
    buttonText: 'Next',
  },
  {
    id: 3,
    sort_order: 3,
    type: 'introduction',
    buttonType: 'introduction',
    intro_id: 3,
    total: 4,
    buttonText: 'Next',
  },
  {
    id: 4,
    sort_order: 4,
    type: 'introduction',
    buttonType: 'introduction',
    intro_id: 4,
    total: 4,
    buttonText: 'I agree'
  },
  {
    id: 5,
    sort_order: 5,
    type: 'categories',
    buttonType: 'regular',
    question: 'Please choose a category:',
    description: 'Gender refers to current gender which may be different from sex assigned at birth and may be different from what is indicated on legal documents',
    categories: [
      { id: 'cat1', text: 'Category 1' },
      { id: 'cat2', text: 'Category 2' },
    ],
  },
  {
    id: 6,
    sort_order: 6,
    type: 'categories',
    buttonType: 'regular',
    question: 'Thank you for answering these preliminary questions.',
    description: 'Now we are going to ask you some questions about your experience with Resulta - Testing resulta. Your experience matters.',
  },
  {
    id: 7,
    sort_order: 7,
    type: 'question',
    buttonType: 'regular',
    question: 'On a scale of 1-10, how would you rate your experience?',
  },
  {
    id: 8,
    sort_order: 8,
    text: 'Question 1',
    type: 'multipleChoice',
    buttonType: 'regular',
    choices: [
      { id: '1a', text: 'Disagree strongly' },
      { id: '1b', text: 'Disagree moderately' },
      { id: '1c', text: 'Disagree a little' },
      { id: '1d', text: 'Neither agree nor disagree' },
      { id: '1e', text: 'Agree a little' },
      { id: '1f', text: 'Agree moderately' },
      { id: '1g', text: 'Agree strongly' },
    ],
  },
  {
    id: 9,
    sort_order: 9,
    type: 'feedback',
    buttonType: 'regular',
    direction: 'min',
    question: 'It’s safe to take risks and be bold when solving problems here.',
    score: 1,
  },
  {
    id: 10,
    sort_order: 10,
    type: 'follow_up',
    buttonType: 'regular',
    direction: 'min',
    response: 'I do feel safe to take risks and be bold when solving problems here.',
    question: 'It’s safe to take risks and be bold when solving problems here.',
  },
  {
    id: 11,
    sort_order: 11,
    type: 'comment',
    buttonType: 'regular',
    question: 'It’s safe to take risks and be bold when solving problems here.',
  },
  {
    id: 12,
    sort_order: 12,
    type: 'submit',
    buttonType: 'regular',
    buttonType: 'Submit',
  },
  {
    id: 13,
    sort_order: 13,
    type: 'introSection',
    buttonType: 'regular',
    emoji: '📝',
    title: 'Getting to know you',
    text: ['In an effort to better contextualize the feedback we have received from you, we’d like to ask you a few questions about your personality. For the following 10 questions please rate the extent to which you agree or disagree with the statement.', 'Your data is always kept private, and no one can access your individual responses to these questions.'],
    buttonType: 'Begin',
  },
  {
    id: 14,
    sort_order: 14,
    type: 'values',
    buttonType: 'regular',
    statement: 'Using only one word, finish the sentence with whatever comes to your mind:',
    question: 'I would describe this organization as a culture of...'
  },
  {
    id: 15,
    sort_order: 15,
    type: 'completed',
    buttonType: 'regular',
  },
  // Add slider, multipleChoice, and feedback questions as needed
];



const AuditSurvey = () => {


  // 
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [direction, setDirection] = useState('slide');
  const [isVisible, setIsVisible] = useState(true);
  const [surveyFinished, setSurveyFinished] = useState(false)
  // 

  const handleNext = () => {
    if (currentQuestion < questionsList.length - 1) {
      setDirection('slide');
      setCurrentQuestion(currentQuestion + 1);
      if (currentQuestion === questionsList.length - 2) {
        setSurveyFinished(true)
      }
    }
  };


  const handleBack = () => {
    if (currentQuestion > 0) {
      setDirection('slide-back');
      setCurrentQuestion(currentQuestion - 1);
    }
  };



  const renderModule = (question) => {
    switch (question.type) {
      case 'introduction':
        return <Introduction id={question.intro_id} currentQuestion={currentQuestion} total={question.total} />;
      case 'categories':
        return <Categories question={question.question} categories={question.categories} description={question.description} />;
      case 'question':
        return <Question question={question.question} />;
      case 'multipleChoice':
        return <MultipleChoice choices={question.choices} question={question.text} isVisible={!isVisible} />;
      case 'feedback':
        return <Feedback data={question} />;
      case 'follow_up':
        return <FollowUp data={question} />;
      case 'introSection':
        return <IntroSection data={question} />;
      case 'comment':
        return <Comments question={question.question} />;
      case 'values':
        return <Values statement={question.statement} question={question.question} />;
      case 'completed':
        return <Completed />;
      case 'submit':
        return <Submit />;
      default:
        return null;
    }
  };

  return (
    <SurveyContainer>
      {/* progress bar when it not introduction */}
      {currentQuestion > 4 && !surveyFinished && <ProgressBar currentQuestion={currentQuestion} totalQuestions={questionsList.length} />}

      {/* back button to take one question back when it not introduction */}
      {currentQuestion > 0 && currentQuestion < 4 && <Back onClick={handleBack}><Icon name="chevron left" /> </Back>}

      <Language />
      <TransitionGroup>
        <CSSTransition
          key={questionsList[currentQuestion].id}
          classNames={direction}
          timeout={{ enter: 500, exit: 500 }}
          onEntered={() => setIsVisible(true)}
          onExited={() => setIsVisible(false)}
        >
          <ModuleContainer className="module-wrapper">
            
            {renderModule(questionsList[currentQuestion])}
          
          </ModuleContainer>
        </CSSTransition>

      </TransitionGroup>




      <ButtonContainer>
      <NavigationButtons currentQuestion={currentQuestion}
					questionsList={questionsList} 
					handleBack={handleBack} 
					handleNext={handleNext} 
					surveyFinished={surveyFinished} />
        {/* {renderButtons(questionsList[currentQuestion])} */}
      </ButtonContainer>

    </SurveyContainer>
  );
};

export default AuditSurvey;


// ...
const SurveyContainer = styled.div`
width: 100%;
min-height: 100vh;
display: flex;
flex-direction: column;
background-color: #f8f9fa;
position:relative;
font-family: 'Raleway', sans-serif;
overflow-x:hidden;
`;

const SurveyContent = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
// ...

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f8f8f8;
  z-index: 2;
`;


// ...


const ModuleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:20px;
`;

const Back = styled.div`
    position:absolute;
    left:28px;
    top:20px;
    cursor: pointer;
    font-size:20px;
    width:50px;
    height:50px;
    z-index:2;
    color:#2A3039;
`