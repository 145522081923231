import React from 'react';
import styled from 'styled-components';


const End = ({ message }) => {

  let CustomMessage = 'There was an error in the survey'
  
  function handleNext() {
    window.location.reload();
  }
// console.log(status > 4, CustomMessage)
  // const Start = format(onTime, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
  return (
    <Container>
      <Title>
        {message ? message : CustomMessage}
      </Title>

      <SubTitle>  Please try completing this survey again!
        
      </SubTitle>
      <Next enabled={true} onClick={()=> handleNext()}>Try Again</Next>

    </Container>

  )
}


export default End

const Next = styled.div`
	width: 170px;
  height: 47px;
  border-radius: 5px;
  background-color: #009370;
  color: rgba(0, 0, 0,0.9);
  font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  margin-top: 25px;
  cursor: pointer;
  ${(props) => !props.enabled && `
    opacity: 0.4;
    cursor:auto;
  `}
`

const Title = styled.div`
	font-size: 30px;
  font-weight: bold;
  font-family: 'Barlow', sans-serif;
  margin-bottom:20px;
  color:white;
   line-height:1;
   max-width:654px;

   @media only screen and (min-width:992px){
   font-size: 30px;
   margin-bottom:50px;
  }
`
const SubTitle = styled.div`
	font-size: 18px;
	line-height: 1.17;
  color: white;
  font-family: 'Barlow', sans-serif;
  margin-bottom:20px;
  max-width:654px;

   @media only screen and (min-width:992px){
   font-size: 72px;
   margin-bottom:50px;
   font-size: 24px;
  }
`



const Container = styled.div`
  position:absolute;
  top:15%;
  padding:20px;

  @media only screen and (min-width:992px){
    left: 25%;
  top: 25%;
  }
`