import React from 'react';
import styled from 'styled-components';
import Slider from './Slider';

const Question = ({ question, id, setSelectedResponse,selectedResponse,scale}) => {

 return (<QuestionContainer>
    <QuestionText>{question}</QuestionText>
    <Slider id={`slider-track-${id}`} save={(r)=>setSelectedResponse(r)} selectedResponse={selectedResponse!==null?selectedResponse:5} scale={scale}/>
  </QuestionContainer>)
};
export default Question;

const QuestionContainer = styled.div`
  width: 100%;
  max-width:600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.div`
  color: #2D70E2;
  text-transform: uppercase;
  font-size:14px;
  font-weight:600;
  margin-bottom: 40px;
`

const Qualifier = styled.div`
  color:#2A3039;
  font-size: 20px;
  font-weight:600;
  margin-bottom:14px;
`

const QuestionText = styled.div`
  color:#2A3039;
  font-size: 20px;
  font-weight:600;
  margin-bottom:14px;
  line-height: 1.5;

  @media(max-width:768px){
    font-size: 18px;
  }
`