import { modularCommentTypeQuestion } from "constants/questionTypes";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import {words} from '../translations'

const QuestionComments = ({
  questionSequenceMap,
  direction,
  handleNext,
  handleBack,
  saveUpdatedAnswers,
  currentQuestionDet,
  language,
}) => {
  const textareaRef = useRef();

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = `${
        textareaRef.current.scrollHeight - 16
      }px`;
    }
  }, []);

  const handleInput = (e) => {
    e.target.style.height = "inherit";
    e.target.style.height = `${e.target.scrollHeight - 16}px`;
  };

  const askOnRefResp = questionSequenceMap[currentQuestionDet?.askOnRefQID];
  
  console.log("currentQuestionDet", currentQuestionDet);
  // console.log("askOnRefResp", askOnRefResp);
  // console.log(`currentQuestionDet?.askOnRef?.value?.length > 0
  //  && Number.isInteger(askOnRefResp?.response?.response)`, 
  // currentQuestionDet?.askOnRef?.value?.length > 0 &&
  //     Number.isInteger(askOnRefResp?.response?.response));
  console.log("currentQuestionDet?.askOnRef?.value", currentQuestionDet?.askOnRef?.value);
  console.log("askOnRefResp?.response?.response", Number.isInteger(askOnRefResp?.response?.response));
  // askOnRef: {value": [ 2, 5], enabled: true, ref_type: 'outcome', ref_question_id: '1.1'}
  // askOnRefQID: 47
  useEffect(() => {
    // Introduce a microsecond delay (e.g., 100 milliseconds)
    // const delay = 10;

    // Check if the required data is loaded
    if (
      currentQuestionDet?.askOnRef?.value?.length > 0 &&
      Number.isInteger(askOnRefResp?.response?.response)
    ) {
      // Use setTimeout to delay the execution of the effect
      // const timerId = setTimeout(() => {
        // Rest of your existing code
        let _refQuesResp = askOnRefResp?.response?.response;
        // Convert _refQuesResp to an integer if it's a valid number
        if (!isNaN(_refQuesResp)) {
          _refQuesResp = parseInt(_refQuesResp, 10);
        }
        const currentAskRefVal = currentQuestionDet?.askOnRef?.value;

        function isInRange(value, min, max) {
          console.log("value, min, max", value, min, max);
          if ( value >= min && value <= max)
          {
            return false
          }
          return true
        }

        const skipQuestion = isInRange(
          _refQuesResp,
          currentAskRefVal[0],
          currentAskRefVal[1]
        );

        // console.log("isInRange", isInRange(
        //   _refQuesResp,
        //   currentAskRefVal[0],
        //   currentAskRefVal[1]
        // ));
        
        console.log("currentQuestionDet.id", currentQuestionDet.id);

        if (skipQuestion) {
          // console.log("out of range skip", currentQuestionDet.id);
          if (direction === "slide-back") {
            handleBack();
          } else {
            const QuesResp = {
              ques_sort_order: currentQuestionDet?.id,
              comments: `askOnRefResp - out of range skip [checking for ${_refQuesResp} in ${currentAskRefVal}]`,
              ques_order: currentQuestionDet?.id,
              response: null,
              scale: currentQuestionDet?.scale,
              question: currentQuestionDet?.question?.question,
              fr: currentQuestionDet?.question?.fr,
              es: currentQuestionDet?.question?.es,
              question_type: currentQuestionDet?.quesType,
              order: currentQuestionDet?.id,
              clicked: true,
              skip: true,
              s: currentQuestionDet?.s,
              q: currentQuestionDet?.q,
              id: currentQuestionDet?.id,
            };

            saveUpdatedAnswers(
              currentQuestionDet?.id,
              QuesResp,
              modularCommentTypeQuestion
            );
            handleNext();
          }
        }

        // console.log(
        //   "is in range ask",
        //   currentQuestionDet.id,
        //   isInRange(_refQuesResp, currentAskRefVal[0], currentAskRefVal[1])
        // );
      // }, delay);

      // Clear the timeout on component unmount to avoid memory leaks
      // return () => clearTimeout(timerId);
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentQuestionDet,
    askOnRefResp,
    // handleNext,
    // saveUpdatedAnswers,
    // direction,
    // handleBack,
  ]);

  const handleChange = (event) => {
    // setFeedbackText(event.target.value);
    const QuesResp = {
      ques_sort_order: currentQuestionDet?.id,
      ques_order: currentQuestionDet?.id,
      response: event.target.value,
      scale: currentQuestionDet?.scale,
      question: currentQuestionDet?.question?.question,
      fr: currentQuestionDet?.question?.fr,
      es: currentQuestionDet?.question?.es,
      question_type: currentQuestionDet?.quesType,
      order: currentQuestionDet?.id,
      clicked: true,
      s: currentQuestionDet?.s,
      q: currentQuestionDet?.q,
      id: currentQuestionDet?.id,
    };
    saveUpdatedAnswers(
      currentQuestionDet?.id,
      QuesResp,
      modularCommentTypeQuestion
    );
  };

  const getDescription = () => {
    if (language === "FR" && currentQuestionDet?.question?.fr) {
      return currentQuestionDet?.question?.fr;
    }
    if (language === "ES" && currentQuestionDet?.question?.es) {
      return currentQuestionDet?.question?.es;
    }
    return currentQuestionDet?.question?.question;
  }

  return (
    <FeedbackContainer>
      <Title>{words['Feedback'][language]}</Title>
      <Description>
        {getDescription()}
      </Description>

      <StyledTextarea
        id={`txt_${currentQuestionDet?.id}`}
        value={currentQuestionDet?.response?.response || ""}
        onChange={handleChange}
        placeholder={words['textarea'][language]}
        ref={textareaRef}
        onInput={handleInput}
      />
    </FeedbackContainer>
  );
};

export default QuestionComments;

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 600px;
  width: 100%;
  font-family: "Raleway", sans-serif;
`;

const Description = styled.div`
  color: #2a3039;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 34px;
  line-height: 1.6;
  white-space: pre-wrap;
`;
const Title = styled.div`
  color: #2d70e2;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 40px;
`;

const StyledTextarea = styled.textarea`
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #2a3039;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: none;
  font-family: "Raleway", sans-serif;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #2d70e2;
    outline: 0;
  }
`;
