
export function ModularSurveyCheck({ surveyComponents }) {    // get questions and enabled data
    // const dispatch = useDispatch();

    const ModularSurvey = []


    const _openSurvey = surveyComponents.filter(
        br => br?.enabled === true
            && br?.editable === true
            && br?.completed === false
            && br?.question?.sort_order
            && br?.ask_again?.value > 0)



    _openSurvey.map(op => {
        if (op?.enabled === true) {
            ModularSurvey.push(op)
        }
        return null

    })

    return ModularSurvey
}

