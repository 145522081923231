import React from 'react';
import styled from 'styled-components';
import Pic from 'assets/images/completed.png'


const End = (props) =>{

	return (
		<Container>
      <div style={{alignItems:'center',display:'flex',flexDirection:'column'}}>
        <Image src={Pic} />

			      <Title>Survey Closed</Title>
       </div>
			<SubTitle>This survey has closed.</SubTitle>
			
		</Container>

		)
}


export default End

const Title = styled.div`
	font-size: 30px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
   line-height:1;
     margin-bottom:10px;

`
const SubTitle = styled.div`
	font-size: 16px;
	line-height: 1.17;
  font-family: 'Red Hat Display', sans-serif;
  margin-bottom:20px;
  text-align:center;

   @media only screen and (min-width:992px){
   margin-bottom:50px;
   font-size: 24px;
  }
`

const Container = styled.div`
  position:absolute;
  top:15%;
  padding:20px;
  width:700px;
  
  @media only screen and (min-width:992px){
    left: 25%;
  top: 15%;
  }

  @media(max-width:768px){
    width:100%;
  }
   
`

const Image = styled.img`
  width:400px;
  margin-bottom:40px;

  @media(max-width:768px){
    width:100%;
  }
`