import styled from "styled-components";
import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SET_ANSWERS, SELECTED_LEADERSHIP } from "constants/actions";

import Intro from "./Intro";

import LeadershipQuestion from "./Question";
import MultiQuestion from "./MultiQuestion";

import { CSSTransition, SwitchTransition } from "react-transition-group";
import {TRANSITIONSPEED} from "constants/animation"

import "survey/survey.css";

import {
  // BrowserView,
  // MobileView,
  isBrowser,
  isMobile,
  deviceType,
  osName,
  osVersion,
  deviceDetect,
} from "react-device-detect";

// const width = 500;

const deviceDetected = deviceDetect();
const Leadership = (props) => {
  const dispatch = useDispatch();
  const { leadershipQuestionList } = props;
  const [answers, setAnswers] = React.useState([]);
  const [currQuestion, setCurrQuestion] = React.useState(0);
  const [questions, setQuestions] = React.useState([]);
  const [maxQuestion, setMaxQuestion] = React.useState(0);
  const [leaderList, setLeaderList] = React.useState([]);


  const { get_answers, get_leadership_answers,
    get_leader_list, get_leader_count } = useSelector(
    (state) => ({
      get_answers: state.answers,
      get_leadership_answers: state.answers.leadership_answers,
      get_leader_list: state.answers.leader_list,
      get_leader_count: state.answers.leader_count,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    setCurrQuestion(get_answers.leadership_cur_ques);
    setMaxQuestion(get_answers.leadership_max_ques);
  }, [get_answers]);

  React.useEffect(() => {
    if (get_leader_list) {
      setLeaderList(get_leader_list);
    }
  }, [get_leader_list]);




  React.useEffect(() => {
    if (leadershipQuestionList?.questions) {
      setQuestions(
        leadershipQuestionList.questions.find(
          ({ id }) => id === get_answers.leadership_cur_ques
        )
      );
    }
  }, [leadershipQuestionList, get_answers]);

  function startSurvey() {
    dispatch({
      type: SET_ANSWERS,
      payload: {
        leadership_cur_ques: 1,
        curr_direction: 1,
        isBrowser: isBrowser,
        isMobile: isMobile,
        browser: deviceDetected.browserName,
        deviceType,
        osVersion,
        osName,
        deviceDetected: deviceDetected,
      },
    });
  }

  const next = (data) => {
     window.scrollTo(0, 0)
    if (answers.length > currQuestion + 1) {
      setAnswers((answers) => [...answers, data]);
    } else {
      let answer_copy = answers;
      answer_copy[currQuestion - 1] = data;
      setAnswers(answer_copy);
    }
  };

  function SelectedLeader(e) {
    dispatch({
      type: SELECTED_LEADERSHIP,
      payload: {
        leadership_selected: e,
        leader_list: get_answers.leader_list.filter(
          (ll) => ll.leader_id !== e.leader_id
        ),
      },
    });
  }

  // console.log(get_leadership_answers)
  // console.log(questions)
// console.log(get_leadership_answers.filter((p) => p.id === questions.id))

  // function Filter() {
  //   // leader_id=110, id=2 => remove this
  //   //  {leader_id: 110, id: 2, answer: 5, comments: undefined}
  //  const Leader = SurveyAnswers.filter(dl => dl.leader_id !== 110)
  //  const Survey = Leader.filter(dl => dl.id !== 2 )
  //  console.log(Leader)
  // console.log(Survey)
  // const NewList = [...Leader, ...Survey]
  // console.log(NewList)
  // }
  // Filter()

  return (
    <SurveyContainer>
      <SwitchTransition>
					<CSSTransition
						key={currQuestion}
						timeout={TRANSITIONSPEED}
						classNames={props.direction === 1 ? "SlideUp" : "SlideDown"}
					>
            <>
            {currQuestion === 0 ? (
                      <Intro
                        next={startSurvey}
                        key={currQuestion}
                        leaderList={leaderList}
                        setLeaderList={setLeaderList}
                        SelectedLeader={SelectedLeader}
                      />
                    ) : (
                      ""
                    )}
                    {get_leader_count > 1 && currQuestion>0 ? 
                            <MultiQuestion
                              leadershipAnswersList={get_leadership_answers}
                              get_answers={get_answers}
                              currQuestion={currQuestion}
                              leaderList={leaderList}
                              leaderCount={get_leader_count}
                              key={currQuestion}
                              maxQuestion={maxQuestion}
                              question={questions}
                              next={(data) => next(data)}
                              // answer={
                              //   answers.length >= currQuestion ? answers[currQuestion] : null
                              // }
                            />
                      : currQuestion > 0 &&
                          currQuestion <= get_answers.leadership_max_ques ?
                            <LeadershipQuestion
                              currQuestion={currQuestion}
                              leaderList={leaderList}
                              key={currQuestion}
                              maxQuestion={maxQuestion}
                              question={questions}
                              next={(data) => next(data)}
                              // answer={
                              //   answers.length >= currQuestion ? answers[currQuestion] : null
                              // }
                            />
                          :""}

                    {/* need to work on this */}
                    {/* {currQuestion === 7 ? <CultureComment key={currQuestion} /> : ""} */}
                  
            </>
          </CSSTransition>
          </SwitchTransition>
      
    </SurveyContainer>
  );
};

export default Leadership;

// const Leadership = (props) => {
//   const dispatch = useDispatch();
//   const { leadershipQuestionList, leadershipAnswersList } = props;
//   const [answers, setAnswers] = React.useState([]);
//   const [currQuestion, setCurrQuestion] = React.useState(0);
//   const [questions, setQuestions] = React.useState([]);
//   const [maxQuestion, setMaxQuestion] = React.useState(0);
//   const [leaderList, setLeaderList] = React.useState([]);
//   const [leaderSelected, setLeaderSelected] = React.useState(null)

//   const { get_answers, get_leader_list } = useSelector(
//     (state) => ({
//       get_answers: state.answers,
//       get_leader_list: state.answers.leader_list,
//     }),
//     shallowEqual
//   );

//   React.useEffect(() => {
//     setCurrQuestion(get_answers.leadership_cur_ques);
//     setMaxQuestion(get_answers.leadership_max_ques);
//   }, [get_answers]);

//   React.useEffect(() => {
//     if (get_leader_list) {
//       setLeaderList(get_leader_list);
//     }
//   }, [get_leader_list]);

//   React.useEffect(() => {
//     if (get_answers) {
//       setLeaderSelected(get_answers.leadership_selected);
//     }
//   }, [get_answers]);

//   React.useEffect(() => {
//     if (leadershipQuestionList?.questions) {
//       setQuestions(
//         leadershipQuestionList.questions.find(
//           ({ id }) => id === get_answers.leadership_cur_ques
//         )
//       );
//     }
//   }, [leadershipQuestionList, get_answers]);

//   function startSurvey() {
//     dispatch({
//       type: SET_ANSWERS,
//       payload: {
//         leadership_cur_ques: 1,
//         curr_direction: 1,
//       },
//     });
//   }

//   const next = (data) => {
//     if (answers.length > currQuestion + 1) {
//       setAnswers((answers) => [...answers, data]);
//     } else {
//       let answer_copy = answers;
//       answer_copy[currQuestion - 1] = data;
//       setAnswers(answer_copy);
//     }
//   };

//   function SelectedLeader(e) {
//     dispatch({
//       type: SELECTED_LEADERSHIP,
//       payload: {
//         leadership_selected: e,
//         leader_list: get_answers.leader_list.filter(
//           (ll) => ll.leader_id !== e.leader_id
//         ),
//       },
//     });
//   }

// // console.log()

// // function Filter() {
// //   // leader_id=110, id=2 => remove this
// //   //  {leader_id: 110, id: 2, answer: 5, comments: undefined}
// //  const Leader = SurveyAnswers.filter(dl => dl.leader_id !== 110)
// //  const Survey = Leader.filter(dl => dl.id !== 2 )
// //  console.log(Leader)
// // console.log(Survey)
// // const NewList = [...Leader, ...Survey]
// // console.log(NewList)
// // }
// // Filter()

//   return (
//     <SurveyContainer>
//       <CSSTransitionGroup
//         transitionLeaveTimeout={1000}
//         transitionEnterTimeout={1000}
//         transitionName={props.direction === 1 ? "SlideUp" : "SlideDown"}
//       >
//         {currQuestion === 0 ? (
//           <Intro
//             next={startSurvey}
//             key={currQuestion}
//             leaderList={leaderList}
//             setLeaderList={setLeaderList}
//             SelectedLeader={SelectedLeader}
//           />
//         ) : (
//           ""
//         )}
//         {
//         currQuestion > 0 &&
//         currQuestion <= get_answers.leadership_max_ques ? (
//           <MultiQuestion
//             leadershipAnswersList={leadershipAnswersList}
//             currQuestion={currQuestion}
//             leaderSelected={leaderSelected}
//             key={currQuestion}
//             maxQuestion={maxQuestion}
//             question={questions}
//             next={(data) => next(data)}
//             // answer={
//             //   answers.length >= currQuestion ? answers[currQuestion] : null
//             // }
//           />
//         ) : (
//           ""
//         )}

//         {/* need to work on this */}
//         {/* {currQuestion === 7 ? <CultureComment key={currQuestion} /> : ""} */}
//       </CSSTransitionGroup>
//     </SurveyContainer>
//   );
// };

// export default Leadership;

const SurveyContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  position: relative;
  width: 100vw;

  @media only screen and (min-width: 992px) {
    padding: 30px;
  }
`;

// const IMG = styled.img`
//   margin-bottom: 8px;
//   height: 50px;
//   margin-right: 22px;
// `;

// const Question = styled.div`
//   font-size: 23px;
//   font-weight: bold;
//   font-family: "Red Hat Display", sans-serif;
//   color: white;
//   margin-top: 28px;
// `;

// const Title = styled.div`
//   font-size: 23px;
//   font-weight: bold;
//   font-family: "Red Hat Display", sans-serif;
//   color: white;
// `;

// const Sub = styled.div`
//   font-size: 14px;
//   font-family: "Red Hat Display", sans-serif;
//   margin-top: 12px;
//   color: white;
// `;

// const QuestionContainer = styled.div`
//   width: ${width + "px"};
//   height: 65px;
//   margin-top: 40px;
//   display: flex;
//   flex-direction: row;
// `;

// const QuestionItem = styled.div`
//   width: 20%;
//   height: 65px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 28px;
//   font-weight: bold;
//   font-family: "Red Hat Display", sans-serif;
//   border-radius: ${(props) =>
//     props.i == 0 ? "7px 0px 0px 7px" : props.i == 4 ? "0 7px 7px 0" : "none"};
//   border: 1px solid white;
//   cursor: pointer;
//   background-color: ${(props) => (props.answer == props.i ? "white" : "none")};
//   color: ${(props) => (props.answer == props.i ? "#6a00ff" : "white")};
// `;

// const Scale = styled.div`
//   width: ${width + "px"};
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   margin-top: 20px;
//   font-size: 146x;
//   font-family: "Red Hat Display", sans-serif;
//   color: white;
// `;

// const Button = styled.div`
//   height: 40px;
//   border-radius: 10px;
//   background-color: white;
//   color: #6a00ff;
//   font-size: 16px;
//   font-weight: bold;
//   font-family: "Red Hat Display", sans-serif;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   width: 100px;
//   margin-top: 50px;
// `;

// const CounterContainer = styled.div`
//   position: absolute;
//   bottom: 50px;
//   right: 50px;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
// `;

// const Counter = styled.div`
//   padding: 10px 15px 10px 15px;
//   background-color: #4f00f7;
//   color: white;
//   font-family: "Red Hat Display", sans-serif;
//   font-size: 16px;
//   border-radius: 7px;
//   margin-right: 25px;
// `;

// const Container = styled.div`
//   background-image: linear-gradient(308deg, #6d00aa, #6a00ff);
//   height: 100vh;
//   width: 100%;
// `;

// function useKeyPress(targetKey) {
//   // State for keeping track of whether key is pressed
//   const [keyPressed, setKeyPressed] = React.useState(false);

//   // If pressed key is our target key then set to true
//   const downHandler = ({ key }) => {
//     if (key === targetKey) {
//       setKeyPressed(true);
//     }
//   };

//   // If released key is our target key then set to false
//   /* const upHandler = ({ key }) => {
//     if (key === targetKey) {
//       setKeyPressed(false);
//     }
//   };*/

//   // Add event listeners
//   React.useEffect(() => {
//     window.addEventListener("keydown", downHandler);
//     // window.addEventListener('keyup', upHandler);
//     // Remove event listeners on cleanup
//     return () => {
//       window.removeEventListener("keydown", downHandler);
//       // window.removeEventListener('keyup', upHandler);
//     };
//   }, []); // Empty array ensures that effect is only run on mount and unmount

//   return keyPressed;
// }
