import styled from 'styled-components';

// const width = 500;

export const SurveyContainer = styled.div`
padding:10px;
width:100%;
position:absolute;
margin-top:10vh;
left:0px;

@media only screen and (min-width:992px){
    padding:30px;
}

@media only screen and (max-width:992px){
  left:0px;
}
`

export const Highlight = styled.div`
     z-index:1;
    background-color:#ffe175;
     width:100%;
    height:${props=>props.comment?'350px':'150px'};
    position:absolute;
    top:${props=>props.top*130+20+'px'};
    border-radius:10px;
    transition:all 500ms;

  @media only screen and (min-width:992px){
   width:600px;
   top:${props=>props.top*110+50+'px'};
   height:${props=>props.comment?'400px':'200px'};
}

`;

export const LeaderContainer = styled.div`
  width:100vw;
display:flex;
flex-direction:column;

 @media only screen and (min-width:992px){
     width:600px;
     margin-top:40px;
 }
`;
export const ResponseContainer = styled.div`
  overflow-y:scroll;
  max-height:80vh;
  position:relative;

`

export const IMG = styled.img`
margin-bottom:8px;
height:50px;
margin-right:22px;
`

export const Avatar = styled.img`
  width: 45px;
  height: 45px;
  border: solid 2px #ffffff;
  border-radius: 22.5px;
  min-width:45px;
`;


export const Name = styled.div`
  font-size:18px;
  color:black;
  margin-left:10px;
  font-weight: bold;
`

export const Leader = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
`

export const Question = styled.div`
font-size: 16px;
font-weight: 500;
width: 700px;
font-family: 'Red Hat Display', sans-serif;
color:#4a4a4a;
line-height: 1.6;
margin-top:14px;
max-width:100%;

@media only screen and (min-width:992px){
   font-size: 20px;
    margin-top:28px;

}
`

export const Title = styled.div`
font-size: 23px;
font-weight: bold;
font-family: 'Red Hat Display', sans-serif;
color:#4a4a4a;
`

export const Sub = styled.div`
font-size: 14px;
font-family: 'Red Hat Display', sans-serif;
margin-top:12px;
color:#4a4a4a;
`

export const QuestionContainer = styled.div`
width:100vw;
height:65px;
margin-top:10px;
display:flex;
flex-direction:row;

 @media only screen and (min-width:992px){
     width:600px;
     margin-top:10px;
 }
`

export const QuestionItem = styled.div`
  width:18%;
  height:65px;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  font-family: 'Red Hat Display', sans-serif;
  border-radius:${(props) =>
    props.i === 1 ? "7px 0px 0px 7px" : props.i === 5 ? "0 7px 7px 0" : "none"};
  border:1px solid #4a4a4a;
  cursor:${(props) => (props.selected ? "pointer" : "auto")};
  background-color:${(props) => (props.answer === props.i ? "white" : "none")};
  color:${(props) => (props.answer === props.i ? "black" : "#4a4a4a")};

  @media only screen and (min-width:992px){
      height:85px;
      width:20%;
  }
  `;

export const Num = styled.div`
font-size: 18px;
font-weight: bold;

@media only screen and (min-width:992px){
    font-size: 18px;
}
`
export const Rating = styled.div`
font-size: 10px;
font-weight: 500;
margin-top:0px;
display:${props=>props.selected?'flex':'none'};

@media only screen and (min-width:992px){
  font-size: 14px;
   margin-top:2px;
   display:${props=>props.selected?'flex':'none'};
}
`

export const Scale = styled.div`
width:600px;
display:flex;
flex-direction:row;
justify-content:space-between;
margin-top:20px;
font-size: 146x;
font-family: 'Red Hat Display', sans-serif;
color:white;
`

export const Button = styled.div`
height: 40px;
border-radius: 10px;
background-color: ${props => props.enabled? "rgba(255, 255, 255,1)": "rgba(255, 255, 255,0.2)" }; 
color: ${props => props.enabled? "rgba(0, 0, 0,1)": "rgba(0, 0, 0,0.2)" }; 
font-size: 16px;
font-weight: bold;
font-family: 'Red Hat Display', sans-serif;
display:flex;
justify-content:center;
align-items:center;
cursor:${props => props.enabled? "pointer" : "not-allowed"};
width:100px;
margin-top:50px;
`

export const SubmitButton = styled(Button)`
width: 170px;
height: 47px;
border-radius: 5px;
background-color: ${props => props.enabled? "#ffffff": "#bbc7bd" }; 
color: ${props => props.enabled? "#4a4a4a": "white" }; ;
font-size: 16px;
font-weight: bold;
font-family: "Red Hat Display", sans-serif;
cursor:${props => props.enabled? "pointer" : "not-allowed"};
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;

`

export const CounterContainer = styled.div`
position:absolute;
bottom:50px;
right:50px;
display:flex;
flex-direction:row;
align-items:center;
`

export const Counter = styled.div`
padding:10px 15px 10px 15px;
background-color:#4f00f7;
color:white;
font-family: 'Red Hat Display', sans-serif;
font-size:16px;
border-radius:7px;
margin-right:25px;
`

export const Container = styled.div`
background-image: linear-gradient(308deg, #6d00aa , #6a00ff );
height:100vh;
width:100%;
`

