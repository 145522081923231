import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import createSagaMiddleware from 'redux-saga';
// import { logger } from 'redux-logger';
import rootReducer from "./reducers";
import { SET_ERROR_STATE } from "constants/actions";

import { rootSaga } from 'sagas/index'

// import LogRocket from 'logrocket';
// this where we initialize state
const initialState = {};

const sagaMiddleware = createSagaMiddleware({
  onError: () => {
    store.dispatch({ type: SET_ERROR_STATE })
  }
})

const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsBlacklist, actionsCreators and other options
});

const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(sagaMiddleware)
    ),
);

// const store = createStore(
//   rootReducer,
//   initialState,
//   applyMiddleware(sagaMiddleware)
//   ,
// );

sagaMiddleware.run(rootSaga)

export default store;