import {
    GET_CHAT_GPT_SUMMARY,
    CLEAR_CHAT_GPT_SUMMARY,
    PENDING_CHAT_GPT_SUMMARY,
    CLEAR_ALL,
  } from "constants/actions";
  
  const initialState = {
    chat_gpt_summary: false,
    response_pending: false,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case PENDING_CHAT_GPT_SUMMARY:
        return {
            ...state,
            response_pending: true
        }
      case GET_CHAT_GPT_SUMMARY:
        return {
            ...state,
            chat_gpt_summary: action.payload,
            response_pending: false
        }
      case CLEAR_CHAT_GPT_SUMMARY:
        return {...state,
          ...initialState
        }
     
      case CLEAR_ALL:
        return {...state,
          ...initialState
        }
      default:
        return state;
    }
  }
  