import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";

import Intro from "./Intro";
import End from './End'
import styled from "styled-components";
import { Icon } from "semantic-ui-react";

import {
	// BrowserView,
	// MobileView,
	isBrowser,
	isMobile,
	deviceType,
	osName,
	osVersion,
	deviceDetect,
} from "react-device-detect";

import SurveyMessage from "./Message";
import NoToken from "./NoToken";

import { SwitchTransition, CSSTransition } from "react-transition-group";
import {TRANSITIONSPEED, SlideDirection} from "constants/animation"

import "../survey.css";
import QuestionTemplate from "./QuestionTemplate"
import ThankYou from "./ThankYou"
import ErrorMessage from "./ErrorMessage"
import ReLoadPage from "./ReLoadPage";

import {
	SG_START_BRIEF_SURVEY,
	SG_POST_BRIEF_RESPONSE,
	UPDATE_BRIEF_RESPONSE,
} from "constants/actions";


const SelectedQuestion = ({
	questionNo,
	briefResponse,
	answers,
	questions,
	setQuestionNo,
	saveAnswer,
	complete,
	submitAnswer,
	get_errors }) => {

	const [errorMessage, setErrorMessage] = useState(false);

	useEffect(() => {
		if (get_errors?.errors?.data) {
		setErrorMessage(get_errors?.errors?.data?.['detail'])
		}
		
	}, [get_errors]);

	if (complete) {
		return <End
			key={questionNo}
			title={''}
			submitAnswer={() => submitAnswer()}
			intro={'You have completed all the questions in this survey. Please submit your responses'} />

	}

	if (errorMessage) {
		return <ErrorMessage errorMessage={errorMessage} />
	}



	if (questionNo === 0) {
		return <Intro
			key={questionNo}
			title={briefResponse?.title}
			intro={briefResponse?.intro}
			next={() => setQuestionNo(questionNo + 1)} />
	}

	if (complete) {
		return <End submitAnswer={submitAnswer} />
	}
	return (
		<QuestionTemplate
			key={questionNo}
			data={questions?.[questionNo - 1]}
			value={answers?.[questionNo]}
			save={(a) => saveAnswer(a)} />
	)

}

const Template = () => {
	const dispatch = useDispatch();
	let { token } = useParams();
	const [questionNo, setQuestionNo] = useState(0)
	const [answers, setAnswers] = useState({})
	const [direction, setDirection] = useState(1);
	const [showPrev, setShowPrev] = useState(true);
	const [showNext, setShowNext] = useState(true);

	const [questions, setQuestions] = useState([])
	const [briefResponse, setBriefResponse] = useState([])
	const [complete, setComplete] = useState(false)
	const [thankMessage, setThankMessage] = useState(false)

	// const [loading, setLoading] = React.useState(true)

	const [submitError, setSubmitError] = React.useState(false)


	const [surveyStatus, setSurveyStatus] = useState(0)
	const [surveyDeleted, setSurveyDeleted] = useState(false);
	const [surveyDeletedOn, setSurveyDeletedOn] = useState(false);
	// const [surveyMessage, setSurveyMessage] = useState(false);

	// useEffect(() => {
	// 	setTimeout(() => setLoading(false), 1000);
	// });

	const { get_brief_response,
		get_errors } = useSelector(
			(state) => ({
				get_brief_response: state.briefresponse,
				get_errors: state.errors,
			}),
			shallowEqual
		);

	useEffect(() => {
		setQuestions(get_brief_response?.questions)
	}, [get_brief_response]);

	useEffect(() => {
		setShowPrev(true)
		setShowNext(false)
		setBriefResponse(get_brief_response)
		if ((questionNo) <= (get_brief_response?.high_answer)) {
			setShowNext(true)
		}
		if (!questionNo) {
			setShowPrev(false)
			setShowNext(false)
		}

		if (questionNo === 1) {
			setShowPrev(false)
		}
		// 
		setSurveyDeleted(get_brief_response?.to_delete)
		setSurveyDeletedOn(get_brief_response?.deleted_on)

		// setSurveyMessage(get_brief_response?.token_message)

		setSurveyStatus(get_brief_response?.survey_status)

	}, [get_brief_response, questionNo]);

	useEffect(() => {
		// Run! Like go get some data from an API.
		if (token) {
			dispatch({ type: SG_START_BRIEF_SURVEY, payload: `?token=${token}` });
		}
	}, [dispatch, token]);

	const deviceDetected = deviceDetect();

	function submitAnswer() {
		dispatch({
			type: SG_POST_BRIEF_RESPONSE,
			payload: {
				survey_token: briefResponse?.survey_token,
				debrief_schedule: briefResponse?.debrief_schedule,
				employee: briefResponse?.employee,
				response: answers,
				full_response: {
					isBrowser: isBrowser,
					isMobile: isMobile,
					browser: deviceDetected.browserName,
					deviceType,
					osVersion,
					osName,
					deviceDetected: deviceDetected,
				}
			},
		});
		setThankMessage(true)
	}



	const saveAnswer = (response) => {

		setDirection(1)
		
		let _answers = answers
		
		if (answers?.questionNo) {
			_answers.questionNo = response

		} else {
			_answers[questionNo] = response
		}
		setAnswers(_answers)

		if ((questionNo + 1) <= briefResponse?.total_questions) {
			setQuestionNo(questionNo + 1)
			setComplete(false)
		} else {
			setComplete(true)

		}

		dispatch({
			type: UPDATE_BRIEF_RESPONSE,
			payload: {
				current_question: questionNo,
				current_answer: questionNo,
				high_answer: questionNo > briefResponse?.high_answer ? questionNo : briefResponse?.high_answer,
				answers: _answers,
				complete: complete
			},
		});
	}

	const forward = () => {
		if (briefResponse?.high_answer >= questionNo) {
			setDirection(1)
			if ((questionNo + 1) <= briefResponse?.total_questions) {
				setQuestionNo(questionNo + 1)
				setComplete(false)
			} else {
				setComplete(true)
			}
		}
	}

	const back = () => {
		if (questionNo > 1) {
			setDirection(0)
			if ((questionNo) === briefResponse?.total_questions) {
				setComplete(false)
				if (questionNo === briefResponse?.current_question) {
					dispatch({
						type: UPDATE_BRIEF_RESPONSE,
						payload: {
							current_question: 0,
							current_answer: 0,
							complete: false
						},
					});
				}
				else {
					setQuestionNo(questionNo - 1)
				}
			}
			else {
				setQuestionNo(questionNo - 1)
			}
		}
	}

	useEffect(() => {
		if (get_errors?.errors?.error?.request?.response) {
			let RESP = JSON.parse(get_errors?.errors?.error?.request?.response)
			setSubmitError(RESP?.detail)
		}
		
	}, [get_errors]);

	// console.log(surveyDeleted)

	if (!token) {
		return <NoToken />
	}


	if (submitError) {
		return (
			<Container>

				<SwitchTransition>
					<CSSTransition
						key={questionNo}
						timeout={TRANSITIONSPEED}
						classNames={SlideDirection[direction]}
					>
						<ReLoadPage
							message={submitError}
						/>
					</CSSTransition>
				</SwitchTransition>
			</Container>
		)
	}
	

	if (surveyDeleted) {
		return (
			<Container>
				<SwitchTransition>
					<CSSTransition
						key={questionNo}
						timeout={TRANSITIONSPEED}
						classNames={SlideDirection[direction]}
					>
						<SurveyMessage
							onTime={surveyDeletedOn}
							status={surveyStatus}
						/>
					</CSSTransition>
				</SwitchTransition>

			</Container>
		)
	}

	if (thankMessage) {
		return (
			<Container>
				<SwitchTransition>
					<CSSTransition
						key={questionNo}
						timeout={TRANSITIONSPEED}
						classNames={SlideDirection[direction]}
					>
						<ThankYou />
					</CSSTransition>
				</SwitchTransition>

				
			</Container>
		)
	}


	return (
		<Container>
			
				<SwitchTransition>
					<CSSTransition
						key={questionNo}
						timeout={TRANSITIONSPEED}
						classNames={SlideDirection[direction]}
					>
						<SelectedQuestion
							questionNo={questionNo}
							briefResponse={briefResponse}
							questions={questions}
							answers={answers}
							setQuestionNo={setQuestionNo}
							saveAnswer={saveAnswer}
							complete={complete}
							submitAnswer={submitAnswer}
							thankMessage={thankMessage}
							get_errors={get_errors}
						/>
						
					</CSSTransition>
				</SwitchTransition>

			{briefResponse?.survey_token &&
				<CounterContainer>
					<Counter>
						<div>
							<Title>{briefResponse?.short}</Title>
							{questionNo} of {questions?.length} questions
						</div>
					</Counter>
					<Icon
						name="chevron up"
						disabled={!showPrev}
						style={{ cursor: showPrev ? "pointer" : "not-allowed" }}
						onClick={() => back()}
					/>
					<Space />

					<Icon
						name="chevron down"
						style={{ cursor: showNext ? "pointer" : "not-allowed", zIndex: 2 }}
						disabled={!showNext}
						onClick={() => forward()}
					/>
				</CounterContainer>
			}

		</Container>
	)
}

export default Template




const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #15056b;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CounterContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-size: 14px;

  @media only screen and (min-width: 992px) {
    bottom: 50px;
    right: 50px;
    font-size: 20px;
  }
`;

const Counter = styled.div`
  padding: 5px 10px 5px 10px;
  background-color: rgba(30, 33, 114, 0.3);
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;
  border-radius: 7px;
  margin-right: 10px;

  @media only screen and (min-width: 992px) {
    padding: 10px 25px 10px 25px;
    font-size: 16px;
    margin-right: 25px;
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;

  @media only screen and (min-width: 992px) {
    margin-bottom: 5px;
    font-size: 20px;
  }
`;

const Space = styled.div`
  @media only screen and (min-width: 992px) {
    width: 20px;
  }
`;
