import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Intro = (props) =>{
  const {saveAnswer, title, intro, surveyEnabled=true, customElement } = props;

  const handleNext = () => {
    if (surveyEnabled) {
      saveAnswer();
    }
  }

	return (
		<Container>
			<Title>{title}</Title>
			<SubTitle>{intro}</SubTitle>
      {customElement}
			<Next id="intro1SubmitButton" enabled={surveyEnabled} onClick={()=> handleNext()}>Begin</Next>
		</Container>
		)
}

Intro.propTypes = {
  saveAnswer: PropTypes.func,
  title: PropTypes.string,
  surveyEnabled: PropTypes.bool,
  intro: PropTypes.node,
  customElement: PropTypes.node,
}

/******** Styles ****************************************************/

const Title = styled.div`
	font-size: 30px;
  font-weight: bold;
  font-family: 'Barlow', sans-serif;
  margin-bottom:20px;
  color:white;
  line-height:1;
  max-width:654px;

  @media only screen and (min-width:992px){
   font-size: 34px;
   margin-bottom:20px;
  }
`
const SubTitle = styled.div`
	font-size: 18px;
	line-height: 1.17;
  color: white;
  font-family: 'Barlow', sans-serif;
  margin-bottom:20px;
  max-width:654px;

   @media only screen and (min-width:992px){
   margin-bottom:30px;
   font-size: 20px;
   line-height:1.4;
  }
`;

const Next = styled.div`
	width: 170px;
  height: 47px;
  border-radius: 5px;
  background-color: #009370;
  color: rgba(0, 0, 0,0.9);
  font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  margin-top: 25px;
  cursor: pointer;
  ${(props) => !props.enabled && `
    opacity: 0.4;
    cursor:auto;
  `}
`

const Container = styled.div`
  position:absolute;
  top:5%;
  padding:20px;
  @media only screen and (min-width:992px){
    left: 25%;
  }
`

export default Intro;
