import React from "react";
import styled from "styled-components";




const Personality = ({q, answer, UpdateAnswer, words, questions}) => {
// const [answer, setAnswer] = useState(null)

  return (
   
        <SurveyContainer>


          <Question>{`I see myself as ${questions[q]}`}</Question>

          <QuestionContainer>
            {[0, 1, 2, 3, 4,5,6].map((item, i) => {
              return (
                <QuestionItem
                  i={i + 1}
                  answer={answer[q+1]?.response}
                  key={i}
                  onClick={() => UpdateAnswer(i+1)}
                >
                  <Num>{item + 1}</Num>
                  <Rating>{words[i]}</Rating>
                </QuestionItem>
              );
            })}
          </QuestionContainer>

          

        </SurveyContainer>
   

  )
}


export default Personality


const SurveyContainer = styled.div`
  padding: 0px;
  box-sizing: border-box;
  position: absolute;
  left: 0%;
  top: 10%;
  padding: 20px;
  width:100%;
   max-width:650px;

  @media only screen and (min-width: 768px) {
    padding: 30px;
    left: calc(50% - 350px);
    top: 15%;
  }
`;

const Question = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height:1.6;
  height:50px;

  color: white;
  margin-top: 14px;
  max-width:100%;

  @media only screen and (min-width: 992px) {
    font-size: 23px;
    margin-top: 28px;
  }
`;

const QuestionItem = styled.div`
  width: 100%;
  height: 115px;
  display: flex;
  flex-direction: row;
  align-items: center;

  border: 1px solid white;
  cursor: pointer;
  background-color: ${(props) => (props.answer === props.i ? "white" : "none")};
  color: ${(props) => (props.answer === props.i ? "#002046" : "white")};
   padding:5px;

  &:active {
    background: white;
    color:#002046;
  }

  @media only screen and (min-width: 992px) {
    height: 115px;
    width: 20%;
    flex-direction: column;
    padding:5px;
      border-radius: ${(props) =>
    props.i === 1 ? "7px 0px 0px 7px" : props.i === 7 ? "0 7px 7px 0" : "none"};

      &:hover {
    background: white;
    color: #002046;
  }
  &:active {
    background: #002046;
    color:white;
  }
  }

  
`;

const QuestionContainer = styled.div`
  max-width:650px;
  height: 75px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    width: 600px;
    margin-top: 30px;
     flex-direction: row;
  }
`;


const Num = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;

  @media only screen and (min-width: 992px) {
    font-size: 24px;
    margin-top: 20px;
  }
`;
const Rating = styled.div`
  font-size: 10px;
  font-weight: 500;
  margin-top: 0px;
  text-align:center;
  line-height:1.4;
  margin-top:10px;
  margin-left:20px;

  @media only screen and (min-width: 992px) {
    font-size: 11px;
    margin-top: 10px;
    margin-left:0px;
  }
`;

