import React from 'react'
import styled from 'styled-components'

const Checkbox = ({ className, checked, id, ...props }) => (
  <CheckboxContainer  className={className}  onClick={props.onChange}>
    <HiddenCheckbox defaultChecked={checked} />
    <CheckboxWrapper color={props.color}>
        <StyledCheckbox id={id} checked={checked}>
            <Icon viewBox="0 0 24 24">
                <polyline points="20 6 9 17 4 12" />
            </Icon>
        </StyledCheckbox>
        <CheckLabel>{props.language===1?"Je suis d'accord":props.label}</CheckLabel>
    </CheckboxWrapper>
  </CheckboxContainer>
);

/******** Styles ****************************************************/

const CheckboxWrapper = styled.div`
  display: flex;
  color: ${props=>props.color};
  align-items:center;
  padding-bottom: 15px;
`;

const CheckLabel = styled.div`
    margin-left: 10px;
    font-size: 12px;
    cursor: pointer;
    @media only screen and (min-width: 992px) {
      font-size: 14px;
    };
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props => (props.checked ? 'black' : 'lightgrey')};
  border-radius: 3px;
  transition: all 150ms;
  flex: 1;
  cursor: pointer;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px #009370;
  }
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }
`;

export default Checkbox;
