import styled from "styled-components";
import React from "react";

import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';


const PrettoSlider = withStyles({
  root: {
    color: 'grey',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#FA675C',
    color: '#FA675C',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const SliderQ = ({ value, saveResult, words }) => {

  const [res1, setRes1] = React.useState(value ? value : 0)

  const getScore = (val) => {

    if (val < 20) {
      return words[0]
    }
    if (val < 40) {
      return words[1]
    }
    if (val < 60) {
      return words[2]
    }
    if (val < 80) {
      return words[3]
    }
    return words[4]
  }

  return (
    <QuestionContainer>
      <Text>{res1 ? getScore(res1) : ""}</Text>
      <PrettoSlider
        valueLabelDisplay={"on"}
        aria-label="pretto slider"
        value={res1}
        onChange={(e, t) => setRes1(t)}
      />

      <Button onClick={() => saveResult(res1)}>Next</Button>
    </QuestionContainer>
  )
}


export default SliderQ



const QuestionContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  padding-left:10px;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    width: 600px;
    margin-top: 40px;
  }
`;


const Button = styled.button`
  height: 40px;
  border-radius: 10px;
  background-color: white;
  border: 0;
  box-shadow: none;
  color: #6a00ff;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100px;
  margin-top: 50px;
  // display: none;

  @media only screen and (min-width: 992px), (min-height: 760px) {
    display: flex;
  }
`;

const Text = styled.div`
  color:white;
  font-size:20px;
  margin-bottom:40px;
  margin-top:20px;
`