import styled from "styled-components";
import React, { useLayoutEffect, useState } from "react";


import EmotionCard from "./EmotionCard";

function useWindowSize() {

  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      let width =
        window.innerWidth < 992 ? window.innerWidth : window.innerWidth - 304;
      setSize([width, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const Reflection = (props) => {

  const [selected, setSelected] = React.useState(0);
  const [
    width, 
    // height
  ] = useWindowSize();
  const [shift, setShift] = useState(0);

  const getNext = () => {
    if (selected < Object.keys(props.answers).length - 1) {
      setSelected(selected + 1);
      if (width - (selected + 2) * 324 < 0) {
        setShift((selected + 2) * 324 - width);
      } else {
        setShift(0);
      }
    }
    // 
  };

  const getClicked = (i) => {
    setSelected(i);
    if (width - (i + 1) * 324 < 0) {
      setShift((i + 1) * 324 - width);
    } else {
      setShift(0);
    }
  };

  const back = () => {
    let newSelect = selected > 0 ? selected - 1 : 0;
    setSelected(newSelect);
    if (width - (newSelect + 1) * 324 < 0) {
      setShift((newSelect + 1) * 324 - width);
    } else {
      setShift(0);
    }
  };



  return (
    <Container>
      <TopText>Take a moment to reflect</TopText>
      <SubText>
        Improve the emotional intelligence of yourself and your team by
        reflecting on the cause of each emotion you selected
      </SubText>
      <EmotionContainer shift={shift}>
        {props.answers.map((item, i) => {
          return (
            <EmotionCard
              mood={item}
              // setSelectedMood={setSelectedMood}
              // color={props.answers[item]}
              selected={selected === i}
              setSelected={() => getClicked(i)}
              key={i}
            />
          );
        })}
      </EmotionContainer>
      <ButtonContainer>
        <Back onClick={() => back()} selected={selected}>
          Back
        </Back>
        {selected < Object.keys(props.answers).length - 1 ? (
          <Next onClick={() => getNext()}>Next</Next>
        ) : (
          <FinishNext onClick={() => props.SubmitAnswers()}>
            Finish
          </FinishNext>
        )}
      </ButtonContainer>
    </Container>
  );
};

export default Reflection;


const TopText = styled.div`
  color: #4a4a4a;
  font-size: 23px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 10px;
  margin-top:30px;
`;

const SubText = styled.p`
  line-height: 1.31;
  letter-spacing: normal;
  color: #4a4a4a;
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 30px;
  display: flex;

`;
const Next = styled.div`
  width: 111px;
  height: 47px;
  border-radius: 5px;
  background-color: #fff0cf;
  color: #4a4a4a;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const FinishNext = styled.div`
  width: 111px;
  height: 47px;
  border-radius: 5px;
   background-color: #fff0cf;
  color: #4a4a4a;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const Back = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  color: ${(props) => (props.selected === 0 ? "grey" : "black")};
  margin-right: 10px;

  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const Container = styled.div`
  width: calc(100% - 15px);
  position:absolute;
  top:50px;

  @media (max-height:600px){
    top:20px;
  }

  @media only screen and (min-width: 992px) {
    width: calc(100vw - 274px);
  }
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (min-width: 992px) {
    margin-top: 90px;
  }

   @media only screen and (min-width:768px) and (max-height:750px){
     margin-top:40px;
   }
`;

const EmotionContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  min-height: 300px;
  transition: margin-left 500ms;

  @media only screen and (min-width: 992px) {
    margin-left: ${(props) => -props.shift + "px"};
  }
`;