import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Content from "./content";
import { Icon } from 'semantic-ui-react'
import { Container, IMG, Avatar, Name, SectionContainer, Selected } from "./styled";

const SideBar = ({ surveyType }) => {
  // const [survey, setSurvey] = useState([]);
  // const [
  //   // answers, 
  //   setAnswers] = useState([]);
  // const [
  //   // currQuestion, 
  //   setCurrQuestion] = useState(0);
  // const [
  //   // maxQuestion, 
  //   setMaxQuestion] = useState(0);
  // const [
  //   // highAnswer, 
  //   setHighAnswer] = useState(0);
  const [surveyData, setSurveyData] = useState([]);


  const { get_answers } = useSelector(
    (state) => ({
      get_answers: state.answers,
    }),
    shallowEqual
  );

  // React.useEffect(() => {
  //   setAnswers(get_answers);
  //   switch (surveyType) {
  //     case 1:
  //       // culture
  //       setMaxQuestion(get_answers.culture_max_ques);
  //       setCurrQuestion(get_answers.culture_cur_ques);
  //       setHighAnswer(get_answers.culture_high_ans);
  //       return;
  //     case 2:
  //       // mood
  //       setMaxQuestion(get_answers.mood_max_ques);
  //       setCurrQuestion(get_answers.mood_cur_ques);
  //       setHighAnswer(get_answers.mood_high_ans);
  //       return;
  //     case 3:
  //       // leadership
  //       setMaxQuestion(get_answers.leadership_max_ques);
  //       setCurrQuestion(get_answers.leadership_cur_ques);
  //       setHighAnswer(get_answers.leadership_high_ans);
  //       return;
  //     default:
  //       setMaxQuestion(0);
  //       setCurrQuestion(0);
  //       setHighAnswer(0);
  //       return;
  //   }
  // }, [get_answers, surveyType]);

  React.useEffect(() => {
    // if (get_answers) {
    //   setAnswers(get_answers);
    // }
    function CheckCultureCompleted() {
      if (surveyType > 1) {
        return true
      }
      return get_answers.culture_max_ques === get_answers.culture_cur_ques
    }

    function CheckCultureProgress() {
      if (surveyType === 1) {
        return `${get_answers.culture_cur_ques}/${get_answers.culture_max_ques}`
      }
      
    }

    function CheckMoodCompleted() {
      if (surveyType > 2) {
        return true
      }
      return get_answers.mood_max_ques === get_answers.mood_cur_ques
    }

    function CheckMoodProgress() {
      if (surveyType > 2) {
        return true
      }
      return `${get_answers.mood_high_ans}/${get_answers.mood_max_ques}`
    }

    setSurveyData([
      {
        id: 1,
        survey_type: 1,
        title: "Culture",
        top_margin: 0,
        curr_ques: get_answers.culture_cur_ques,
        answered: get_answers.culture_high_ans,
        completed: CheckCultureCompleted(),
        progress: CheckCultureProgress(),
      },
      {
        id: 2,
        survey_type: 2,
        top_margin: 85,
        title: "Mood",
        curr_ques: get_answers.mood_cur_ques ? get_answers.mood_cur_ques : "",
        answered: get_answers.mood_high_ans,
        completed: CheckMoodCompleted(),
        progress: CheckMoodProgress(),
      },
      {
        id: 3,
        survey_type: 3,
        top_margin: 165,
        title: "Leadership",
        curr_ques: get_answers.leadership_cur_ques,
        answered: get_answers.leadership_high_ans,
        completed:
          get_answers.leadership_max_ques === get_answers.leadership_cur_ques,
        progress: `${get_answers.leadership_cur_ques}/${get_answers.leadership_max_ques}`,
      },
    ]);
  }, [get_answers, surveyType]);

  // const SurveyData = [
  //   { survey_type: 1, title: "Culture",
  //   top_margin: 0, completed: false,
  //   progress: "1/6" },
  //   { survey_type: 2, top_margin: 85,
  //     title: "Mood", completed: false, progress: "0/3" },
  //   { survey_type: 3, top_margin: 165,
  //     title: "Leadership", completed: false, progress: "0/8" },
  // ];

  return (
    <Container surveyType={surveyType}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 54,
          alignItems: "center",
        }}
      >
        <IMG>
          {get_answers?.employee_picture ? <Avatar src={get_answers?.employee_picture}
            alt='Employee Pic' /> :
            <Icon name="user circle" style={{ fontSize: 40, marginRight: 0, color: 'white', marginTop: 20 }} />
          }
        </IMG>
         {/* eslint-disable-next-line eqeqeq */}
        <Name surveyType={surveyType}>{get_answers?.employee_name ? get_answers.employee_name == 'None None' ? "" : get_answers.employee_name : ""} </Name>
      </div>

      <SectionContainer surveyType={surveyType}>
        <Selected
          top={
            surveyType && surveyData
              ? surveyData.find(({ survey_type }) => survey_type === surveyType)?.top_margin
              : ""
          }
          // top={170}
          surveyType={surveyType}
        />

        {surveyData
          ? surveyData.map((item, i) => (

              <Content
                data={item}
                i={"0" + (i + 1)}
                color={"#696969"}
                key={i}
              />
            ))

          : ""}
      </SectionContainer>
    </Container>
  );
};

export default SideBar;
