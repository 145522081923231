import { put, call, all, takeLatest } from "redux-saga/effects";

import {
  PENDING_DEBRIEF_SURVEY,
  DEBRIEF_SURVEY,
  SG_DEBRIEF_SURVEY,
  ADD_EMPLOYEE_TO_DEBRIEF_SURVEY,
  SG_ADD_EMPLOYEE_TO_DEBRIEF_SURVEY,
  ALL_ERRORS,
} from "constants/actions";

import * as api from "constants/api";

function* getDebriefSurvey(action) {
  yield put({ type: PENDING_DEBRIEF_SURVEY });
  try {
    const json = yield call(api.DEBRIEF_SURVEY, action.payload);
    yield put({ type: DEBRIEF_SURVEY, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* loadDebriefSurvey() {
  yield takeLatest(SG_DEBRIEF_SURVEY, getDebriefSurvey);
}

function* addEmployeeToDebrief(action) {
  yield put({ type: PENDING_DEBRIEF_SURVEY });
  try {
    const json = yield call(api.ADD_EMPLOYEE_TO_DEBRIEF_SURVEY, action.payload);
    yield put({ type: ADD_EMPLOYEE_TO_DEBRIEF_SURVEY, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* postEmployeeToDebrief() {
  yield takeLatest(SG_ADD_EMPLOYEE_TO_DEBRIEF_SURVEY, addEmployeeToDebrief);
}
export default function* index() {
  yield all([loadDebriefSurvey(), postEmployeeToDebrief()]);
}
