import React from "react";
import styled from "styled-components";

const PersonalityIntro = ({start}) =>{


	return (
		<Container>
			<Title>Getting to know you</Title>

			<SubTitle>In an effort to better contextualize the feedback we have received from you, we'd like to 
			ask you a few questions about your personality. For the following 10 questions please rate the extent to which you agree or disagree with the statement.</SubTitle>


			<SubTitle>
				Your data is always kept private, and no one can access your individual responses to these questions.
			</SubTitle>
			<Next 
				onClick={()=>start()}
				enabled={true}>Begin</Next>
		</Container>
		)
}


export default PersonalityIntro





const SubTitle = styled.div`
	font-size: 18px;
	line-height: 1.5;
  color: white;
  margin-bottom:30px;
  max-width:654px;



   @media only screen and (min-width:992px){

   margin-bottom:20px;
   font-size: 23px;
  }


`

const Container = styled.div`
  position:absolute;
  top:10%;
  padding:20px;
  background:${props=>props.color};


   @media only screen and (min-width: 768px) {
    left: calc(50% - 350px);
    top: 15%;
  }

  @media only screen and (max-height:550px){
  top: 10%;
  }

   @media(max-width:350px){
    height:850px;
  }
`

const Next = styled.button`
	width: 170px;
  height: 47px;
   border-radius: 5px;
  background-color: white;
  color: ${props=>props.color};
   font-size: 16px;
  font-weight: bold;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  border: none;

   ${(props) => !props.enabled && `
    opacity: 0.4;
    cursor:auto;
    pointer-events: none;
  `}
`

const Title = styled.div`
	font-size:22px;
	font-weight:bold;
	margin-bottom:15px;
	color:white;
`