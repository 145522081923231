import React, { useEffect, useState } from "react";
import styled from "styled-components";




const Courtex = () => {

    return (
        <Container>
             <IMG src="https://firebasestorage.googleapis.com/v0/b/charactercode-a247a.appspot.com/o/email%2FSterling%20Hawkins%20Logo%20Black.png?alt=media&token=19095f99-8382-434c-b115-6fbf1c662aba" />
        
       
        <Content>
           
            <Text>
            The Sterling Hawkins Group is seeking your <strong>anonymous feedback</strong> to assess the organizational culture within Fonterra's South Asia & South-East Asia division.</Text>
            <Text>
            <strong>Please provide your work email address</strong> to receive a unique survey link. Your responses will remain <strong>confidential</strong> and will only be analyzed in aggregate once the survey closes.            </Text>

            <Text>
            If you have any questions, please contact Renú at <a href="mailto:renu.nair@fonterra.com">renu.nair@fonterra.com</a>.
            </Text>

            <Text>
                Thank you for your participation.
            </Text>
        </Content>
        </Container>
    )
}


export default Courtex;


const Container = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
`

const Content = styled.div`
    max-width: 600px;
    width: 100%;
    padding: 40px;
    border-radius:20px;
    background-color: #F8FAFF;
    line-height: 1.5;
    font-weight:500;
    color:#2A3039;
    font-family: 'Raleway', sans-serif;

    @media(max-width:768px){
        font-size:14px;
        padding:20px;
    }

`
const IMG = styled.img`
    width:200px;
    margin-bottom:20px;

    @media(max-width:768px){
        width:75%;
    }
`

const Text = styled.div`
    margin-bottom:20px;
    line-height:1.6;
`

const T1 = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
`;
