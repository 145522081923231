import React from "react";
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'
import CustomButton from './Button'
import {words} from '../translations'

const SkipModal = ({open,setOpen, SkipQuestion,language}) =>{
  
    return (
      <Modal
      dimmer={'blurring'}
      open={open}
      onClose={() => setOpen(false)}>
        <Modal.Header>{words['matters'][language]} <span role="img" aria-label="star">🌟</span></Modal.Header>
          <ModalContent>
          {words['welcomeMessage'][language]}
           <span role="img" aria-label="world">🌍</span> {words['sharing'][language]}
           <ul>
              <li style={{marginBottom:15}}><strong>{words['driveChangeTitle'][language]} <span role="img" aria-label="drive">🛠️</span> </strong> - {words['driveChangeText'][language]}</li>
            <li style={{marginBottom:15}}>
            <strong>{words['reinforceStrengthsTitle'][language]} <span role="img" aria-label="strength">💪</span></strong> - {words['reinforceStrengthsText'][language]}
            </li>
            <li>
              <strong>{words['influenceDecisionsTitle'][language]} <span role="img" aria-label="compass">🧭</span></strong> - {words['influenceDecisionsText'][language]}
            </li>
           </ul>
           
          </ModalContent>
          <Modal.Actions>
           
          
            <CustomButton type='skip' onClick={SkipQuestion}>
              {words['Skip anyway'][language]}
            </CustomButton>

            <CustomButton onClick={() =>setOpen(false)} type='leave'>
              {words['Leave feedback'][language]}
            </CustomButton>
          </Modal.Actions>
      </Modal>
  
    )
  }

export default SkipModal


const ModalContent = styled.div`
  padding:20px;
  font-size:16px;
  line-height:1.6;
  font-family:Poppins, sans-serif;

  @media (max-width:768px){
    font-size:14px;
  }
`