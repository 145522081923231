import React from "react";
import styled from "styled-components";
import {  useDispatch } from "react-redux";
import { SET_ANSWERS } from "constants/actions";
// import {  Button } from 'semantic-ui-react'
// import { Link } from "react-router-dom";
import Pic from 'assets/images/intro.png'



const Intro = (props) => {
  const {currQuestion} = props
  const dispatch = useDispatch();
  function StartSurvey() {
    dispatch({
      type: SET_ANSWERS,
      payload: { culture_cur_ques: currQuestion + 1, curr_direction: 1 },
    });
    props.next()
  }

 
  return (
    <StyledContainer>
      <ImageContainer>
        <Image src={Pic} />
      </ImageContainer>
      <Title>Team Health Check</Title>


      {/* eslint-disable-next-line eqeqeq */}
      {parseInt(props.role) == 21 ?
        <SubTitle>Your responses in this section are <strong>completely anonymous.</strong></SubTitle> :
        // eslint-disable-next-line eqeqeq
        parseInt(props.role) == 11 ?
          <SubTitle>Your responses in this section are only shared with your team leader(s)</SubTitle>
          : <SubTitle>Your responses in this section are only visible to both team leader(s),
            and your team members.</SubTitle>}


      {/* missing no of team members your [12] team members */}
      <Next onClick={StartSurvey}>Begin</Next>
    </StyledContainer>
  );
};

export default Intro;

const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 20px;
  color: black;
  line-height: 1;

  @media only screen and (min-width: 992px) {
    font-size: 72px;
    margin-bottom: 50px;
  }


`;
const SubTitle = styled.div`
  font-size: 16px;
  line-height: 1.17;
  color: black;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 20px;
  max-width: 654px;

  @media only screen and (min-width: 992px) {
    font-size: 72px;
    margin-bottom: 50px;
    font-size: 24px;
  }
`;

// const StyledMessage = styled.div`
//   font-size: 18px;
//   line-height: 1.17;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   padding: 15px;
//   background-color: black;
//   color: white;
//   font-family: "Red Hat Display", sans-serif;
//   margin-bottom: 20px;
//   max-width: 654px;

//   @media only screen and (min-width: 992px) {
//     font-size: 72px;
//     margin-bottom: 50px;
//     font-size: 24px;
//   }
// `;

const Next = styled.div`
  width: 170px;
  height: 47px;
  border-radius: 5px;
  background-color: #6a00ff;
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom:40px;

  @media(max-width:768px){
    margin-top:30px;
  }
`;

const StyledContainer = styled.div`
  position: absolute;

  top:100px;
  width: calc(100vw - 30px);

  @media(max-width:768px){
    top:10%;


  }

  @media(max-height:750px){
    top:5%;
  }
`;

const Image = styled.img`
  height:200px;
  margin-bottom:20px;

  @media(max-width:768px){
    width:80%;
    height:auto;
  }

  @media(max-width:767px) and (max-height:750px){
    width:60%;
  }

 
`

const ImageContainer = styled.div`
  width:654px;
  display:flex;
  justify-content:center;

  @media(max-width:768px){
    width:100%;
  }

`
