import React, { useState } from "react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  ADD_LEADERSHIP_ANSWER,
  RESET_LEADERSHIP,
  // PULL_ANSWER,
  SUBMIT_ANSWER,
  SET_ANSWERS,
} from "constants/actions";

import {
  // BrowserView,
  // MobileView,
  isBrowser,
  isMobile,
  // deviceType,
  osName,
  // osVersion,
  deviceDetect,
} from "react-device-detect";

import CommentBox from "./comment_box";

import {
  SurveyContainer,
  // IMG,
  Title,
  Question,
  QuestionContainer,
  QuestionItem,
  Num,
  Rating,
  Button,
  SubmitButton,
} from "./styled-list";


const leadershipAnswersList = [
  "Not at all",
  "Not much",
  "Somewhat",
  "Mostly",
  "Absolutely",
];

const LeadershipQuestion = (props) => {
  const deviceDetected = deviceDetect();
  const [fullName, setFullName] = React.useState(false);
  // const [leaderPic, setLeaderPic] = React.useState(false);
  const [leaderSelected, setLeaderSelected] = React.useState(false);
  const { question, leaderList } = props;
  const dispatch = useDispatch();
  const [answer, setAnswer] = useState(false);
  const [comment, setComment] = useState(false);
  const [showComment, setShowComment] = useState(false);

  const { get_answers } = useSelector(
    (state) => ({
      get_answers: state.answers,
    }),
    shallowEqual
  );
// console.log(leadershipAnswersList)
// console.log(leadershipAnswersList.filter((p) => p.id === question.id)[0]);

  React.useEffect(() => {
    setLeaderSelected(leaderList[0]);
    setFullName(`${leaderList[0].first_name} ${leaderList[0].last_name}`);
    // setLeaderPic(`https://storage.googleapis.com/innerlogic_staging_storage_2020/${leaderList[0].picture}`)
  }, [leaderList]);
  // console.log(leaderSelected);

  React.useEffect(() => {
    if (get_answers?.leadership_answers) {
      setAnswer(
        get_answers.leadership_answers.find(
          ({ id, leader_id }) =>
            id === question.id && leader_id === leaderSelected.leader_id
        )?.answer
      );
      setComment(
        get_answers.leadership_answers.find(
          ({ id, leader_id }) =>
            id === question.id && leader_id === leaderSelected.leader_id
        )?.comments
      );
    }
  }, [get_answers, question, leaderSelected]);

  function NoClick() {
    // optional error message
  }

   React.useEffect(() => {
    //  console.log(answer!==false && answer!==undefined)
    if (answer!==false && answer!==undefined) {
      setShowComment(true)
    } else {
      setShowComment(false)
    }
  },[answer])

  // console.log(leaderPic);

  const SaveAnswer = () => {
    dispatch({
      type: ADD_LEADERSHIP_ANSWER,
      payload: {
        row_id: `${leaderSelected.leader_id}${question.id}`,
        leader_id: leaderSelected.leader_id,
        id: question.id,
        answer: answer,
        comments: comment,
      },
    });
    dispatch({
      type: SET_ANSWERS,
      payload: {
        leadership_high_ans: question.id,
        curr_direction: 1,
      },
    });
    let response = [answer, comment];
    setAnswer(null);
    setComment(null);

    props.next(response);
  };

  const SubmitAnswer = () => {
    dispatch({
      type: ADD_LEADERSHIP_ANSWER,
      payload: {
        row_id: `${leaderSelected.leader_id}${question.id}`,
        leader_id: leaderSelected.leader_id,
        id: question.id,
        answer: answer,
        comments: comment,
      },
    });

    dispatch({
      type: SUBMIT_ANSWER,
      payload: {
        leadership_answers: [
          ...get_answers.leadership_answers,
          {
            row_id: `${leaderSelected.leader_id}${question.id}`,
            leader_id: leaderSelected.leader_id,
            id: question.id,
            answer: answer,
            comments: comment,
          },
        ],
        employee: get_answers.employee,
        token: get_answers.token,
        device: deviceDetected,
        mobile: isMobile? "Yes" : "No",
        browser: isBrowser? "Desktop" : "Other Devise",
        operating_system: osName,
        user_ip: get_answers.user_ip,
        leadership_survey_compliance: get_answers.leadership_survey_compliance,
      },
    });

    dispatch({
      type: SET_ANSWERS,
      payload: {
        curr_survey_type: get_answers.all_survey_types[1],
        survey_type: get_answers.all_survey_types[1],
        all_survey_types: get_answers.all_survey_types.slice(1),
      },
    });
    dispatch({
      type: RESET_LEADERSHIP,
    });
    // props.next(response);
  };

  // console.log(get_answers.leadership_max_ques === get_answers.leadership_cur_ques);
  // console.log(get_answers.leadership_max_ques);
  // console.log(question);
  // console.log(question.member);
  return (
    <SurveyContainer>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Title>{question.category}</Title>
      </div>
      <Question>{question.member.replace("%s", fullName)}</Question>
      <CommentBox
       answer={leadershipAnswersList[answer - 1]}
        visible={showComment}
        comment={comment}
        setComment={(c) => setComment(c)}
      />

      <QuestionContainer>
        {[1, 2, 3, 4, 5].map((item, i) => {
          return (
            <QuestionItem
              i={item}
              answer={answer}
              onClick={() => setAnswer(item)}
              key={i}
            >
              <Num>{item}</Num>
              <Rating selected={true}>
                {leadershipAnswersList[item - 1]}
              </Rating>
        
            </QuestionItem>
          );
        })}
      </QuestionContainer>

      {get_answers.leadership_max_ques === get_answers.leadership_cur_ques ? (
        <SubmitButton
          enabled={answer}
          onClick={answer ? SubmitAnswer : NoClick}
        >
          Submit
        </SubmitButton>
      ) : (
        <Button enabled={answer} onClick={answer ? SaveAnswer : NoClick}>
          Next
        </Button>
      )}
    </SurveyContainer>
  );
};

export default LeadershipQuestion;
