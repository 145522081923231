import { all, fork } from 'redux-saga/effects';
import Questions from "./Questions";
import Answers from "./Answers"
import Start from "./Start"
import BriefSurvey from "./BriefSurvey"
import ChatGpt from "./ChatGpt"
import MarketingRoi from './MarketingRoi';
import DebriefSurvey from "./DebriefSurvey"

export function* rootSaga() {
  yield all([
    fork(Questions),
    fork(Answers),
    fork(Start),
    fork(BriefSurvey),
    fork(ChatGpt),
    fork(MarketingRoi),
    fork(DebriefSurvey),
  ]);
}

export default rootSaga