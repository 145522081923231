import React, { useState, useEffect } from 'react'


import { useDispatch } from "react-redux";

import { Button, Header, Icon, Modal } from 'semantic-ui-react'

import {
    RESET_VALIDATION_TOKEN,
    UPDATE_BRIEF_RESPONSE_ANSWERS,
    SG_UPDATE_BRIEF_RESPONSE,
    // SG_UPDATE_BRIEF_RESPONSE_ANSWERS,
} from "constants/actions";

function ExistingDataFound({ 
    briefResponseId,
    token,
    validationToken,
    setQuestionNo,
    setVisualQuestionNo,
    show = true,
    setShowIntro,
    setShowIntro2,
    storedResponse }) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false)

    const [validToken, setValidToken] = useState(false)
    const [timed, setTimed] = useState(false)
    const [defaultMessage, setDefaultMessage] = useState({
        header: "Searching ...",
        text: "Looking for earlier response, please wait ..."
    })

    useEffect(() => {
        setOpen(show)
    }, [show]);



    function ResetSurvey() {
        dispatch({
            type: SG_UPDATE_BRIEF_RESPONSE,
            payload: {
                id: briefResponseId,
                query: `?token=${token}`,
                // validation_token: null,
                reset_stored_data : true, // makes validation_token null
            }
        })
        localStorage.removeItem("validation_token");
        setOpen(false)
        dispatch(
            {
                type: RESET_VALIDATION_TOKEN,
                payload: false
            }
        )
        window.location.reload();
    }

    // console.log(validationToken)
    // console.log(storedResponse?.questionSequenceMap)
    // console.log("storedResponse?.validation_token", storedResponse)
    // console.log(validationToken === storedResponse?.validation_token)
    // console.log("token", token)
    useEffect(() => {
          const _validating = validationToken === storedResponse?.validation_token
        setTimed(false)
        const timer1 = setTimeout(() => {
            if (_validating) {
                setDefaultMessage({
                    header: "Exiting data found",
                    text: "Would you like to resume from you last left off?"
                })
            } else {
                dispatch({
                    type: SG_UPDATE_BRIEF_RESPONSE,
                    payload: {
                        id: briefResponseId,
                        query: `?token=${token}`,
                        // validation_token: null,
                        reset_stored_data : true, // makes validation_token null
                    }
                })
                localStorage.removeItem("validation_token");
                setOpen(false)
                dispatch(
                    {
                        type: RESET_VALIDATION_TOKEN,
                        payload: false
                    }
                )
                window.location.reload();
            }
            setTimed(true)
            setValidToken(_validating)
        }, 2000);
        return () => clearTimeout(timer1);

        
    }, [dispatch, validationToken, token, briefResponseId, storedResponse]);

    function Continue() {
        dispatch({
            type: UPDATE_BRIEF_RESPONSE_ANSWERS,
            payload: storedResponse
        });
        setQuestionNo(storedResponse?.current_question)
        setShowIntro(storedResponse?.intro_completed)
        setOpen(false)

        if (storedResponse?.visual_question) {
            setVisualQuestionNo(storedResponse?.visual_question)
        }
        if (storedResponse?.intro2_completed) {
            setShowIntro2(storedResponse?.intro2_completed)
        }
        // dispatch({
        //     type: UPDATE_BRIEF_RESPONSE_ANSWERS,
        //     payload: {
        //         ...storedResponse, 
        //     }
        // });

    }

    return (
        <Modal
            open={open}
            size='small'
        >
            <Header icon>

                {timed ? <>
                    {validToken ?
                        <Icon name='archive' />
                        : ""
                    }
                </>
                    : <Icon loading name='spinner' />
                }
                {defaultMessage?.header}

            </Header>
            <Modal.Content>
                <p>
                    {defaultMessage?.text}
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button color='grey' onClick={ResetSurvey}
                    id={'btn-existingdata-reset'}
                    size="small"
                >
                    <Icon name='remove' /> Restart survey
                </Button>

                <Button
                    disabled={!validToken}
                    color='green'  onClick={Continue}
                    size="small"
                    id={'btn-existingdata-resume'}>
                    <Icon name='checkmark' /> Load saved survey
                </Button>

            </Modal.Actions>
        </Modal>

    )
}

export default ExistingDataFound