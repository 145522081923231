import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Logo from 'assets/images/Logo blue.png';
import Smiley from 'assets/images/top-view-smiley-face-growth-arrow 1.png';
import Dial from './Dial';

const InitialReport = () =>{


    return (
        <Container>
           

            <Content>
                <IMG src={Logo}/>
                <Title>
                    <div style={{display:'flex',alignItems:'center'}}>ROI <IMG2 src={Smiley}/></div> 
                    <div>calculation</div> 
                    <div>results</div> 
                </Title>

                <Data>
                    <Block style={{marginBottom:40}}>
                        <Text>
                        <div><T2>Readiness for innerlogic</T2>
                        <P>Includes question 1, 2, 5, and 7 - 
                            the total score is out of 40 but can 
                            be averaged to be calculated out of 10
                             if we want to show the values beneath 
                             the dial visual/state what they scored.</P></div>
                        </Text>
                        <DialContainer>
                            <Dial text={'High'} borderPercentage={75}/>
                        </DialContainer>
                        
                        
                    </Block>

                    <Block>
                        <Text>
                        <div><T2>Projected ROI of innerlogic</T2>
                        <P>Includes question 1, 2, 5, and 7 - 
                            the total score is out of 40 but can 
                            be averaged to be calculated out of 10
                             if we want to show the values beneath 
                             the dial visual/state what they scored.</P></div>
                        </Text>
                        <DialContainer>
                            <Dial borderColor={'#F15062'} text={'Low'}/>
                        </DialContainer>
                        
                        
                    </Block>
                </Data>
            </Content>


            <Email>
                <T2>Want to get the extended readiness and ROI report?</T2>
                <P>Enter your work e-mail below</P>

                <InputContainer>
                    <EmailInput
                    type="email"
                    placeholder="your@email.com"
                    />
                    <SubmitButton>Submit</SubmitButton>
                </InputContainer>
            </Email>
        </Container>
    )
}

export default InitialReport;

const Container = styled.div`
    width:100%;
    padding:60px 20px;
    display:flex;
    flex-direction:column;
    align-items:center;

   
`

const IMG = styled.img`
    width:100px;
    position:absolute;
    left:60px;
    top:-60px;

    @media(max-width:768px){
        left:10px;
    }   
`

const IMG2 = styled.img`
    border-radius:50px;
    margin-left:20px;
    width:150px;

    @media(max-width:768px){
        width:100px;
    }
`


const Content = styled.div`
    width:100%;
    max-width:1200px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-top:60px;
    background: #F8FAFF;
    border-radius: 20px;
    padding: 60px;
    position:relative;

    @media (max-width: 768px) {
        flex-direction:column;
        padding:10px;
    }
`
const Title = styled.div`
    display:flex;
    font-family: 'Raleway';
    flex-direction:column;
    font-weight: 500;
    font-size: 60px;
    line-height:1.3;
    text-transform: uppercase;
    color: #2A3039;

    @media(max-width:768px){
        font-size: 40px;
    }
`

const Data = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;

    @media(max-width:768px){
        margin-top:50px;
    }
`

const Block = styled.div`
    display:flex;
    width:564px;
    justify-content:space-evenly;

    @media(max-width:768px){
        width:100%;
    }
`

const T2 = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 20px;
color: #2A3039;
line-height:1.3;
`

const P = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 125%;
color: #666D79;
max-width:220px;
margin-top:10px;
`

const DialContainer = styled.div`
    width:150px;
    margin-top:-20px;

`

const Text = styled.div`
    width:300px;
`

const Email = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;

    @media(max-width:768px){
        margin-top:50px;
        padding:10px;
      }
`

const EmailInput = styled.input`
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  font-family: 'Raleway';
font-style: normal;
font-weight: 400;
color: #666D79;
width:300px;

@media(max-width:768px){
    width:200px;
}
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  font-size: 14px;
  background-color: #2D70E2;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Raleway';

  &:hover {
    background-color: #45a049;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top:20px;

  
`;