import styled from "styled-components";
import React from "react";

const Box = styled.div`
  width: calc(100% - 30px);
  border-radius: 8px;
  background-color: white;
  margin-top: ${(props) => (props.visible != null ? "32px" : "10px")};
  max-height: ${(props) => (props.visible != null ? "300px" : "0px")};
  transition: max-height 1s ease;
  overflow: hidden;

  @media only screen and (min-width: 992px) {
    width: 401px;
  }
`;
const Answer = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: black;
  width: 100%;
  border-bottom: 1px solid white;
  padding: 15px;
  font-family: "Red Hat Display", sans-serif;
`;

const SubText = styled.textarea`
  width: calc(100% - 24px);
  color: black;
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  background-color: #d9d9d9;
  border-radius: 8px;
  border: none;
  padding: 15px;
  margin: 12px;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: black;
  }
`;

const CommentBox = (props) => {
 
  return (
    <Box visible={props.visible? true : undefined}>
      <Answer>{props.answer}</Answer>
      <SubText
        placeholder="Use this space to provide feedback and exapnd on your response"
        value={props.comment ? props.comment : ""}
        rows="5"
        onChange={(e) => props.setComment(e.target.value)}
      />
    </Box>
  );
};

export default CommentBox;
