// ... other imports ...

import styled from 'styled-components';
import React,{ useState } from 'react';
import Pic from 'assets/images/lock.png'
import {words} from '../translations'
import Checkbox from '../../SurveyComponents/Checkbox'

const EN25 = (<div><strong>Remember</strong> if you are under 19 years old, please complete this survey in the presence of an adult. This adult can be a parent or guardian.</div>)
const FR25 = (<div><strong>Rappel</strong> si vous avez moins de 19 ans, veuillez remplir ce sondage en présence d'un adulte. Cet adulte peut être un parent ou un tuteur.</div>)

const EN26 = ( <div><strong>Reminder</strong> - The Hockey Culture Index - Participant Survey is meant for players, parents/guardians, coaches, officials, volunteers, and administrators/operators of Minor Hockey Associations. Please note:
    <ul>
        <li>If you are a <strong>player</strong> between the ages of 14 - 18 years old, complete the survey with a parent/guardian.</li>
        <li>If you are a <strong>parent/guardian</strong> and your player is <strong>between 14 - 18 years old</strong>, please help them complete the survey from their perspective.</li>
        <li>If you are a <strong>parent/guardian</strong> and your player is <strong>under 14 years old</strong>, fill it out from your perspective (as a parent or guardian of that child)!</li>
    </ul></div>)

const FR26 = ( <div><strong>Rappel</strong> - L'indice de la culture du hockey - Enquête des participants est destiné aux joueurs, aux parents/tuteurs, aux entraîneurs, aux officiels, aux bénévoles et aux administrateurs/exploitants des associations de hockey mineur. Veuillez noter :
    <ul>
        <li>Si vous êtes un <strong>joueur</strong> âgé de 14 à 18 ans, remplissez le sondage avec un parent/tuteur.</li>
        <li>Si vous êtes un <strong>parent/tuteur</strong> et que votre joueur a <strong>entre 14 et 18 ans</strong>, aidez-le à remplir le sondage de son point de vue.</li>
        <li>Si vous êtes un <strong>parent/tuteur</strong> et que votre joueur a <strong>moins de 14 ans</strong>, remplissez-le de votre point de vue (en tant que parent ou tuteur de cet enfant) !</li>
    </ul></div>)

const Screen7 = ({language,disableNext}) =>{

    const [agree,setAgree] = useState(null);
    const [agree8,setAgree8] = useState(false);

    React.useEffect(()=>{
        disableNext(true)

        return ()=>disableNext(false)
    },[])


    React.useEffect(()=>{
        if(agree){
          setAgree8(false)
          disableNext(false)
        }
        else{
          disableNext(true)
        }
      },[agree])

    React.useEffect(()=>{
        if(agree8){
          disableNext(false)
        }
        if(!agree8 && agree===false){
            disableNext(true)
        }
       
    },[agree8])


    return (
        <Container>
             <Text>
           {words['intro7-0'][language]}
            </Text>

            <Reminder>
                {language=='FR'?FR26:EN26}
            </Reminder>

        <Checkbox label={language=='FR'?"Oui, j'ai plus de 19 ans":'Yes, I am 19 years old or older'} color={'black'} onChange={()=>setAgree(true)} checked={agree}/>
        <Checkbox label={language=='FR'?"Non, j'ai moins de 19 ans":'No, I am under 19 years old'} color={'black'} onChange={()=>setAgree(false)} checked={agree===false}/>


        <SubTitle expanded={agree===false}>
         <p>
          {language=='FR'?FR25:EN25}

         </p>
         <Checkbox label={language=='FR'?"Consentement du parent/tuteur en cochant cette case.":'Parent/guardian’s consent by checking this box.'} color={'black'} onChange={()=>setAgree8(!agree8)} checked={agree8}/>

         </SubTitle> 

        </Container>
       
    )
}

export default Screen7

const Text = styled.div`
font-weight: 500;
font-size: 24px;
line-height: 1.6;
color: #2A3039;
margin-bottom:24px;
`

const Container = styled.div`
    font-family: 'Raleway', sans-serif;
    line-height: 1.6;

    li{
        margin-bottom: 10px;
}
`

const Reminder = styled.div`
    font-size: 16px;
    margin-bottom: 24px;
`

const Linked = styled.a`
    text-decoration: underline;
    font-weight: 500;
    font-size: 16px;
    line-height: 128%;
    color: #2D70E2;
`

const Emoji = styled.img`
    height:40px;
    margin-bottom: 40px;
`

const SubTitle = styled.div`
	font-size: 16px;
	line-height: 1.17;
  color: black;
  margin-bottom:${props=>props.expanded?'20px':0};
  max-height:${props=>props.expanded?'1000px':0};
  overflow:hidden;
  transition:all 1s ease-in-out;
  margin-top:10px;



   @media only screen and (min-width:992px){
   margin-bottom:${props=>props.expanded?'10px':'10px'};
  }
`