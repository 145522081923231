import React from 'react';

import styled from 'styled-components';
// import format from "date-fns/format";

const FR1 = "Nous ne pouvons pas obtenir d'informations sur cette enquête"
const FR2 = "Cette enquête a été complétée"
const FR3 ="Cette enquête a été fermée et n'est plus accessible"
const FR4 = "Hmm! Il semble que cette enquête a expiré"

const End = ({ message, 
  // onTime, 
  status,language,background,organizationInfo,title }) => {
  // const [endDate, setEndDate] = useState(Date())
  // console.log( message, onTime, status)

  // console.log(status == 100)

  // useEffect(() => {
  //   if (onTime) {
  //     setEndDate(format(new Date(onTime), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"))
  //   }
  // }, [onTime])

  let CustomMessage = 'We are unable to get information about this survey'

  if(language === 1){
    CustomMessage = FR1
  }
  
  if (status === 3) {
    CustomMessage = 'This survey has been completed'
    if(language === 1){
      CustomMessage = FR2
    }
  }
  if (status > 4 && status < 11) {
    CustomMessage = 'This survey has been closed and is no longer accessible'
     if(language === 1){
      CustomMessage = FR3
    }
  }
  if (status === 100) {
    CustomMessage = 'Hmm! It looks like this survey has expired'
    if(language === 1){
      CustomMessage = FR4
    }
  }
  
// console.log(status > 4, CustomMessage)
  // const Start = format(onTime, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
  return (
    <Container color={background}>
      {organizationInfo?.survey_logo_end? (
        <div>
        <Logo src={organizationInfo?.survey_logo_end}/>
        <T>We know sport.</T>
        </div>
        ):<TitleTop show={true}>{language===1?'Indice de Culture':title?title:'Culture Index'}</TitleTop>}
      <Title>
        {message ? message : CustomMessage}. 

       {/* {endDate ? `${language===1?' le' : " on"} ${format(
          new Date(endDate.toLocaleString()),
          "EEEE, MMM do h:mm a"
        )}` : ''}*/}
      </Title>


    </Container>

  )
}


export default End


const Title = styled.div`
	font-size: 20px;

  margin-bottom:20px;
  color:white;
   line-height:1.3;
   max-width:654px;

   @media only screen and (min-width:992px){
   font-size: 20px;
   margin-bottom:50px;
  }
`
// const SubTitle = styled.div`
// 	font-size: 18px;
// 	line-height: 1.17;
//   color: white;

//   margin-bottom:20px;
//   max-width:654px;

//    @media only screen and (min-width:992px){
//    font-size: 72px;
//    margin-bottom:50px;
//    font-size: 24px;
//   }
// `

// const Next = styled.div`
// 	width: 170px;
//   height: 47px;
//    border-radius: 5px;
//   background-color: white;
//   color: #6a00ff;
//    font-size: 16px;
//   font-weight: bold;
//   font-family: 'Red Hat Display', sans-serif;
//   display:flex;
//   align-items:center;
//   justify-content:center;
//   cursor:pointer;
// `

const Container = styled.div`
  position:absolute;
  top:10%;
  padding:20px;
  background:${props=>props.color};


  @media only screen and (min-width:992px){
    left: 25%;
  top: 15%;
  }
`

const Logo = styled.img`
  height:40px;
 

  @media(max-width:768px){
    height:30px;
  }

`

const TitleTop = styled.div`
  font-size:40px;
  font-weight:bold;
  color:white;
  line-height:1;
  margin-bottom:20px;
`

const T = styled.div`
 margin-bottom:40px;
 color:#5A3080;
 font-size:20px;
 font-weight:bold;
 margin-left:70px;

 @media(max-width:768px){
   margin-left:0px;
 }
`

// const T2 = styled.div`
//   color:white;
//   margin-bottom:5px;
// `