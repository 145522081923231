import React from 'react';
// import styled from 'styled-components';
// import { Dropdown, Input } from 'semantic-ui-react'
// import Slider from '@material-ui/core/Slider';
// import { withStyles } from '@material-ui/core/styles';
import Selection from './Selection'
import Question from './Question'

// const Question_templates = [
// 	"It’s good to see your %s improving since our last check-in, however your %s is still below the team average. \nWhat would help improve your clarity even more moving forward?",
// 	"It’s good to see your %s score improving since our last check-in, and even better to see it remaining above the team average. \nWhat helps keep your clarity high within our team?",
// 	"It’s good to see your %s score improving since our last check-in, and even better to see it go above the team average. \nWhat has to continue happening to keep your clarity high?",
// 	"Your %s has decreased since our last check-in and remains below the team average. Let’s learn from this: \nWhy do you think your %s isn’t improving within our team?",
// 	"Your %s has decreased since our last check-in but is still above the team average. Let’s get curious about this: \nWhat do you think caused your %s to decrease?",
// 	"Your %s has decreased since our last check-in and is now below the team average. Let’s learn from this: \nWhat has changed to cause your %s to decrease and dip below the team average?"
// ]

// const areas = ['Trust', 'Clarity', 'Communication', 'Alignment', 'Resilience', 'Impact']

// const questions = ['Lately, i’ve been comfortable sharing my honest opinions and ideas on this team, even if they differ from others.',
// 	'I have a clear understanding of my role and what’s expected of me right nowon this team.',
// 	'Information and ideas are being expressed clearly, directly, and respectfully lately within our team.',
// 	'Lately on our team I feel like we share the same values.',
// 	'I’m able to handle difficulties and cope with challenges effectively right now.',
// 	'I understand how what I’m working on right now contributes to our team’s bigger mission or goal.'
// ]




const TeamHealth = (props) => {
	const { get_questions, previous_response,
		intQuestion, setIntQuestion,
		comment, setComment,
		answer, setAnswer, cultureAnswers,
		SaveAnswer, SubmitAnswer,
		currQuestion, maxQuestion } = props

	const [askFollowUp, setAskFollowUp] = React.useState(false)

	const [indResponse, setIndResponse] = React.useState(false)
	const [teamResponse, setTeamResponse] = React.useState(false)

	const getAnswer = (a) => {
		props.setAnswer(a)
		props.setQuestion()
	}
	// console.log(get_questions)
// console.log(get_questions.previous)

	React.useEffect(() => {
		setIntQuestion(get_questions?.questions.filter(
			p => p.id === currQuestion)[0])
		setAskFollowUp(get_questions?.previous)
		// 
	}, [get_questions, currQuestion, setIntQuestion])

	React.useEffect(() => {
		if (previous_response?.individual_response?.responses) {
			setIndResponse(previous_response?.individual_response?.responses.filter(p => p.id === currQuestion)[0])
		}

		if (previous_response?.team_response) {
			setTeamResponse(previous_response?.team_response.filter(p => p.id === currQuestion)[0])
		}

	}, [previous_response, currQuestion])

	// console.log(answer)
	// if (askFollowUp) {
	// 	console.log(askFollowUp?.find(({ level }) => level === 1))
	// }

	// console.log(indResponse)
	// console.log(teamResponse)
	// console.log(get_questions.questions)
	// console.log(get_questions.previous)
	// console.log(previous_response?.individual_response?.responses.filter(p => p.id === currQuestion)[0])
	// console.log(previous_response?.team_response.filter(p => p.id === currQuestion)[0])
	// ?.responses
	return (
		<>
			{!answer ?
				<Selection
					setStage={(a) => getAnswer(a)}
					text={intQuestion?.question}
					area={intQuestion?.area}
					setAnswer={setAnswer}
					cultureAnswers={cultureAnswers}
					currQuestion={currQuestion}
					prevScore={indResponse?.score}
				/>
				:
				<Question
					answer={answer}
					comment={comment}
					intQuestion={intQuestion}
					currQuestion={currQuestion}
					maxQuestion={maxQuestion}
					setComment={setComment}
					askFollowUp={askFollowUp}
					team_avg={Number(teamResponse?.score)} // team_avg
					previous_score={Number(indResponse?.score)} // previous_score
					area={intQuestion?.area}
					SaveAnswer={SaveAnswer}
					SubmitAnswer={SubmitAnswer}
				/>
			}
		</>
	)
	
}

export default TeamHealth
