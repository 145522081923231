import styled from "styled-components";
import React, { useState, useRef } from "react";
import CommentBox from "./comment_box_multi";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  ADD_LEADERSHIP_ANSWER,
} from "constants/actions";

import BlankImage from "assets/images/person_image.png"

const leadershipAnswersList = [
  "Not at all",
  "Not much",
  "Somewhat",
  "Mostly",
  "Absolutely",
];



const MultiLeaderItem = (props) => {
  const { selected, leaderSelected, question,
     } = props;
  const dispatch = useDispatch();
  const [answer, setAnswer] = useState(false);
  const [comment, setComment] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [fullName, setFullName] = React.useState(false);
  const [leaderPic, setLeaderPic] = React.useState(false);
  // const [visible, setVisible] = useState(null);
  const ourRef = useRef(null);



  React.useEffect(() => {
    setFullName(
      `${leaderSelected.first_name} ${leaderSelected.last_name}`
    );
    setLeaderPic(`https://storage.googleapis.com/innerlogic_staging_storage_2020/${leaderSelected.picture}`)
  }, [leaderSelected]);

  const { get_answers } = useSelector(
    (state) => ({
      get_answers: state.answers,
    }),
    shallowEqual
  );
  // console.log(leaderSelected)
  // https://storage.googleapis.com/innerlogic_staging_storage_2020/

  React.useEffect(() => {
    if (get_answers?.leadership_answers) {
      setAnswer(
        get_answers.leadership_answers.find(
          ({ id, leader_id }) =>
            id === question.id && leader_id === leaderSelected.leader_id
        )?.answer
      );
      // setShowComment(true)
      setComment(
        get_answers.leadership_answers.find(
          ({ id, leader_id }) =>
            id === question.id && leader_id === leaderSelected.leader_id
        )?.comments
      );
    }
  }, [get_answers, question, leaderSelected]);

  // function NoClick() {
  //   // optional error message
  // }

  React.useEffect(() => {
    if (answer && selected ) {
      setShowComment(true)
    } else {
      setShowComment(false)
    }
  },[answer, selected])
  // console.log(question);

  const StartAnswer = (item) => {
    // console.log(item);
    dispatch({
      type: ADD_LEADERSHIP_ANSWER,
      payload: {
        row_id: `${leaderSelected.leader_id}${question.id}`,
        leader_id: leaderSelected.leader_id,
        id: question.id,
        answer: item,
        comments: comment,
      },
    });
    
    setShowComment(true)
    // let response = [answer, comment];
    // setAnswer(null);
    // setComment(null);
    // if (selected) {
    //   setAnswer(item);
    //   setVisible(true);
    //   props.onCommentOpen(true);
    // }
  };

  const SaveAnswer = () => {
    // console.log(item);
    dispatch({
      type: ADD_LEADERSHIP_ANSWER,
      payload: {
        row_id: `${leaderSelected.leader_id}${question.id}`,
        leader_id: leaderSelected.leader_id,
        id: question.id,
        answer: answer,
        comments: comment,
      },
    });
    setShowComment(true)
    props.NextLeader()
    // let response = [answer, comment];
    setAnswer(null);
    setComment(null);
    if (selected) {
      // setAnswer(item);
      // setVisible(true);
      setShowComment(false)
      // props.onCommentOpen(true);
    }
  };

  // const onComplete = () => {
  //   setVisible(null);
  //   props.onCommentOpen();
  //   props.onComplete(false);
  // };

  // const selectAnswer = (item) => {};

  // console.log(selected)

  return (
    <LeaderContainer
      selected={selected}
      ref={ourRef}
      onClick={() => props.onClicked(leaderSelected.leader_id)}
    >
      <Leader>
        <Avatar src={leaderPic? leaderPic : BlankImage} />
        {selected ? (
          <Question>{question.member.replace("%s", fullName)}</Question>
        ) : (
          <Name>{fullName}</Name>
        )}
      </Leader>

      <CommentBox
        answer={leadershipAnswersList[answer - 1]}
        visible={showComment && showComment}
        comment={comment}
        setComment={(c) => setComment(c)}
        onComplete={() => SaveAnswer()}
      />

      <QuestionContainer selected={selected}>
        {[1, 2, 3, 4, 5].map((item, i) => {
          return (
            <QuestionItem
              i={item}
              answer={answer}
              onClick={() => StartAnswer(item)}
              key={item}
              selected={selected}
            >
              <Num>{item}</Num>
              <Rating selected={selected}>
                {leadershipAnswersList[item - 1]}
              </Rating>
            </QuestionItem>
          );
        })}
      </QuestionContainer>
      {/* <Highlight 
    top={false} 
    comment={showComment} 
    /> */}
    </LeaderContainer>
  );
};

export default MultiLeaderItem;

const QuestionContainer = styled.div`
  width: 100%;
  height: ${(props) => (props.selected ? "65px" : "25px")};
  display: flex;
  flex-direction: row;
  margin-bottom: ${(props) => (props.selected ? "20px" : 0)};
  z-index: 3;

  @media only screen and (min-width: 992px) {
    width: 100%;
    max-width: 600px;
    margin-top: 10px;
  }
`;

const QuestionItem = styled.div`
	width:18%;
	height:height:${(props) => (props.selected ? "85px" : "35px")};
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	font-family: 'Red Hat Display', sans-serif;
	border-radius:${(props) =>
    props.i === 1 ? "7px 0px 0px 7px" : props.i === 5 ? "0 7px 7px 0" : "none"};
	border:1px solid #4a4a4a;
	cursor:${(props) => (props.selected ? "pointer" : "auto")};
	background-color:${(props) => (props.answer === props.i ? "white" : "none")};
	color:${(props) => (props.answer === props.i ? "black" : "#4a4a4a")};

	@media only screen and (min-width:992px){
	    height:${(props) => (props.selected ? "85px" : "25px")};
	    width:20%;
	}
	`;

const Num = styled.div`
  font-size: 18px;
  font-weight: bold;

  @media only screen and (min-width: 992px) {
    font-size: 18px;
  }
`;
const Rating = styled.div`
  font-size: 10px;
  font-weight: 500;
  margin-top: 0px;
  display: ${(props) => (props.selected ? "flex" : "none")};

  @media only screen and (min-width: 992px) {
    font-size: 14px;
    margin-top: 2px;
    display: ${(props) => (props.selected ? "flex" : "none")};
  }
`;

const Avatar = styled.img`
  width: 45px;
  height: 45px;
  border: solid 2px #ffffff;
  border-radius: 22.5px;
  min-width: 45px;
`;

const LeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  transition: background-color 500ms ease-in;

  @media only screen and (min-width: 992px) {
    background-color:${(props) => (props.selected ? "#ffe175" : "0")};
    width: 600px;
    margin-top: ${(props) => (props.selected ? "40px" : "0")};
    padding: ${(props) => (props.selected ? "20px" : "10px")};
    z-index: 999;
  }
`;
const Name = styled.div`
  font-size: 18px;
  color: black;
  margin-left: 10px;
  font-weight: bold;
`;

const Leader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 3;
`;

const Question = styled.div`
  font-size: 14px;
  font-weight: bold;
  width: 700px;
  margin-left: 10px;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
  line-height: 1.6;
  margin-top: 14px;

  @media only screen and (min-width: 992px) {
    font-size: 18px;
  }
`;
