
import React from 'react';
import styled from 'styled-components';
// import { Dropdown, Input } from 'semantic-ui-react'
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';

import Pic from 'assets/images/score.png'

const PrettoSlider = withStyles({
  root: {
    color: 'grey',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#FA675C',
    color:'#FA675C',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);




const Selection = (props) => {
  const { prevScore, cultureAnswers, setStage, currQuestion } = props
  const [value, setValue] = React.useState(0)
  const [started, setStarted] = React.useState(false)


  const handleChange = (event, newValue) => {
    setValue(newValue);
    setStarted(true)
  };

  const getWord = () => {
    if (value === 0 && !started) {
      return ""
    }
    if (value < 20) {
      return 'Not at all'
    }
    if (value < 40) {
      return "Not much"
    }
    if (value < 60) {
      return "Somewhat"
    }
    if (value < 80) {
      return "Mostly"
    }
    return "Absolutely"
  }

  function getDifference() {

    if (value === 0 || !prevScore) {
      return ""
    }
    if (value - prevScore < 0) {
      return (<div style={{textAlign:'center'}}> 
      This is a&nbsp; <Change color='red'> {(value - prevScore)}% </Change> &nbsp;change from last week
      </div>)
    }


    if (value - prevScore > 0) {
      return (<div  style={{textAlign:'center'}}> 
     This is a&nbsp;<Change color='green'> +{(value - prevScore)}% </Change> &nbsp;change from last week
      </div>)
    }
    console.log('3')
    return (<div  style={{textAlign:'center'}}>
     This is a  {` ${(value - prevScore)}%`} change from last week
    </div>)

    
  }
  function NextQuestion() {
    if (value > 0) {
      setStage(value)
    }
    
  }
//   console.log(currQuestion)
// console.log(cultureAnswers)
React.useEffect(() => {
  const FoundAns = cultureAnswers.filter(ca => ca.id=== currQuestion)?.[0]
  if (FoundAns) {
    setValue(FoundAns.answer)
  }
  else {
    setValue(0)
  }
  
}, [cultureAnswers, currQuestion]);
  return (
    <Container>
      <Title>{props.area}</Title>
      <Question>{props.text}</Question>
      {/* <SubText>Please select at least 1 person and at most 10</SubText> */}

      <Anchor>
        <BackImg src={Pic}/>
        <Visual>{value?value+'%':""}</Visual>
        <Value> {getWord()}</Value>
        <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
         <ColoredValue>{getDifference(70)} </ColoredValue>
         </div>
       </Anchor>
      <SliderContainer>
        <PrettoSlider
          valueLabelDisplay={"off"}
          aria-label="pretto slider"
          value={value}
          onChange={handleChange}
        />
      </SliderContainer>
     
      <ButtonContainer>
        <Button 
        enabled={value > 0} 
        onClick={NextQuestion}>
          Next
        </Button>
      </ButtonContainer>
    </Container>
  )

}

export default Selection






const Container = styled.div`
   top:50px;
   position: absolute;
   width:90%;
   display:flex;
   flex-direction:column;

   @media(max-width:768px){
    height:calc(100vh - 50px);
    top:50px;
    display:flex;
    flex-direction:column;
  }

   @media(max-height:600px){
     top:50px;
   }

    @media(min-width:992px) and (min-height:850px){
    top:200px;
  }

  
`

const Visual = styled.div`
  width:700px;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  justify-content:center;
  font-size:40px;
  font-weight:bold;
  line-height:1;
  margin-bottom:5px;
  height:40px;

  @media(max-width:768px){
    width:100%;
  }
`

const BackImg = styled.img`
  position:absolute;
  width:250px;
  height:250px;
   top:-55%;
   left:calc(350px - 125px);

  @media(max-width:768px){
     left:calc(50% - 125px);
 
  }
 

  @media(max-height:640px){
    width:250px;
    height:250px;
    left:calc(50% - 125px);
    top:-55%;
  }

`

const Anchor = styled.div`
  position:relative;
  margin-top:100px;
  margin-bottom:80px;
  width:700px;
 

  @media(max-width:768px){
     margin-top:50px;
    margin-bottom:80px;
    width:100%;
  }

  @media(max-height:600px) and (min-width:768px){
    margin-top:80px;
    margin-bottom:50px;
  }

  @media(max-height:600px) and (max-width:768px){
    margin-top:30px;
    margin-bottom:50px;
  }
`

const Question = styled.div`
font-size: 16px;
font-weight: bold;
width: 700px;
font-family: 'Red Hat Display', sans-serif;
line-height: 1.6;
margin-top:14px;
max-width:95%;
margin-bottom:10px;


@media only screen and (min-width:992px) and (max-height:700px){
   font-size: 20px;
    margin-top:28px;
}

@media only screen and (min-width:992px) and (min-height:700px){
   font-size: 23px;
    margin-top:28px;
}

@media (max-width:768px){
	margin-bottom:60px;
	font-size:16px;
	font-weight:bold;
}
`

const Change = styled.span`
color:${props => props.color ? props.color : "grey"};
font-weight: 800;

`


const Title = styled.div`
font-size: 16px;
font-family: 'Red Hat Display', sans-serif;
width: 700px;


@media(max-width:768px){
	font-size:14px;
}
`

const SliderContainer = styled.div`
	max-width:700px;

`

const Value = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	width:700px;
	display:flex;
	justify-content:center;
	height:25px;
		font-size:20px;

      @media(max-width:768px){
    width:100%;
  }
`
const ColoredValue = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	width:130px;
	display:flex;
	justify-content:center;
  align-items:center;
	height:50px;
	margin-bottom:20px;
		font-size:12px;
	color:# ${props => props.color ? "#7F7F7F" : props.color};
  white-space:pre-wrap;

  @media(max-width:768px){
    width:130px;
  }
`

const Button = styled.div`
height: 40px;
border-radius: 10px;
background-color: ${props => props.enabled ? "#6a00ff" : "grey"}; 
color:white;
font-size: 16px;
font-weight: bold;
font-family: 'Red Hat Display', sans-serif;
display:flex;
justify-content:center;
align-items:center;
cursor:${props => props.enabled ? "pointer" : "not-allowed"};
width:100px;
margin-top:50px;

@media (max-height:600px){
  margin-top:20px;
}
`
const ButtonContainer = styled.div`
	width:700px;
`