// ... other imports ...

import styled from 'styled-components';
import React,{ useState } from 'react';
import Pic from 'assets/images/lock.png'
import {words} from '../translations'
import Checkbox from '../../SurveyComponents/Checkbox'

const EN25 = "If you are under the age of 16, you must complete this assessment in the presence of an adult (aged 18 years or older). This adult can be a parent, guardian."
const FR25 = "Si vous avez moins de 16 ans, vous devez effectuer cette évaluation en présence d'un adulte (âgé de 18 ans ou plus). Cet adulte peut être un parent."



const Screen5 = ({language,disableNext}) =>{

    const [agree,setAgree] = useState(null);
    const [agree8,setAgree8] = useState(false);

    React.useEffect(()=>{
        disableNext(true)

        return ()=>disableNext(false)
    },[])


    React.useEffect(()=>{
        if(agree){
          setAgree8(false)
          disableNext(false)
        }
        else{
          disableNext(true)
        }
      },[agree])

    React.useEffect(()=>{
        if(agree8){
          disableNext(false)
        }
        if(!agree8 && agree===false){
            disableNext(true)
        }
       
    },[agree8])


    return (
        <Container>
             <Text>
           {words['intro5-0'][language]}
            </Text>

        <Checkbox label={language=='FR'?"Oui, j'ai plus de 16 ans":'Yes, I am over the age of 16'} color={'black'} onChange={()=>setAgree(true)} checked={agree}/>
        <Checkbox label={language=='FR'?"Non, j'ai moins de 16 ans":'No, I am under the age of 16'} color={'black'} onChange={()=>setAgree(false)} checked={agree===false}/>


        <SubTitle expanded={agree===false}>
         <p>
          {language=='FR'?FR25:EN25}

         </p>
         <Checkbox label={language=='FR'?"Consentement du parent/tuteur en cochant cette case.":'Parent/guardian’s consent by checking this box.'} color={'black'} onChange={()=>setAgree8(!agree8)} checked={agree8}/>

         </SubTitle> 

        </Container>
       
    )
}

export default Screen5

const Text = styled.div`
font-weight: 500;
font-size: 24px;
line-height: 1.6;
color: #2A3039;
margin-bottom:24px;
`

const Container = styled.div`
    font-family: 'Raleway', sans-serif;
`

const Linked = styled.a`
    text-decoration: underline;
    font-weight: 500;
    font-size: 16px;
    line-height: 128%;
    color: #2D70E2;
`

const Emoji = styled.img`
    height:40px;
    margin-bottom: 40px;
`

const SubTitle = styled.div`
	font-size: 18px;
	line-height: 1.17;
  color: black;
  font-family: 'Barlow', sans-serif;
  margin-bottom:${props=>props.expanded?'20px':0};
  max-height:${props=>props.expanded?'1000px':0};
  overflow:hidden;
  transition:all 1s ease-in-out;


   @media only screen and (min-width:992px){
   margin-bottom:${props=>props.expanded?'10px':'10px'};
   font-size: 18px;
  }
`