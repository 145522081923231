import styled from "styled-components";
import React from "react";
import { useDispatch } from "react-redux";
import { SET_ANSWERS } from "constants/actions";
import MoodCard from "./MoodCard";
import MoodSelect from "./MoodSelect";

const MoodQuestion = (props) => {
  const { moodQuestions } = props;
  const dispatch = useDispatch();
  const [flipped, setFlip] = React.useState(null);
  const [emotions, setEmotion] = React.useState([]);
// console.log(emotions)
// console.log(moodQuestions)
  const selectEmotion = (selected) => {

    if (emotions.length < 3) {
      if (emotions.filter((p) => p.id === selected.id)[0]) {
        setEmotion(emotions.filter((em) => em.id !== selected.id));
      } else {
        let _emot = emotions;
        // _emot.push(selected)
        _emot.push({
          sort: emotions.length + 1,
          id: selected.id,
          name: selected.name,
          color: selected.color,
          score: selected.score,
          feeling: selected.feeling,
        });
        setEmotion(_emot.sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort)));
      }
      setFlip(null);
    }

    dispatch({
      type: SET_ANSWERS,
      payload: { mood_answers: emotions.sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort)),
         },
    });
  };

  const remove = (emotion_id) => {
    setEmotion(emotions.filter((em) => em.id !== emotion_id));
    dispatch({
      type: SET_ANSWERS,
      payload: { 
        mood_answers: emotions.filter((em) => em.id !== emotion_id)
      },
      
    });
  };


  return (
    <Container>
      <Question>
        In relation to work lately, what emotions best describe how you’ve been
        feeling?
      </Question>

      <Pick>Pick up to 3 emotions</Pick>

      <EmotionContainer>
        {emotions.sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort)).map((item, i) => (
          <MoodSelect item={item} remove={remove} key={i} />
        ))}
      </EmotionContainer>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
          marginBottom: 26,
          maxWidth: 650,
        }}
      >
        {moodQuestions
          ? moodQuestions.map((item, i) => {
              return (
                <MoodCard
                  mood={item}
                  flipped={flipped}
                  i={i}
                  setFlip={(x) => setFlip(x)}
                  selectEmotion={(e) => selectEmotion(e)}
                  selected={emotions}
                  key={i}
                />
              );
            })
          : ""}
      </div>

      {emotions.length === 0 ? (
        <Filler />
      ) : (
        <Next onClick={() => props.askComments(emotions)}>Next</Next>
      )}
    </Container>
  );
};

export default MoodQuestion;

const Container = styled.div`
  position:absolute;
  top:30px;

  @media only screen and (min-width: 992px) {
     top:10vh;
     min-height:750px;
  }
`

// const TopText = styled.div`
//   color: #4a4a4a;
//   font-size: 18px;
//   font-weight: 500;
//   font-family: "Red Hat Display", sans-serif;
//   margin-bottom: 10px;
// `;

const Question = styled.div`
  color: #4a4a4a;
  font-size: 23px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: bold;
  max-width: 672px;
  line-height: normal;
  margin-bottom: 10px;

  @media(max-height:600px){
    font-size:16px;
  }

  @media only screen and (min-width: 992px) {
    font-size: 23px;
    margin-bottom: 20px;
  }

  @media (max-height:900){
    margin-bottom: 10px;
  }
`;

const Pick = styled.div`
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;

  @media only screen and (min-width: 992px) {
    margin-bottom: 10px;
  }
`;

const EmotionContainer = styled.div`
  height: 55px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 5px;

  @media only screen and (min-width: 768px) and (max-height:750px){
   height:30px;
  }

  @media only screen and (min-width: 992px) and (min-height:750px){
    margin-bottom: 30px;
    height: 50px;
  }


`;

const Next = styled.div`
  width: 111px;
  height: 47px;
  border-radius: 5px;
  background-color: #fff0cf;
  color: #4a4a4a;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  cursor: pointer;

   @media only screen and (min-width: 768px) and (max-height:850px) {
    margin-top: -20px;
  }

  @media only screen and (min-width: 992px) and (min-height:850px) {
    margin-top: 20px;
  }
`;

const Filler = styled.div`
  height: 47px;
  margin-top: 10px;
  @media only screen and (min-width: 992px) {
    margin-top: 90px;
  }
`;

// const moods = [
//   "Curiosity",
//   "Enjoyment",
//   "Motivation",
//   "Discontent",
//   "Stress",
//   "Anger",
// ];
