import React from "react";
import styled from "styled-components";

import Emotion from "./Emotion";

const Card = styled.div`
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 #ececec;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  transform: rotateY(180deg);
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
`;


const FlippedCard = (props) => {

  return (
    <Card>
      {props.values.map((item, i) => {
           return (
           <Emotion
          mood={item}
          color={props.color}
          i={i}
          selected={props.selected.find(({id}) => id === item.id)}
          selectEmotion={(e) => props.selectEmotion(e)}
          key={i}
        />
        )
      }
       
      )}
    </Card>
  );
};

export default FlippedCard;
