import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SET_ANSWERS, SUBMIT_ANSWER } from "constants/actions";

import MoodQuestion from "./Selection/index";
import Reflection from "./Reflection/index";
import styled from "styled-components";
import Intro from "./Intro";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { TRANSITIONSPEED } from "constants/animation"

import "survey/survey.css";

import {
  // BrowserView,
  // MobileView,
  isBrowser,
  isMobile,
  deviceType,
  osName,
  osVersion,
  deviceDetect,
} from "react-device-detect";

const SurveyContainer = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  box-sizing: border-box;
  min-height:750px;

  @media only screen and (min-width: 992px) {
    padding: 30px;
  }
`;
const deviceDetected = deviceDetect();
const Mood = (props) => {
  const dispatch = useDispatch();

  const { moodQuestions } = props;
  const [moodAnswers, setMoodAnswer] = React.useState({});
  const [q, setQ] = React.useState(0);

  const { get_answers } = useSelector(
    (state) => ({
      get_answers: state.answers,
    }),
    shallowEqual
  );

  const SubmitAnswers = (selectedMood) => {
    // check for next required survey on the list
    // dispatch({type: ADD_MOOD_ANSWER, payload : selectedMood})
    dispatch({
      type: SUBMIT_ANSWER,
      payload: {
        mood_answers: get_answers.mood_answers,
        employee: get_answers.employee,
        token: get_answers.token,
        device: deviceDetected,
        mobile: isMobile ? "Yes" : "No",
        browser: isBrowser ? "Desktop" : "Other Devise",
        operating_system: osName,
        user_ip: get_answers.user_ip,
        mood_survey_compliance: get_answers.mood_survey_compliance,
        mood_high_ans: 3,
      },
    });

    dispatch({
      type: SET_ANSWERS,
      payload: {
        curr_survey_type: get_answers.all_survey_types[1],
        survey_type: get_answers.all_survey_types[1],
        all_survey_types: get_answers.all_survey_types.slice(1),
      },
    });
  };

  function startSurvey() {
    window.scrollTo(0, 0)
    dispatch({
      type: SET_ANSWERS,
      payload: {
        mood_cur_ques: 0,
        mood_ques_start: true,
        isBrowser: isBrowser,
        isMobile: isMobile,
        browser: deviceDetected.browserName,
        deviceType,
        osVersion,
        osName,
        deviceDetected: deviceDetected,
        mood_high_ans: 2,
      },
    });
    setQ(q + 1);
  }

  React.useEffect(() => {
    if (get_answers?.mood_answers.length > 1) {
      setMoodAnswer(
        get_answers.mood_answers.sort(
          (a, b) => parseFloat(a.id) - parseFloat(b.id)
        )
      );
    } else {
      setMoodAnswer(get_answers.mood_answers);
    }
  }, [get_answers]);

  function askComments(e) {
    // console.log(e)
    // setMoodAnswer(e)
    window.scrollTo(0, 0)
    setQ(q + 1);
    dispatch({
      type: SET_ANSWERS,
      payload: {
        mood_high_ans: 3,
      },
    });
  }

  function ShowResponse() {
    if (q === 0) {
      return (
        <Intro next={startSurvey} key={q} role={get_answers?.employee_role} />
      )
    }
    if (q === 1) {
      return (
        <MoodQuestion
                moodQuestions={moodQuestions?.moods}
                askComments={askComments}
                key={q}
              />
      )
    }
    if (q === 2) {
      return (
              <Reflection
                answers={moodAnswers}
                SubmitAnswers={SubmitAnswers}
                key={q}
              />
      
    )
    }
  }

  return (
    <SurveyContainer>
      <SwitchTransition>
        <CSSTransition
          key={q}
          timeout={TRANSITIONSPEED}
          classNames="SlideUp"
        >
          {ShowResponse()}
        </CSSTransition>
      </SwitchTransition>

    </SurveyContainer>
  );
};

export default Mood;
