import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {ArrowIcon} from './ArrowIcon'

import Select from "react-select";

 const style = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? 0 : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0
      }
    })
  };

const CategoryQuestions = (props) => {
	const { prevResponse, 
			data, 
			employeeCategory,
			language,
			textColor } = props

	const [quesResponse, setQuesResponse] = useState(null)
	const [formatOptions, setFormatOptions] = useState([])
	const [hasValue, setHasValue] = useState(false)

	useEffect(() => {
		if(language===1){
				setFormatOptions(data.answers.map((item) => {
			return {
				text: 'fr'in item? item.fr? item.fr : item.name : item.name,
				value: item.id,
			}
			}))
		}else{
			setFormatOptions(data.answers.map((item) => {
			return {
				text: item.description? item.name + ' - '+item.description:item.name,
				value: item.id,
			}
		}))
		}

	}, [data, quesResponse,language]);

	// console.log(employeeCategory)
	// console.log(data)

	useEffect(() => {
		const EmployeeCat = employeeCategory.filter(ec => ec?.name_id === data?.id)?.[0]
		// const EmployeeCat = employeeCategory.find(
		// 	({ name_id }) => name_id === data?.id)
		setQuesResponse(null)
		if (EmployeeCat) {
			
			setQuesResponse(data.answers.filter(
				an => an?.id === EmployeeCat?.value_id
				).map((item) => {
				return {
					text: item.name,
					value: item.id
				}
			})[0])
		}
	}, [employeeCategory, data]);
	

	useEffect(() => {
		setHasValue(false)
		if (quesResponse) {
			setHasValue(true)
		}
	}, [quesResponse]);


	useEffect(() => {
		if (prevResponse?.response !== null) {
			if (!quesResponse) {
				setQuesResponse(formatOptions.filter(
					so => so.value === prevResponse?.response)[0])
			}
		}
	}, [prevResponse, formatOptions, quesResponse]);

	// console.log(quesResponse?.value)
	// console.log(formatOptions)

	const next = () => {


		if(hasValue){
				props.save({
				id: data.id,
				response: quesResponse?.value,
				// question: props.data.question,
				subfactor: data?.subfactor,
				priority: data?.priority,
				role: data?.role,
				reverse: data?.reverse,
			})
			
			setQuesResponse(null)
			setHasValue(false)
		}
		

	}

	// console.log(formatOptions)

	const getQuestion = () => {
		return (
			<>
				<Text>{language===1 && 'fr' in data?data.fr:data.question}</Text>
				<DropDownContainer>
					<Select
						placeholder={language===1 && 'fr' in data?"Sélectionner une option":"Select an option"}
						onChange={setQuesResponse}
						value={quesResponse}
						options={formatOptions}
						styles={style}
						// isLoading={loading}	
						getOptionLabel={(option) => option.text}
						getOptionValue={(option) => option.value}
					/>
				</DropDownContainer>
			</>)
	}

	return (
		<SurveyContainer>
			{getQuestion()}

				<Next
				color={props.textColor}
				onClick={() => next()}
				disabled={!hasValue}
				id="next">

				 <Image>
	                {ArrowIcon(textColor=='#5A3080'?'#5A3080':'#09E1C0')}
	         </Image>
		
			</Next>

		</SurveyContainer>
	)
}


export default CategoryQuestions


const SurveyContainer = styled.div`
  padding: 0px;
  box-sizing: border-box;
  position: absolute;
  left: 0%;
  top: 10%;
  padding: 20px;
  width:100%;
  max-width:650px;


   @media only screen and (min-width: 768px) {
    padding: 30px;
    left: calc(50% - 350px);
    top: 15%;
  }
`;

const DropDownContainer = styled.div`
  padding: 0px;
  left: 0%;
  top: 10%;

  width:600px;
  max-width:100%;

  @media only screen and (min-width: 992px) {

    left: 25%;
    top: 15%;
  }
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: white;
  margin-top: 14px;
  width:100%;
  max-width:600px;
  line-height:1.3;
  margin-bottom:30px;

  @media only screen and (min-width: 992px) {
    font-size: 23px;
    margin-top: 28px;
  }
`;


const Button = styled.button`
  height: 40px;
  border-radius: 10px;
  background-color: white;
  border: 0;
  box-shadow: none;
  color: ${props=>props.color};
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100px;
  margin-top: 50px;
  // display: none;

  :disabled{
  	background-color:lightgrey;
  	color:grey;
  	cursor:not-allowed;
  	opacity:0.5;
  }


  @media only screen and (min-width: 992px), (min-height: 760px) {
    display: flex;
  }
`;

const Image = styled.div`
  height:50px;
  cursor:pointer;
`

const Next = styled.div`

  cursor:pointer;
  border: none;
  font-family: 'Poppins', sans-serif;
  width:100%;
  display:flex;
  justify-content:flex-end;
 	opacity:${props=>props.disabled?0.3:1};
 	margin-top:30px;
`