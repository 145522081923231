import React from "react";
import styled from "styled-components";
import Selection from "./Selection";


const Emotion = (props) => {
// console.log(props.selected)
  return (
    <Container end={props?.i} 
    onClick={() => props.selectEmotion(props.mood)}
    >
      <ColorStrip color={props.color + "," + (5 - props.i) / 5} />
      <Selection color={props.color} selected={props.selected} />
      {props.mood.name}
    </Container>
  );
};

export default Emotion;

const Container = styled.div`
  height: 20%;
  width: 100%;
  border-bottom: ${(props) => (props.end === 4 ? "1px solid #e9e9e9" : null)};
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  padding-left: 15px;
  font-family: "Red Hat Display", sans-serif;
  font-size: 12px;

  @media only screen and (min-width: 992px) {
    padding-left: 15px;
    font-size: 14px;
  }
`;

const ColorStrip = styled.div`
  height: 100%;
  width: 8px;
  background-color: ${(props) => "rgba(" + props.color + ")"};
  position: absolute;
  left: 0px;
  top: 0px;
`;