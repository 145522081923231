import React from 'react';
import styled, { keyframes } from 'styled-components';


const fadeInOut = keyframes`
  0%, 20%, 70%, 100% {
    opacity: 0;
  }
  21%, 69% {
    opacity: 1;
  }
`;

const Container = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width:100%;
  `

const LogAnimationContainer = styled.div`
  height:50px;
  path {
    opacity: 0;
    animation: ${fadeInOut} 2s linear infinite;
    animation-fill-mode: forwards;
  }

  path:nth-child(1) {
    animation-delay: 0s;
  }

  path:nth-child(2) {
    animation-delay: 0.1s;
  }

  path:nth-child(3) {
    animation-delay: 0.2s;
  }

  path:nth-child(4) {
    animation-delay: 0.3s;
  }

  path:nth-child(5) {
    animation-delay: 0.4s;
  }
`;


const LogAnimation = () => {
  return (
    <Container>
    <LogAnimationContainer>
      <svg width="100%" height="100%" viewBox="0 0 139 167" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.2897 36.3891V104.359H0V36.3891H14.2897ZM14.2897 115.535V131.261H0V115.535H14.2897Z" fill="url(#paint0_linear_934_7256)"/>
       
       
       
      
        <path d="M31.1776 12.3463H45.4673V155.303H31.1776V12.3463Z" fill="url(#paint3_linear_934_7256)"/>
        <path d="M93.5327 12.3463H107.822V155.303H93.5327V12.3463Z" fill="url(#paint4_linear_934_7256)"/>
        <path d="M62.3551 89.0233H76.6449V167H62.3551V89.0233Z" fill="url(#paint1_linear_934_7256)"/>
        <path d="M124.71 131.261V63.2911H139V131.261H124.71ZM124.71 52.1144V36.3891H139V52.1144H124.71Z" fill="url(#paint5_linear_934_7256)"/>
        <path d="M62.3551 0H76.6449V77.9767H62.3551V0Z" fill="url(#paint2_linear_934_7256)"/>
        
       
        <defs>
        <linearGradient id="paint0_linear_934_7256" x1="139" y1="166.961" x2="-1.6685e-06" y2="166.961" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3F86FE"/>
        <stop offset="1" stopColor="#3F86FE" stopOpacity="0.3"/>
        </linearGradient>
        <linearGradient id="paint1_linear_934_7256" x1="139" y1="166.961" x2="-1.6685e-06" y2="166.961" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3F86FE"/>
        <stop offset="1" stopColor="#3F86FE" stopOpacity="0.3"/>
        </linearGradient>
        <linearGradient id="paint2_linear_934_7256" x1="139" y1="166.961" x2="-1.6685e-06" y2="166.961" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3F86FE"/>
        <stop offset="1" stopColor="#3F86FE" stopOpacity="0.3"/>
        </linearGradient>
        <linearGradient id="paint3_linear_934_7256" x1="139" y1="166.961" x2="-1.6685e-06" y2="166.961" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3F86FE"/>
        <stop offset="1" stopColor="#3F86FE" stopOpacity="0.3"/>
        </linearGradient>
        <linearGradient id="paint4_linear_934_7256" x1="139" y1="166.961" x2="-1.6685e-06" y2="166.961" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3F86FE"/>
        <stop offset="1" stopColor="#3F86FE" stopOpacity="0.3"/>
        </linearGradient>
        <linearGradient id="paint5_linear_934_7256" x1="139" y1="166.961" x2="-1.6685e-06" y2="166.961" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3F86FE"/>
        <stop offset="1" stopColor="#3F86FE" stopOpacity="0.3"/>
        </linearGradient>
        </defs>
        </svg>

    </LogAnimationContainer>
    </Container>
  );
};

export default LogAnimation;