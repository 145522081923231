import React from "react";
import styled from "styled-components";

const Intro = (props) => {
  const { leaderList } = props;
  function StartSurvey() {
    props.next();
  }

  return (
    <Container>
      <Title>Leadership Pulse</Title>
      <SubTitle>
       Your responses in this section are completely anonymous.
      </SubTitle>
      {/* missing no of team members your [12] team members */}
      {leaderList.map((p, i) => {
        return (
          <SubTitle key={i}>
            <div style={{marginBottom:5,fontWeight:'bold'}}>Leader's being evaluated:</div>
            {p.first_name} {p.last_name}
          </SubTitle>
        );
      })}
      <Next onClick={() => StartSurvey()}>Start</Next>
    </Container>
  );
};

export default Intro;

const Title = styled.div`
  font-size: 50px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 20px;
  color: #4a4a4a;
  line-height: 1;

  @media only screen and (min-width: 992px) {
    font-size: 72px;
    margin-bottom: 50px;
  }
`;
const SubTitle = styled.div`
  font-size: 18px;
  line-height: 1.17;
  color: #4a4a4a;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 20px;
  max-width: 654px;

  @media only screen and (min-width: 992px) {
    font-size: 72px;
    margin-bottom: 50px;
    font-size: 24px;
  }
`;

const Next = styled.div`
  width: 170px;
  height: 47px;
  border-radius: 5px;
  background-color: white;
  color: black;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Container = styled.div`
  margin-top: 20vh;
  position: absolute;
  width: calc(100vw - 30px);
  left:0px;

  @media only screen and (min-width: 992px) {
    margin-top: 20vh;
  }
  @media only screen and (max-width:992px){
    left:10px;
  }
`;

// const InnerContainer = styled.div`
//   float: left;
//   width: 33.33%; /* three boxes (use 25% for four, and 50% for two, etc) */
//   padding: 50px;
//   word-wrap: break-word;
// `;
