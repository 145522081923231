import {
  PENDING_DEBRIEF_SURVEY,
  DEBRIEF_SURVEY,
  ADD_EMPLOYEE_TO_DEBRIEF_SURVEY,
  CLEAR_DEBRIEF_SURVEY,
} from "constants/actions";

const initialState = {
  pending: false,
  debrief_survey: {},
  employee_added: false,
};

export default function DebriefSurvey(state = initialState, action) {
  switch (action.type) {
    case PENDING_DEBRIEF_SURVEY:
      return {
        ...state,
        pending: true,
      };

    case DEBRIEF_SURVEY:
      return {
        ...state,
        pending: false,
        debrief_survey: action.payload,
      };
    case ADD_EMPLOYEE_TO_DEBRIEF_SURVEY:
      return {
        ...state,
        pending: false,
        employee_added: action.payload,
      };
    case CLEAR_DEBRIEF_SURVEY:
      return {
        ...state,
        pending: false,
        debrief_survey: {},
      };

    default:
      return state;
  }
}
