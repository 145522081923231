import React, { useEffect, useState, useRef } from "react";
import styled,{keyframes} from "styled-components";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { Modal,Button,Loader } from 'semantic-ui-react'
import "../survey.css";
import Typewriter from 'typewriter-effect';
import {REVERSE} from "data/reverseScores"
import { nextTick } from "process";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_GET_CHAT_GPT_SUMMARY,
} from "constants/actions";
import {getPromptLanguage,getBottomQuestionLanguage,getTopQuestionLanguage} from './GPTprompts/index'
import { useTranslation } from 'react-i18next';

import { v4 as uuidv4 } from 'uuid';
const NewValidationToken = uuidv4()

const CommentsFeedback = ({ ruleFeedback,
  setCompletedRuleFeedback,
  setRuleFeedbackComplete,
  language,
  textColor,
  setAskFeedback,
  setComplete }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // console.log(ruleFeedback)
  const [totalQues, setTotalQues] = useState(0)
  const [currQues, setCurrQues] = useState(0)
  const [open, setOpen] = useState(false)
  const [closed, setClosed] = useState(false)
  const [phase, setPhase] = useState(0)
  const [phase2resp, setPhase2resp] = useState('')
  const [phase2Ques, setPhase2Ques] = useState('')
  const [finish, setFinish] = useState(0)
  const [loading,setLoading] = useState(true)
  const timeout = useRef(null)
  const [prompt,setPrompt] = useState('')


  const [respValue, setRespValue] = useState('')
  const [responseList, setResponseList] = useState([])
  const [getResponse, setGetResponse] = useState({})

  const { get_chat_gpt } = useSelector(
    (state) => ({
      get_chat_gpt: state.chatgpt,
    }),
    shallowEqual
  );

  useEffect(() => {
    if(get_chat_gpt?.response_pending === true){
      timeout.current = setTimeout(()=>{
          setLoading(false)
          setFinish(0)
          setPhase(0)
          save()
      },15000)
    }

    if(get_chat_gpt?.response_pending === false){
      clearTimeout(timeout.current)
      timeout.current = null
    }
    setPhase2Ques(get_chat_gpt.chat_gpt_summary?.response)
    setLoading(get_chat_gpt?.response_pending)


    return () => {
      clearTimeout(timeout.current)
    }

  },[get_chat_gpt])

  function SubmitChatGpt() {
    let _prompt = getPromptLanguage(language, getResponse, respValue)
    // console.log(_prompt)
    setPrompt(_prompt)
    dispatch({
      type: SG_GET_CHAT_GPT_SUMMARY,
      payload: {
        payload: _prompt,
        token: NewValidationToken
      }
    });
  }

  useEffect(() => {
    setTotalQues(ruleFeedback?.length)
  }, [ruleFeedback]);

  useEffect(() => {
    setGetResponse(ruleFeedback[currQues])
  }, [ruleFeedback, currQues]);

  // console.log("responseList", responseList)
  
  const save = () => {
    const _resList = responseList
    _resList.push({
      ...getResponse,
      feedback: respValue,
      follow_up: phase2resp,
      follow_up_question: phase2Ques,
      prompt
    })
    setResponseList(_resList)

    if (currQues + 2 > totalQues) {
      setCompletedRuleFeedback(_resList)
      setRuleFeedbackComplete(true)
      setAskFeedback(false)
      setComplete(true)

      return
    }

    if (currQues + 1 <= totalQues) {
      setCurrQues(currQues + 1)
    }


  
    setRespValue('')
    setPhase2Ques('')
    setPhase2resp('')
    // 
  }

  const next = () =>{
    // console.log("next clicked")


    if(phase == 0 && (respValue.length > 0) && getResponse.type === 'min'){
      SubmitChatGpt()
      setPhase(1)
      setFinish(0)
    }
    else if((phase==0 && (respValue.length > 0 || closed)) || (phase==1 && (phase2resp.length> 0 || closed))){
      save()
      setFinish(0)
      setPhase(0)
    }else{
      setOpen(true)
    }
  }

  const close = () =>{
    setOpen(false)
    setClosed(true)
  }


  const getQuestion = () => {
   return getBottomQuestionLanguage(language, getResponse)
  }

  const getTopQuestion = () =>{
    // console.log("getTopQuestionLanguage", getTopQuestionLanguage(language, getResponse),language)
    return getTopQuestionLanguage(language, getResponse)
  }

  const getTypeWriter = (i) =>{
    if(i <= finish && i==1){
      document.querySelectorAll(".Typewriter__cursor")[0].style.display = "none"
      return <Typewriter
      onInit={(typewriter) => {
        typewriter.changeDelay(17)
        .typeString(language && language === 1 ? getResponse?.fr : getResponse?.question)
        .pauseFor(100)
        .callFunction(() => {
          setFinish(2)
        })
        .start();
      }}
    />
    }

    if(i<=finish && i==2){
      document.querySelectorAll(".Typewriter__cursor")[1].style.display = "none"
      return <Typewriter
      onInit={(typewriter) => {
        typewriter.changeDelay(17)
        .typeString(getQuestion())
        .callFunction(() => {
          setFinish(3)
        })
        .start();
      }}
      />
    }

    return ''
  }

  // console.log("full response", fullResponse)
  // console.log("phase2resp", phase2resp)
  // console.log("phase2Ques", phase2Ques)
  // console.log("respValue", respValue)

function SubTextChange(e) {
  // console.log("text change +++++++++++++++++", e.target.value)
  if (phase===0){
    // console.log("text change 0 +++++++++++++++++++++++", e.target.value)
    setRespValue(e.target.value) 
  } else {
    // console.log("text change 1 ----------------------", e.target.value)
    setPhase2resp(e.target.value)
  }
}

  return (
    <>
    <EmptyModal open={open} close={close}/>
    <SwitchTransition>
      <CSSTransition
        key={phase==0?currQues:1}
        addEndListener={(node, done) => {
          node.addEventListener("transitionend", done, false);
        }}
        classNames="feedback"
      >
        <SurveyContainer>

        {phase==1?
          <Phase2 setFinish={(f)=>setFinish(f)} phase2Ques={phase2Ques} loading={loading}/>
        :<>
        <Score>
        <Typewriter
            onInit={(typewriter) => {
              typewriter.changeDelay(17)
              .typeString(getTopQuestion())
              .pauseFor(100)
              .callFunction((el) => {
                setFinish(1)
              })
              .start()

            }}
          /></Score>
          
          
          
          <Text>
            {getTypeWriter(1)}
          </Text>
          
          <TopText>
          {getTypeWriter(2)}
          
          </TopText>
          </>}
        <Hidden show={finish==3}>
         <SubText
            value={phase==0?respValue:phase2resp}
            id="textbox"
            rows="6"
            onChange={(e) => SubTextChange(e)} />
          
         
          <Button2
            onClick={() => next()}
            color={textColor} id="next">
            {language === 1 ? 'Suivant' : 'Next'}
          </Button2>

        </Hidden>
     
         
        </SurveyContainer>
      </CSSTransition>
    </SwitchTransition>
    </>
  )
}

export default CommentsFeedback

const EmptyModal = ({open,close}) =>{
  const { t } = useTranslation();
  return (
    <Modal
    dimmer={'blurring'}
    open={open}
    onClose={() => close()}>
      <Modal.Header>{t('ModalHeader')}</Modal.Header>
          <ModalContent>
          {t('ModalContent.Intro')}
          {t('ModalContent.Emoji')}
          <ul>
            <li style={{marginBottom:15}}>
              <strong>{t('ModalContent.DriveChange')}</strong> - {t('ModalContent.DriveChangeDesc')}
            </li>
            <li style={{marginBottom:15}}>
              <strong>{t('ModalContent.Strengths')}</strong> - {t('ModalContent.StrengthsDesc')}
            </li>
            <li>
              <strong>{t('ModalContent.Decisions')}</strong> - {t('ModalContent.DecisionsDesc')}
            </li>
          </ul>
        </ModalContent>
        <Modal.Actions>
         
          <Button color='grey' onClick={() =>close()}>
            Close
          </Button>
        </Modal.Actions>
    </Modal>

  )
}

const Phase2 = ({setFinish,phase2Ques,loading}) =>{

  if(loading){
    return <Loader active inline />
  }

    return (
      <Score>
        <Typewriter
            onInit={(typewriter) => {
              typewriter.changeDelay(17)
              .typeString(phase2Ques)
              .pauseFor(100)
              .callFunction((el) => {
                setFinish(3)
              })
              .start()

            }}
          /></Score>
    )
}

const SurveyContainer = styled.div`
  padding: 0px;
  box-sizing: border-box;
  position: absolute;
  left: 0%;
  top: 10%;
  padding: 20px;
   color: white;

   @media only screen and (min-width: 768px) {
    padding: 30px;
    left: calc(50% - 350px);
    top: 15%;
  }
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: white;
  margin-top: 14px;
  max-width:600px;
  line-height:1.6;
  font-style:italic;
  margin-bottom: 20px;
  margin-left:20px;

  @media only screen and (min-width: 992px) {
    font-size: 20px;
    margin-top: 20px;
  }
`;

const SubText = styled.textarea`
  width:600px;
  color: black;
  font-size: 18px;
  background-color:white;
  border-radius:8px;
  border:none;
  padding:15px;
  resize: none;

  &:focus{
  outline: none;
  }

  ::placeholder{
    color: #b8b8b8;
  }

  @media(max-width:768px){
    max-width:calc(100vw - 40px);

  }
`

const Button2 = styled.button`
  height: 40px;
  border-radius: 10px;
  background-color: white;
  border: 0;
  box-shadow: none;
  color: ${props => props.color};
  font-size: 16px;
  font-weight: bold;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100px;
  margin-top: 50px;
  // display: none;

  :disabled{
  	background-color:lightgrey;
  	color:grey;
  	cursor:not-allowed;
  	opacity:0.5;
  }


  @media only screen and (min-width: 992px), (min-height: 760px) {
    display: flex;
  }
`;


const TopText = styled.div`
  font-size: 18px;
   max-width:600px;
   line-height:1.6;
   white-space: pre-line;
   margin-bottom:30px;

   @media only screen and (min-width: 992px) {
    font-size: 20px;
    margin-top: 10px;
   
  }

`

const Score = styled.div`
  font-size:18px;
  margin-bottom:20px;
  margin-top:10px;
  line-height:1.6;
  max-width:600px;

   @media only screen and (min-width: 992px) {
    font-size: 20px;
  }
`

const Hidden = styled.div`
  opacity:${props => props.show? 1 : 0};
  transition: opacity 0.8s ease-in-out;
`

const ModalContent = styled.div`
  padding:20px;
  font-size:16px;
  line-height:1.6;
  font-family:Poppins, sans-serif;

  @media (max-width:768px){
    font-size:14px;
  }
`

const FeedbackPackage = {
  "1": [{
    "type":"question",
    "question": "We noticed you scored this item lower than others. Please provide a clear and specific solution or suggestion for our organization to improve in this area.",
  },
  {
    "type":"AI"
  },
  {
    "type":"question",
    "question": "We noticed you scored this item lower than others. Please provide a clear and specific solution or suggestion for our organization to improve in this area.",
  }],
  "2": [{
    "type":"question",
    "question": "We noticed you scored this item lower than others. Please provide a clear and specific solution or suggestion for our organization to improve in this area.",
  },
  {
    "type":"AI"
  },
  {
    "type":"question",
    "question": "We noticed you scored this item lower than others. Please provide a clear and specific solution or suggestion for our organization to improve in this area.",
  }],
}