import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { Dimmer, Loader, Segment, Icon } from "semantic-ui-react";

import Outcome from "ModularQuestions/Outcome";
import ValuesBuilder from "ModularQuestions/ValuesBuilder";

import {
  UPDATE_BRIEF_RESPONSE,
  SG_UPDATE_BRIEF_RESPONSE,
} from "constants/actions";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { TRANSITIONSPEED, SlideDirection } from "constants/animation";

const ModularQuestion = ({
  id,
  setModularComplete,
  token,
  briefResponseId,
  setEnableNavigation,
  language,
}) => {
  const dispatch = useDispatch();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(false);

  const [allQuestions, setAllQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [questionInfo, setQuestionInfo] = useState({
    values_builder: {},
    feedback_builder: {},
    outcome_question: {},
  });

  const { get_brief_response, get_modular_questions } = useSelector(
    (state) => ({
      get_brief_response: state.briefresponse,
      get_modular_questions: state.questions,
    }),
    shallowEqual
  );

  // console.log("modular_question", get_modular_questions.modular_questions);

  // Extract feedback_builder questions
  useEffect(() => {
    const _totalQuestions = [];

    if (
      get_modular_questions?.modular_questions?.feedback_builder?.questions?.[0]
        ?.questions
    ) {
      let sno = 1;
      get_modular_questions.modular_questions.feedback_builder.questions[0].questions.forEach(
        (q) =>
          _totalQuestions.push({
            question_type: "feedback_builder",
            sno: sno,
            order: sno++,
            ...q,
          })
      );
      setQuestionInfo({
        ...questionInfo,
        feedback_builder: {
          label: "Feedback Builder",
          value: "feedback_builder",
          on_date: new Date(),
          ask_again: 1, //"based on toggle in admin app"
          briefResponseId: briefResponseId,
          brief_schedule_id: get_brief_response?.debrief_schedule,
          ...get_modular_questions?.modular_questions?.feedback_builder,
          questions_list: _totalQuestions,
          responses: [],
        },
      });
    }

    if (
      get_modular_questions?.modular_questions?.feedback_builder?.questions?.[0]
        ?.questions
    ) {
      let sno = 1;
      get_modular_questions.modular_questions.values_builder.questions[0].questions.forEach(
        (q) =>
          _totalQuestions.push({
            question_type: "values_builder",
            sno: sno,
            order: sno++,
            ...q,
          })
      );
      setQuestionInfo({
        ...questionInfo,
        values_builder: {
          label: "Values Builder",
          value: "values_builder",
          on_date: new Date(),
          ask_again: 1, //"based on toggle in admin app"
          briefResponseId: briefResponseId,
          brief_schedule_id: get_brief_response?.debrief_schedule,
          ...get_modular_questions?.modular_questions?.values_builder,
          questions_list: _totalQuestions,
          responses: [],
        },
      });
    }

    if (get_modular_questions?.modular_questions?.outcome_question?.questions) {
      let sno = 1;
      get_modular_questions.modular_questions.outcome_question.questions.map(
        (oq) =>
          oq.questions.forEach((q) =>
            _totalQuestions.push({
              question_type: "outcome_question",
              sub_type: oq.name,
              sno: sno,
              order: sno++,
              ...q,
            })
          )
      );
      setQuestionInfo({
        ...questionInfo,
        outcome_question: {
          label: "Outcome question",
          value: "outcome_question",
          on_date: new Date(),
          ask_again: 1, //"based on toggle in admin app"
          briefResponseId: briefResponseId,
          brief_schedule_id: get_brief_response?.debrief_schedule,
          ...get_modular_questions?.modular_questions?.outcome_question,
          questions_list: _totalQuestions,
          responses: [],
        },
      });
    }

    setAllQuestions(_totalQuestions);
    const timer1 = setTimeout(() => {
      if (_totalQuestions?.length === 0) {
        setModularComplete(true);
      }
    }, 1000);
    return () => clearTimeout(timer1);
  }, [get_modular_questions]);

  useEffect(() => {
    if (allQuestions?.length > 0) {
      setCurrentQuestion(allQuestions[currentQuestionIndex]);
    }
  }, [
    currentQuestionIndex,
    allQuestions,
    setModularComplete,
    setEnableNavigation,
  ]);

  // console.log("currentQuestionIndex", currentQuestionIndex);
  // console.log("allQuestions?.length", allQuestions?.length);
  const saveAnswer = (currentresponse) => {
    const _answers = { ...answers }; // Create a shallow copy of answers

    // Initialize the nested objects if they don't exist
    if (!_answers[currentresponse?.question_type]) {
      _answers[currentresponse?.question_type] = {};
    }

    // Assign currentresponse to the appropriate question type and id
    _answers[currentresponse?.question_type][currentresponse?.sno] =
      currentresponse;

    const responses = Object.values(_answers[currentresponse?.question_type]);

    const _questionType = currentresponse?.question_type;

    const updatedQuestionInfo = {
      ...questionInfo,
      [_questionType]: {
        ...questionInfo[_questionType],
        responses: responses,
      },
    };
   
    setQuestionInfo(updatedQuestionInfo);

    setAnswers(_answers);

    if (currentQuestionIndex + 2 > allQuestions?.length) {
      // run this function to update the redux store
      // after the last question has been answered
      MarkCurrentComplete(updatedQuestionInfo);
      setModularComplete(true);
      setEnableNavigation(true);
    }

    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  // console.log(allQuestions)
  // console.log("questionInfo", questionInfo);
  // console.log("answers", answers);
  // console.log("allQuestions", allQuestions);

  function MarkCurrentComplete(updatedQuestionInfo) {
    const payload = {
      visual_question: get_brief_response?.visual_question,
      current_question: get_brief_response?.current_question,
      current_answer: get_brief_response?.current_answer,
      high_answer: get_brief_response?.high_answer,
      answers: get_brief_response?.answers,
      complete: get_brief_response?.complete,
      intro_completed: get_brief_response?.intro_completed,
      intro2_completed: get_brief_response?.intro2_completed,
      validation_token: get_brief_response?.validation_token,
      feedback: get_brief_response?.feedback,
      survey_personality: get_brief_response?.survey_personality,
      in_survey_personality: get_brief_response?.in_survey_personality,
      modular_response: updatedQuestionInfo,
    };
    dispatch({
      type: UPDATE_BRIEF_RESPONSE,
      payload,
    });

    // persist with api
    if (briefResponseId) {
      dispatch({
        type: SG_UPDATE_BRIEF_RESPONSE,
        payload: {
          id: briefResponseId,
          stored_response: payload,
          query: `?token=${token}`,
          validation_token: get_brief_response?.validation_token,
        },
      });
    }
  }
  // console.log("currentQuestion", currentQuestion)
  // console.log("currentQuestionIndex", currentQuestionIndex)
  return (
    <SwitchTransition>
      <CSSTransition
        key={`${currentQuestion?.question_type}-${currentQuestionIndex}` || 0}
        timeout={TRANSITIONSPEED}
        classNames={SlideDirection[1]}
      >
        {!currentQuestion ? (
          <SpinnerContainer>
            <Icon loading name="spinner" />
          </SpinnerContainer>
        ) : (
          <>
            {currentQuestion?.question_type === "outcome_question" && (
              <Outcome
                currentQuestion={currentQuestion}
                saveAnswer={saveAnswer}
              />
            )}

            {(currentQuestion?.question_type === "values_builder") |
              (currentQuestion?.question_type === "feedback_builder") && (
              <ValuesBuilder
                currentQuestion={currentQuestion}
                saveAnswer={saveAnswer}
                language={language}
              />
            )}
          </>
        )}
      </CSSTransition>
    </SwitchTransition>
  );
};

export default ModularQuestion;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .spinner.icon {
    font-size: 2em; /* Adjust the size as needed */
  }
`;
