import React from 'react';
import styled from 'styled-components';

const Intro = (props) =>{

	return (
		<Container>
			<Title>Big 5 Personality Assessment</Title>
			<SubTitle>Please be aware that your summary results will be visible to your team members. 
      This is to aid in the development of both self and others awareness. There is no good or 
      bad when it comes to personality results. Simply follow your gut when answering each question.</SubTitle>
			<Next onClick={()=>props.next()}>Begin</Next>
		</Container>

		)
}

export default Intro


const Title = styled.div`
	font-size: 50px;
  font-weight: bold;
  margin-bottom:20px;
  color:white;
   line-height:1;
   max-width:654px;

   @media only screen and (min-width:992px){
   font-size: 72px;
   margin-bottom:50px;
  }
`
const SubTitle = styled.div`
	font-size: 18px;
	line-height: 1.17;
  color: white;
  margin-bottom:20px;
  max-width:654px;

   @media only screen and (min-width:992px){
   font-size: 72px;
   margin-bottom:50px;
   font-size: 24px;
  }
`

const Next = styled.div`
	width: 170px;
  height: 47px;
   border-radius: 5px;
  background-color: white;
  color: #6a00ff;
   font-size: 16px;
  font-weight: bold;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
`

const Container = styled.div`
  position:absolute;
  top:15%;
  padding:20px;

  @media only screen and (min-width:992px){
    left: 25%;
  top: 25%;
  }
`