
import React, { useState } from "react";
import { TransitionGroup, SwitchTransition, CSSTransition } from "react-transition-group";
import { Button, Divider, Header, Icon } from "semantic-ui-react";

// import "./styles.css";

// const SlideDirectionOne = ["SlideDown", "SlideUp"];
const SlideDirection = ["slide-down", "slide-up"];
const MoveDirection = ["feedback-left", "feedback-right"];
const Transition = ["out-in", "in-out"]
const TodoList = [{
  id: 1,
  name: "Run 1k"
},
{
  id: 2,
  name: "wights"
},
{
  id: 3,
  name: "cardio"
}
]



const AnimateOne = ({ change, state }) => {
  return (
    <SwitchTransition>
      <CSSTransition
        key={state}
        timeout={300}
        classNames={SlideDirection[change]}
      >
        <>
          <h2>New One {state ? "Hello, world!" : "Goodbye, world!"} -- {MoveDirection[change]}</h2>
        </>
      </CSSTransition>

    </SwitchTransition>
  );
};


const AnimateTwo = ({ change, state }) => {
  return (
    <SwitchTransition>
      <CSSTransition
        key={state}
        timeout={300}
        classNames={MoveDirection[change]}
      >
        <>
          <h2>New Two {state ? "Hello, world!" : "Goodbye, world!"} -- {MoveDirection[change]}</h2>
        </>
      </CSSTransition>

    </SwitchTransition>
  );
};

function AnimateThree({ change, state }) {


  // React.useEffect(() => {
  //   setDirection(Math.random())
  // }, [state]);
  return (
    <SwitchTransition>
      <CSSTransition
        key={state}
        timeout={300}
        classNames={MoveDirection[change]}
      >

        <>
          <h2>New Three -- {SlideDirection[change]}</h2>

          {state ? "true" : "false"}

        </>
      </CSSTransition>
    </SwitchTransition>

  )
}

function AnimateFive({ change, number }) {
  // const [inProp, setInProp] = useState(false);
  return (
    <SwitchTransition>
      <CSSTransition
        key={number}
        timeout={300}
        classNames={MoveDirection[change]}
      >
        <div>
          {"I'll receive my-node-* classes slide-down " + number}
        </div>
      </CSSTransition>
    </SwitchTransition>

  );
}

function AnimateText({ number }) {
  // const [inProp, setInProp] = useState(false);
  return (

    <div>
      {"I'll receive my-node-* classes slide-down " + number}
    </div>


  );
}

export default function App() {

  const [state, setState] = useState(true);
  const [wholeCont, setWholeCont] = useState(true);
  const [direction, setDirection] = useState(1);
  const [change, setChange] = useState(1);
  const [todoList, setTodoList] = useState(TodoList)
  // const [showMessage, setShowMessage] = useState(true);

  function ChangeDirection() {
    if (direction) {
      setDirection(0)
    } else {
      setDirection(1)
    }
    // if (direction < 3) {
    //     setDirection(direction + 1)
    // } else {
    //     setDirection(0)
    // }
  }

  function ChangeThis() {
    if (change) {
      setChange(0)
    } else {
      setChange(1)
    }
  }

  const NumberList = []
  const loopTime = 10;

  for (let i = 0; i < loopTime; i++) {
    NumberList.push(i)
  }
  const [randomNumber, setRandomNumber] = useState(0)

  function InRandm() {

    setRandomNumber(randomNumber + 1)
  }
  return (
    <>
      {NumberList}
      <Divider horizontal>
        <Header as='h4'>
          <Icon name='tag' />
          Next
        </Header>
      </Divider>


      <SwitchTransition>
        <CSSTransition
          key={`${randomNumber} ${state} ${change}`}
          timeout={300}
          classNames={MoveDirection[change]}
        >
          {randomNumber % 2 ?
            <AnimateText number={`One Odd No ${randomNumber}`} />
            :
            <AnimateText number={`One Even No ${randomNumber}`} />
          }
        </CSSTransition>
      </SwitchTransition>



      <Divider horizontal>
        <Header as='h4'>
          <Icon name='tag' />
          Next
        </Header>
      </Divider>

      {randomNumber % 2 ?
        <AnimateFive
          change={change}
          state={state}
          number={`3 Odd No ${randomNumber}`}
        />
        :
        <AnimateFive
          change={change}
          state={state}
          number={`3 Even No ${randomNumber}`}
        />
      }



      <Button onClick={InRandm}>
        Next {randomNumber}
      </Button>

      <AnimateThree
        change={change}
        state={state}
      />
      <AnimateTwo
        change={change}
        state={state}
      />
      <AnimateOne
        change={change}
        state={state}
      />

      {/* <AnimateFive 
      change={change}
              state={state} 
            /> */}

      <Button onClick={ChangeDirection}>
        Next {change} {direction ? "true" : "false"}
      </Button>
      <Button onClick={() => setState(state => !state)}>
        {state ? "Hello, world!" : "Goodbye, world!"}
      </Button>
      <Button onClick={ChangeThis}>
        Change {ChangeThis}
      </Button>
      {/* out-in in-out*/}
      <Divider horizontal>
        <Header as='h4'>
          <Icon name='tag' />
          Next
        </Header>
      </Divider>
      <SwitchTransition mode={Transition[change]}>
        <CSSTransition
          key={wholeCont}
          timeout={300}
          classNames="fade"
        // addEndListener={(node, done) => {
        //           node.addEventListener("transitionend", done, false);
        //       }}
        >
          <div className="contained">
            <h2>Whole component -- {wholeCont ? "Hello, world!" : "Goodbye, world!"} -- {SlideDirection[change]}</h2>
            <Button onClick={() => setWholeCont(wholeCont => !wholeCont)}
              color={wholeCont ? "blue" : "green"}>
              {wholeCont ? "Hello, world!" : "Goodbye, world!"}
            </Button>
          </div>
        </CSSTransition>
      </SwitchTransition>
      <Divider horizontal>
        <Header as='h4' onClick={() => {
          setTodoList(TodoList)

        }
        }>
          <Icon name='tag' />
          Reset All
        </Header>
      </Divider>
      <TransitionGroup>

        <ol>
          {todoList.map(({ id, name }) => {
            return (
              <CSSTransition
                key={id}
                in={"true"}
                timeout={500}
                classNames="todos"
              >

                <li key={id} className="favorites">
                  {name}
                  <Button
                    className="remove-btn"
                    onClick={() => setTodoList(todoList.filter(td => td.id !== id))}
                  >Remove &times;</Button>



                </li>

              </CSSTransition>
            )
          })}
        </ol>
      </TransitionGroup>
    </>
  );
}
