import React, { useState,useRef,useEffect } from 'react';
import styled from 'styled-components';

import Logo from 'assets/images/Logo blue.png';


const IntroSection = ({data}) =>{


    return (

        <FeedbackContainer>
            <IMG src={Logo}/>
            <QuestionText>{data.title}</QuestionText>
            <SubTitle>{data.subtitle}</SubTitle>
            {data.text.map((item, i)=>{
                return <Description key={i}>{item}</Description>
            })}
           
        </FeedbackContainer>

    )
}


export default IntroSection;


const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width:600px;
  font-family: 'Raleway', sans-serif;
`;

const QuestionText = styled.h2`
  margin-bottom: 1rem;
  color:#2A3039;
  font-size: 24px;
  font-weight: 700;
`;

const Description = styled.div`
color: #2A3039;
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
margin-bottom:26px;
line-height: 150%;
font-style: italic;
`
const Emoji = styled.div`
    font-size: 48px;
    margin-bottom:24px;
`

const Bold = styled.span`
    font-weight:600;
    color:#2D70E2;
`
const SubTitle = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:24px;
    margin-top:24px;
`

const IMG = styled.img`
    width:150px;
    margin-bottom:10px;
`