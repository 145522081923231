import styled  from "styled-components";
import React from "react";
import { useDispatch } from "react-redux";

import {
  ADD_PERSONALITY_ANSWER,
  SET_ANSWERS,
  SG_UPDATE_PERSONALITY_ANSWER,
  // SG_START_PERSONALITY_ANSWER,
  SUBMIT_ANSWER,
} from "constants/actions";

import {
  // BrowserView,
  // MobileView,
  isBrowser,
  isMobile,
  // deviceType,
  osName,
  // osVersion,
  deviceDetect,
} from "react-device-detect";

const words = ["Disagree", "", "Neutral", "", "Agree"];

const PersonalityQuestion = (props) => {
  const dispatch = useDispatch();
  const deviceDetected = deviceDetect();
  const { question, highAns, answer, get_answers } = props;
  // const [answer, setAnswer] = useState([]);

  React.useEffect(() => {
    // setAnswer(props.answer);
  }, [props]);

  const nextQuestion = (i) => {
    // setAnswer(i);

    setTimeout(() => {
      props.next(i);
      // setAnswer(null);
       SaveAnswer(i);
      props.setShowNext(false)
    }, 150);
   

  };

  // React.useEffect(() => {

    
  // }, [answer, dispatch, get_answers])
  // console.log(get_answers)

  const SaveAnswer = (i) => {
    dispatch({
      type: ADD_PERSONALITY_ANSWER,
      payload: {
        id: question.id,
        answer: i,
      },
    });
    dispatch({
      type: SET_ANSWERS,
      payload: {
        personality_high_ans: question.id,
        curr_direction: 1,
      },
    });
    let response = [answer];
    // setAnswer(null);
    props.setShowPrev(true)
    props.next(response);

    dispatch({
      type: SG_UPDATE_PERSONALITY_ANSWER,
      payload: {
        token: get_answers.token,
        full_response: {
          personality_answers: get_answers.personality_answers,
          personality_max_ques: get_answers.personality_max_ques,
          personality_high_ans: get_answers.personality_high_ans,
          personality_cur_ques: get_answers.personality_cur_ques,
          personality_complete: get_answers.personality_complete,
          personality_survey_status: get_answers.personality_survey_status,
          participants_in_personality: get_answers.participants_in_personality,
        } 
       },
    })
  };
// console.log(get_answers)
// console.log(get_answers.personality_answers)
  const SubmitAnswer = () => {
    dispatch({
      type: ADD_PERSONALITY_ANSWER,
      payload: {
        id: question.id,
        answer: answer,
      },
    });

    dispatch({
      type: SUBMIT_ANSWER,
      payload: {
        personality_answers: [
          ...get_answers.personality_answers,
          { id: question.id, answer: answer },
        ],

        employee: get_answers.employee,
        token: get_answers.token,
        device: deviceDetected,
        mobile: isMobile? "Yes" : "No",
        browser: isBrowser? "Desktop" : "Other Devise",
        operating_system: osName,
        user_ip: get_answers.user_ip,
        participants_in_personality: get_answers.participants_in_personality,
      },
    });

    dispatch({
      type: SET_ANSWERS,
      payload: {
        personality_complete: true,
      },
    });

  };
// console.log(get_answers.personality_cur_ques)
// console.log(get_answers.personality_high_ans)
  return (
    <SurveyContainer>
      <Question>{`I ${question.question}`}</Question>

      <QuestionContainer>
        {[0, 1, 2, 3, 4].map((item, i) => {
          return (
            <QuestionItem
              i={i + 1}
              answer={answer}
              onClick={() => (highAns < 101 ? nextQuestion(i + 1) : "")}
              key={i}
            >
              <Num>{item + 1}</Num>
              <Rating>{words[i]}</Rating>
            </QuestionItem>
          );
        })}
      </QuestionContainer>
      {get_answers.personality_high_ans === 100 && (
        <SubmitButton 
        enabled={get_answers.personality_high_ans === 100} 
        onClick={SubmitAnswer}>
          Submit Answers
        </SubmitButton>
      )}
    </SurveyContainer>
  );
};

export default PersonalityQuestion;

const SurveyContainer = styled.div`
  padding: 0px;
  box-sizing: border-box;
  position: absolute;
  left: 0%;
  top: 25%;
  padding: 20px;

  @media only screen and (min-width: 992px) {
    padding: 30px;
    left: 25%;
    top: 25%;
  }
`;

// const IMG = styled.img`
//   margin-bottom: 8px;
//   height: 50px;
//   margin-right: 22px;
// `;

const Question = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: white;
  margin-top: 14px;
  max-width:100%;

  @media only screen and (min-width: 992px) {
    font-size: 23px;
    margin-top: 28px;
  }
`;

// const Title = styled.div`
//   font-size: 23px;
//   font-weight: bold;
//   font-family: "Red Hat Display", sans-serif;
//   color: white;
// `;

// const Sub = styled.div`
//   font-size: 14px;
//   font-family: "Red Hat Display", sans-serif;
//   margin-top: 12px;
//   color: white;
// `;

const QuestionItem = styled.div`
  width: 18%;
  height: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${(props) =>
    props.i === 1 ? "7px 0px 0px 7px" : props.i === 5 ? "0 7px 7px 0" : "none"};
  border: 1px solid white;
  cursor: pointer;
  background-color: ${(props) => (props.answer === props.i ? "white" : "none")};
  color: ${(props) => (props.answer === props.i ? "#6a00ff" : "white")};

  &:active {
    background: white;
    color:#6a00ff;
  }

  @media only screen and (min-width: 992px) {
    height: 85px;
    width: 20%;

      &:hover {
    background: white;
    color: #6a00ff;
  }
  &:active {
    background: #6a00ff;
    color:white;
  }
  }

  
`;

const QuestionContainer = styled.div`
  width: 100vw;
  height: 65px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;

  @media only screen and (min-width: 992px) {
    width: 600px;
    margin-top: 40px;
  }
`;

const Num = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;

  @media only screen and (min-width: 992px) {
    font-size: 24px;
    margin-top: 20px;
  }
`;
const Rating = styled.div`
  font-size: 10px;
  font-weight: 500;
  margin-top: 0px;

  @media only screen and (min-width: 992px) {
    font-size: 15px;
    margin-top: 10px;
  }
`;

// const Scale = styled.div`
//   width: 600px;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   margin-top: 20px;
//   font-size: 146x;
//   font-family: "Red Hat Display", sans-serif;
//   color: white;
// `;

const Button = styled.div`
  height: 40px;
  border-radius: 10px;
  background-color: white;
  color: #6a00ff;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100px;
  margin-top: 50px;
`;

// const CounterContainer = styled.div`
//   position: absolute;
//   bottom: 50px;
//   right: 50px;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
// `;

// const Counter = styled.div`
//   padding: 10px 15px 10px 15px;
//   background-color: #4f00f7;
//   color: white;
//   font-family: "Red Hat Display", sans-serif;
//   font-size: 16px;
//   border-radius: 7px;
//   margin-right: 25px;
// `;

// const Container = styled.div`
//   background-image: linear-gradient(308deg, #6d00aa, #6a00ff);
//   height: 100vh;
//   width: 100%;
// `;

const SubmitButton = styled(Button)`
  width: 170px;
  height: 47px;
  border-radius: 5px;
  background-color: ${(props) => (props.enabled ? "white" : "#bbc7bd")};
  // color: white;
  // background-color: white;
  color: #6A00FF;
  font-size: 16px;
  font-weight: bold;
  cursor: ${(props) => (props.enabled ? "pointer" : "not-allowed")};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
