import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { feedbackTypeQuestion } from 'constants/questionTypes';
import Logo from 'assets/images/Ilogo.svg';
import {words} from '../translations'


const Feedback = ({ 
      ruleFeedback, 
      saveUpdatedAnswers, 
      language, 
      currentQuestionDet, 
      skipNextQuestion,
      setAskNextQuestion,
      sortOrder
     }) => {
  const [feedbackText, setFeedbackText] = useState('');
  const [open, setOpen] = useState(false);
  const textareaRef = useRef();

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight - 16}px`;
    }
  }, []);

  const handleInput = (e) => {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight - 16}px`;
  };

  const handleChange = (event) => {
    setFeedbackText(event.target.value);
    const ResponseObj = {
      ques_sort_order: currentQuestionDet?.id,
      survey_q_id: currentQuestionDet.id,
      feedback: event.target.value,
      question_type: currentQuestionDet?.quesType,
      quesType: currentQuestionDet?.quesType,
      org_ref_ques_order: ruleFeedback?.ques_order,
      dimension: ruleFeedback?.dimension,
      factor: ruleFeedback?.factor,
      fr: ruleFeedback?.fr,
      es: ruleFeedback?.es,
      id: ruleFeedback?.id,
      q: ruleFeedback?.q,
      ques_order: ruleFeedback?.ques_order,
      question: ruleFeedback?.question,
      response: ruleFeedback?.response,
      reverse: ruleFeedback?.reverse,
      role: ruleFeedback?.role,
      type: ruleFeedback?.type,
      clicked: ruleFeedback?.clicked,
      skipNext: false,
    }
    saveUpdatedAnswers(currentQuestionDet.id, ResponseObj, feedbackTypeQuestion)
    setAskNextQuestion(true)
  };

  const handleSkip = () => {
    setOpen(true);
  }

  function SkipQuestion() {
    const ResponseObj = {
      ques_sort_order: currentQuestionDet?.id,
      survey_q_id: currentQuestionDet.id,
      feedback: '',
      question_type: currentQuestionDet?.question_type,
      org_ref_ques_order: ruleFeedback?.ques_order,
      dimension: ruleFeedback?.dimension,
      factor: ruleFeedback?.factor,
      fr: ruleFeedback?.fr,
      es: ruleFeedback?.es,
      id: ruleFeedback?.id,
      q: ruleFeedback?.q,
      ques_order: ruleFeedback?.ques_order,
      question: ruleFeedback?.question,
      response: ruleFeedback?.response,
      reverse: ruleFeedback?.reverse,
      role: ruleFeedback?.role,
      type: ruleFeedback?.type,
      clicked: ruleFeedback?.clicked,
      skipNext: true,
    }
    saveUpdatedAnswers(currentQuestionDet.id, ResponseObj, feedbackTypeQuestion)
    setOpen(false)
    skipNextQuestion()
  }

  useEffect(() => {
    if (currentQuestionDet?.response?.feedback) {
      setFeedbackText(currentQuestionDet?.response?.feedback);
    } else {
      setFeedbackText('')
    }
  }, [currentQuestionDet.response])


  const getQuestion = () => {
    if (language === 'FR' && ruleFeedback?.fr) {
      return ruleFeedback?.fr
    }
    if (language === 'ES' && ruleFeedback?.es) {
      return ruleFeedback?.es
    }
    return ruleFeedback?.question
  }

  const getFeedbackSortOrder = () => {
    if(sortOrder == 160){
      return "min_feedback3"
    }

    return "min_feedback2"
  }

 
  return (
    <FeedbackContainer>
      <Title><Circle><IMG src={Logo}/></Circle>Innerlogic AI</Title>
      <Description>
        {ruleFeedback?.type === 'min' ? 
        words['min_feedback'][language] :
        words['max_feedback'][language]
        }
      </Description>
      <QuestionText>{getQuestion()}</QuestionText>
      <Description>
       {ruleFeedback?.type === 'min'?
        <span>{words['min_feedback1'][language]} <Highlight>{ruleFeedback?.response}/10</Highlight>. {words[getFeedbackSortOrder()][language]}</span>
        :
        words['max_feedback1'][language] }
      </Description>
      <StyledTextarea
        id={`txt_${currentQuestionDet?.id}`}
        value={feedbackText || ''}
        onChange={handleChange}
        placeholder={words['textarea'][language]}
        ref={textareaRef}
        onInput={handleInput} />

      {/* <Skip onClick={handleSkip}>Skip &gt;</Skip>
      <SkipModal open={open} setOpen={setOpen} SkipQuestion={SkipQuestion} /> */}
    </FeedbackContainer>
  );
};

export default Feedback;

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width:600px;
  font-family: 'Raleway', sans-serif;

`;

const QuestionText = styled.div`
font-style: normal;
font-weight: 600;
font-size: 16px;
border-left:2px solid #D5E2F9;
margin-left:24px;
padding-left:16px;
margin-bottom:24px;
`;

const TextArea = styled.textarea`
  font-size: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  min-height: 150px;
  resize: vertical;
`;

const StyledTextarea = styled.textarea`
  display: block;
  width: 100%; 
  padding: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #2A3039;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: none;
  font-family: 'Raleway', sans-serif;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #2D70E2;
    outline: 0;
  }
`;

const Title = styled.div`
color: #2D70E2;
text-transform: uppercase;
font-size:14px;
font-weight:600;
margin-bottom:40px;
display:flex;
`

const Description = styled.div`
color: #2A3039;
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
margin-bottom:24px;
`

const Highlight = styled.span`
color: #2D70E2;
font-weight:bold;
`

const Skip = styled.div`
font-style: normal;
font-weight: 600;
font-size: 16px;
color: #2D70E2;
margin-top:40px;
cursor:pointer;
`


const Circle = styled.div`
    width:40px;
    height:40px;
    border-radius:10px;
    background-color:#2D70E2;
    margin-right:10px;
    min-width:40px;
    align-items:center;
    display:flex;
    justify-content:center;
    position:relative;
`

const IMG = styled.img`
    width:20px;
`