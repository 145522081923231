import React from 'react';
import styled from 'styled-components';
import Pic from 'assets/images/intro_mood.png'


const Title = styled.div`
	font-size: 30px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  margin-bottom:20px;
  line-height:1;

  @media only screen and (min-width:992px){
   font-size: 72px;
   margin-bottom:50px;
  }
`
const SubTitle = styled.div`
	font-size: 16px;
	line-height: 1.17;
  color: #4a4a4a;
  font-family: 'Red Hat Display', sans-serif;
  margin-bottom:20px;
  max-width:654px;

   @media only screen and (min-width:992px){
   font-size: 72px;
   margin-bottom:50px;
   font-size: 24px;
  }
`

const Next = styled.div`
	width: 170px;
  height: 47px;
   border-radius: 5px;
  background-color: #6a00ff;
  color: white;
   font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
`

const Container = styled.div`
   top:200px;
   position: absolute;

    @media only screen and (max-width:768px){
    top:10%;
  }

   @media(max-height:750px){
    top:5%;
  }
`
const Image = styled.img`
  height:250px;
  margin-bottom:20px;

  @media(max-width:768px){
    width:60%;
    height:auto;
  }

  @media(max-height:650px){
    width:30%;
    height:auto;
  }
`

const ImageContainer = styled.div`
  width:654px;
  display:flex;
  justify-content:center;

  @media(max-width:768px){
    width:100%;
  }
`


const Intro = (props) =>{

	return (
		<Container>
      <ImageContainer>
          <Image src={Pic} />
        </ImageContainer>
			<Title>Mood Check-in</Title>
      {parseInt(props.role) === 21 ?
        <SubTitle>Your responses in this section are <strong>completely anonymous.</strong></SubTitle> :
        parseInt(props.role) === 11 ?
          <SubTitle>Your responses in this section are only shared with your team leader(s)</SubTitle>
          : <SubTitle>Your responses in this section are only visible to both team leader(s),
            and your team members.</SubTitle>}
			<Next onClick={()=>props.next()}>Begin</Next>
		</Container>

		)
}


export default Intro