import React, { useEffect, useState } from "react";
import styled from "styled-components";



const Comments = ({ saveCommentAnswer,
  question,
  response,
  language,
textColor}) => {

  const [respValue, setRespValue] = useState('')

  const save = () => {
    saveCommentAnswer({
      id: question?.id,
      question: question?.question,
      ques_order: question?.order,
      response: respValue
      })
  }

  useEffect(() => {
    setRespValue('')
    if (response) {
      setRespValue(response?.response)
    }
  }, [response]);


	return (
		<SurveyContainer>
			<Text>{language===1?question?.fr: question?.question}</Text>
			<SubText
			placeholder={language===1?"Utilisez cet espace pour ajouter des commentaires":"You can use this space to expand If you'd like :) "}
	        value={respValue}
	        id="textbox"
	        rows="6"
	        onChange={(e)=>setRespValue(e.target.value)}/>

	        <Button onClick={()=>save()} color={textColor} id="next">{language===1?'Suivant':'Next'}</Button>
		</SurveyContainer>
		)
}

export default Comments


const SurveyContainer = styled.div`
  padding: 0px;
  box-sizing: border-box;
  position: absolute;
  left: 0%;
  top: 10%;
  padding: 20px;

  @media only screen and (min-width: 768px) {
    padding: 30px;
    left: calc(50% - 350px);
    top: 15%;
  }
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: white;
  margin-top: 14px;
  max-width:600px;
  line-height:1.3;
  margin-bottom:30px;

  @media only screen and (min-width: 992px) {
    font-size: 23px;
    margin-top: 28px;
  }
`;

const SubText = styled.textarea`
  width:600px;
  color: black;
  font-size: 16px;
  background-color:white;
  border-radius:8px;
  border:none;
  padding:15px;
  resize: none;

  &:focus{
  outline: none;
  }

  ::placeholder{
    color: #b8b8b8;
  }

  @media(max-width:768px){
    max-width:calc(100vw - 40px);

  }
`

const Button = styled.button`
  height: 40px;
  border-radius: 10px;
  background-color: white;
  border: 0;
  box-shadow: none;
  color: ${props=>props.color};
  font-size: 16px;
  font-weight: bold;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100px;
  margin-top: 50px;
  // display: none;

  :disabled{
  	background-color:lightgrey;
  	color:grey;
  	cursor:not-allowed;
  	opacity:0.5;
  }


  @media only screen and (min-width: 992px), (min-height: 760px) {
    display: flex;
  }
`;