import React, { useEffect, useState } from "react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";

import Personality from "../InSurveyPersonality";

import { v4 as uuidv4 } from "uuid";

import {
  SG_START_BRIEF_SURVEY,
  NEW_VALIDATION_TOKEN,
  RESET_VALIDATION_TOKEN,
  CLEAR_ALL,
  SG_POST_BRIEF_RESPONSE,
  UPDATE_BRIEF_RESPONSE,
  SG_UPDATE_BRIEF_RESPONSE,
  // SG_GET_ALL_MODULAR_QUESTIONS,
} from "constants/actions";

import {
  // BrowserView,
  // MobileView,
  isBrowser,
  isMobile,
  deviceType,
  osName,
  osVersion,
  deviceDetect,
} from "react-device-detect";

import Intro from "./Intro";
import Intro2 from "./Intro2";
import Intro3 from "./Intro3";
import SurveyMessage from "./Message";
import ReLoadPage from "./ReLoadPage";
import styled, { keyframes, css } from "styled-components";
import { Icon } from "semantic-ui-react";
import Language from "./Language";
import CCIntro from "./CCIntro";

import NoToken from "./NoToken";

import { RunRules } from "utilities/FeedbackRules";

import CategoryQuestion from "./Categories";
import CommentsFeedback from "./CommentsFeedback";

import { SwitchTransition, CSSTransition } from "react-transition-group";
import { TRANSITIONSPEED, SlideDirection } from "constants/animation";

import "../survey.css";

import QuestionTemplate from "./Questions";
import CommentTemplate from "./Comments";
import ExistingDataFound from "survey/SurveyComponents/ExistingData";

import Agreement from "survey/SurveyComponents/Agreement";
import End from "./End";
import ModularQuestion from "ModularQuestions/index";
import { useTranslation } from "react-i18next";

const categoryType = "category";
const questionsType = "questions";
const commentsType = "comments";

const arrange_Questions = (questions) => {
  if (questions?.dimensions) {
    return questions.dimensions
      .map((item, i) => {
        return item.factors.map((factor, f) => {
          return factor.questions.map((q, idx) => {
            return {
              question: q.q,
              dimension: i,
              factor: f,
              q: idx,
              role: q.role,
              reverse: q.reverse,
              order: q.id,
              fr: "fr" in q ? q.fr : null,
            };
          });
        });
      })
      .flat()
      .flat()
      .sort((a, b) => a.order - b.order);
  }
};

const Audit = () => {
  const dispatch = useDispatch();
  const deviceDetected = deviceDetect();
  const { t, i18n } = useTranslation();

  let { token } = useParams();

  // eslint-disable-next-line no-unused-vars
  let [searchParams, setSearchParams] = useSearchParams();
  const direct_language = Number(searchParams.get("language"));

  const [direction, setDirection] = useState(1);
  const [showPrev, setShowPrev] = useState(true);
  const [showNext, setShowNext] = useState(true);
  // const [location, setLocation] = useState(0)
  const [showIntro, setShowIntro] = useState(false);
  const [showIntro2, setShowIntro2] = useState(false);
  const [showIntro3, setShowIntro3] = useState(false);
  const [noResponse, setNoResponse] = useState(false);
  const [loading, setLoading] = useState(true);
  const [feedbackKey, setFeedbackKey] = useState(0);

  const [ruleFeedback, setRuleFeedback] = useState(false);
  const [completedRuleFeedback, setCompletedRuleFeedback] = useState([]);
  const [ruleFeedbackComplete, setRuleFeedbackComplete] = useState(true);
  const [askFeedback, setAskFeedback] = useState(false);
  const [enableNavigation, setEnableNavigation] = useState(true);

  const [defaultMessage, setDefaultMessage] = useState({
    title: "Survey completed",
    subTitle: "This survey has been successfully submitted",
  });
  //
  const [totalQuestions, setTotalQuestions] = useState({
    total: 0,
    runningTotal: 0,
    questions: 0,
    culture: 0,
    sequence: {},
  });

  const [questions, setQuestions] = useState([]);
  const [categoryQues, setCategoryQues] = useState([]);
  const [employeeCategory, setEmployeeCategory] = useState([]);
  // stored persisting response
  const [validationToken, setValidationToken] = useState(false);
  const [briefResponseId, setBriefResponseId] = useState(false);
  const [storedResponseFound, setStoredResponseFound] = useState(false);
  //
  const [briefResponse, setBriefResponse] = useState([]);
  const [questionNo, setQuestionNo] = useState(0);
  const [visualQuestionNo, setVisualQuestionNo] = useState(0);
  //
  const [prevResponse, setPrevResponse] = useState({});
  //
  const [checkRole, setCheckRole] = useState(false);

  const [questionAnswers, setQuestionAnswers] = useState({
    categories: {},
    questions: {},
    comments: {},
  });
  const [surveyFeedbackResp, setSurveyFeedbackResp] = useState([]);
  const [surveyPersonalityResp, setSurveyPersonalityResp] = useState({});
  const [surveyModularResp, setSurveyModularResp] = useState({});

  // in_survey_personality
  const [showInSurveyPersonality, setShowInSurveyPersonality] = useState(true);

  const [thankMessage, setThankMessage] = useState(false);
  const [complete, setComplete] = useState(false);

  const [surveyStatus, setSurveyStatus] = useState(0);
  const [resetAnswers, setResetAnswers] = useState(false);
  const [surveyDeleted, setSurveyDeleted] = useState(false);
  const [surveyDeletedOn, setSurveyDeletedOn] = useState(false);

  const [primaryCategory, setPrimaryCategory] = useState(false);
  const [surveyName, setSurveyName] = useState(false);
  const [neverAskResponse, setNeverAskResponse] = useState(false);
  const [prevFoundResponse, setPrevFoundResponse] = useState(false);

  const [consentProvided, setConsentProvided] = useState(false);
  const [language, setLanguage] = React.useState(0);
  const [showLanguage, setShowLanguage] = React.useState(false);
  const [background, setBackground] = React.useState(false);

  const [askComments, setAskComments] = useState(false);

  const [submitError, setSubmitError] = useState(false);
  const [organizationInfo, setOrganizationInfo] = useState(false);

  const [surveyModules, setSurveyModules] = useState([]);

  const [modularComplete, setModularComplete] = useState(false);
  const [modSurveyRule, setModSurveyRule] = useState([]);

  const { get_brief_response, get_errors } = useSelector(
    (state) => ({
      get_brief_response: state.briefresponse,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  // console.log("ruleFeedback", ruleFeedback)

  useEffect(() => {
    if (direct_language === 1) {
      i18n.changeLanguage(direct_language == 1 ? "fr" : "en");
      setLanguage(direct_language);
    }
  }, [direct_language]);

  useEffect(() => {
    i18n.changeLanguage(language == 1 ? "fr" : "en");
  }, [language]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  useEffect(() => {
    setRuleFeedbackComplete(true);
    const RuleRun = RunRules({ questions, get_brief_response });
    // console.log("Feedback Rules", RuleRun)
    if (RuleRun.length > 0) {
      setRuleFeedbackComplete(false);
    }
    setRuleFeedback(RuleRun);
    setSurveyModules(
      get_brief_response?.response?.organization_info?.survey_sequence
    );
  }, [get_brief_response, questions]);

  // console.log(surveyModules)
  // console.log(get_brief_response)
  // console.log(get_brief_response?.visual_question)
  // console.log(totalQuestions)
  // console.log("questionNo", questionNo)

  // console.log("show not have In Survey Personality enabled")
  useEffect(() => {
    const SurveyPersonality =
      get_brief_response?.response?.organization_services?.filter(
        (os) => os.name === "In Survey Personality"
      )?.[0];
    // console.log(SurveyPersonality)
    // console.log("should not contain in_survey_personality")
    // console.log(get_brief_response?.response?.employee_survey_spec?.['survey_personality'])
    const sp_earlier =
      get_brief_response?.response?.employee_survey_spec?.[
        "survey_personality"
      ];
    if (SurveyPersonality?.enabled === true) {
      // personality completed ealier dont ask again but inject in the response
      if (sp_earlier) {
        setShowInSurveyPersonality(false);
        setSurveyPersonalityResp(sp_earlier?.survey_personality);
      } else {
        // console.log(get_brief_response?.response?.employee_survey_spec)
        // personality survey completed and refreshing and collection from store data
        if (get_brief_response?.in_survey_personality) {
          setShowInSurveyPersonality(false);
          setSurveyPersonalityResp(get_brief_response?.survey_personality);
        } else {
          setShowInSurveyPersonality(true);
        }
      }
    } else {
      setShowInSurveyPersonality(false);
    }
  }, [
    get_brief_response.response,
    get_brief_response.in_survey_personality,
    get_brief_response.survey_personality,
  ]);

  // console.log(surveyPersonalityResp)
  // console.log(showInSurveyPersonality)
  // question no based on role skip logic
  // step 1 set role
  useEffect(() => {
    if (questionAnswers?.categories) {
      const CatRes = Object.values(questionAnswers?.categories);
      setCheckRole(
        CatRes.filter((ct) => ct.priority === "secondary")?.[0]?.response
      );
    }
  }, [questionAnswers]);

  // // Ask Comments
  useEffect(() => {
    if (get_brief_response?.response?.questions?.comments) {
      if (get_brief_response?.response?.questions?.comments?.length > 0) {
        setAskComments(get_brief_response?.response?.questions?.comments);
      }
    }
  }, [get_brief_response]);

  useEffect(() => {
    if (get_brief_response && !organizationInfo) {
      setQuestions(arrange_Questions(get_brief_response?.response?.questions));
      setEmployeeCategory(get_brief_response?.response?.employee_category);
      setOrganizationInfo(get_brief_response?.response?.organization_info);
      setCategoryQues(
        get_brief_response?.response?.culture_questions?.sort(
          (a, b) => a?.id - b?.id
        )
      );

      //Do something here to set the language component
      if (get_brief_response?.response?.questions?.language) {
        setShowLanguage(true);
        // console.log(get_brief_response)
      }
    }
  }, [get_brief_response, organizationInfo]);

  // console.log(get_brief_response)
  // console.log("get_brief_response?.validation_token", get_brief_response?.validation_token)
  // console.log("get_brief_response?.response?.stored_response?.high_answer", get_brief_response?.response?.stored_response?.high_answer)

  // primary Response
  useEffect(() => {
    let _id = "";
    let _response = 0;
    let subfactorFound = false;
    let _subfactor = null;
    const SurveyName = get_brief_response?.response?.survey_name;
    setSurveyName(get_brief_response?.response?.survey_name);
    if (get_brief_response?.response?.primary_question) {
      get_brief_response.response.primary_question.map((pq) => {
        _id = pq.id;
        if (pq?.subfactor) {
          subfactorFound = true;
        }
        pq &&
          pq.answers.map((aq) => {
            if (aq?.name === SurveyName) {
              if (subfactorFound) {
                _subfactor = aq?.id;
                _response = aq?.parent_id;
              } else {
                _response = aq?.id;
              }
            }
            return null;
          });
        return null;
      });
    }

    setBriefResponseId(get_brief_response?.id);

    if (get_brief_response?.validation_token) {
      // console.log("get_brief_response?.validation_token", get_brief_response?.validation_token)
      setValidationToken(get_brief_response?.validation_token);
      if (get_brief_response?.response?.stored_response?.high_answer > 2) {
        // console.log("get_brief_response?.response?.stored_response?.high_answer > 2", get_brief_response?.response?.stored_response?.high_answer)
        setStoredResponseFound(true);
      } else {
        setStoredResponseFound(false);
      }
    } else {
      setStoredResponseFound(false);
      setValidationToken(false);
    }

    setShowIntro(get_brief_response?.intro_completed);
    setShowIntro2(get_brief_response?.intro2_completed);
    // setStoredResponse(get_brief_response?.storedResponse)

    setPrimaryCategory({
      id: _id,
      response: _response,
      subfactor: _subfactor,
      priority: "primary",
    });
  }, [get_brief_response]);

  //skip questions
  useEffect(() => {
    const _seq = {};
    // const _empCat = employeeCategory
    // add category question nos to list

    const _prevResp = {};
    const _neverAskResp = {};

    let running_total = 0;
    let total = 0;
    let CatLength = 0;
    if (categoryQues) {
      categoryQues.map((q, v) => {
        if (q?.input === "always_ask") {
          // console.log(q)
          running_total += 1;
          CatLength += 1;
          total += 1;
          _seq[running_total] = {
            from: `always_ask ${q.id}`,
            quesType: categoryType,
            skip: false,
            sno: running_total,
          };
          return null;
        }
        // change this to ids
        if (q?.input === "ask_if_not_found") {
          // setPrevFoundResponse
          const _empCat = employeeCategory.filter(
            (ec) => ec?.name_id === q.id
          )?.[0]?.value_id;
          if (_empCat) {
            // console.log(_empCat)
            const _response = q.answers.filter((rq) => rq?.id === _empCat)?.[0]
              ?.id;
            running_total += 1;
            CatLength += 1;
            total += 0;
            _seq[running_total] = {
              from: `ask_if_not_found ${q.id}`,
              quesType: categoryType,
              skip: true,
              sno: running_total,
            };
            _prevResp[running_total] = {
              id: q.id,
              response: _response,
              priority: q.priority,
            };
          } else {
            running_total += 1;
            CatLength += 1;
            total += 1;
            _seq[running_total] = {
              from: `ask_if_not_found  ${q.id}`,
              quesType: categoryType,
              skip: false,
              sno: running_total,
            };
          }
          return null;
        }
        // change this to ids
        if (q?.input === "never_ask") {
          running_total += 1;
          CatLength += 1;
          total += 0;
          _seq[running_total] = {
            from: `never_ask  ${q?.id}`,
            quesType: categoryType,
            skip: true,
            sno: running_total,
          };
          const _empCat = employeeCategory.filter(
            (ec) => ec?.name_id === q.id
          )?.[0]?.value_id;

          _neverAskResp[running_total] = {
            id: q.id,
            response: _empCat ? _empCat : null,
            priority: q.priority,
          };
          return null;
        }

        if (!q?.input) {
          running_total += 1;
          CatLength += 1;
          total += 1;
          _seq[running_total] = {
            from: `undefined`,
            quesType: categoryType,
            skip: false,
            sno: running_total,
          };
        }
        return null;
      });
    }

    setPrevFoundResponse(_prevResp);
    setNeverAskResponse(_neverAskResp);

    let quesLength = 0;
    if (questions) {
      questions.map((q, v) => {
        if (checkRole) {
          if (q?.role) {
            if (q?.role.includes(checkRole)) {
              running_total += 1;
              quesLength += 1;
              total += 1;
              _seq[running_total] = {
                quesType: questionsType,
                from: "question for specific role",
                skip: false,
                sno: running_total,
              };
              return null;
            } else {
              running_total += 1;
              // quesLength += 1
              _seq[running_total] = {
                quesType: questionsType,
                from: "question not included role specifics",
                skip: true,
                sno: running_total,
              };
              return null;
            }
          } else {
            running_total += 1;
            quesLength += 1;
            total += 1;
            _seq[running_total] = {
              quesType: questionsType,
              from: "question without role",
              skip: false,
              sno: running_total,
            };
            return null;
          }
        } else {
          running_total += 1;
          quesLength += 1;
          total += 1;
          _seq[running_total] = {
            quesType: questionsType,
            from: "no role checked",
            skip: false,
            sno: running_total,
          };
          return null;
        }
      });
    }

    let commLength = 0;
    if (askComments) {
      askComments.map((q, v) => {
        running_total += 1;
        commLength += 1;
        total += 1;
        _seq[running_total] = {
          quesType: commentsType,
          skip: false,
          sno: running_total,
        };
        return null;
      });
    }

    setTotalQuestions({
      total: total,
      runningTotal: running_total,
      questions: quesLength,
      category: CatLength, // add primaryCategory
      comments: commLength,
      sequence: _seq,
    });
  }, [questions, categoryQues, askComments, checkRole, employeeCategory]);

  // if the number of questions changes based on responses
  // reset or remove the older questions which were answered
  useEffect(() => {
    setResetAnswers(true);
  }, [totalQuestions.total]);

  useEffect(() => {
    if (resetAnswers && questionAnswers?.categories !== undefined) {
      setResetAnswers(false);
      let _quesAns = questionAnswers;

      setQuestionAnswers({
        categories: _quesAns?.categories,
        questions: {},
        comments: {},
      });
    }
  }, [resetAnswers, questionAnswers, dispatch, totalQuestions, questionNo]);

  function nextQuestion() {
    let _questionNo = questionNo + 1;
    while (_questionNo <= totalQuestions?.runningTotal) {
      if (totalQuestions.sequence[_questionNo]?.skip) {
        _questionNo++;
      } else {
        setQuestionNo(_questionNo);
        setVisualQuestionNo(visualQuestionNo + 1);
        return;
      }
    }
  }

  function prevQuestion() {
    let _questionNo = questionNo - 1;
    while (_questionNo >= 1) {
      if (totalQuestions.sequence[_questionNo]?.skip) {
        _questionNo--;
      } else {
        setQuestionNo(_questionNo);
        setVisualQuestionNo(visualQuestionNo - 1);
        return;
      }
    }
  }

  function PreCloseSurvey() {
    if (questionNo + 1 <= totalQuestions.runningTotal) {
      nextQuestion();
      return false;
    } else {
      if (!ruleFeedbackComplete) {
        setAskFeedback(true);
        return false;
      }
      setAskFeedback(false);
      setComplete(true);
      return true;
    }
  }

  useEffect(() => {
    if (get_brief_response?.response?.id) {
      setNoResponse(false);
    } else {
      setNoResponse(true);
    }
  }, [get_brief_response]);

  useEffect(() => {
    setPrevResponse(null);
    if (questionNo) {
      const QueType = totalQuestions?.sequence?.[questionNo]?.quesType;
      // console.log(QueType)
      if (QueType === categoryType) {
        setPrevResponse(get_brief_response?.answers?.categories?.[questionNo]);
      }
      if (QueType === questionsType) {
        setPrevResponse(get_brief_response?.answers?.questions?.[questionNo]);
      }
      if (QueType === commentsType) {
        setPrevResponse(get_brief_response?.answers?.comments?.[questionNo]);
      }
    }
  }, [totalQuestions, get_brief_response.answers, questionNo]);

  useEffect(() => {
    setShowPrev(false);
    setShowNext(false);
    setBriefResponse(get_brief_response);

    if (!questionNo) {
      setShowPrev(false);
      setShowNext(false);
    }
    if (questionNo === 1) {
      setShowPrev(false);
      if (questionNo <= get_brief_response?.high_answer) {
        if (questionNo < totalQuestions?.total) {
          setShowNext(true);
        }
      }
    }
    if (questionNo > 1) {
      setShowPrev(true);
      if (questionNo <= get_brief_response?.high_answer) {
        if (questionNo < totalQuestions?.total) {
          setShowNext(true);
        }
      }
    }
    //
    setSurveyDeleted(
      get_brief_response?.to_delete ? get_brief_response?.to_delete : false
    );
    setSurveyDeletedOn(
      get_brief_response?.deleted_on ? get_brief_response?.deleted_on : false
    );
    setSurveyStatus(
      get_brief_response?.survey_status ? get_brief_response?.survey_status : 0
    );

    if (get_brief_response?.response?.stored_response?.answers) {
      setQuestionAnswers(
        get_brief_response?.response?.stored_response?.answers
      );
    }
    if (get_brief_response?.response?.stored_response?.feedback) {
      setSurveyFeedbackResp(
        get_brief_response?.response?.stored_response?.feedback
      );
    }

    // if (get_brief_response?.response?.stored_response?.modular_response) {
    //   setSurveyModularResp(
    //     get_brief_response?.response?.stored_response?.modular_response
    //   );
    // }

    if (get_brief_response?.response?.stored_response?.survey_personality) {
      setSurveyPersonalityResp(
        get_brief_response?.response?.stored_response?.survey_personality
      );
    }
   
  }, [get_brief_response, questionNo, totalQuestions]);

  useEffect(() => {
    // Run! Like go get some data from an API.
    if (token) {
      dispatch({
        type: SG_START_BRIEF_SURVEY,
        payload: `?token=${token}`,
      });
    }
  }, [dispatch, token]);

  // console.log("completedRuleFeedback", completedRuleFeedback)
  // console.log("get_brief_response", get_brief_response)

  function submitAnswer() {
    const CompleteCategories = {
      ...questionAnswers?.categories,
      ...prevFoundResponse,
      ...neverAskResponse,
    };
    if (CompleteCategories) {
      CompleteCategories[0] = primaryCategory;
    }
    if (totalQuestions?.questions && questionAnswers?.questions) {
      if (
        totalQuestions?.questions >
        Object.values(questionAnswers?.questions).length
      ) {
        // console.log("error found")
        setSubmitError("There was error in the submition please try again!");
        return;
      }
    }

    let _completeCategories = [];
    if (CompleteCategories) {
      _completeCategories = Object.values(CompleteCategories);
    }

    let _completeQuestions = [];
    if (questionAnswers.questions) {
      _completeQuestions = Object.values(questionAnswers.questions);
    }

    let _completeComments = [];
    if (questionAnswers.comments) {
      _completeComments = Object.values(questionAnswers.comments);
    }

    // const QuestionArray = Object.values(questionAnswers.questions).filter(qa => qa.response !== undefined)
    // const surveyComponents = get_brief_response?.modular_response
    // console.log({
    //   questions: {
    //     questions: _completeQuestions,
    //     categories: _completeCategories,
    //     comments: _completeComments,
    //   },
    //   total_question_map: totalQuestions,
    //   feedback: completedRuleFeedback,
    //   survey_personality: surveyPersonalityResp,
    //   modular_response: surveyModularResp,
    // });
    dispatch({
      type: SG_POST_BRIEF_RESPONSE,
      payload: {
        survey_token: briefResponse?.survey_token,
        debrief_schedule: briefResponse?.debrief_schedule,
        employee: briefResponse?.employee,
        validation_token: null,
        response: {
          questions: {
            questions: _completeQuestions,
            categories: _completeCategories,
            comments: _completeComments,
          },
          total_question_map: totalQuestions,
          feedback: completedRuleFeedback,
          survey_personality: surveyPersonalityResp,
          modular_response: surveyModularResp,
        },
        full_response: {
          isBrowser: isBrowser,
          isMobile: isMobile,
          browser: deviceDetected.browserName,
          deviceType,
          osVersion,
          osName,
          deviceDetected: deviceDetected,
          response: {
            questions: {
              questions: _completeQuestions,
              categories: _completeCategories,
              comments: _completeComments,
            },
            total_question_map: totalQuestions,
            feedback: completedRuleFeedback,
            survey_personality: surveyPersonalityResp,
            modular_response: surveyModularResp,
          },
        },
      },
    });
    dispatch({
      type: RESET_VALIDATION_TOKEN,
      payload: null,
    });
    dispatch({
      type: CLEAR_ALL,
      payload: null,
    });
    setThankMessage(true);
  }

  const IntroQuestionAnswer = () => {
    let CatLength = 0;
    if (categoryQues) {
      CatLength = categoryQues?.length;
    }
    const NewValidationToken = uuidv4();

    const payload = {
      current_question: CatLength > 0 ? 0 : 1,
      current_answer: 0,
      high_answer: 0,
      answers: {
        categories: {},
        questions: {},
        comments: {},
      },
      complete: false,
      intro_completed: true,
      validation_token: NewValidationToken,
      modular_response: get_brief_response?.modular_response,
    };

    setDirection(1);
    dispatch({
      type: NEW_VALIDATION_TOKEN,
      payload: NewValidationToken,
    });
    dispatch({
      type: UPDATE_BRIEF_RESPONSE,
      payload: {
        total_categories: CatLength,
        total_questions: totalQuestions?.runningTotal,
        current_category: CatLength > 0 ? 1 : 0,
        category_answer: 0,
        high_category: 0,
        total_question_map: totalQuestions,
        ...payload,
      },
    });
    // persist with api
    if (briefResponseId) {
      dispatch({
        type: SG_UPDATE_BRIEF_RESPONSE,
        payload: {
          id: briefResponseId,
          stored_response: payload,
          validation_token: NewValidationToken,
          query: `?token=${token}&start=true`,
        },
      });
    }

    setShowIntro(true);
    nextQuestion();
  };

  const Intro2QuestionAnswer = () => {
    setShowIntro2(true);
    const payload = {
      visual_question: visualQuestionNo,
      current_question: questionNo,
      current_answer: questionNo,
      high_answer:
        questionNo > briefResponse?.high_answer
          ? questionNo
          : briefResponse?.high_answer,
      answers: questionAnswers,
      complete: false,
      intro_completed: showIntro,
      intro2_completed: true,
      validation_token: validationToken,
      survey_personality: get_brief_response?.survey_personality,
      in_survey_personality: get_brief_response?.in_survey_personality,
      modular_response: get_brief_response?.modular_response,
    };
    dispatch({
      type: UPDATE_BRIEF_RESPONSE,
      payload: {
        intro2_completed: true,
      },
    });
    if (briefResponseId) {
      dispatch({
        type: SG_UPDATE_BRIEF_RESPONSE,
        payload: {
          id: briefResponseId,
          stored_response: payload,
          query: `?token=${token}`,
          validation_token: validationToken,
        },
      });
    }
  };

  const saveCategoryAnswer = (response) => {
    let _answers = questionAnswers?.categories;
    setDirection(1);
    if (_answers?.questionNo) {
      _answers.questionNo = response;
    } else {
      _answers[questionNo] = response;
    }
    setQuestionAnswers({
      categories: _answers,
      questions: questionAnswers?.questions,
      comments: questionAnswers?.comments,
    });

    let _complete = PreCloseSurvey();
    // payload for storing response
    const payload = {
      visual_question: visualQuestionNo,
      current_question: questionNo,
      current_answer: questionNo,
      high_answer:
        questionNo > briefResponse?.high_answer
          ? questionNo
          : briefResponse?.high_answer,
      answers: {
        questions: questionAnswers?.questions,
        comments: questionAnswers?.comments,
        categories: _answers,
      },
      complete: _complete,
      intro_completed: showIntro,
      intro2_completed: showIntro2,
      validation_token: validationToken,
      in_survey_personality:
        get_brief_response?.stored_response?.in_survey_personality,
      feedback: surveyFeedbackResp,
      survey_personality: surveyPersonalityResp,
      modular_response: surveyModularResp,
    };

    dispatch({
      type: UPDATE_BRIEF_RESPONSE,
      payload,
    });

    // persist with api
    if (briefResponseId) {
      dispatch({
        type: SG_UPDATE_BRIEF_RESPONSE,
        payload: {
          id: briefResponseId,
          stored_response: payload,
          query: `?token=${token}`,
          validation_token: validationToken,
        },
      });
    }
  };

  const saveQuestionAnswer = (response) => {
    let _answers = questionAnswers.questions;
    setDirection(1);
    if (_answers?.questionNo) {
      _answers.questionNo = response;
    } else {
      _answers[questionNo] = response;
    }
    setQuestionAnswers({
      categories: questionAnswers?.categories,
      comments: questionAnswers?.comments,
      questions: _answers,
    });
    let _complete = PreCloseSurvey();
    const payload = {
      visual_question: visualQuestionNo,
      current_question: questionNo,
      current_answer: questionNo,
      high_answer:
        questionNo > briefResponse?.high_answer
          ? questionNo
          : briefResponse?.high_answer,
      answers: {
        categories: questionAnswers?.categories,
        comments: questionAnswers?.comments,
        questions: _answers,
      },
      complete: _complete,
      intro_completed: showIntro,
      intro2_completed: showIntro2,
      validation_token: validationToken,
      in_survey_personality:
        get_brief_response?.stored_response?.in_survey_personality,
      feedback: surveyFeedbackResp,
      survey_personality: surveyPersonalityResp,
      modular_response: surveyModularResp,
    };
    dispatch({
      type: UPDATE_BRIEF_RESPONSE,
      payload,
    });
    // persist with api
    if (briefResponseId) {
      dispatch({
        type: SG_UPDATE_BRIEF_RESPONSE,
        payload: {
          id: briefResponseId,
          stored_response: payload,
          query: `?token=${token}`,
          validation_token: validationToken,
        },
      });
    }
  };

  const saveCommentAnswer = (response) => {
    // console.log(response)
    let _answers = questionAnswers.comments;
    setDirection(1);
    if (_answers?.questionNo) {
      _answers.questionNo = response;
    } else {
      _answers[questionNo] = response;
    }

    setQuestionAnswers({
      categories: questionAnswers?.categories,
      questions: questionAnswers?.questions,
      comments: _answers,
    });

    let _complete = PreCloseSurvey();

    setComplete(_complete);
    const payload = {
      visual_question: visualQuestionNo,
      current_question: questionNo,
      current_answer: questionNo,
      high_answer:
        questionNo > briefResponse?.high_answer
          ? questionNo
          : briefResponse?.high_answer,
      answers: {
        categories: questionAnswers?.categories,
        questions: questionAnswers?.questions,
        comments: _answers,
      },
      complete: _complete,
      intro_completed: showIntro,
      intro2_completed: showIntro2,
      validation_token: validationToken,
      in_survey_personality:
        get_brief_response?.stored_response?.in_survey_personality,
      feedback: surveyFeedbackResp,
      survey_personality: surveyPersonalityResp,
      modular_response: surveyModularResp,
    };
    dispatch({
      type: UPDATE_BRIEF_RESPONSE,
      payload,
    });
    // persist with api
    if (briefResponseId) {
      dispatch({
        type: SG_UPDATE_BRIEF_RESPONSE,
        payload: {
          id: briefResponseId,
          stored_response: payload,
          query: `?token=${token}`,
          validation_token: validationToken,
        },
      });
    }
  };

  // console.log(briefResponse?.high_answer)
  // console.log("questionNo", questionNo)
  // console.log("visualQuestionNo", visualQuestionNo)

  const forward = () => {
    if (briefResponse?.high_answer >= questionNo) {
      setPrevResponse(null);
      setDirection(1);
      if (questionNo + 1 <= totalQuestions.runningTotal) {
        nextQuestion();
        setComplete(false);
      } else {
        setComplete(true);
      }
    }
  };

  const back = () => {
    if (questionNo > 1) {
      setDirection(0);
      setPrevResponse(null);
      setComplete(false);
      if (questionNo === totalQuestions.runningTotal) {
        setComplete(false);
        if (questionNo === briefResponse?.current_question) {
          dispatch({
            type: UPDATE_BRIEF_RESPONSE,
            payload: {
              current_question: 0,
              current_answer: 0,
              complete: false,
            },
          });
        } else {
          prevQuestion();
        }
      } else {
        prevQuestion();
      }
    }
  };

  const getBackgroundColor = () => {
    if (background) {
      return "#66D686";
    }
    if (organizationInfo?.survey_background) {
      return organizationInfo.survey_background;
    }
    return "#002046";
  };

  const getTextColor = () => {
    if (organizationInfo?.survey_button_text) {
      return organizationInfo.survey_button_text;
    }
    return "#4048FF";
  };

  const getSurveyFont = () => {
    if (organizationInfo?.survey_font) {
      return organizationInfo.survey_font;
    }
    return "Poppins, sans-serif;";
  };

  useEffect(() => {
    if (get_errors?.errors?.error?.request?.response) {
      let RESP = JSON.parse(get_errors?.errors?.error?.request?.response);
      setSubmitError(RESP?.detail);
    }
  }, [get_errors]);

  useEffect(() => {
    // setPageLoading(true);
    setDefaultMessage({
      title: "Looking for Survey",
      subTitle: "Please wait while we try and get that survey loaded",
    });
    const timer1 = setTimeout(() => {
      setDefaultMessage({
        title: "No Survey details found",
        subTitle:
          "Unfortunately we have not been able to retrive any information on this survey",
      });
    }, 5000);
    return () => clearTimeout(timer1);
  }, [noResponse]);

  const Main_section = () => {
    // const introText = "Innerlogic processes your personal data and aggregates responses on behalf of OTP. Your personal data is never directly personally identifiable to your NSO and is only ever presented in an anonymized fashion. Please consult our privacy statement for further details."
    const consentText =
      "Do you agree with your personal data being processed as described above and in our privacy statement? You must click accept in order to give your feedback.";
    const consentTextFR =
      "Acceptez-vous que vos données personnelles soient traitées comme décrit ci-dessus et dans notre déclaration de confidentialité ? Vous devez cliquer sur accepter pour donner votre avis.";

    const EndText =
      "Congratulations on completing every question in this survey! Your valuable insights matter to us. Go ahead and hit that 'Submit' button to share your thoughts and help shape our organization's future.";
    const EndTextFR =
      "Vous avez répondu à toutes les questions de ce sondage. Merci de soumettre vos réponses.";

    const customAgreement = (
      <Agreement
        agreementText={language === 1 ? consentTextFR : consentText}
        color={"white"}
        handleConsentCb={(checked) => setConsentProvided(checked)}
        hasConsented={consentProvided}
        language={language}
      />
    );

    if (!showIntro) {
      // ask for existing data in storage
      if (storedResponseFound) {
        return (
          <ExistingDataFound
            show={true}
            setQuestionNo={setQuestionNo}
            setVisualQuestionNo={setVisualQuestionNo}
            setShowIntro={setShowIntro}
            setShowIntro2={setShowIntro2}
            token={token}
            briefResponseId={briefResponseId}
            validationToken={validationToken}
            storedResponse={get_brief_response?.response?.stored_response}
          />
        );
      }

      return (
        <CCIntro
          key={questionNo}
          title={
            organizationInfo?.survey_logo ? (
              <Logo
                src={
                  showIntro
                    ? organizationInfo?.survey_logo
                    : organizationInfo?.survey_logo_end
                }
                fade={showIntro}
              />
            ) : (
              <TitleTop show={!showIntro}>
                {language === 1
                  ? "Indice de Culture Sportive"
                  : "Sport Culture Index"}
              </TitleTop>
            )
          }
          intro={"test"}
          setShowIntro={setShowIntro}
          saveAnswer={IntroQuestionAnswer}
          consentText={consentText}
          consentCheckedCb={(response) => setConsentProvided(response)}
          customElement={customAgreement}
          surveyEnabled={consentProvided}
          textColor={getTextColor()}
          background={getBackgroundColor()}
          language={language}
          customContent={get_brief_response?.response?.questions}
        />
      );

      // return <Intro
      // 	key={questionNo}
      // 	title={organizationInfo?.survey_logo ? <Logo src={showIntro ? organizationInfo?.survey_logo : organizationInfo?.survey_logo_end} fade={showIntro} />
      // 		: <TitleTop show={!showIntro}>{language === 1 ? 'Indice de Culture Sportive' : 'Sport Culture Index'}</TitleTop>}
      // 	intro={'test'}
      // 	setShowIntro={setShowIntro}
      // 	saveAnswer={IntroQuestionAnswer}
      // 	consentText={consentText}
      // 	consentCheckedCb={(response) => setConsentProvided(response)}
      // 	customElement={customAgreement}
      // 	surveyEnabled={consentProvided}
      // 	textColor={getTextColor()}
      // 	background={getBackgroundColor()}
      // 	language={language}
      // 	customContent={get_brief_response?.response?.questions}

      // />
    }

    // comments feedback as for more info based on rules set for min - max for each question
    if (askFeedback) {
      return (
        <CommentsFeedback
          ruleFeedback={ruleFeedback}
          setRuleFeedback={setRuleFeedback}
          setCompletedRuleFeedback={setCompletedRuleFeedback}
          language={language}
          setRuleFeedbackComplete={setRuleFeedbackComplete}
          key={feedbackKey}
          setFeedbackKey={() => setFeedbackKey(feedbackKey + 1)}
          setAskFeedback={setAskFeedback}
          setComplete={setComplete}
        />
      );
    }

    if (totalQuestions?.sequence[questionNo]?.quesType === categoryType) {
      // console.log("totalQuestions?.sequence[questionNo]?.quesType === categoryType 1205")
      return (
        <CategoryQuestion
          key={questionNo}
          save={saveCategoryAnswer}
          prevResponse={prevResponse}
          data={categoryQues[questionNo - 1]}
          employeeCategory={employeeCategory}
          textColor={getTextColor()}
          language={language}
        />
      );
    }

    if (!showIntro2) {
      return (
        <Intro2
          saveAnswer={Intro2QuestionAnswer}
          textColor={getTextColor()}
          background={getBackgroundColor()}
          surveyName={surveyName}
          organizationInfo={organizationInfo}
          language={language}
          organization={get_brief_response?.response?.organization_name}
          primary={get_brief_response?.response?.employee_category?.find(
            (f) => f?.priority == "primary"
          )}
          text={
            language == 1
              ? get_brief_response?.response?.questions?.intro2_fr
                ? get_brief_response?.response?.questions?.intro2_fr
                : get_brief_response?.response?.questions?.intro2
              : get_brief_response?.response?.questions?.intro2
          }
        />
      );
    }

    // console.log("totalQuestions?.sequence[questionNo]?.quesType", totalQuestions?.sequence[questionNo]?.quesType)
    // console.log("complete", complete)

    // regular question answer template
    if (!complete) {
      if (totalQuestions?.sequence[questionNo]?.quesType === questionsType) {
        return (
          <QuestionTemplate
            key={questionNo}
            saveQuestionAnswer={saveQuestionAnswer}
            response={prevResponse}
            textColor={getTextColor()}
            language={language}
            type={get_brief_response?.response?.questions?.type}
            setBackground={(b) => setBackground(b)}
            qualifier={organizationInfo?.survey_qualifier}
            question={
              questions.filter(
                (q) => q.order === questionNo - totalQuestions?.category
              )[0]
            }
          />
        );
      }
      if (totalQuestions?.sequence[questionNo]?.quesType === commentsType) {
        if (!showIntro3) {
          return (
            <Intro3
              saveAnswer={() => setShowIntro3(true)}
              textColor={getTextColor()}
              background={getBackgroundColor()}
              surveyName={surveyName}
              language={language}
              organizationInfo={organizationInfo}
              organization={get_brief_response?.response?.organization_name}
              primary={get_brief_response?.response?.employee_category.find(
                (f) => f.priority == "primary"
              )}
              text={get_brief_response?.response?.questions?.comment_intro}
            />
          );
        }

    
        // questions which need answer in comments
        return (
          <CommentTemplate
            key={questionNo}
            // save={(r) => saveQ(r)}
            saveCommentAnswer={saveCommentAnswer}
            response={prevResponse}
            textColor={getTextColor()}
            language={language}
            question={
              askComments.filter(
                (q) => Number(q.order) === questionNo - totalQuestions?.category
              )[0]
            }
          />
        );
      }
    }

    if (showInSurveyPersonality) {
      return (
        <Personality
          setSurveyPersonalityResp={setSurveyPersonalityResp}
          setShowInSurveyPersonality={setShowInSurveyPersonality}
          setEnableNavigation={setEnableNavigation}
          briefResponseId={briefResponseId}
          token={token}
        />
      );
    }
    // console.log("modularComplete   ", modularComplete,  " complete ", complete)
    // this is the last step in main section
    if (complete) {
      // before we close check for any additonal modular questions which are enabled
      if (!modularComplete) {
        return (
          <ModularQuestion
            briefResponseId={briefResponseId}
            setModularComplete={setModularComplete}
            setEnableNavigation={setEnableNavigation}
            setSurveyModularResp={setSurveyModularResp}
            language={language}
            token={token}
            key={questionNo}
          />
        );
      }

      if (modularComplete) {
        console.log("modularComplete   ", modularComplete, " complete ", complete)
        return (
          <End
            key={questionNo}
            title={""}
            submitAnswer={() => submitAnswer()}
            language={language}
            textColor={getTextColor()}
            background={getBackgroundColor()}
            intro={language === 1 ? EndTextFR : EndText}
          />
        );
      }
    }

    return (
      <NoToken textColor={getTextColor()} background={getBackgroundColor()} />
    );
  };

  if (thankMessage) {
    const ThanksMessage = "Thank you for contributing";

    const ThanksMessageFR = "Merci pour votre réponse au sondage";

    let end_message = get_brief_response?.response?.questions?.end_message;
    let messages = [ThanksMessage, ThanksMessageFR];
    return (
      <Container background={getBackgroundColor()} font={getSurveyFont()}>
        <SurveyMessage
          language={language}
          message={end_message ? end_message[language] : messages[language]}
          onTime={new Date()}
          background={getBackgroundColor()}
          organizationInfo={organizationInfo}
          title={get_brief_response?.response?.questions?.title}
        />
      </Container>
    );
  }

  if (noResponse) {
    return (
      <SwitchTransition>
        <CSSTransition
          key={questionNo}
          timeout={TRANSITIONSPEED}
          classNames={SlideDirection[1]}
        >
          <NoToken
            textColor={getTextColor()}
            background={getBackgroundColor()}
            {...defaultMessage}
          />
        </CSSTransition>
      </SwitchTransition>
    );
  }
  if (!token) {
    return (
      <NoToken
        textColor={getTextColor()}
        background={getBackgroundColor()}
        {...defaultMessage}
      />
    );
  }

  if (submitError) {
    return (
      <Container background={getBackgroundColor()} font={getSurveyFont()}>
        <SwitchTransition>
          <CSSTransition
            key={`${questionNo}${showIntro2}`}
            timeout={TRANSITIONSPEED}
            classNames={SlideDirection[direction]}
          >
            <ReLoadPage message={submitError} />
          </CSSTransition>
        </SwitchTransition>
      </Container>
    );
  }

  if (surveyDeleted) {
    return (
      <Container background={getBackgroundColor()} font={getSurveyFont()}>
        <SwitchTransition>
          <CSSTransition
            key={questionNo}
            timeout={TRANSITIONSPEED}
            classNames={SlideDirection[direction]}
          >
            <SurveyMessage
              language={language}
              onTime={surveyDeletedOn}
              status={surveyStatus}
              organizationInfo={organizationInfo}
              background={getBackgroundColor()}
              title={get_brief_response?.response?.questions?.title}
            />
          </CSSTransition>
        </SwitchTransition>
      </Container>
    );
  }

  return (
    <Container
      toggle={language === 0}
      background={getBackgroundColor()}
      font={getSurveyFont()}
    >
      <Dimmer active={loading}>
        <Loader />
      </Dimmer>
      <SwitchTransition>
        <CSSTransition
          key={`${questionNo} ${showIntro} ${showIntro2} ${askFeedback} ${complete}`}
          timeout={TRANSITIONSPEED}
          classNames={SlideDirection[direction]}
        >
          {Main_section()}
        </CSSTransition>
      </SwitchTransition>
      {showLanguage ? (
        <Language
          selected={language}
          setSelected={setLanguage}
          color={getTextColor()}
        />
      ) : (
        ""
      )}

      {/* {enableNavigation && showIntro && (
        <CounterContainer>
          <Counter color={getTextColor()}>
            <div>
              <Title>
                {language === 1
                  ? "Indice de Culture Sportive"
                  : get_brief_response?.response?.questions?.title}
              </Title>
              {visualQuestionNo === 0 ? 1 : visualQuestionNo}{" "}
              {language === 1 ? "de" : "of"} {totalQuestions?.total} questions
            </div>
          </Counter>
          <Icon
            name="chevron up"
            disabled={
              !showPrev || (complete && !ruleFeedbackComplete) || askFeedback
            }
            style={{
              cursor:
                !showPrev || (complete && !ruleFeedbackComplete) || askFeedback
                  ? "not-allowed"
                  : "pointer",
            }}
            onClick={back}
          />
          <Space />
          <Icon
            name="chevron down"
            style={{
              cursor:
                !showPrev || (complete && !ruleFeedbackComplete) || askFeedback
                  ? "not-allowed"
                  : "pointer",
              zIndex: 2,
            }}
            disabled={
              !showNext || (complete && !ruleFeedbackComplete) || askFeedback
            }
            onClick={forward}
          />
        </CounterContainer>
      )} */}
    </Container>
  );
};

export default Audit;

const transition = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`;

const fadeout = keyframes`
  0% {opacity: 1;}
  50% {opacity: 0;}
  100% {opacity: 1;}
`;

const transition2 = keyframes`
  0% {opacity: 0;}
  100% {opacity: 1;}
`;

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  min-height: 600px;
  background-color: ${(props) => props.background};
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  font-family: ${(props) => props.font};

  animation: ${(props) =>
    props.toggle
      ? css`
          ${transition} 1.5s linear
        `
      : css`
          ${transition2} 1.5s linear
        `};
`;

const CounterContainer = styled.div`
  position: fixed;
  bottom: 10px;
  right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-size: 14px;

  @media only screen and (min-width: 992px) {
    bottom: 50px;
    right: 50px;
    font-size: 20px;
  }

  @media (max-width: 350px) {
    bottom: 20px;
  }
`;

const Counter = styled.div`
  padding: 5px 10px 5px 10px;
  background-color: ${(props) => props.color};

  font-size: 12px;
  border-radius: 7px;
  margin-right: 10px;

  @media only screen and (min-width: 992px) {
    padding: 10px 25px 10px 25px;
    font-size: 16px;
    margin-right: 25px;
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;

  @media only screen and (min-width: 992px) {
    margin-bottom: 5px;
    font-size: 20px;
  }
`;

const Space = styled.div`
  @media only screen and (min-width: 992px) {
    width: 20px;
  }
`;

const Logo = styled.img`
  width: 200px;
  margin-bottom: 10px;
  animation: ${(props) =>
    props.fade
      ? css`
          ${fadeout} 0.5s linear
        `
      : `none`};
  position: absolute;

  top: -100px;
  left: 30px;

  @media (max-width: 768px) {
    top: -100px;
    left: 30px;
  }

  @media (max-height: 800px) {
    top: 50px;
  }

  @media (max-width: 376px) {
    height: 20px;
    top: 20px;
  }

  @media (max-height: 550px) {
    top: 20px;
  }
`;

const TitleTop = styled.div`
  font-size: 40px;
  font-weight: bold;
  color: white;
  margin-top: 10px;
  display: ${(props) => (props.show ? "flex" : "none")};
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 26px;
  }

  @media (max-width: 376px) {
    height: 26px;
    font-size: 22px;
  }
`;
