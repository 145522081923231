import React, { useState,useRef,useEffect } from 'react';
import styled from 'styled-components';
import {words} from '../translations'




const Values = ({question,statement,language}) =>{

    const textareaRef = useRef();
    const [feedbackText, setFeedbackText] = useState('');

    useEffect(() => {
        if (textareaRef.current) {
          textareaRef.current.style.height = `${textareaRef.current.scrollHeight -16}px`;
        }
      }, []);
    
      const handleInput = (e) => {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight -16}px`;
      };
    
      const handleChange = (event) => {
        setFeedbackText(event.target.value);
      };


    return (

        <FeedbackContainer>
            <QuestionText>{statement}</QuestionText>
            <Description>
            {question}
            </Description>
            <StyledTextarea 
                value={feedbackText} 
                onChange={handleChange} 
                placeholder="Type your answer here"
                ref={textareaRef}
                onInput={handleInput}/>
        </FeedbackContainer>

    )
}


export default Values;


const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width:600px;
  font-family: 'Raleway', sans-serif;
`;

const QuestionText = styled.h2`
  margin-bottom: 1rem;
  color:#2A3039;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
`;

const Description = styled.div`
color: #2A3039;
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
margin-bottom:26px;
line-height: 150%;
`


const StyledTextarea = styled.textarea`
  display: block;
  width: 100%; 
  padding: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #2A3039;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: none;
  font-family: 'Raleway', sans-serif;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #2D70E2;
    outline: 0;
  }
`;