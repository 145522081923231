import React from "react";
import { Icon } from "semantic-ui-react";
import styled from 'styled-components';

const Oval = styled.div`
  width: 16px;
  height: 16px;
  border-radius:8px;
  border-style: solid;
  border-width: 1px;
  border-color:${props=>'rgb('+props.color+')'};
  background-color: ${props=>props.selected?'rgb('+props.color+')' :"white"};
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  margin-right:14px;
  min-width:16px;

  @media only screen and (min-width:992px){
    width: 24px;
  height: 24px;
  border-radius:12px;
  min-width:24px;
  }
`


const Selection = (props) => {

  return (
    <Oval color={props.color} selected={props.selected}>
        {props.selected?<Icon
          name="check"
          size={"small"}
          style={{ margin: 0, color: "white" }}
        />:""}
    </Oval>
  );
};

export default Selection