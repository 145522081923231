import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
    UPDATE_BRIEF_RESPONSE,
    SG_UPDATE_BRIEF_RESPONSE
} from "constants/actions";

import { SwitchTransition, CSSTransition } from "react-transition-group";
import { Icon } from "semantic-ui-react";

import PersonalityIntro from './PersonalityIntro'
import Personality from './Personality'
import {personalityQuestionList, personalityWordList} from "constants/questionTypes"


const InSurveyPersonality = ({ setSurveyPersonalityResp,
    setShowInSurveyPersonality,
    setEnableNavigation,
    briefResponseId,
    token }) => {
        const dispatch = useDispatch();
    const [answer, setAnswer] = useState({})
    const [q, setQ] = useState(-1)
    const [direction, setDirection] = useState("right")

    const { get_brief_response } = useSelector(
        (state) => ({
            get_brief_response: state.briefresponse,
        }),
        shallowEqual
    );

    function PrevQuestion() {
        if ((q -1) >= 0) {
            setQ(q - 1)
            setDirection("right")
        }
    }

    function NextQuestion() {
        if ((q) < personalityQuestionList.length) {
            setQ(q + 1)
            setDirection("left")
        }
    }

    function StartPersonality() {
        setQ(0)
        setEnableNavigation(false)
    }

    
    function UpdateAnswer(e) {
        console.log("updated answer", e, q)
        if ((q) < personalityQuestionList.length && q >= 0) {

            setQ(q + 1)
            const _answer = answer
            _answer[q + 1] = {
                question: q + 1,
                response: e
            }
            setAnswer(_answer)
            setSurveyPersonalityResp(_answer)
            if ((q + 1) === personalityQuestionList.length) {
                CompletedPersonality(_answer)
            }
        }
    }


    function CompletedPersonality(_answer) {
        const payload = {
            visual_question: get_brief_response?.visual_question,
            current_question: get_brief_response?.current_question,
            current_answer: get_brief_response?.current_answer,
            high_answer: get_brief_response?.high_answer,
            answers: get_brief_response?.answers,
            complete: get_brief_response?.complete,
            intro_completed: get_brief_response?.intro_completed,
            intro2_completed: get_brief_response?.intro2_completed,
            validation_token: get_brief_response?.validation_token,
            feedback: get_brief_response?.feedback,
            modular_response: get_brief_response?.modular_response,
            survey_personality: _answer,
            in_survey_personality: true,
        }
        dispatch({
            type: UPDATE_BRIEF_RESPONSE,
            payload
        });
        // persist with api
        if (briefResponseId) {
            dispatch({
                type: SG_UPDATE_BRIEF_RESPONSE,
                payload: {
                    id: briefResponseId,
                    query: `?token=${token}`,
                    validation_token: get_brief_response?.validation_token,
                    ...(payload && { stored_response: payload }),
                }
            })
        }
        setShowInSurveyPersonality(false)
        setEnableNavigation(true)

    }
    // console.log(questions.length)
    // console.log(answer[q - 1])
    // console.log(answer[q + 1])
    // console.log(answer)
    // console.log(answer[q + 1] === undefined)

    if (q === personalityQuestionList.length) {
        return (<SwitchTransition>
            <CSSTransition
                key={q}
                addEndListener={(node, done) => {
                    node.addEventListener("transitionend", done, false);
                }}
                classNames="feedback"
            >
                <PersonalityIntro start={() => setQ(0)} />
            </CSSTransition>
        </SwitchTransition>
        )
    }


    if (q === -1) {
        return (<SwitchTransition>
            <CSSTransition
                key={q}
                addEndListener={(node, done) => {
                    node.addEventListener("transitionend", done, false);
                }}
                classNames="feedback"
            >
                <PersonalityIntro start={StartPersonality} />
            </CSSTransition>
        </SwitchTransition>
        )
    }

    return (
        <>

            <SwitchTransition>
                <CSSTransition
                    key={q}
                    addEndListener={(node, done) => {
                        node.addEventListener("transitionend", done, false);
                    }}
                    classNames={`feedback-${direction}`}
                >
                    <SurveyContainer>

                        <Personality
                            q={q}
                            answer={answer}
                            UpdateAnswer={UpdateAnswer}
                            words={personalityWordList}
                            questions={personalityQuestionList}
                        />

                    </SurveyContainer>
                </CSSTransition>
            </SwitchTransition>
            <CounterContainer>
            <Icon
					name="chevron up"
                    disabled={answer[q] === undefined}
                    onClick={PrevQuestion}
				/>
				<Space />
				<Icon
					name="chevron down"
					disabled={answer[q + 1] === undefined}
                        onClick={NextQuestion}
				/>
                {/* <Button.Group>
                    <Button icon='left chevron'
                        disabled={answer[q - 1] === undefined}
                        onClick={PrevQuestion} />
                    <Button icon='right chevron'
                        disabled={answer[q + 1] === undefined}
                        onClick={NextQuestion} />
                </Button.Group> */}
            </CounterContainer>

        </>



    );

};

export default InSurveyPersonality;

const SurveyContainer = styled.div`
  padding: 0px;
  box-sizing: border-box;
  position: absolute;
  left: 0%;
  top: 10%;
  padding: 20px;
  width:100%;
   max-width:650px;


  @media only screen and (min-width: 768px) {
    padding: 30px;
    left: calc(50% - 350px);
    top: 15%;
  }
`;

const ScrollContainer = styled.div`
  max-width: 650px;
  height: 35px;
  margin-top: 100px;
  display: flex;

  justify-content: flex-end;

  @media only screen and (min-width: 992px) {
    width: 600px;
    margin-top: 70px;
  }
`;

const Space = styled.div`
  @media only screen and (min-width: 992px) {
    width: 20px;
  }
`;

const CounterContainer = styled.div`
  position: fixed;
  bottom: 10px;
  right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-size: 14px;
  z-index: 999;

  @media only screen and (min-width: 992px) {
    bottom: 50px;
    right: 50px;
    font-size: 20px;
  }

   @media(max-width:350px){
  	bottom:20px;
  }
`;