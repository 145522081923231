import { put, call, all, takeLatest } from "redux-saga/effects";

import {
  PENDING_MARKETING_ROI,
  MARKETING_ROI,
  SG_MARKETING_ROI,
  ALL_ERRORS,
} from "constants/actions";

import * as api from "constants/api";

function* getMarketingRoi(action) {
  yield put({ type: PENDING_MARKETING_ROI });
  try {
    const json = yield call(api.POST_MARKETING_ROI, action.payload);
    yield put({ type: MARKETING_ROI, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* loadMarketingRoi() {
  yield takeLatest(SG_MARKETING_ROI, getMarketingRoi);
}


export default function* index() {
  yield all([loadMarketingRoi()
  ]);
}

