

export const structurePrompt = (responses,language) => {

    if(language == 'EN'){
        return structureENPrompt(responses)
    }

    if(language == 'FR'){
        return structureFRPrompt(responses)
    }

    if(language == 'ES'){
        return structureESPrompt(responses)
    }
   


}

const structureENPrompt = (responses) => {
    let follow_up = false
    let prompt = 'The following are the quantitative responses to a culture survey from a respondent:\n\n'
    Object.keys(responses).map((i) => {
        if(responses[i].type == "quantitative"){
            prompt += `Question: ${responses[i].q.question}\Score: ${responses[i].response} /10\n\n`
        }
        if(responses[i].type == 'ai'){
            follow_up = true
            prompt +='\n\n Based on the quantitative questions, we have already generated the following follow up question(s), and included are the respondents answer(s):\n'
            prompt += `Question: ${responses[i].question}\nResponse: ${responses[i].response}\n\n`
        }
        
    })

    if(follow_up){

        prompt += `## TASK:\nYour goal is to generate a follow up question based on the respondents last response that is gets feedback that is actionable and can be used to generate recommendations for the organization. 
        Dont be ambiguous about the question. You should focus on understanding why the respondent gave the response they did, and provide enough information for the 
        respondent to understand what you are asking. If the scores are predominantly positive, then you should seek to understand what is working well and why.
        You are trying gain a better understanding of the respondents perspective and potential solution. The goal here is to understand to the greatest extent possible the cause and potential solutions to the lowest
        scoring areas. Deeper context and understanding about a single very low score is always better then shallow understanding of multiple scores. \n\n
        The question should be simple enough for a 7th grader to understand. Only ever return the question itself. Never reference a question number. Be compassionate and empathetic in your question.
        Act as a coach, not a judge. \n\n
        Question:\n`

    }else{
        prompt += `## TASK:\nYour goal is to generate a follow up question which is designed to get feedback which will
        get the respondent to elaborate on their score in such a way that the feedback is actionable and can be used to generate recommendations for the organization. 
        Dont be ambiguous about the score or question. You should focus on understanding why the respondent gave the score they did, and provide enough information for the 
        respondent to understand what you are asking. If the scores are predominantly positive, then you should seek to understand what is working well and why.
        The question should be simple enough for a 7th grader to understand. Only ever return the question itself. Never reference a question number. Be compassionate and empathetic in your question.
        Act as a coach, not a judge. \n\n
        Question:\n`

    }

    return prompt
}

const structureFRPrompt = (responses) => {
    let follow_up = false
    let prompt = 'Les réponses quantitatives suivantes sont celles d\'un répondant à une enquête sur la culture:\n\n'
    Object.keys(responses).map((i) => {
        if(responses[i].type == "quantitative"){
            prompt += `Question: ${responses[i].q.question}\Score: ${responses[i].response} /10\n\n`
        }
        if(responses[i].type == 'ai'){
            follow_up = true
            prompt +='\n\n Sur la base des questions quantitatives, nous avons déjà généré la ou les questions de suivi suivantes, et les réponses des répondants sont incluses:\n'
            prompt += `Question: ${responses[i].question}\nRéponse: ${responses[i].response}\n\n`
        }
        
    })

    if(follow_up){

        prompt += `## TÂCHE:\nVotre objectif est de générer une question de suivi basée sur la dernière réponse du répondant qui obtient des commentaires qui sont exploitables et peuvent être utilisés pour générer des recommandations pour l'organisation. 
        Ne soyez pas ambigu sur la question. Vous devriez vous concentrer sur la compréhension de pourquoi le répondant a donné la réponse qu'il a donnée, et fournir suffisamment d'informations pour que le répondant comprenne ce que vous demandez. Si les scores sont principalement positifs, alors vous devriez chercher à comprendre ce qui fonctionne bien et pourquoi.
        Vous essayez de mieux comprendre la perspective du répondant et la solution potentielle. L'objectif ici est de comprendre dans la plus grande mesure possible la cause et les solutions potentielles aux domaines les moins bien notés. Un contexte et une compréhension plus approfondis sur un seul score très bas sont toujours meilleurs qu'une compréhension superficielle de plusieurs scores. \n\n
        La question doit être assez simple pour qu'un élève de 7e année la comprenne. Ne retournez jamais que la question elle-même. Ne faites jamais référence à un numéro de question. Soyez compatissant et empathique dans votre question.
        Agissez en tant que coach, pas en tant que juge. \n\n
        Question:\n`

    }else{
        prompt += `## TÂCHE:\nVotre objectif est de générer une question de suivi conçue pour obtenir des commentaires qui permettront au répondant d'élaborer sur sa note de manière à ce que les commentaires soient exploitables et puissent être utilisés pour générer des recommandations pour l'organisation. 
        Ne soyez pas ambigu sur la note ou la question. Vous devriez vous concentrer sur la compréhension de pourquoi le répondant a donné la note qu'il a donnée, et fournir suffisamment d'informations pour que le répondant comprenne ce que vous demandez. Si les scores sont principalement positifs, alors vous devriez chercher à comprendre ce qui fonctionne bien et pourquoi.
        La question doit être assez simple pour qu'un élève de 7e année la comprenne. Ne retournez jamais que la question elle-même. Ne faites jamais référence à un numéro de question. Soyez compatissant et empathique dans votre question.
        Agissez en tant que coach, pas en tant que juge. \n\n
        Question:\n`

    }

    return prompt
}

const structureESPrompt = (responses) => {
    let follow_up = false
    let prompt = 'Las siguientes respuestas cuantitativas son de un encuestado a una encuesta de cultura:\n\n'
    Object.keys(responses).map((i) => {
        if(responses[i].type == "quantitative"){
            prompt += `Pregunta: ${responses[i].q.question}\Puntuación: ${responses[i].response} /10\n\n`
        }
        if(responses[i].type == 'ai'){
            follow_up = true
            prompt +='\n\n Basado en las preguntas cuantitativas, ya hemos generado la(s) siguiente(s) pregunta(s) de seguimiento, y se incluyen las respuestas de los encuestados:\n'
            prompt += `Pregunta: ${responses[i].question}\Respuesta: ${responses[i].response}\n\n`
        }
        
    })

    if(follow_up){

        prompt += `## TAREA:\nSu objetivo es generar una pregunta de seguimiento basada en la última respuesta del encuestado que obtenga comentarios que sean accionables y se puedan utilizar para generar recomendaciones para la organización. 
        No sea ambiguo sobre la pregunta. Debería centrarse en comprender por qué el encuestado dio la respuesta que dio y proporcionar suficiente información para que el encuestado comprenda lo que está preguntando. Si las puntuaciones son predominantemente positivas, entonces debería tratar de comprender qué está funcionando bien y por qué.
        Está intentando comprender mejor la perspectiva del encuestado y la solución potencial. El objetivo aquí es comprender en la mayor medida posible la causa y las soluciones potenciales a las áreas menos puntuadas. Un contexto y una comprensión más profundos sobre una sola puntuación muy baja siempre son mejores que una comprensión superficial de múltiples puntuaciones. \n\n
        La pregunta debe ser lo suficientemente simple para que un estudiante de séptimo grado la entienda. Solo devuelva la pregunta en sí misma. Nunca haga referencia a un número de pregunta. Sea compasivo y empático en su pregunta.
        Actúe como un entrenador, no como un juez. \n\n
        Pregunta:\n`

    }else{
        prompt += `## TAREA:\nSu objetivo es generar una pregunta de seguimiento diseñada para obtener comentarios que permitan al encuestado elaborar sobre su puntuación de tal manera que los comentarios sean accionables y se puedan utilizar para generar recomendaciones para la organización.
        No sea ambiguo sobre la puntuación o la pregunta. Debería centrarse en comprender por qué el encuestado dio la puntuación que dio y proporcionar suficiente información para que el encuestado comprenda lo que está preguntando. Si las puntuaciones son predominantemente positivas, entonces debería tratar de comprender qué está funcionando bien y por qué.
        La pregunta debe ser lo suficientemente simple para que un estudiante de séptimo grado la entienda. Solo devuelva la pregunta en sí misma. Nunca haga referencia a un número de pregunta. Sea compasivo y empático en su pregunta.
        Actúe como un entrenador, no como un juez. \n\n
        Pregunta:\n`

    }

    return prompt

}