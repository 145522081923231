import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const CustomDropdown = ({ id, listId, value, options, onSelect,questionLength,language }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState('');

  const handleSelect = (option) => {
    setSelected(option);
    setOpen(false);
    onSelect(option);
  };

  useEffect(() =>{
    if (value) {
      setSelected(value)
    } else {
      setSelected('')
    }
  },[value])
  
  const getOptionName = (option) => {
    if (language === 'FR' && option?.fr) {
      return option.fr
    } 
    if (language === 'ES' && option?.es) {
      return option.es
    }
    return option.name

  
  }


  const getSelectedName = (selectedOption) => {
    if (language === 'FR' && selectedOption?.fr) {
      return selectedOption.fr
    } 
    if (language === 'ES' && selectedOption?.es) {
      return selectedOption.es
    }
    return selectedOption.name
  }



  return (
    <DropdownWrapper>
    <DropdownButton onClick={() => setOpen(!open)} id={id}>
      {getSelectedName(selected)}
      {}
      <Chevron open={open} />
    </DropdownButton>
    <DropdownContent open={open} id={listId} questionLength={questionLength}>
      {options.map((option, index) => (
        <DropdownItem key={index} onClick={() => handleSelect(option)}>
          {getOptionName(option)}
        </DropdownItem>
      ))}
    </DropdownContent>
  </DropdownWrapper>
  )
};

export default CustomDropdown;

const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  font-family: 'Raleway';
`;

const DropdownButton = styled.button`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid blue;
  cursor: pointer;
  width: 100%;
  text-align: left;
  padding: 8px 16px;
  font-size: 16px;
  color: #666D79;
  outline: none;
  font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

`;

const DropdownContent = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: absolute;
  background-color: white;
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
  max-height: 225px;
  overflow-y: auto;
  @media (max-height: 775px) {
    max-height:175px;
  }

  @media (max-height: 700px) {
    max-height:150px;
  }

  @media (max-height: 650px) {
    max-height:125px;
  }

  @media (max-height: 600px) {
    max-height:75px;
  }
`;

const DropdownItem = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;

const Chevron = styled.i`
  float: right;
  border: solid #666D79;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: ${({ open }) => (open ? 'rotate(-135deg)' : 'rotate(45deg)')};
`;