import React, { useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SET_ANSWERS } from "constants/actions";

import { Icon } from "semantic-ui-react";
import { CounterContainer, Counter, Title, Space } from "./styledList";

const SurveyList = [
  {
    id: 1,
    name: "Team health",
  },
  {
    id: 2,
    name: "Mood",
  },
  {
    id: 3,
    name: "Leadership",
  },
];
const BottomBar = ({ setDirection, surveyType }) => {
  const dispatch = useDispatch();
  const [currQuestion, setCurrQuestion] = useState(0);
  const [maxQuestion, setMaxQuestion] = useState(0);
  const [highAnswer, setHighAnswer] = useState(0);

  const { get_answers } = useSelector(
    (state) => ({
      get_answers: state.answers,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    switch (surveyType) {
      case 1:
        // culture
        setMaxQuestion(get_answers.culture_max_ques);
        setCurrQuestion(get_answers.culture_cur_ques);
        setHighAnswer(get_answers.culture_high_ans);
        return;
      case 2:
        // mood
        setMaxQuestion(get_answers.mood_max_ques);
        setCurrQuestion(get_answers.mood_high_ans);
        setHighAnswer(get_answers.mood_high_ans);
        return;
      case 3:
        // leadership
        setMaxQuestion(get_answers.leadership_max_ques);
        setCurrQuestion(get_answers.leadership_cur_ques);
        setHighAnswer(get_answers.leadership_high_ans);
        return;
      default:
        setMaxQuestion(0);
        setCurrQuestion(0);
        setHighAnswer(0);
        return;
    }
  }, [get_answers, surveyType]);

  // current survey type

  // console.log(answers);

  const back = () => {
    // console.log("back");
    // if (q !== 0) {
    setDirection(0);
    //   setQ(q - 1);
    // }
    if (currQuestion - 1 > 0) {
      switch (surveyType) {
        case 1:
          // culture

          dispatch({
            type: SET_ANSWERS,
            payload: { culture_cur_ques: currQuestion - 1, curr_direction: 0 },
          });
          return;
        case 2:
          // mood
          dispatch({
            type: SET_ANSWERS,
            payload: { mood_cur_ques: currQuestion - 1, curr_direction: 0 },
          });
          return;
        case 3:
          // leadership
          dispatch({
            type: SET_ANSWERS,
            payload: {
              leadership_cur_ques: currQuestion - 1,
              curr_direction: 0,
            },
          });
          return;
        default:
          return;
      }
    }
  };

  const forward = () => {
    // console.log("forward", highAnswer);
    // if (answers.length > q) {
    setDirection(1);
    //   setQ(q + 1);
    // }

    if (currQuestion <= highAnswer && currQuestion + 1 <= maxQuestion) {
      switch (surveyType) {
        case 1:
          // culture

          dispatch({
            type: SET_ANSWERS,
            payload: { culture_cur_ques: currQuestion + 1, curr_direction: 1 },
          });
          return;
        case 2:
          // mood
          dispatch({
            type: SET_ANSWERS,
            payload: { mood_cur_ques: currQuestion + 1, curr_direction: 1 },
          });
          return;
        case 3:
          // leadership
          dispatch({
            type: SET_ANSWERS,
            payload: {
              leadership_cur_ques: currQuestion + 1,
              curr_direction: 1,
            },
          });
          return;
        default:
          return;
      }
    }
  };

  return (
    <>
      <CounterContainer type={surveyType}>
        <Counter type={surveyType}>
          <div>
            <Title>
              {surveyType
                ? SurveyList.find(({ id }) => id === surveyType)?.name
                : ""}
            </Title>
         {currQuestion >= 0? `${currQuestion} of ${maxQuestion}` : ''}
          </div>
        </Counter>
        {surveyType !==2 ? (
          <div>
        <Icon name="chevron up" style={{ cursor: "pointer" }} onClick={back} />
        <Space />
        <Icon
          name="chevron down"
          style={{ cursor: "pointer", zIndex: 2 }}
          onClick={forward}
        />
       </div> )
        : ' '}
        
      </CounterContainer>
    </>
  );
};

export default BottomBar;
