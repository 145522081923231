import styled from 'styled-components';

export const Container = styled.div`
height:100vh;
min-height:650px;
width:100vw;
background-image: ${props=>props.survey===3?
          'linear-gradient(94deg, #ffb347 2%, #ffcc33 109%)': 'none'};
display:flex;
flex-direction:row;

@media only screen and (min-width:992px){
    height:100vh;
}

`

export const CounterContainer = styled.div`
position:absolute;
bottom:10px;
right:20px;
display:flex;
flex-direction:row;
align-items:center;
color:${props=>props.type===3? 'black' :'#4a4a4a'};
font-size:14px;

 @media only screen and (min-width:992px){
    bottom:50px;
    right:50px;
    font-size:20px;
  }

  @media(max-height:650px){
    display:none;
  }
`

export const Counter = styled.div`
padding:5px 10px 5px 10px;
background-color: ${props=>props.type===1? 'rgba(30, 33, 114,0.3)' : props.type===3? 'white' : '#9b9b9b'};
font-family: 'Red Hat Display', sans-serif;
font-size:12px;
border-radius:7px;
margin-right:10px;


 @media only screen and (min-width:992px){
     padding:10px 25px 10px 25px;
     font-size:16px;
     margin-right:25px;
 }
`

export const Title = styled.div`
font-size: 14px;
font-weight: bold;
font-family: 'Red Hat Display', sans-serif;


   @media only screen and (min-width:992px){
       margin-bottom:5px;
     font-size:20px;
 }
`

export const Space= styled.div`

 @media only screen and (min-width:992px){
     width:20px;
 }
`