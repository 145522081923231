import React from "react";
import styled from "styled-components";
// import { Dropdown, Input } from 'semantic-ui-react'
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';

const PrettoSlider = withStyles({
  root: {
    color: 'white',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#FA675C',
    color:'#FA675C',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);




const Inclusion = () =>{

	  const prevScore  = 50
  const [value, setValue] = React.useState(0)
  const [started, setStarted] = React.useState(false)


  const handleChange = (event, newValue) => {
    setValue(newValue);
    setStarted(true)
  };

  const getWord = () => {
    if (value === 0 && !started) {
      return ""
    }
    if (value < 20) {
      return 'Not at all'
    }
    if (value < 40) {
      return "Not much"
    }
    if (value < 60) {
      return "Somewhat"
    }
    if (value < 80) {
      return "Mostly"
    }
    return "Absolutely"
  }

  function getDifference() {
    if (value === 0 || !prevScore) {
      return ""
    }
    if (value - prevScore < 0) {
      return <> 
      This is a&nbsp; <Change color='red'> {(value - prevScore)}% </Change> &nbsp;change from last week
      </>
    }


    if (value - prevScore > 0) {
      return <> 
     This is a&nbsp;<Change color='green'> +{(value - prevScore)}% </Change> &nbsp;change from last week
      </>
    }

    return <span>
     This is a  {` ${(value - prevScore)}%`} change from last week
    </span>

    
  }

	return (
		<Container>
      <Title>Inclusion</Title>
      <Question>How included have you felt at work lately?</Question>
      {/* <SubText>Please select at least 1 person and at most 10</SubText> */}
      <Value> {getWord()}</Value>
      <SliderContainer>
        <PrettoSlider
          valueLabelDisplay={started ? "on" : "auto"}
          aria-label="pretto slider"
          value={value}
          onChange={handleChange}
        />
      </SliderContainer>
      <ColoredValue>{getDifference(70)} </ColoredValue>
      <ButtonContainer>
        <Button enabled={started} >Next</Button>
      </ButtonContainer>
    </Container>
		)
}


export default Inclusion





const Container = styled.div`
   top:50px;
   left:20%;
   position: absolute;
   width:90%;
   display:flex;
   flex-direction:column;
   color:white;

   @media(max-height:600px){
     top:100px;
   }

    @media only screen and (min-width:992px){
   top:200px;
  }

  @media(max-width:768px){
  	height:calc(100vh - 50px);
  	display:flex;
  	flex-direction:column;
  }
`



const Question = styled.div`
font-size: 16px;
font-weight: bold;
width: 700px;
font-family: 'Red Hat Display', sans-serif;
line-height: 1.6;
margin-top:14px;
max-width:95%;
margin-bottom:10px;
color:white;


@media only screen and (min-width:992px) and (max-height:700px){
   font-size: 20px;
    margin-top:28px;
}

@media only screen and (min-width:992px) and (min-height:700px){
   font-size: 23px;
    margin-top:28px;
}

@media (max-width:768px){
	margin-bottom:60px;
	font-size:16px;
	font-weight:bold;
}
`

const Change = styled.div`
color:${props => props.color ? props.color : "grey"};
font-weight: 800;

`

// const ChangeContainer = styled.div`

// `

// const SubText = styled.div`
// 	color:white;
// 	width: 700px;
// 	font-family: 'Red Hat Display', sans-serif;
// 	margin-top:10px;
// `

const Title = styled.div`
font-size: 16px;
font-family: 'Red Hat Display', sans-serif;
width: 700px;


@media(max-width:768px){
	color:#e4e4e4;
	font-size:14px;
}
`

const SliderContainer = styled.div`
	max-width:700px;

`

const Value = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	width:700px;
	display:flex;
	justify-content:space-between;
	height:50px;
	margin-bottom:20px;
		font-size:20px;
	color:white;
`
const ColoredValue = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	width:700px;
	display:flex;
	justify-content:flex-end;
	height:50px;
	margin-bottom:20px;
		font-size:12px;
	color:# ${props => props.color ? "#7F7F7F" : props.color};;
`

const Button = styled.div`
height: 40px;
border-radius: 10px;
background-color: ${props => props.enabled ? "white" : "grey"}; 
color:black;
font-size: 16px;
font-weight: bold;
font-family: 'Red Hat Display', sans-serif;
display:flex;
justify-content:center;
align-items:center;
cursor:${props => props.enabled ? "pointer" : "not-allowed"};
width:100px;
margin-top:50px;

@media (max-height:600px){
  margin-top:30px;
}
`
const ButtonContainer = styled.div`
	width:700px;
`