import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  background-color: ${props=>props.color? props.color:'#2D70E2'};
  border: none;
  color: white;
  padding:12px 24px 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
  border-radius: 8px;
  font-weight: 400;
font-size: 14px;
line-height: 140%;
min-width: 167px;
z-index:99;

&:disabled{
  opacity:0.3;
  cursor: not-allowed;
}
`;

const StyledBackButton = styled.button`
  background-color: #EBF1FD;
  border: none;
  color: #2D70E2;
  padding:12px 24px 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
  border-radius: 8px;
  font-weight: 400;
font-size: 14px;
line-height: 140%;
width: 167px;
z-index:99;

`;

const StyledLeaveButton = styled.button`
  background-color: #EBF1FD;
  border: none;
  color: #2D70E2;
  padding:12px 24px 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
  border-radius: 8px;
  font-weight: 400;
font-size: 14px;
line-height: 140%;
min-width: 167px;
z-index:99;


@media (max-width: 768px) {
  padding:10px;
  width:125px;
  margin-bottom:20px;
}
`;

const StyledSkipButton = styled.button`
  background-color: #2D70E2;
  border: none;
  color: white;
  padding:12px 24px 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
  border-radius: 8px;
  font-weight: 400;
font-size: 14px;
line-height: 140%;
min-width: 167px;
z-index:99;


&:disabled{
  opacity:0.3;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  padding:10px;
  width:125px;
  margin-bottom:20px;
}
`;

const CustomButton = ({ children, id, onClick, type, disabled,color}) => {
  if(type=="skip"){
    return (
      <StyledSkipButton id={id} onClick={onClick} disabled={disabled}>{children}</StyledSkipButton>
    );
  }

  if(type=="leave"){
    return (
      <StyledLeaveButton id={id} onClick={onClick}>{children}</StyledLeaveButton>
    );
  }


  if(type=="back"){
    return (
      <StyledBackButton id={id} onClick={onClick}>{children}</StyledBackButton>
    );
  }

  return <StyledButton id={id} onClick={onClick} disabled={disabled} color={color}>{children}</StyledButton>
}

export default CustomButton;
