import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SwitchTransition, CSSTransition } from "react-transition-group";


const ValuesBuilder = ({
    currentQuestion,
    saveAnswer,
    language
}) => {

    const [respValue, setRespValue] = useState('')
 
    useEffect(() => {
        setRespValue('')
        if (currentQuestion?.response) {
            setRespValue(currentQuestion?.response)
        }
    }, [currentQuestion]);


    const save = () => {
        const _answer = {...currentQuestion}
        _answer['response'] = respValue
        saveAnswer(_answer)
        setRespValue('')
        // 
    }

    return (
        <>
            <SwitchTransition>
                <CSSTransition
                    key={currentQuestion?.id}
                    addEndListener={(node, done) => {
                        node.addEventListener("transitionend", done, false);
                    }}
                    classNames="feedback"
                >
                    <SurveyContainer>

                        <Text>{language==1? currentQuestion?.fr :currentQuestion?.question}</Text>
                        <div style={{display:'flex',flexDirection:'column'}}>
                        <SubText
                            value={respValue}
                            id={`valuesTextbox_${currentQuestion?.id}`}
                            maxLength="300"
                            type="text"
                            autoComplete="off"
                            onChange={(e) => setRespValue(e.target.value)} />

                        <Button
                            onClick={() => save()}
                            id={`valuesButton_${currentQuestion?.id}`}
                            disabled={currentQuestion?.required? respValue?.length === 0: false}
                        >
                            Next
                        </Button>
                        </div>
                       
                    </SurveyContainer>
                </CSSTransition>
            </SwitchTransition>


        </>
    )
}

export default ValuesBuilder


const SurveyContainer = styled.div`
  padding: 0px;
  box-sizing: border-box;
  position: absolute;
  left: 0%;
  top: 10%;
  padding: 20px;

  @media only screen and (min-width: 768px) {
    padding: 30px;
    left: calc(50% - 350px);
    top: 15%;
  }
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: white;
  margin-top: 14px;
  max-width:600px;
  line-height:1.6;
  margin-bottom:10px;
  white-space: pre-line;
  margin-bottom: 20px;

  @media only screen and (min-width: 992px) {
    font-size: 20px;
    margin-top: 20px;
  }
`;

const SubText = styled.input`
  width:250px;
  color: black;
  font-size: 16px;
  background-color:white;
  border:none;
  padding:10px;
  border-radius:10px;
  resize: none;

  &:focus{
  outline: none;
  }

  ::placeholder{
    color: #b8b8b8;
  }

  @media(max-width:768px){
    max-width:calc(100vw - 40px);

  }
`

const Button = styled.button`
  height: 40px;
  border-radius: 10px;
  background-color: white;
  border: 0;
  box-shadow: none;
  color: ${props => props.color};
  font-size: 16px;
  font-weight: bold;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100px;
  margin-top: 50px;
  // display: none;

  :disabled{
  	background-color:lightgrey;
  	color:grey;
  	cursor:not-allowed;
  	opacity:0.5;
  }


  @media only screen and (min-width: 992px), (min-height: 760px) {
    display: flex;
  }
`;