import React, { useState, useEffect } from 'react'

import { useDispatch } from "react-redux";

import { Button, Header, Icon, Modal } from 'semantic-ui-react'

import {
    RESET_VALIDATION_TOKEN,
    UPDATE_BRIEF_RESPONSE_ANSWERS,
    // SG_UPDATE_BRIEF_RESPONSE_ANSWERS,
} from "constants/actions";

function ExistingDataFound({ validationToken,
    setQuestionNo,
    setVisualQuestionNo,
    show = true,
    setShowIntro,
    setShowIntro2,
    storedResponse }) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false)

    const [validToken, setValidToken] = useState(false)
    const [timed, setTimed] = useState(false)
    const [defaultMessage, setDefaultMessage] = useState({
        header: "Searching ...",
        text: "Looking for earlier response, please wait ..."
    })

    useEffect(() => {
        setOpen(show)
    }, [show]);


    function ResetSurvey() {
        setOpen(false)
        dispatch(
            {
                type: RESET_VALIDATION_TOKEN,
                payload: false
            }
        )
        // window.location.reload();
    }

    // console.log(validationToken)
    // console.log(storedResponse?.validation_token)
    // console.log(storedResponse)
    useEffect(() => {
          const _validating = validationToken === storedResponse?.validation_token
        setTimed(false)
        const timer1 = setTimeout(() => {
            if (_validating) {
                setDefaultMessage({
                    header: "Exiting data found",
                    text: "Would you like to resume from you last left off?"
                })
            } else {
                setDefaultMessage({
                    header: "Unable to retrive",
                    text: "Unfortunately we have not been able to retrive any information on this survey"
                })
                dispatch(
                    {
                        type: RESET_VALIDATION_TOKEN,
                        payload: false
                    }
                )
                localStorage.clear();
            }
            setTimed(true)
            setValidToken(_validating)
        }, 5000);
        return () => clearTimeout(timer1);

        
    }, [dispatch, validationToken, storedResponse]);

    function Continue() {
        dispatch({
            type: UPDATE_BRIEF_RESPONSE_ANSWERS,
            payload: storedResponse
        });
        setQuestionNo(storedResponse?.current_question)
        setShowIntro(storedResponse?.intro_completed)
        setOpen(false)

        if (storedResponse?.visual_question) {
            setVisualQuestionNo(storedResponse?.visual_question)
        }
        if (storedResponse?.intro2_completed) {
            setShowIntro2(storedResponse?.intro2_completed)
        }

    }

    return (
        <Modal
            basic
            open={open}
            size='small'
        >
            <Header icon>
                
                {timed ? <>
                    {validToken ?
                    <Icon name='archive' />
                        :<Icon name='warning sign' /> 
                    }
                 </>
                    : <Icon loading name='spinner' />
                }
                {defaultMessage?.header}

            </Header>
            <Modal.Content>
                <p>
                    {defaultMessage?.text}
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button basic color='red' inverted onClick={ResetSurvey} size="tiny">
                    <Icon name='remove' /> Reset
                </Button>
               
                    <Button 
                    disabled={!validToken}
                    color='green' inverted onClick={Continue} size="tiny">
                        <Icon name='checkmark' /> Resume
                    </Button>
                
            </Modal.Actions>
        </Modal>
    )
}

export default ExistingDataFound