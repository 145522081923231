import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form, Radio } from 'semantic-ui-react'

import { primary_question_subfactor, primary_question_non } from "./subfactor_question_data"

const TestSubfactorQuestion = () => {
    const [primaryCategory, setPrimaryCategory] = useState(
        {
            steps: "Select Subfactor or on Subfactor",
            current: "None selected"
        }
    );
    const [primaryQues, setPrimaryQues] = useState({
        name: "With Subfactors",
        id: 1,
        data: primary_question_subfactor
    })
    
    
    function submitAnswer() {
        let _id = ''
        let _response = 0
        let subfactorFound = false
        let _subfactor = null

        if (primaryQues?.data) {
            primaryQues.data.map(pq => {
                _id = pq.id
                if (pq?.subfactor) {
                    subfactorFound = true
                }
                pq && pq.answers.map(aq => {
                    if (aq?.name === primaryQues?.SurveyName) {
                        
                        if (subfactorFound) {
                            _subfactor = aq?.id
                            _response = aq?.parent_id
                        } else {
                            _response = aq?.id
                        }
                    }
                    return null
                })
                return null
            })
        }

        setPrimaryCategory({
            id: _id,
            response: _response,
            subfactor: _subfactor,
            priority: "primary"
        })

    }

    console.log(primaryQues)

    return (
        <Container>
            <Form>
        <Form.Field>
          Selected value: <b>{primaryQues.name}</b>
        </Form.Field>
        <Form.Field>
          <Radio
            label='With Subfactors'
            name='radioGroup'
            value={1}
            checked={primaryQues.id === 1}
            onChange={() => setPrimaryQues({
                name: "With Subfactors",
                SurveyName: "Artistic Swimming",
                id: 1,
                data: primary_question_subfactor
            })}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label='No Subfactors'
            name='radioGroup'
            value={2}
            checked={primaryQues.id === 2}
            onChange={() => setPrimaryQues({
                name: "No Subfactors",
                SurveyName: "Hockey (W)",
                id: 2,
                data: primary_question_non
            })}
          />
        </Form.Field>
      </Form>
            <Title>Test Primary with Subfactor</Title>
            
            <SubTitle>{JSON.stringify(primaryCategory)}</SubTitle>
            <Next onClick={() => submitAnswer()}>Subfactor</Next>

        </Container>

    )
}

export default TestSubfactorQuestion


const Title = styled.div`
	font-size: 20px;
  font-weight: bold;
  font-family: 'Barlow', sans-serif;
  margin-bottom:20px;
  color:black;
   line-height:1;
   max-width:654px;

   @media only screen and (min-width:992px){
   font-size: 30px;
   margin-bottom:50px;
  }
`
const SubTitle = styled.div`
	font-size: 18px;
	line-height: 1.17;
  color: black;
  font-family: 'Barlow', sans-serif;
  margin-bottom:20px;
  max-width:654px;

   @media only screen and (min-width:992px){
   font-size: 72px;
   margin-bottom:50px;
   font-size: 24px;
  }
`

const Next = styled.div`
	width: 170px;
  height: 47px;
   border-radius: 5px;
  background-color: #6a00ff;
  color: white;
   font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
`

const Container = styled.div`
  position:absolute;
  top:15%;
  padding:20px;

  @media only screen and (min-width:992px){
    left: 25%;
  top: 25%;
  }
`