import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import ErrorList from './ErrorList';
import { ValidateEmail } from "utilities/Validate";
import Logo from 'assets/images/Logo blue.png';
import Smiley from 'assets/images/top-view-smiley-face-growth-arrow 1.png';
import Dial from './Dial';

const readiness_questions = [2,3,5,7]
const roi_questions = [4,6,8]

const Q6 ={0:0,1:4,2:6,3:8,4:10}
const Q8 ={0:10,1:8,2:6,3:4,4:0}

const ROI = ({data, showerrors, email, setEmail, setShowErrors, handleSubmit}) =>{

    const calculate_readiness =() =>{
        let total = 0;
        let count = 0;
        Object.keys(data).forEach((item)=>{

            if(readiness_questions.includes(Number(item))){
                total += data[item]
                count++
            }
        })
        return total/count
    }

    const calculate_roi =() =>{
        let total = 0;
        let count = 0;
        Object.keys(data).forEach((item,i)=>{
            if(roi_questions.includes(Number(item))){
                if(i==2){
                    if(!data[item].includes(4)){
                        total+=10
                    }
                }
                else if(i==4){
                    total+= Q6[data[item]]
                }
                else{
                    total += Q8[data[item]]
                }
                count++
            }
        })
        return total/count
    }

    const [pristine, setPristine] = useState(true);
    const [readiness, setReadiness] = useState(calculate_readiness());
    const [roi, setRoi] = useState(calculate_roi());

   

    const handleInputChange = (event) => {
        const { value } = event.target;
        setEmail(value);
        setShowErrors(false)
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
          // Enter key pressed
          FormSubmit();
        }
      };

      function FormSubmit() {
        if (showerrors?.errors > 0) {
            setPristine(false);
            return;
          }
          handleSubmit();
      }

      useEffect(() => {
        const ErrorList = [];
        // let totalErrors = 0
        
        if (email?.length === 0) {
          ErrorList["email"] = ["Please enter an email"]
        //   totalErrors += 1
        } else {
            if (!ValidateEmail(email)) {
              ErrorList["email"] = ["Email address is invalid"]
            //   totalErrors += 1
            }
        }
        
        // ErrorList['errors'] = totalErrors
        setShowErrors(ErrorList);
    
      }, [ email, pristine, setShowErrors]);


      const getLanguage = (value) =>{
        if(value<4){
            return 'Low'
        }
        else if(value<7){
            return 'Moderate'
        }
        else{
            return 'High'
        }
      }

      const getColor = (value) =>{
        if(value<4){
            return '#F15062'
        }
        else if(value<7){
            return '#F1C62B'
        }
        else{
            return '#2D70E2'
        }
      }

    return (
        <Container>
             <Content>
                <IMG src={Logo}/>
                <Title>
                    <div style={{display:'flex',alignItems:'center'}}>ROI <IMG2 src={Smiley}/></div> 
                    <div>calculation</div> 
                    <div>results</div> 
                </Title>

                <Data>
                    <Block style={{marginBottom:40}}>
                        <Text>
                        <div><T2>Readiness for innerlogic</T2>
                        <P>Your overall readiness for innerlogic is based on how important prioritizing healthy, 
                            high performance organizational culture is. It considers the value of unique employee
                             perspectives in identifying culture gaps and growth areas in a timely manner.</P></div>
                        </Text>
                        <DialContainer>
                            <Dial  borderColor={getColor(readiness)} text={getLanguage(readiness)} borderPercentage={readiness*10}/>
                        </DialContainer>
                        
                        
                    </Block>

                    <Block>
                        <Text>
                        <div><T2>Projected ROI of innerlogic</T2>
                        <P>Your return on investment in using innerlogic considers your current practices for 
                            collecting organizational culture data, as well as time and access when it comes to
                             deploying curated solutions across your organization based on survey results.</P></div>
                        </Text>
                        <DialContainer>
                            <Dial borderColor={getColor(roi)} text={getLanguage(roi)} borderPercentage={roi*10}/>
                        </DialContainer>
                        
                        
                    </Block>
                </Data>
            </Content>
            
           
           

            <Email>
                <T2>Get your detailed readiness and ROI report:</T2>
                <P>Enter your work e-mail below</P>

                <InputContainer>
                    <EmailInput
                     type="email"
                    placeholder="your@email.com"
                    value={email}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    />
                    <SubmitButton onClick={FormSubmit}>Submit</SubmitButton>
                </InputContainer>

                <ErrorList errors={showerrors} />
            </Email>
            
        </Container>
    )
}


export default ROI


const Container = styled.div`
    width:100%;
    padding:0px 0px 20px 0px;
    display:flex;
    flex-direction:column;
    align-items:center;

    @media(max-width:768px){
        padding:0px 0px 30px 0px;
        height:100%;
    }
`

const IMG = styled.img`
    width:100px;
    position:absolute;
    left:60px;
    top:-60px;

    @media(max-width:768px){
        left:10px;
        top:-40px;
    }   
`

const IMG2 = styled.img`
    border-radius:50px;
    margin-left:20px;
    width:150px;

    @media(max-width:768px){
        width:100px;
    }
`


const Content = styled.div`
    width:100%;
    max-width:1200px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-top:60px;
    background: #F8FAFF;
    border-radius: 20px;
    padding: 60px;
    position:relative;

    @media (max-width: 768px) {
        flex-direction:column;
        padding:10px;
    }
`
const Title = styled.div`
    display:flex;
    font-family: 'Raleway';
    flex-direction:column;
    font-weight: 500;
    font-size: 60px;
    line-height:1.3;
    text-transform: uppercase;
    color: #2A3039;

    @media(max-width:768px){
        font-size: 40px;
    }
`

const Data = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;

    @media(max-width:768px){
        margin-top:50px;
    }
`

const Block = styled.div`
    display:flex;
    width:564px;
    justify-content:space-evenly;

    @media(max-width:768px){
        width:100%;
    }
`

const T2 = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 20px;
color: #2A3039;
line-height:1.3;
`

const P = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 125%;
color: #666D79;
max-width:300px;
margin-top:10px;
`

const DialContainer = styled.div`
    width:150px;
    margin-top:-20px;

`

const Text = styled.div`
    width:300px;
`

const Email = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;

    @media(max-width:768px){
        margin-top:30px;
        padding:10px;
        margin-bottom:30px;
      }
`

const EmailInput = styled.input`
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  font-family: 'Raleway';
font-style: normal;
font-weight: 400;
color: #666D79;
width:300px;

@media(max-width:768px){
    width:200px;
}
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  font-size: 14px;
  background-color: #2D70E2;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Raleway';

  &:hover {
    background-color: #45a049;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top:20px;

  
`;