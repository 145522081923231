import {REVERSE} from "data/reverseScores"

export function RunRules(props) {
    const { 
        questions,
        get_brief_response
    } = props

    const MinQuestions = []
    const MaxQuestions = []

    const MinQuestionSet = []
    const MaxQuestionSet = []

    let maxRows = 0
    let minRows = 0


    if (get_brief_response?.response?.organization_info?.rules) {
        //   console.log("get_brief_response?.response?.organization_info?.rules", get_brief_response?.response?.organization_info?.rules)
        const getMax = get_brief_response?.response?.organization_info?.rules?.filter(ru => ru?.type === "max")?.[0]?.rows > 0
        maxRows = get_brief_response?.response?.organization_info?.rules?.filter(ru => ru?.type === "max")?.[0]?.rows
        const getMin = get_brief_response?.response?.organization_info?.rules?.filter(ru => ru?.type === "min")?.[0]?.rows > 0
        minRows = get_brief_response?.response?.organization_info?.rules?.filter(ru => ru?.type === "min")?.[0]?.rows

        // console.log(getMax)
        // console.log(maxRows)
        // console.log(getMin)
        // console.log(minRows)
        // console.log(questions)
        // console.log("get_brief_response?.answers?.questions", get_brief_response?.answers?.questions)

        if (get_brief_response?.answers?.questions) {
            // const MaxScore = 10
            //  reverse_score = MaxScore + 1 - your_score
            const _revisedValues = Object.values(get_brief_response?.answers?.questions).map(val => {
                // console.log("Check for reverse ", val)
                if (val?.reverse === true) {
                    // console.log("reversed for ############", val)
                    return (
                        {
                            ...val,
                            response: REVERSE[val.response]
                        }
                    )
                }
                return { ...val }
            })

            if (getMax) {
                const maxValues = _revisedValues.sort((a, b) => b.response - a.response);

                maxValues.map(m => {
                    const _ques = questions[m.ques_order - 1]
                     // filter based on factor and dimensions
                     const _found = MaxQuestionSet.filter(mm => mm?.dimension === _ques?.dimension && mm?.factor === _ques?.factor)?.[0]

                     if (!_found) {
                        MaxQuestionSet.push({dimension:_ques?.dimension,
                             factor:  _ques?.factor})
 
                        MaxQuestions.push({
                             ...m,
                             fr: _ques?.fr,
                             es: _ques?.es,
                             question: _ques?.question,
                             feedback: "",
                             dimension: _ques?.dimension,
                             factor: _ques?.factor,
                             type: 'max'
                         })
                    }

                    return null
                })

                // maxValues.slice(0, maxRows).map(m => {
                //     const _ques = questions[m.ques_order - 1]
                //     MaxQuestions.push({
                //         ...m,
                //         fr: _ques?.fr,
                //         question: _ques?.question,
                //         feedback: "",
                //         dimension: _ques?.dimension,
                //         factor: _ques?.factor,
                //         type: 'max'
                //     })
                //     return null
                // })
            }

            if (getMin) {
                const minValues = _revisedValues.sort((a, b) => a.response - b.response);
                // console.log(minValues.sort((a,b) => a.response - b.response).slice(0,maxRows));
                
                // console.log(minValues)
               
                minValues.map(m => {
                const _ques = questions[m.ques_order - 1]

                // filter based on factor and dimensions
                const _found = MinQuestionSet.filter(mm => mm?.dimension === _ques?.dimension && mm?.factor === _ques?.factor)?.[0]

                if (!_found) {
                
                    MinQuestionSet.push({dimension:_ques?.dimension,
                        factor:  _ques?.factor})

                    MinQuestions.push({
                        ...m,
                        fr: _ques?.fr,
                        es: _ques?.es,
                        question: _ques?.question,
                        feedback: "",
                        dimension: _ques?.dimension,
                        factor: _ques?.factor,
                        type: 'min'
                    })
                }

                return null
            })
                
            // // console.log(minValues)
            // minValues.slice(0, minRows).map(m => {
            //     const _ques = questions[m.ques_order - 1]
            //     finalMinList.push({
            //         ...m,
            //         fr: _ques?.fr,
            //         question: _ques?.question,
            //         feedback: "",
            //         dimension: _ques?.dimension,
            //         factor: _ques?.factor,
            //         type: 'min'
            //     })
            // return null
            // })
               
            }
        }

        // console.log(finalMaxList)
        // console.log("finalMinList", finalMinList)
        // console.log("MinQuestionSet", MinQuestionSet)
        // console.log("MaxQuestions", MaxQuestions)
        // MaxQuestions.slice(0, maxRows)
        // MinQuestions.slice(0, minRows)
    }
    
  
    // console.log([
    //     ...MaxQuestions, 
    //     ...MinQuestions
    // ])

    return (
        [
            ...MinQuestions.slice(0, minRows),
            ...MaxQuestions.slice(0, maxRows)
        ]
    )
}


export function AuditRunRules( questions, orgRules, questionAnswers,surveyStandards) {
    const MinQuestions = []
    const MaxQuestions = []

    const MinQuestionSet = []
    const MaxQuestionSet = []

    let maxRows = 0
    let minRows = 0

    //Implementation of lowest scores by factor. We fall back to the old method if this fails
    try {
        return FactorScoreRules(questions,orgRules,questionAnswers,surveyStandards)
    } catch (error) {
        console.log(error)
    }
    

    if (orgRules) {
        //   console.log("orgRules", orgRules)
        const getMax = orgRules?.filter(ru => ru?.type === "max")?.[0]?.rows > 0
        maxRows = orgRules?.filter(ru => ru?.type === "max")?.[0]?.rows
        const getMin = orgRules?.filter(ru => ru?.type === "min")?.[0]?.rows > 0
        minRows = orgRules?.filter(ru => ru?.type === "min")?.[0]?.rows



        if (questionAnswers?.questions) {
            // const MaxScore = 10
            //  reverse_score = MaxScore + 1 - your_score
            const _revisedValues = Object.values(questionAnswers?.questions).map(val => {
                // console.log("Check for reverse ", val)
                if (val?.reverse === true) {
                    // console.log("reversed for ############", val)
                    return (
                        {
                            ...val,
                            response: REVERSE[val.response]
                        }
                    )
                }
                return { ...val }
            })

            if (getMax) {
                const maxValues = _revisedValues.sort((a, b) => b.response - a.response);

                maxValues.map(m => {
                    const _ques = questions[m.ques_order - 1]
                     // filter based on factor and dimensions
                     const _found = MaxQuestionSet.filter(mm => mm?.dimension === _ques?.dimension && mm?.factor === _ques?.factor)?.[0]

                     if (!_found) {
                        MaxQuestionSet.push({dimension:_ques?.dimension,
                             factor:  _ques?.factor})
 
                        MaxQuestions.push({
                             ...m,
                             fr: _ques?.fr,
                             es: _ques?.es,
                             question: _ques?.question,
                             feedback: "",
                             dimension: _ques?.dimension,
                             factor: _ques?.factor,
                             type: 'max'
                         })
                    }

                    return null
                })

            }

            if (getMin) {
                const minValues = _revisedValues.sort((a, b) => a.response - b.response);
                // console.log(minValues.sort((a,b) => a.response - b.response).slice(0,maxRows));
                
                // console.log(minValues)
               
                minValues.map(m => {
                const _ques = questions[m.ques_order - 1]

                // filter based on factor and dimensions
                const _found = MinQuestionSet.filter(mm => mm?.dimension === _ques?.dimension && mm?.factor === _ques?.factor)?.[0]

                if (!_found) {
                
                    MinQuestionSet.push({dimension:_ques?.dimension,
                        factor:  _ques?.factor})

                    MinQuestions.push({
                        ...m,
                        fr: _ques?.fr,
                        es: _ques?.es,
                        question: _ques?.question,
                        feedback: "",
                        dimension: _ques?.dimension,
                        factor: _ques?.factor,
                        type: 'min'
                    })
                }

                return null
            })
                

               
            }
        }


    }
    

    return (
        [
            ...MinQuestions.slice(0, minRows),
            ...MaxQuestions.slice(0, maxRows)
        ]
    )
}

const get_average_by_factor = (questionAnswers) => {

    const groupedData = {};

    questionAnswers.forEach(entry => {
        const key = `${entry.id}_${entry.factor}`;
        if (!groupedData[key]) {
            groupedData[key] = {
                totalResponse: 0,
                count: 0,
                average: 0
            };
        }

        groupedData[key].totalResponse += entry.response;
        groupedData[key].count += 1;
        groupedData[key].average = groupedData[key].totalResponse / groupedData[key].count;
    });

    const arrayData = Object.keys(groupedData).map(key => {
        const [id, factor] = key.split('_').map(Number);
        return {
            id,
            factor,
            ...groupedData[key]
        };
    });
    
    arrayData.sort((a, b) => a.average - b.average);

    return arrayData

}


const get_benchmark_diff_by_factor = (questionAnswers,surveyStandards) => {

    const groupedData = {};

    questionAnswers.forEach(entry => {
        const key = `${entry.id}_${entry.factor}`;
        if (!groupedData[key]) {
            groupedData[key] = {
                totalResponse: 0,
                count: 0,
                average: 0
            };
        }
       

        groupedData[key].totalResponse += entry.response;
        groupedData[key].count += 1;

        const average = groupedData[key].totalResponse / groupedData[key].count;

        let factor = entry.factor

        let dimension = "dimension" in entry ?entry.dimension:entry.id

        const benchmark = surveyStandards?.response?.pillars.filter(s => s.id === dimension && s.factor === factor)[0].average;
        groupedData[key].average = average - benchmark
    });

    const arrayData = Object.keys(groupedData).map(key => {
        const [id, factor] = key.split('_').map(Number);
        return {
            id,
            factor,
            ...groupedData[key]
        };
    });
    
    arrayData.sort((a, b) => a.average - b.average);

    return arrayData

}


function filterQuestions(questions, averages, criteria = 'highest') {
    // Sort averages based on the given criteria
    const sortedAverages = criteria === 'highest'
        ? [...averages].sort((a, b) => b.average - a.average) 
        : [...averages].sort((a, b) => a.average - b.average);

    const result = [];

    for (let avg of sortedAverages) {
        const { factor, id } = avg;

        // Get the relevant questions for this factor-id pairing
        const relevantQuestions = questions.filter(q => q.factor === factor && q.id === id);

        // Sort these relevant questions based on the given criteria
        relevantQuestions.sort((a, b) => criteria === 'highest' ? b.response - a.response : a.response - b.response);

        if (relevantQuestions.length > 0) {
            result.push(relevantQuestions[0]); // Pick the top question
        }
    }

    return result;
}

const FactorScoreRules = (questions, orgRules, questionAnswers,surveyStandards) => {
    const MinQuestions = []
    const MaxQuestions = []

    const MinQuestionSet = []
    const MaxQuestionSet = []

    let maxRows = 0
    let minRows = 0

    if (orgRules) {

        const getMax = orgRules?.filter(ru => ru?.type === "max")?.[0]?.rows > 0
        maxRows = orgRules?.filter(ru => ru?.type === "max")?.[0]?.rows

        const getMin = orgRules?.filter(ru => ru?.type === "min")?.[0]?.rows > 0
        minRows = orgRules?.filter(ru => ru?.type === "min")?.[0]?.rows

        if (questionAnswers?.questions) {

            const _revisedValues = Object.values(questionAnswers?.questions).map(val => {
                // console.log("Check for reverse ", val)
                if (val?.reverse === true) {
                    // console.log("reversed for ############", val)
                    return (
                        {
                            ...val,
                            response: REVERSE[val.response]
                        }
                    )
                }
                return { ...val }
            })


            let factor_averages
            if(surveyStandards && surveyStandards?.response){
                console.log('has survey standards')
                factor_averages = get_benchmark_diff_by_factor(_revisedValues,surveyStandards)
            }else{
                factor_averages = get_average_by_factor(_revisedValues)
            }         

            console.log(factor_averages)

            if (getMax) {
                const maxValues = filterQuestions(_revisedValues,factor_averages,'highest')
                
                maxValues.map(m => {
                    const _ques = questions[m.ques_order - 1]
                     // filter based on factor and dimensions
                     const _found = MaxQuestionSet.filter(mm => mm?.dimension === _ques?.dimension && mm?.factor === _ques?.factor)?.[0]

                     if (!_found) {
                        MaxQuestionSet.push({dimension:_ques?.dimension,
                             factor:  _ques?.factor})
 
                        MaxQuestions.push({
                             ...m,
                             fr: _ques?.fr,
                             es: _ques?.es,
                             question: _ques?.question,
                             feedback: "",
                             dimension: _ques?.dimension,
                             factor: _ques?.factor,
                             type: 'max'
                         })
                    }

                    return null
                })

            }

            if (getMin) {
                const minValues = filterQuestions(_revisedValues,factor_averages,'lowest')

              
                minValues.map(m => {
                    const _ques = questions[m.ques_order - 1]
    
                    // filter based on factor and dimensions
                    const _found = MinQuestionSet.filter(mm => mm?.dimension === _ques?.dimension && mm?.factor === _ques?.factor)?.[0]
                    if (!_found) {
                    
                        MinQuestionSet.push({dimension:_ques?.dimension,
                            factor:  _ques?.factor})
    
                        MinQuestions.push({
                            ...m,
                            fr: _ques?.fr,
                            es: _ques?.es,
                            question: _ques?.question,
                            feedback: "",
                            dimension: _ques?.dimension,
                            factor: _ques?.factor,
                            type: 'min'
                        })
                    }
    
                    return null
                })

            }

        }
    }

    return (
        [
            ...MinQuestions.slice(0, minRows),
            ...MaxQuestions.slice(0, maxRows)
        ]
    )


}

export default RunRules;