import React from "react";
import styled from "styled-components";
import SliderOutcome from "../Components/SliderOutcome";

const QuestionOutcome = ({
  question,
  saveUpdatedAnswers,
  currentQuestionDet,
  resetResponsesOnChange,
  questionSequenceMap,
  language,
  teamName
}) => {

  console.log(currentQuestionDet, 'currentQuestionDet')

  const getTitle = ()=>{
    if(currentQuestionDet?.fr_name && language === 'FR'){
      return currentQuestionDet?.fr_name
    } 
    if(currentQuestionDet?.es_name && language === 'ES'){
      return currentQuestionDet?.es_name
    }

      return currentQuestionDet?.name
  }

  const getQuestion = ()=>{
    if(question?.fr && language === 'FR'){
      return question?.fr.replace('{entity}', teamName)
    } 
    if(question?.es && language === 'ES'){
      return question?.es.replace('{entity}', teamName)
    }

      return question?.question.replace('{entity}', teamName)
  }


  return (
    <QuestionContainer>
      <Title>
        {getTitle()}
      </Title>
      {/* <Qualifier>In this culture...</Qualifier>  */}
      <QuestionText >{getQuestion()}</QuestionText>
      <SliderOutcome
        id={`slider-track-${currentQuestionDet?.id}`}
        resetResponsesOnChange={resetResponsesOnChange}
        question={question}
        language={language}
        saveUpdatedAnswers={saveUpdatedAnswers}
        currentQuestionDet={currentQuestionDet}
        questionSequenceMap={questionSequenceMap}
      />
    </QuestionContainer>
  );
};
export default QuestionOutcome;

const QuestionContainer = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.div`
  color: #2d70e2;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 40px;
`;

const Qualifier = styled.div`
  color: #2a3039;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 14px;
`;

const QuestionText = styled.div`
  font-size: 16px;
  color: #2a3039;
  white-space: pre-wrap;
`;
