import styled from "styled-components";
import React from "react";

import Slider from '@material-ui/core/Slider';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

function isInt(value) {
  return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
}

// const PrettoSlider = withStyles({
//   root: {
//     color: 'grey',
//     height: 8,
//   },
//   thumb: {
//     height: 24,
//     width: 24,
//     backgroundColor: '#FA675C',
//     color: '#FA675C',
//     border: '2px solid currentColor',
//     marginTop: -8,
//     marginLeft: -12,
//     '&:focus, &:hover, &$active': {
//       boxShadow: 'inherit',
//     },
//   },
//   active: {},
//   valueLabel: {
//     left: 'calc(-50% + 4px)',
//   },
//   track: {
//     height: 8,
//     borderRadius: 4,
//   },
//   rail: {
//     height: 8,
//     borderRadius: 4,
//   },
// })(Slider);


const SliderQ = ({ preValue, saveResult,language }) => {

  const [res1, setRes1] = React.useState(0)
  // const [clicked, setClicked] = React.useState(false)
  const [isNA,setNA] = React.useState(false)

   let muiTheme = createMuiTheme({overrides:{
        MuiSlider: {
          thumb:{
          color:'#E5B63D',
          },
          mark:{
            color:'white'
          },
          track: {
            color:'#E5B63D'
          },
          rail: {
            color: '#E5B63D'
          }
        }
    }})

  // console.log(preValue)
  // console.log(clicked)
  // console.log(preValue !== undefined)

  // React.useEffect(() => {
  //   if (preValue === undefined || preValue === null) {
  //     setRes1(0)
  //     setClicked(false)
  //   } else {
  //     setRes1(preValue)
  //     setClicked(true)
  //   }
  // }, [preValue]);

  // function ClickedOnSlider(t) {
  //   setClicked(true)
  //   setRes1(t)
  // }

  // const save = () => {
  //   saveResult(res1)
  //   setRes1(0)
  //   setClicked(false)
  // }

  React.useEffect(() => {
    if (isInt(preValue)) {
      setRes1(preValue)
      // setClicked(true)
    }
     else {
      setRes1(0)
      // setClicked(false)
    }

    if(preValue==='N/A'){
      setNA(true)
    }else{
       setNA(false)
    }
  }, [preValue]);

  function ClickedOnSlider(t) {
    // setClicked(true)
    setRes1(t)
     
  }

   function commitSlide(t) {
    saveResult(t)
     
  }

  // const save = () => {
  //   saveResult(res1)
  //   setRes1(0)
  //   // setClicked(false)
  // }


  return (
    <QuestionContainer>
      <Text>{ }</Text>
      <ThemeProvider theme={muiTheme}>
      <Slider
        valueLabelDisplay={"on"}
        aria-label="pretto slider"
        value={res1}
        step={1}
        marks
        min={0}
        max={10}
        id="slider"
        onChange={(e, t) => ClickedOnSlider(t)}
        onChangeCommitted={(e, t) => commitSlide(t)}
      />
      </ThemeProvider>
      <Scale>
        <div>{language===1?"Pas du tout d'accord":"Strongly Disagree"}</div>
        <div style={{marginLeft:language==1?-10:20}}>{language===1?"Neutre":"Neutral"}</div>
        <div>{language===1?"Tout à fait d'accord":"Strongly Agree"}</div>
      </Scale>

      <div style={{display:'flex',justifyContent:'flex-end'}}>
       <NA 
         selected={isNA}
         onClick={()=>saveResult('N/A')}>
         N/A</NA>
       </div>


      {/*<Button
        onClick={() => save()}
        disabled={!clicked}
        id="next">
        Next
      </Button>*/}
    </QuestionContainer>
  )
}


export default SliderQ



const QuestionContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  padding-left:10px;
  display: flex;
  flex-direction: column;
  max-width:100%;

  @media only screen and (min-width: 992px) {
    width: 600px;
    margin-top: 40px;
  }
`;


// const Button = styled.button`
//   height: 40px;
//   border-radius: 10px;
//   background-color: #6a00ff;
//   border: 0;
//   box-shadow: none;
//   color:white ;
//   font-size: 16px;
//   font-weight: bold;
//   font-family: "Barlow", sans-serif;

//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   width: 100px;
//   margin-top: 50px;

//   :disabled {
//     background-color:lightgrey;
//     color:grey;
//     cursor:not-allowed;
//     opacity:0.5;
//   }


//   @media only screen and (min-width: 992px), (min-height: 760px) {
//     display: flex;
//   }
// `;

const Text = styled.div`
  color:white;
  font-size:20px;
  margin-bottom:40px;
  margin-top:20px;
`

const Scale = styled.div`
  width:calc(100% + 40px);
  margin-left:-20px;
  display:flex;
  justify-content:space-between;
  font-size:12px;
`

const NA = styled.div`
   font-size: 12px;
  font-weight: bold;
  font-family: "Barlow", sans-serif;
  background-color:${props=>props.selected? '#6a00ff':'lightgrey'};
  color:${props=>props.selected? 'white':'black'};
   height: 30px;
  border-radius: 10px;
   justify-content: center;
  align-items: center;
  display: flex;
  width:75px;
  margin-top:50px;
  cursor:pointer;
  opacity:0.8;
  margin-right:-20px;

  @media(max-width:768px){
    margin-right:-10px;
  }

`