import styled from "styled-components";
import React from "react";



const TakeAway = ({ value, saveResult }) => {

  const [res1, setRes1] = React.useState(value ? value[0] : "")
  const [res2, setRes2] = React.useState(value ? value[1] : "")
  const [res3, setRes3] = React.useState(value ? value[2] : "")



  return (
    <QuestionContainer>
      <Title>Point #1</Title>
      <TextBox
        rows="4"
        value={res1}
        onChange={(e) => setRes1(e.target.value)}
        placeholder={'Add comment here'} />

      <Title>Point #2</Title>
      <TextBox
        rows="4"
        value={res2}
        onChange={(e) => setRes2(e.target.value)}
        placeholder={'Add comment here'} />

      <Title>Point #3</Title>
      <TextBox
        rows="4"
        value={res3}
        onChange={(e) => setRes3(e.target.value)}
        placeholder={'Add comment here'} />

      <Button onClick={() => saveResult([res1, res2, res3])}>Next</Button>
    </QuestionContainer>
  )
}


export default TakeAway



const QuestionContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    width: 600px;
    margin-top: 40px;
    padding-left:30px;
  }
`;

const TextBox = styled.textarea`
  width:400px;
  color: #4a4a4a;
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  background-color: white;
  border-radius: 8px;
  border: none;
  padding: 15px;
  margin: 12px;

  @media(max-width:768px){
    width:100%;
    margin:0;
    margin-bottom:10px;
  }
`
const Title = styled.div`
  color:white;
  font-family: "Red Hat Display", sans-serif;
`

const Button = styled.button`
  height: 40px;
  border-radius: 10px;
  background-color: white;
  border: 0;
  box-shadow: none;
  color: #6a00ff;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100px;
  margin-top: 50px;
  // display: none;

  @media only screen and (min-width: 992px), (min-height: 760px) {
    display: flex;
  }
`;