import styled from "styled-components";
import React from "react";

const Box = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: white;
  margin-top: ${(props) => (props.visible != null ? "15px" : "10px")};
  max-height: ${(props) => (props.visible != null ? "300px" : "0px")};
  transition: max-height 500ms;
  overflow: hidden;
   z-index:3;

  @media only screen and (max-width:992px){
    margin-bottom:10px;
  }


`;
const Answer = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: black;
  width: 100%;
  border-bottom: 1px solid white;
  padding: 15px 15px 0px 15px;
  font-family: "Red Hat Display", sans-serif;
`;

const SubText = styled.textarea`
  width: calc(100% - 24px);
  color: black;
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  background-color: #d9d9d9;
  border-radius: 8px;
  border: none;
  padding: 15px;
  margin: 12px;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: black;
  }
`;

const Submit = styled.div`
  border-radius: 10px;
  background-color: #f9d144;
  text-align: center;
  color: #696969;
  font-size: 12px;
  font-weight: 900;
  font-family: "Red Hat Display", sans-serif;
  width:100px;
  height:35px;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
`

const Dismiss = styled.div`
  font-size: 12px;
  font-weight: 900;
  text-align: center;
  color: #696969;
  font-family: "Red Hat Display", sans-serif;
  margin-right:10px;
  margin-left:10px;
  cursor:pointer;
`

const CommentBox = (props) => {

//  console.log(props.visible)
  return (
    <Box visible={props.visible? true : undefined}>
      <Answer>{props.answer}</Answer>
      <SubText
        placeholder="Use this space to provide feedback and exapnd on your response"
        value={props.comment ? props.comment : ""}
        rows="3"
        onChange={(e) => props.setComment(e.target.value)}
      />
      <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'flex-end',marginBottom:10}}>
        <Submit onClick={()=>props.onComplete()}>SUBMIT</Submit>
        <Dismiss onClick={()=>props.onComplete()}>DISMISS</Dismiss>
      </div>
    </Box>
  );
};

export default CommentBox;
