import React from 'react';
import styled from 'styled-components';


const FR1 ="Votre expérience en tant que partie intégrante de cette culture est importante."
const FR2 ="La culture étant vécue au niveau individuel, il est important que les personnes répondent au sondage de manière honnête et en fonction de leur propre perspective. Veuillez répondre aux questions suivantes en tenant compte de votre rôle et de votre expérience au sein de la culture."
const FR3 ="Par exemple, si vous êtes un athlète, répondez en fonction de votre expérience et de votre point de vue d'athlète. Si vous avez plus d'un rôle, choisissez celui qui vous tient le plus à cœur."
const FR4 = "Merci d'avoir contribué à notre sondage sur la culture."

const EN1 = "Your experience, as an integral part of this culture, matters."
const EN2 = "Because culture is experienced at the individual level, it’s important for people to respond to the survey honestly and informed by their own perspective. Please respond to the following culture questions from the lens of your role and experience within the culture. "
const EN3 ="For example, if you’re an athlete, respond from your lived experience and perspective of being an athlete. If you have more than one role, pick the one that you feel most strongly about and use the open ended questions to share any other comments."
const EN4 = "Thank you for contributing to our culture survey."


const Intro1 = ({setPage,color,language,custom}) =>{

	const [animate,setAnimate] = React.useState(false)
	const [customIntro,setIntro] = React.useState(false)

	function onPageChange(){
		setAnimate(true)
		setTimeout(()=>{
			setPage()
		}, 500);
	}

	React.useEffect(()=>{
		if(custom?.custom_intro){
			setIntro(custom.custom_intro)
		}
	},[custom])

	// React.useEffect(()=>{
	// 	console.log(language)
	// },[language])


	return (
		<Container animate={animate}>
			<SubTitle>
				<div style={{marginBottom:20}}>
				 {language===1?FR1:customIntro? customIntro[0] :EN1}
				</div>
				{ language===1?FR2:customIntro? customIntro[1]:EN2}
				
				<div style={{marginTop:20}}>
				{ language===1?FR3:customIntro? customIntro[2]:EN3}
				</div>

			<div style={{marginTop:20}}>{language===1?FR4:customIntro? customIntro[3] :EN4}</div></SubTitle>

			<Next enabled={true} onClick={onPageChange} color={color}> {language===1?"Suivant":"Next"}</Next>
		</Container>
		)
}



export default Intro1


const Container = styled.div`
	opacity:${props=>props.animate?0:1};
	transition:opacity 1s;
`

const SubTitle = styled.div`
	font-size: 18px;
	line-height: 1.4;
  color: white;

  margin-bottom:20px;
  max-width:654px;

   @media only screen and (min-width:992px){
   margin-bottom:50px;
   font-size: 20px;
  }
`


const Next = styled.button`
	width: 170px;
  height: 47px;
   border-radius: 5px;
  background-color: white;
   color: ${props=>props.color};
   font-size: 16px;
  font-weight: bold;

  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  border: none;

   ${(props) => !props.enabled && `
    opacity: 0.4;
    cursor:auto;
    pointer-events: none;
  `}
`
