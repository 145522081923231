import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Comments = ({ saveCommentAnswer,
  question,
  response,language,onClick,goForward,showNext}) => {

  const [respValue, setRespValue] = useState('')

  const save = () => {
    saveCommentAnswer({
      id: question?.id,
      question: question?.question,
      ques_order: question?.order,
      binary: question?.binary,
      role: question?.role,
      response: respValue
      })
  }

  useEffect(() => {
    setRespValue('')
    if (response) {
      setRespValue(response?.response)
    }
  }, [response]);

	return (
		<SurveyContainer>
      <Controls>
        <BackButton onClick={onClick}><span style={{fontSize:24,marginTop:-3,marginRight:5}}>&#8249;</span> {language===1?'Retour':'Back'}</BackButton>
        {showNext && response!=undefined && <BackButton onClick={goForward}> {language===1?'Suivant':'Forward'}<span style={{fontSize:24,marginTop:-3,marginLeft:5}}>&#8250;</span></BackButton>}
      
        </Controls>
        <Text>{language===1?question?.fr: question?.question}</Text>
			<SubText
			placeholder={language===1?"Veuillez partager vos idées ici.":"Please share your ideas here."}
	        value={respValue}
	        id="textbox"
	        rows="6"
	        onChange={(e)=>setRespValue(e.target.value)}/>

	        <Button onClick={()=>save()} id="next">{language===1?'Suivant':'Next'}</Button>
		</SurveyContainer>
		)
}

export default Comments


const SurveyContainer = styled.div`
  padding: 0px;
  box-sizing: border-box;
  position: absolute;
  left: 0%;
  top: 10%;
  padding: 20px;

   @media only screen and (min-width: 768px) {
    padding: 20px;
    left: calc(50% - 350px);
    top: 10%;
  }


   @media only screen and (min-width:992px){
     padding:30px;
    left: calc(50% - 350px);
    top:10%;
  }
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Barlow", sans-serif;
  color: black;
  margin-top: 14px;
  max-width:600px;
  line-height:1.3;
  margin-bottom:30px;

  @media only screen and (min-width: 992px) {
    font-size: 23px;
    margin-top: 28px;
  }
`;

const SubText = styled.textarea`
  width:600px;
  color: black;
  font-size: 16px;
  font-family: 'Red Hat Display', sans-serif;
  background-color:#ededed;
  border-radius:8px;
  border:none;
  padding:15px;
  resize: none;

  &:focus{
  outline: none;
  }

  ::placeholder{
    color: black;
  }

    @media(max-width:768px){
    max-width:calc(100vw - 40px);

  }
`

const Button = styled.button`
  height: 40px;
  border-radius: 10px;
  background-color: #E5B63D;
  border: 0;
  box-shadow: none;
  color:white ;
  font-size: 16px;
  font-weight: bold;
  font-family: "Barlow", sans-serif;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100px;
  margin-top: 50px;

  :disabled {
    background-color:lightgrey;
    color:grey;
    cursor:not-allowed;
    opacity:0.5;
  }


  @media only screen and (min-width: 992px), (min-height: 760px) {
    display: flex;
  }
`;


const BackButton = styled.div`
color:grey;
font-weight:400;
font-size: 14px;
font-family: "Barlow", sans-serif;

border-radius: 10px;
 justify-content: center;
align-items: center;
display: flex;
width:75px;

cursor:pointer;
margin-bottom:30px;
margin-left:-15px;
`
const Controls = styled.div`
display:flex;
justify-content:space-between;
width:100%;

`