import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import S1 from './s1.svg'
import S2 from './s2.svg'

import S3 from './s3.svg'




const Shapes = () =>{


	return (
		<Wrapper>
			<Container>


		
			<Shape2 src={S2}/>
			<Shape3 src={S3}/>
			<Shape1 src={S1}/>


			</Container>
		</Wrapper>
		)
}


export default Shapes



const Container = styled.div`
	width:100vw;
	height:300px;
	display:flex;
	align-items:flex-end;
	position:relative;
	z-index:-1;

	@media(max-width:1300px){
		height:200px;
	}

	@media(max-width:768px){
		height:150px;
	}

	
`


const Shape1 = styled.img`
	width:50%;

	position:absolute;
`

const Shape2 = styled.img`
	position:absolute;
	right:0%;
	width:55%;
	height:80%;
`

const Shape3 = styled.img`
	position:absolute;
	right:0%;
	height:100%;
`

const Wrapper = styled.div`
	position:fixed;
	bottom:0;
	z-index:-1;

`