import React,{useState,useEffect} from 'react';
import styled, { keyframes, css } from 'styled-components';
import '../AuditV2/index.css'


const MultipleChoice = ({ currentQuestionDet, choices,question,isVisible,setSelectedResponse,selectedResponse }) => {

  const [visibleChoices, setVisibleChoices] = useState([]);


  const handleSelection = (choice) => {
    setSelectedResponse(choice);
  };

  useEffect(() => {
    let timeoutIds = [];
    choices.map((choice, index) => {
      const timeoutId = setTimeout(() => {
        setVisibleChoices((prevVisibleChoices) => [...prevVisibleChoices, choice]);
      }, index * 20); // Adjust the delay as needed (300ms here)
      timeoutIds.push(timeoutId);
    });

    return () => {
      timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, [choices]);


  return (
    <ChoiceContainer>
      <Question>{question}</Question>
      <ListContainer>
      {visibleChoices.map((choice, index) => (
        <StyledChoice key={index} delay={index * 100}>
            <ListItem key={index} isSelected={index === selectedResponse}>
            <RadioInput
              id={`rdo_${index}_${currentQuestionDet?.id}`}
              name="choices"
              value={choice.text}
              checked={index === selectedResponse}
              onChange={() => handleSelection(index)}
            />
            <Checkmark className="checkmark" />
            <Choice> {choice.text}</Choice>
           
          </ListItem>
        </StyledChoice>
      ))}
      </ListContainer>
    </ChoiceContainer>
  );
};

export default MultipleChoice;


const ChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width:calc(100% - 40px);
  max-width:600px;
`;

const Choice = styled.div`
margin-left:20px;
`;

const Title = styled.div`
color: #2D70E2;
text-transform: uppercase;
font-size:14px;
font-weight:600;
margin-bottom: 40px;
`

const fadeInMoveDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledChoice = styled.div`
  transform: translateY(-15px);
  opacity:0;
  animation-name: ${fadeInMoveDown};
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  ${({ delay }) =>
    delay &&
    css`
      animation-delay: ${delay}ms;
    `}
`;
const Question = styled.div`
  color:#2A3039;
  font-size: 20px;
  font-weight:600;
  margin-bottom:14px;
  line-height: 1.5;

  @media(max-width:768px){
    font-size: 18px;
  }
`

const ListContainer = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  width:100%;
`;

const ListItem = styled.label`
  display: block;
  position: relative;
  padding: 16px;
  padding-left: 40px;
  cursor: pointer;
  user-select: none;
  width:100%;
  background-color: ${({ isSelected }) => (isSelected ? '#EBF1FD' : 'transparent')};
  color: ${({ isSelected }) => (isSelected ? '#2D70E2' : 'inherit')};
  
  &:hover {
    background-color: ${({ isSelected }) => (isSelected ? '#EBF1FD' : '#f0f0f0')};
  }
`;

const RadioInput = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ .checkmark {
    background-color: #EBF1FD;
    border-color: #2D70E2;
  }

  &:checked ~ .checkmark:after {
    display: block;
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;

  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #2D70E2;
    transform: translate(-50%, -50%);
  }
`;
