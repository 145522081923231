import React, { useEffect, useState } from 'react';
import styled from 'styled-components';


function ErrorList({ errors }) {

    const [errorKeys, setErrorKeys] = useState([])

    useEffect(() => {
        setErrorKeys(false)
        if (errors) {
            setErrorKeys(Object.keys(errors))
        }
    }, [errors])

    if (errors?.length === 0) {
        return null
    }

    return (
        <FeedbackContainer>
            {errorKeys && errorKeys.map((err, i) => {
                return <RowContainer key={i}>
                 <QuestionText>{err}</QuestionText>{": "}<Description>{errors?.[err]}</Description>
                </RowContainer>
            })}

        </FeedbackContainer>
    )
}


export default ErrorList;


const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width:600px;
  font-family: 'Raleway', sans-serif;
  margin-top:20px;

`;

const QuestionText = styled.h2`
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-bottom:26px;
    line-height: 150%;
    color: red;
`;

const RowContainer = styled.div`
    display: flex;
    flex-flow: row wrap;

`
const Description = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
margin-bottom:26px;
line-height: 150%;
color: red;
`
const Emoji = styled.div`
    font-size: 48px;
    margin-bottom:24px;
`

const Bold = styled.span`
    font-weight:600;
    color:#2D70E2;
`
