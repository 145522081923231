
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {Icon} from 'semantic-ui-react'
import Question from './questionTypes/Question';
import MultipleChoice from './questionTypes/MultipleChoice';
import ProgressBar from './Components/ProgressBar';
import CustomButton from './Components/Button';
import Feedback from './questionTypes/Feedback';
import Categories from './questionTypes/Categories';
import Introduction from './Introduction';
import './index.css'
import BulletProgressBar  from './IntroScreens/BulletProgressBar';
import { SlideDirection } from 'constants/animation';
import FollowUp from './chatGpt/FollowUp';
import Submit from './questionTypes/Submit';
import IntroSection from './IntroSection';
import Values from './questionTypes/Values';
import Completed from './questionTypes/Completed'
import Language from './Language'
import Comments from './questionTypes/Comment'
// ... existing components ...
const IntroductionProgress = styled(BulletProgressBar)`

`;



const questions = [
    {
      id: 1,
      type: 'introduction',
      intro_id:1,
      total:4
    },
    {
        id: 2,
        type: 'introduction',
        intro_id:2,
        total:4
      },
      {
        id: 3,
        type: 'introduction',
        intro_id:3,
        total:4
      },
      {
        id: 4,
        type: 'introduction',
        intro_id:4,
        total:4,
        buttonType:'I agree'
      },
    {
      id: 5,
      type: 'categories',
      question: 'Please choose a category:',
      description:'Gender refers to current gender which may be different from sex assigned at birth and may be different from what is indicated on legal documents',
      categories: [
        { id: 'cat1', text: 'Category 1' },
        { id: 'cat2', text: 'Category 2' },
      ],
    },
    {
        id: 6,
        type: 'categories',
        question: 'Thank you for answering these preliminary questions.',
        description:'Now we are going to ask you some questions about your experience with Resulta - Testing resulta. Your experience matters.',
      },
    {
        id: 7,
        type: 'question',
        question: 'On a scale of 1-10, how would you rate your experience?',
      },
    {
        id: 8,
        text: 'Question 1',
        type: 'multipleChoice',
        choices: [
          { id: '1a', text: 'Disagree strongly' },
          { id: '1b', text: 'Disagree moderately' },
          { id: '1c', text: 'Disagree a little' },
          { id: '1d', text: 'Neither agree nor disagree' },
          { id: '1e', text: 'Agree a little' },
          { id: '1f', text: 'Agree moderately' },
          { id: '1g', text: 'Agree strongly' },
        ],
      },
      // {
      //   id: 9,
      //   type: 'feedback',
      //   direction:'min',
      //   question: 'It’s safe to take risks and be bold when solving problems here.',
      //   score:1,
      // },
      // {
      //   id: 10,
      //   type: 'follow_up',
      //   direction:'min',
      //   response: 'I do feel safe to take risks and be bold when solving problems here.',
      //   question: 'It’s safe to take risks and be bold when solving problems here.',
      // },
      // {
      //   id: 11,
      //   type: 'comment',
      //   question: 'It’s safe to take risks and be bold when solving problems here.',
      // },
      {
        id: 12,
        type: 'submit',
        buttonType:'Submit',
      },
      {
        id: 13,
        type: 'introSection',
        emoji:'📝',
        title: 'Getting to know you',
        text:['In an effort to better contextualize the feedback we have received from you, we’d like to ask you a few questions about your personality. For the following 10 questions please rate the extent to which you agree or disagree with the statement.','Your data is always kept private, and no one can access your individual responses to these questions.'],
        buttonType:'Begin',
      },
      {
        id: 14,
        type: 'values',
        statement: 'Using only one word, finish the sentence with whatever comes to your mind:',
        question:'I would describe this organization as a culture of...'
      },
      {
        id: 15,
        type: 'completed',
      },
    // Add slider, multipleChoice, and feedback questions as needed
  ];


 

  const Survey = () => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [direction, setDirection] = useState('');
    const [clicked,setClicked] = useState(0)
    const [totalIntro, setTotalIntro] = useState(questions.filter(f=>f.type=='introduction').length);
    const [isVisible, setIsVisible] = useState(true);
    const [addResponse,setResponse] = useState(0)
    const [surveyFinished,setSurveyFinished] = useState(false)

    const isIntroductionActive = () => {
        return questions[currentQuestion].type === 'introduction';
      };

    const handleNext = () => {
      if (currentQuestion < questions.length - 1 ) {
        setDirection('next'); 
        setClicked(clicked + 1)
        if(currentQuestion ==questions.length-2){
          setSurveyFinished(true)
        }
      }
    };

    useEffect(()=>{
        if(direction === 'next'){
            setCurrentQuestion(currentQuestion + 1);
        }
        if(direction === 'back'){
            setCurrentQuestion(currentQuestion - 1);
        }
    },[clicked])
  
    const handleBack = () => {
      if (currentQuestion > 0) {
        setDirection('back');
        setClicked(clicked -1)
        
      }
    };

    const onEntered = () => {
      setIsVisible(true);
    };
  
    const onExited = () => {
      setIsVisible(false);
    };

    const renderModule = (question) => {
        switch (question.type) {
          case 'introduction':
            return <Introduction id={question.intro_id} currentQuestion={currentQuestion} total={question.total}/>;
          case 'categories':
            return <Categories question={question.question} categories={question.categories} description={question.description}/>;
          case 'question':
                return <Question question={question.question} />;
          case 'multipleChoice':
            return <MultipleChoice choices={question.choices} question={question.text} isVisible={!isVisible}/>;
          case 'feedback':
            return <Feedback data={question} />;
          case 'follow_up':
            return <FollowUp data={question} />;
          case 'introSection':
            return <IntroSection data={question}/>;
          case 'comment':
              return <Comments question={question.question}/>;
          case 'values':
            return <Values statement={question.statement} question={question.question}/>;
          case 'completed':
            return <Completed/>;
          case 'submit':
              return <Submit/>;
          default:
            return null;
        }
      };

      console.log("currentQuestion", currentQuestion)
      const renderButtons = (question) =>{
        if(surveyFinished){
          return 
        }
        if(isIntroductionActive()){
          return (
          <>
          <IntroductionProgress current={currentQuestion} total={questions[currentQuestion].total} />
          <CustomButton onClick={handleNext}>{question?.buttonType?question?.buttonType:'Next'}</CustomButton>
          </>)
        }

        // if(question?.buttonType){

        //   return(
        //     <>
        //     <Button onClick={handleBack} type={'back'}>Back</Button>
        //     <Button onClick={handleNext}>{question?.buttonType}</Button>
        //     </>
        //   ) 
        // }


        return (
          <>
          <CustomButton onClick={handleBack} type={'back'}>Back oNE</CustomButton>
          <CustomButton onClick={handleNext} disabled={false}>Next TWO</CustomButton>
          </>
        )
      }
  

    return (
        <SurveyContainer>
            {!isIntroductionActive() && !surveyFinished && <ProgressBar currentQuestion={currentQuestion - totalIntro} totalQuestions={questions.length - totalIntro} /> }
            {isIntroductionActive() && currentQuestion!=0 && <Back onClick={handleBack}><Icon name="chevron left" /> </Back>}
            <Language/>
          <TransitionGroup>
          <CSSTransition
                key={questions[currentQuestion].id}
                classNames={direction === 'next' ? 'slide' : 'slide-back'}
                timeout={{ enter: 500, exit: 500 }}
                onEntered={onEntered}
                onExited={onExited}
            >
               <ModuleContainer className="module-wrapper">{renderModule(questions[currentQuestion])}</ModuleContainer> 
            </CSSTransition>
           
          </TransitionGroup>
          



            <ButtonContainer>
              {renderButtons(questions[currentQuestion])}                 
            </ButtonContainer>

        </SurveyContainer>
      );
    };
    
    export default Survey;
  

  // ...
const SurveyContainer = styled.div`
width: 100%;
min-height: 100vh;
display: flex;
flex-direction: column;
background-color: #f8f9fa;
position:relative;
font-family: 'Raleway', sans-serif;
overflow-x:hidden;
`;

const SurveyContent = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
// ...

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f8f8f8;
  z-index: 2;
`;


// ...


const ModuleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:20px;
`;

const Back = styled.div`
    position:absolute;
    left:28px;
    top:20px;
    cursor: pointer;
    font-size:20px;
    width:50px;
    height:50px;
    z-index:2;
    color:#2A3039;
`